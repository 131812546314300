import { useEffect, useRef, useState } from "react";
import { IPostMedia } from "../IPostMedia";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import PostImageUploader from "../PostImageUploader";
import { useTranslation } from "react-i18next";
import PostPhotoCroper from "../PostPhotoCroper/PostPhotoCroper";

import PostAudio from "../PostAudio/PostAudio";

import AudioThumbnailImage from "../../../../images/audio.jpeg";
import VideoThumbnailImage from "../../../../images/video.png";
import PostVideo from "../PostVideo/PostVideo";

import { capture } from "../../../../utils/cropImage";

import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";

export interface IPostCarousel {
  items: IPostMedia[];
  updatedItems: any;
  profileId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    imageContainer: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 500,
      margin: "0 auto",
    },
    margin: {
      margin: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    deleteContainer: {
      display: "flex",
      // position: "absolute",
      // top: 25,
      // right: 5,
    },
    addContainer: {
      display: "flex",
      // position: "absolute",
      // bottom: -10,
      // right: 5,
    },
    thumbContainer: {
      display: "flex",
      // position: "absolute",
      // top: 60,
      // right: 5,
    },
    actionButtonsContainer: {
      display: "flex",
    },
  })
);

const PostCarousel = ({ items, updatedItems, profileId }: IPostCarousel) => {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [images, setImages] = useState(items ? toImages(items) : []);
  const [medias, setMedias] = useState(items);
  const galleryRef = useRef<any>(null);
  const [openAddPhoto, setOpenAddPhoto] = useState(false);
  const handleClose = () => {
    setOpenAddPhoto(false);
  };
  const [snapshotAtTime, setSnapshotAtTime] = useState(0);
  const [videoThumbnail, setVideoThumbnail] = useState("");
  const deleteTooltipText = t("Click here to delete this media");
  const addTooltipText = t("Click here to add new medias");

  const getCurrent = () => {
    return galleryRef?.current?.getCurrentIndex();
  };

  const deleteImage = () => {
    const current = getCurrent();

    const image = images[current].original;
    const newImages = images.filter((i) => i.original !== image);

    const photo = medias[current].src;
    const newPhotos = medias.filter((i) => i.src !== photo);

    setImages(newImages);
    setMedias(newPhotos);

    galleryRef?.current?.slideToIndex(current === 0 ? current : current - 1);
  };

  const handleAddPhoto = () => {
    setOpenAddPhoto(true);
  };

  const setSuccessPhotos = (postmedias: IPostMedia[]) => {
    console.debug("PostCarousel setSuccessPhotos", postmedias);

    updatedItems(postmedias);
    setMedias(postmedias);
    setImages(toImages(postmedias));
  };

  useEffect(() => {
    console.debug("useEffect", images.length);
  }, [images, medias]);

  const updateImage = (imageUrl, croppedAreaPixels, zoom, crop) => {
    const result = medias;
    const photo = medias.find((photo) => photo.src === imageUrl);
    const findIndex = medias.findIndex((photo) => photo.src === imageUrl);

    if (photo) {
      photo.crop = crop;
      photo.croppedAreaPixels = croppedAreaPixels;
      photo.zoom = zoom;
      result.splice(findIndex, 1, photo);
    }

    //console.debug("PostCarousel", imageUrl, croppedAreaPixels, zoom, crop, photo, result);

    updatedItems(result);
  };

  const handleUpdateThumb = (video: any, photo: IPostMedia) => {
    console.debug("handleUpdateThumb", photo);
    capture(video)
      .then((thumbnail) => {
        const newPhoto = photo;
        newPhoto.croppedUrl = thumbnail;
        const index = medias.findIndex((p) => p.src === photo.src);

        const result = medias;
        result.splice(index, 1, newPhoto);

        updatedItems(result);
        setMedias(result);
        setImages(toImages(result));
      })
      .catch((error) => console.error(error));
  };

  const renderItem = (item: ReactImageGalleryItem) => {
    const media = medias.find((photo) => photo.src === item.original);

    if (media?.type === "image")
      return <PostPhotoCroper image={media} updateImage={updateImage} />;

    if (media?.type === "audio") return <PostAudio photo={media} />;

    if (media?.type === "video")
      return (
        <PostVideo media={media} updateThumb={handleUpdateThumb} edit={true} />
      );
  };

  return (
    <div className={classes.root}>
      <div className={classes.imageContainer}>
        <ImageGallery
          ref={galleryRef}
          items={images}
          showIndex
          disableSwipe={true}
          showThumbnails={true}
          showPlayButton={false}
          showFullscreenButton={false}
          renderItem={renderItem}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
        />
      </div>

      <div className={classes.actionButtonsContainer}>
        <div className={classes.deleteContainer}>
          {images.length > 1 && (
            <Tooltip title={deleteTooltipText}>
              <IconButton
                aria-label="delete"
                className={classes.margin}
                size="medium"
                onClick={deleteImage}>
                <DeleteOutlineIcon fontSize="inherit" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        <div className={classes.addContainer}>
          {images.length < 5 && (
            <div>
              <Tooltip title={addTooltipText}>
                <IconButton
                  aria-label="delete"
                  className={classes.margin}
                  size="medium"
                  onClick={handleAddPhoto}>
                  <AddCircleOutlineIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
              {openAddPhoto && (
                <PostImageUploader
                  title={t("New Post")}
                  handleClose={handleClose}
                  open={openAddPhoto}
                  setSuccess={setSuccessPhotos}
                  profileId={profileId}
                  existingPhotos={medias}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const renderLeftNav = (onClick, disabled) => {
  return (
    <button
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}>
      <ChevronLeftRoundedIcon fontSize="large" />
    </button>
  );
};

const renderRightNav = (onClick, disabled) => {
  return (
    <button
      className="image-gallery-icon image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}>
      <ChevronRightRoundedIcon fontSize="large" />
    </button>
  );
};

const getThumbnail = (photo: IPostMedia) => {
  switch (photo.type) {
    case "audio":
      return AudioThumbnailImage;
    case "video":
      return photo.croppedUrl ? photo.croppedUrl : VideoThumbnailImage;
    case "image":
    default:
      return photo.src;
  }
};

const toImages = (items: IPostMedia[]) => {
  return items.map((item) => {
    return {
      original: item.src,
      thumbnail: getThumbnail(item),
      originalHeight: 400,
      thumbnailHeight: 50,
      thumbnailWidth: 50,
      ...item,
    };
  });
};

export default PostCarousel;

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from 'firebase/app'
import "firebase/database"
import "firebase/firestore";
import "firebase/functions";
import 'firebase/analytics'

import * as fireorm from 'fireorm';


export const config = {
    apiKey: process.env.REACT_APP_API_KEY ? process.env.REACT_APP_API_KEY : '',
    authDomain: process.env.REACT_APP_AUTHDOMAIN ? process.env.REACT_APP_AUTHDOMAIN : '',
    databaseURL: process.env.REACT_APP_DB ? process.env.REACT_APP_DB : '',
    projectId: process.env.REACT_APP_PID ? process.env.REACT_APP_PID : '',
    storageBucket: process.env.REACT_APP_SB ? process.env.REACT_APP_SB : '',
    messagingSenderId: process.env.REACT_APP_SID ? process.env.REACT_APP_SID : '',
    appId: process.env.REACT_APP_APPID ? process.env.REACT_APP_APPID : '',
    measurementId: process.env.REACT_APP_MID ? process.env.REACT_APP_MID : ''
};

export const app = firebase.initializeApp(config);

export default firebase;

export const db = firebase.firestore();
db.settings({ ignoreUndefinedProperties: true })

fireorm.initialize(db);

export const logoutFirebase = () => {
    console.debug('logoutFirebase');
    return firebase.auth().signOut();
}

export const functions = process.env.REACT_APP_FUNCTIONS_REGION ? app.functions(process.env.REACT_APP_FUNCTIONS_REGION) : app.functions();

//REACT_APP_FUNCTIONS_REGION="europe-west1"

export const analytics = firebase.analytics();

export const getClaimsLevel = () => {
    return firebase.auth().currentUser?.getIdTokenResult()
        .then((idTokenResult) => {
            return idTokenResult.claims.level;
        })
        .catch(error => {
            return Promise.reject(error);
        })
}


import { makeStyles, Theme, createStyles, Divider, Grid, IconButton, Typography, Avatar } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Profile, { Agent } from '../../../core/entities/Profile';
import { useCurrentProfileContext } from '../../../CurrentProfileContext';
import styles from './ProfileAgents.module.scss';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { useTranslation } from 'react-i18next';
import ProfileController from '../ProfileController';
import { getAvatar } from '../../../utils/utils';
import { Link } from 'react-router-dom';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 10,
      marginTop: 10,
    },
    container: {
      display: 'flex',
    },
    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: 5,
    },
    avatarFundo: {
      width: theme.spacing(5),
      height: theme.spacing(5),
      backgroundColor: "white",
      borderRadius: 5,
    },
    agentName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      fontWeight: 600,
    }
  }),
);


const ProfileAgents = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { error, loading, profile, isCurrent } = useCurrentProfileContext();
  const [agents, setAgents] = useState<Profile[]>([]);

  const profileController = new ProfileController();

  const getAgents = async () => {
    if (profile) {
      try {
        const getAgentsResult = await profileController.getAgents(profile.id)
        setAgents(getAgentsResult);
      } catch (getAgentsError) {
        console.error(getAgentsError);
      };
    }
  }

  useEffect(() => {


    getAgents();

  }, [profile]);

  return (
    <div className={classes.root}>
      {/* <Grid container direction="row" justifyContent="flex-start" alignItems="center"        > */}
      {(agents && agents.length > 0) &&
        (
          <Grid container direction="row" justifyContent="center" alignItems="center"        >
            {/* <Grid item>
              <Typography variant="body2" display='inline'>
                {t("Managed by")}&nbsp;
              </Typography>
            </Grid> */}

            <Grid item>
              <Avatar alt="Avatar" className={classes.avatarFundo} variant="square">
                <Avatar
                  variant="square"
                  component={Link}
                  to={`/${agents[0]?.nickname}`}
                  alt="Avatar"
                  
                  src={
                    agents[0]?.profileImageUrl
                      ? agents[0]?.profileImageUrl
                      : getAvatar(agents[0]?.name)
                  }
                  className={classes.avatar}
                />
              </Avatar>
            </Grid>

            <Grid item>
              <Typography variant="body2" noWrap component={Link} to={`/${agents[0]?.nickname}`} display='inline' className={classes.agentName}>
                {agents[0]?.name}
              </Typography>
            </Grid>
          </Grid>
        )
      }

      {/* {
          agents.map(agent => {

            return (<Grid item>

              {agent &&
                <Grid container direction="row" justifyContent="flex-start" alignItems="center"        >

                  <Grid item key={agent?.id}>
                    <Avatar alt="Avatar" className={classes.avatarFundo} >

                      <Avatar
                        component={Link}
                        to={`/${agent?.nickname}`}
                        alt="Avatar"
                        src={
                          agent?.profileImageUrl
                            ? agent?.profileImageUrl
                            : getAvatar(agent?.name)
                        }
                        className={classes.avatar}
                      />
                    </Avatar>
                  </Grid>
                  <Grid item style={{ overflow: "hidden", textOverflow: "ellipsis", width: '11rem' }}>
                    <Typography variant="body1" noWrap component={Link} to={`/${agent?.nickname}`}>
                      {agent?.name}
                    </Typography>

                  </Grid>
                </Grid>
              }

            </Grid>);

          })
        } */}

      {/* </Grid > */}

    </div >
  )
};

export default ProfileAgents;

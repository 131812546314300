import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import firebase from "firebase";
import { useTranslation } from "react-i18next";

interface VerifyEmailDialogProps {
  open: boolean;
  onClose: () => void;
  title?: string;
  children: string;
}

export default function VerifyEmailDialog({
  open,
  onClose,
  
  children,
}: VerifyEmailDialogProps) {
  const { t } = useTranslation();
  
  const requestVerification = async () => {
    await firebase.auth().currentUser?.sendEmailVerification();
    onClose();
  };
  //"Verify your email"

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle id="alert-dialog-title">{t("Verify your email")}</DialogTitle>
      <DialogContent>
        <DialogContentText id="modal-modal-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Cancel")}</Button>
        <Button
          onClick={requestVerification}
          color="primary"
          variant="contained"
          disableElevation
          autoFocus>
          {t("Request verification email")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Collapse,
  IconButton,
  Button,
  DialogActions,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import { IPostMedia } from "./IPostMedia";
import { DropzoneArea } from "material-ui-dropzone";
import { render } from "@testing-library/react";
import ReactDOM from "react-dom";
import {
  Youtube,
  Instagram,
  Facebook,
  Vimeo,
  Twitter,
} from "reactjs-social-embed";
import { Console } from "console";
import axios from "axios";

interface IPostDropzoneProps {
  onChange: any;
  maxFiles: number;
  onDelete: any;
}

function PostDropzone({ onChange, onDelete }: IPostDropzoneProps) {
  const [fileNames, setFileNames] = useState([]);
  const { t } = useTranslation();

  const handleDrop = (acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name));

    onChange(acceptedFiles);
  };

  const handleDelete = (file) => {
    onDelete(file);
  };

  return (
    <DropzoneArea
      // acceptedFiles={["image/*", "audio/*", "video/*"]}
      acceptedFiles={["image/*"]}
      dropzoneText={t("Drag and drop an image here or click")}
      onChange={handleDrop}
      onDelete={handleDelete}
      filesLimit={5}
      maxFileSize={10000000}
    />
  );
}

interface IPostImageUploaderProps {
  open: any;
  handleClose: any;
  setSuccess: any;
  profileId: string;
  title: string;
  existingPhotos?: IPostMedia[];
}
interface MediaVideoUrl {
  type: string;
  id: string;
}

const PostImageUploader = ({
  open,
  handleClose,
  setSuccess,
  title,
  existingPhotos,
}: IPostImageUploaderProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [videoUrl, setVideoUrl] = useState<MediaVideoUrl>({} as MediaVideoUrl);

  const [files, setFiles] = useState<File[]>([]);
  const [imageUrls, setImageUrls] = useState<string[]>(
    existingPhotos
      ? existingPhotos.map((p) => {
          return p.src;
        })
      : []
  );
  const [photos, setPhotos] = useState<IPostMedia[]>(
    existingPhotos ? existingPhotos : []
  );
  const [video, setVideo] = useState<IPostMedia>();
  const [maxFiles, setMaxFiles] = useState(
    existingPhotos ? 5 - existingPhotos.length : 5
  );

  const [medias, setMedias] = useState<IPostMedia[]>([]);

  useEffect(() => {
    const m = existingPhotos ? existingPhotos.concat(photos) : photos;
    setMedias(m);
  }, [photos]);

  const onFilesDroped = (acceptedFiles: File[]) => {
    setLoading(true);
    setFiles(acceptedFiles);
    let images = acceptedFiles.map((file) => {
      return URL.createObjectURL(file);
    });

    setImageUrls(images);

    const photoList = images.map((id, i) => {
      return {
        src: id,
        fileName: acceptedFiles[i].name,
        type: acceptedFiles[i].type.split("/")[0],
        width: 1,
        height: 1,
        croppedUrl: "",
      };
    });

    setPhotos(photoList);
    setLoading(false);
  };

  const onFileDelete = (file: File) => {
    setFiles(files.filter((f) => f.name !== file.name));
    setPhotos(photos.filter((p) => p.fileName !== file.name));
  };

  const handleNext = () => {
    const allMedias = video ? medias.concat([video]) : medias;
    if (allMedias.length > 0) {
      setSuccess(allMedias);
      handleClose();
    }
  };

  const newLink = async (event) => {
    setErrorMessage("");
    const acceptPlatforms = [
      "youtube",
      "facebook",
      "fb",
      "instagram",
      "vimeo",
      "twitter",
    ];

    let linkConfirm = event.target.value;
    if (linkConfirm.includes("/shorts/")) {
      setErrorMessage(t("Shorts are not available for upload"));
      return;
    }
    let confirmSrchPoint = linkConfirm.search("\\.");

    let thumb = "";

    type VimeoResponse = {
      id?: number;
      title?: string;
      description?: string;
      url?: string;
      thumbnail_small?: string;
      thumbnail_medium?: string;
      thumbnail_large?: string;
    };

    const getVimeoThumbnail = async (id) => {
      const res = await axios.get<VimeoResponse>(
        `https://vimeo.com/api/v2/video/${id}.json`
      );
      if (res.status !== 200) return "";
      if (res.data) {
        const data = res.data[0];
        if (data.thumbnail_large) {
          return data.thumbnail_large;
        } else if (data.thumbnail_medium) {
          return data.thumbnail_medium;
        } else if (data.thumbnail_small) {
          return data.thumbnail_small;
        }
      }
      return "";
    };

    const getYouTubeThumbnail = (id) => {
      return `https://i.ytimg.com/vi/${id}/0.jpg`; // Resolucao baixa, sempre funciona
      // return `https://i.ytimg.com/vi/${id}/maxresdefault.jpg`;  // Resolucao alta, apenas thumbs customizadas
    };

    const getYoutubeVideoId = (url: string): string => {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length === 11 ? match[7] : "";
    };

    if (confirmSrchPoint != -1) {
      var linkPlataform = linkConfirm.split(".");
      var verificCase = "";
      var plataformConfirm = 0;
      var idVideo: string[] = [];

      //EDIT LINK-  NO 'www.'
      if (linkPlataform[0] == "https://twitter") {
        var editTwitter = linkPlataform[0].split("//");
        verificCase = editTwitter[1];
        plataformConfirm = acceptPlatforms.indexOf(verificCase, 0);
      } else if (linkPlataform[0] == "https://fb") {
        var editFb = linkPlataform[0].split("//");
        verificCase = editFb[1];
        plataformConfirm = acceptPlatforms.indexOf(verificCase, 0);
      } else if (linkPlataform[0] == "https://vimeo") {
        var editVimeo = linkPlataform[0].split("//");
        verificCase = editVimeo[1];
        plataformConfirm = acceptPlatforms.indexOf(verificCase, 0);
      } else if (linkPlataform[0].includes("yout")) {
        verificCase = "youtube";
        plataformConfirm = acceptPlatforms.indexOf(verificCase, 0);
      } else {
        verificCase = linkPlataform[1];
        plataformConfirm = acceptPlatforms.indexOf(linkPlataform[1], 0);
      }

      if (plataformConfirm >= 0 && linkPlataform[2] !== "") {
        switch (verificCase) {
          case "youtube":
            //SEARCH ID VIDEO https://www.youtube.com/watch?v=UhaX777if4o&ab_channel=RichardRasmussen
            const videoId = getYoutubeVideoId(linkConfirm);
            thumb = getYouTubeThumbnail(videoId);
            const elementYT = (
              <Youtube
                width="30%"
                height="200px"
                parentClass="p-container"
                iframeClass="v-container"
                id={idVideo[0]}
              />
            );
            ReactDOM.render(elementYT, document.getElementById("previewVideo"));

            break;
          case "facebook":
            //SEARCH ID VIDEO [ALL LINK] https://www.facebook.com/postmalone/videos/908295402959230/
            const elementFB = (
              <Facebook
                type="video"
                width="100%"
                height="680px"
                show_text={true}
                id={linkConfirm}
              />
            );
            ReactDOM.render(elementFB, document.getElementById("previewVideo"));
            break;
          case "fb":
            //SEARCH ID VIDEO [?ALL LINK?] https://fb.watch/9ath6ZMk4R/
            const elementFBW = (
              <Facebook
                type="video"
                width="100%"
                height="680px"
                show_text={true}
                id={linkConfirm}
              />
            );
            ReactDOM.render(
              elementFBW,
              document.getElementById("previewVideo")
            );
            break;
          case "twitter":
            //SEARCH ID VIDEO https://twitter.com/i/status/1458132336172752897
            idVideo = linkConfirm.split("/status/");

            const elementTW = <Twitter id={idVideo[1]} />;
            ReactDOM.render(elementTW, document.getElementById("previewVideo"));
            break;
          case "vimeo":
            //SEARCH ID VIDEO https://vimeo.com/639812245
            idVideo = linkConfirm.split("/");

            thumb = await getVimeoThumbnail(idVideo[3]); // Esse await doeu.
            const elmentVM = (
              <Vimeo width="30%" height="200px" id={idVideo[3]} />
            );
            ReactDOM.render(elmentVM, document.getElementById("previewVideo"));
            break;
          default:
            console.log("erro");
            break;
        }
      } else {
        console.log(
          "Essa plataforma nao esta cadastrada no nosso sistema, utilize plataformas de videos como youtube, facebook. Saiba mais"
        );
      }
    }

    setVideo({
      src: linkConfirm,
      fileName: linkConfirm,
      type: "video",
      width: 1,
      height: 1,
      croppedUrl: thumb || "",
      thumbUrl: thumb || "",
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      disableEscapeKeyDown
      disableBackdropClick>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Collapse in={errorMessage !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {errorMessage}
          </Alert>
        </Collapse>

        <PostDropzone
          onChange={onFilesDroped}
          maxFiles={maxFiles}
          onDelete={onFileDelete}
        />

        <Typography
          variant="subtitle1"
          color="textPrimary"
          align="center"
          paragraph>
          {t("OR")}
        </Typography>

        <div
          style={{
            paddingBottom: "1.333%",
            paddingTop: "1.333%",
          }}>
          <input
            type="text"
            id="link"
            placeholder={t("Paste your video link")}
            onChange={newLink}
            style={{
              width: "100%",
              height: "30px",
              outline: "none",
              backgroundColor: "#1f1f1f1",
              borderRadius: 5,
            }}
          />
        </div>
        <div id="previewVideo" />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit">
          {t("Cancel")}
        </Button>
        <Button
          onClick={handleNext}
          color="inherit"
          disabled={medias.length === 0 && !video}>
          {t("Next")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PostImageUploader;

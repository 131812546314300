import firebase from "firebase";
import { getRepository } from "fireorm";
import Invite, { Accepted } from "../../core/entities/Invite";
import Profile from "../../core/entities/Profile";
import { IRepository } from "../../core/interfaces/IRepository";
import { db } from "./firebasecofig";



export default class InviteRepository implements IRepository<Invite>{
    repository = getRepository(Invite);
    inviteRef = db.collection('Invites');

    getByProfile(profileId: string): Promise<Invite[]> {
        return this.repository.whereEqualTo(x => x.profileId, profileId).find()
    }

    expire(inviteId: string): Promise<boolean> {

        return this.inviteRef.doc(inviteId)
            .update({
                expired: true
            })
            .then(() => {
                return true;
            })
            .catch(error => {
                return Promise.reject(error);
            });

    }


    reactivate(inviteId: string): Promise<boolean> {

        return this.inviteRef.doc(inviteId)
            .update({
                expired: false
            })
            .then(() => {
                return true;
            })
            .catch(error => {
                return Promise.reject(error);
            });

    }

    getAcceptedInvitesByInvite(inviteId: string): Promise<Accepted[]> {

        return this.inviteRef.doc(inviteId)
            .collection('Accepted')
            .orderBy('date', 'desc')
            .get()
            .then(docs => {
                const result: Accepted[] = [];

                docs.forEach(doc => {

                    let id = doc.id;

                    const {
                        profile,
                        date,
                    } = doc.data();

                    result.push({
                        id,
                        profile,
                        date,
                    });

                });

                return Promise.resolve(result);
            })
            .catch(error => { return Promise.reject(error); });

    }

    accept(inviteId: string, profile: Profile): Promise<boolean> {

        const profileObj = Object.assign({}, profile);

        return this.inviteRef
            .doc(inviteId)
            .collection('Accepted')
            .doc(profile.id)
            .set({
                date: firebase.firestore.FieldValue.serverTimestamp(),
                profile: profileObj
            })
            .then(() => {
                return true;
            })
            .catch(error => {
                return Promise.reject(error);
            });
    }

    create(entity: Invite): Promise<Invite> {
        entity.sentDate = firebase.firestore.FieldValue.serverTimestamp();
        
        return this.repository.create(entity);
    }
    update(entity: Invite): Promise<Invite> {
        return this.repository.update(entity);
    }
    delete(id: string): Promise<void> {
        return this.repository.delete(id);
    }
    getById(id: string): Promise<Invite> {
        return this.repository.findById(id);
    }




}
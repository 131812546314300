import { FormControl, InputLabel, makeStyles, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { availableLanguages } from "../../../i18n";


const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const SettingsGeneralLanguageComponent = () => {

    const classes = useStyles();
    const { t, i18n } = useTranslation();
    const [language, setLanguage] = useState('');

    useEffect(() => {
        setLanguage(i18n.language);
    }, []);

    const handleChange = (event) => {
        setLanguage(event.target.value);
        changeLanguage(event.target.value);

    };

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng)
            .then(t => {
                console.debug("Language changed");
            })
            .catch(error => {
                console.error(error);
            });

    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="select-language-label">{t("Language")}</InputLabel>
            <Select
                labelId="select-language-label"
                id="select-language"
                value={language}
                onChange={handleChange}
            >
                {availableLanguages.map(lng => {
                    return (<MenuItem value={lng.value}>{t(lng.name)}</MenuItem>)
                })}

            </Select>
        </FormControl>
    );
}

export default SettingsGeneralLanguageComponent;
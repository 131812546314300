import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import ChipInput from 'material-ui-chip-input';
import { makeStyles, MenuItem, Paper, Theme } from '@material-ui/core';

import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import SkillRepository from '../../../../../infra/firebase/SkillRepository';
import Skill from '../../../../../core/entities/Skill';
import ChipData from '../ChipData';

const suggestions = [
  { text: 'Afghanistan', value: 'Afghanistan', },
  { text: 'Aland Islands', value: 'Aland Islands', },
  { text: 'Albania', value: 'Albania', },
  { text: 'Algeria', value: 'Algeria', },
  { text: 'American Samoa', value: 'American Samoa', },
  { text: 'Andorra', value: 'Andorra', },
  { text: 'Angola', value: 'Angola', },
  { text: 'Anguilla', value: 'Anguilla', },
  { text: 'Antarctica', value: 'Antarctica', },
  { text: 'Antigua and Barbuda', value: 'Antigua and Barbuda', },
  { text: 'Argentina', value: 'Argentina', },
  { text: 'Armenia', value: 'Armenia', },
  { text: 'Aruba', value: 'Aruba', },
  { text: 'Australia', value: 'Australia', },
  { text: 'Austria', value: 'Austria', },
  { text: 'Azerbaijan', value: 'Azerbaijan', },
  { text: 'Bahamas', value: 'Bahamas', },
  { text: 'Bahrain', value: 'Bahrain', },
  { text: 'Bangladesh', value: 'Bangladesh', },
  { text: 'Barbados', value: 'Barbados', },
  { text: 'Belarus', value: 'Belarus', },
  { text: 'Belgium', value: 'Belgium', },
  { text: 'Belize', value: 'Belize', },
  { text: 'Benin', value: 'Benin', },
  { text: 'Bermuda', value: 'Bermuda', },
  { text: 'Bhutan', value: 'Bhutan', },
  { text: 'Bolivia, Plurinational State of', value: 'Bolivia, Plurinational State of', },
  { text: 'Bonaire, Sint Eustatius and Saba', value: 'Bonaire, Sint Eustatius and Saba', },
  { text: 'Bosnia and Herzegovina', value: 'Bosnia and Herzegovina', },
  { text: 'Botswana', value: 'Botswana', },
  { text: 'Bouvet Island', value: 'Bouvet Island', },
  { text: 'Brazil', value: 'Brazil', },
  { text: 'British Indian Ocean Territory', value: 'British Indian Ocean Territory', },
  { text: 'Brunei Darussalam', value: 'Brunei Darussalam' },
]

function getSuggestions(value) {
  const inputValue = value.value.trim().toLowerCase()
  const inputLength = inputValue.length
  let count = 0

  return inputLength === 0
    ? []
    : suggestions.filter(suggestion => {
      const keep =
        count < 5 && suggestion.text.toLowerCase().slice(0, inputLength) === inputValue

      if (keep) {
        count += 1
      }

      return keep
    })
}

function renderInput(inputProps) {
  const { value, onChange, chips, ref, ...other } = inputProps

  return (
    <ChipInput
      fullWidthInput
      label='Tags'
      allowDuplicates={false}
      fullWidth
      clearInputValueOnChange
      onUpdateInput={onChange}
      value={chips}
      inputRef={ref}
      dataSourceConfig={{
        text: 'text',
        value: 'value'
      }}
      {...other}
    />
  )
}

function renderSuggestion(suggestion, { query, isHighlighted }) {
  const matches = match(suggestion.text, query)
  const parts = parse(suggestion.text, matches)

  return (
    <MenuItem
      style={{ zIndex: 1500 }}
      selected={isHighlighted}
      component='div'
      onMouseDown={(e) => e.preventDefault()} // prevent the click causing the input to be blurred
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>
              {part.text}
            </span>
          )
        })}
      </div>
    </MenuItem>
  )
}

function renderSuggestionsContainer(options) {
  const { containerProps, children } = options

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  )
}

function getSuggestionValue(suggestion) {
  return suggestion.text;
}

const useFormStyles = makeStyles((theme: Theme) => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
    left: 0,
    right: 0,
    zIndex: 1
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '100%'
  }
}));

export interface ISkillsChipsAutoSuggestProps {
  categoryId: string;
  addChip: any;
  deleteChip: any;
  selected: ChipData[];
}

const SkillsChipsAutoSuggest = ({ categoryId, addChip, deleteChip, selected }: ISkillsChipsAutoSuggestProps) => {

  //console.debug('SkillsChipsAutoSuggest', selected);

  const classes = useFormStyles();

  const [suggestions, setSuggestions] = useState<ChipData[]>([]);
  const [value, setValue] = useState<ChipData[]>([]);
  const [textFieldInput, setTextFieldInput] = useState('');
  const repository = new SkillRepository();

  const handleSuggestionsFetchRequested = (value: string) => {

    setSuggestions(getSuggestions(value));

  }

  useEffect(() => {    
    setValue(selected);
  }, [selected]);

  const handleSuggestionsClearRequested = () => {
    setSuggestions([]);
  }

  const handletextFieldInputChange = (event, { newValue }) => {
    setTextFieldInput(newValue);
  };

  const handleAddChip = (chip: any) => {
    let c = suggestions.filter(s => s.text === chip);
    let result = c.length > 0 ? c[0] : chip;
    addChip(result);
  }

  const handleDeleteChip = (chip: any, index: number): any => {
    let c = suggestions.filter(s => s.text === chip);
    let result = c.length > 0 ? { text: c[0], value: c[0] } : chip;
    deleteChip(result, index);
  }


  return (
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={renderInput}
        suggestions={suggestions}
        onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(e, { suggestionValue }) => { handleAddChip(suggestionValue); e.preventDefault() }}
        focusInputOnSuggestionClick
        inputProps={{
          chips: value,
          onChange: handletextFieldInputChange,
          value: textFieldInput,
          onAdd: (chip) => handleAddChip(chip),
          onDelete: (chip, index) => handleDeleteChip(chip, index),

        }}
      />
  )
};

export default SkillsChipsAutoSuggest;


import { Chip, makeStyles, Theme } from "@material-ui/core";

const useChipStyles = makeStyles((theme: Theme) => ({
  chip: {
    margin: theme.spacing(1),
  },
  selected: {
    margin: theme.spacing(1),
    color: "#FFF",
  },
}));

interface IProposalChipProps {
  label: string;
  handleClick: any;
  selected: boolean;
}

const ProposalChip = ({ label, handleClick, selected }: IProposalChipProps) => {
  const classes = useChipStyles();

  return (
    <Chip
      className={selected ? classes.selected : classes.chip}
      color="secondary"
      variant={selected ? "default" : "outlined"}
      label={label}
      onClick={handleClick}
    />
  );
};

export default ProposalChip;

import {
  Container,
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useStyles } from "../../Theme";
import styles from "./Settings.module.scss";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import SettingsMenu from "./SettingsMenu/SettingsMenu";
import SettingsGeneral from "./SettingsGeneral/SettingsGeneral";
import {
  Redirect,
  Route,
  Switch,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import SettingsInvites from "./SettingsInvites/SettingsInvites";
import SettingsProfileComponent from "./SettingsProfileComponent/SettingsProfileComponent";
import SettingsProposalComponent from "./SettingsProposalComponent/SettingsProposalComponent";
import { CurrentProfileProvider } from "../../CurrentProfileContext";
import { ProfileContext } from "../../ProfileContext";
import SettingsPrivacyComponent from "./SettingsPrivacyComponent/SettingsPrivacyComponent";
import SettingsPaymentComponent from "./SettingsPaymentComponent/SettingsPaymentComponent";

const drawerWidth = 240;

export const useSettingsStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
}));

const Settings = ({
  isAuthenticated = true,
  path: redirectPath = "",
  isInitializing = true,
}) => {
  const classes = useSettingsStyles();
  const globalClasses = useStyles();

  let { path, url } = useRouteMatch();

  if (!isAuthenticated && !isInitializing) {
    return <Redirect to={`/login?r=${redirectPath}`} />;
  }

  return (
    <Container maxWidth="lg" fixed className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start">
        <Grid
          item
          xl={1}
          lg={1}
          md={1}
          sm={1}
          xs={1}
          className={globalClasses.sectionDesktop}
        />

        <Grid item xl={3} lg={3} md={3} sm={4} xs={4}>
          <SettingsMenu />
        </Grid>

        <Grid item xl={7} lg={7} md={7} sm={8} xs={8}>
          <Switch>
            <Route exact path={path}>
              <SettingsGeneral />
            </Route>
            <Route path={`${path}/general`}>
              <SettingsGeneral />
            </Route>
            <Route path={`${path}/invites`}>
              <SettingsInvites />
            </Route>
            <Route path={`${path}/profile`}>
              <SettingsProfileComponent />
            </Route>
            <Route path={`${path}/proposals`}>
              <SettingsProposalComponent />
            </Route>
            <Route path={`${path}/privacy`}>
              <SettingsPrivacyComponent />
            </Route>
            <Route path={`${path}/payment`}>
              <SettingsPaymentComponent />
            </Route>
          </Switch>
        </Grid>

        <Grid
          item
          xl={1}
          lg={1}
          md={1}
          sm={1}
          xs={1}
          className={globalClasses.sectionDesktop}
        />
      </Grid>
    </Container>
  );
};

export default Settings;

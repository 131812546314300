import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoryRepository from "../../../../infra/firebase/CategoryRepository";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { Category } from "../../../../core/entities/Category";
import SkillsChipsAutoSuggest from "./SkillsChipsAutoSuggest/SkillsChipsAutoSuggest";
import ChipData from "./ChipData";
import ProfileRepository from "../../../../infra/firebase/ProfileRepository";
import Profile, { ProfileCategory } from "../../../../core/entities/Profile";
import { useProfileContext } from "../../../../ProfileContext";
import { functions } from "../../../../infra/firebase/firebasecofig";

export interface IProfileCategoriesEditProps {
  open: boolean;
  handleClose: any;
  profileCategory?: ProfileCategory | null;
  message?: string;
  profileId: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootChips: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      "& > *": {
        margin: theme.spacing(0.5),
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    chip: {
      margin: theme.spacing(1),
    },
    selected: {
      margin: theme.spacing(1),
      color: "#FFF",
    },
    iconChip: {
      color: "#FFF",
    },
  })
);

const getCategoryTranslatedName = (categoryParam: Category, lang: string) => {
  if (categoryParam.translatedNames === undefined) {
    return categoryParam.name;
  }

  return categoryParam.translatedNames[lang]
    ? categoryParam.translatedNames[lang]
    : categoryParam.name;
};

const ProfileCategoriesEdit = ({
  open,
  handleClose,
  profileCategory,
  message,
  profileId
}: IProfileCategoriesEditProps) => {

  const [profile, setProfile] = useState<Profile>();

  const isEdit = profileCategory ? true : false;

  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language.split("-")[0]);

  const [currentCategoryId, setCurrentCategoryId] = useState(
    profileCategory?.categoryId
  );
  const [currentCategory, setCurrentCategory] = useState<Category>();
  const [categoryName, setCategoryName] = useState("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const categoryId = event.target.value as string;
    setCurrentCategoryId(categoryId);
    setCurrentCategory(parentCategories.find((c) => c.id === categoryId));
    changeSubCategories(categoryId);
  };

  const categoryRepository = new CategoryRepository();
  const profileRepository = new ProfileRepository();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [categoriesForCombo, setCategoriesForCombo] = useState<Category[]>([]);

  const [parentCategories, setParentCategories] = useState<Category[]>([]);
  const [subCategories, setSubCategories] = useState<Category[]>([]);

  const [subcategoriesChips, setSubcategoriesChips] = useState<ChipData[]>([]);
  // const [selectedChips, setSelectedChips] = useState<ChipData[]>(profileCategory?.skills ? profileCategory?.skills.map(s => { return { value: s, text: s } }) : []);
  const [selectedChips, setSelectedChips] = useState<ChipData[]>([]);

  const getSelectedChipsBySkill = (subCategoriesParam: Category[]) => {
    let result: ChipData[] = [];
    profileCategory?.skills?.forEach((skill) => {
      console.debug(skill);

      let find = subCategoriesParam.find(
        (s) =>
          s.name.toLocaleLowerCase() === skill.toLocaleLowerCase() ||
          s.translatedNames?.de?.toLocaleLowerCase() ===
          skill.toLocaleLowerCase() ||
          s.translatedNames?.en?.toLocaleLowerCase() ===
          skill.toLocaleLowerCase() ||
          s.translatedNames?.es?.toLocaleLowerCase() ===
          skill.toLocaleLowerCase() ||
          s.translatedNames?.pt?.toLocaleLowerCase() ===
          skill.toLocaleLowerCase()
      );

      if (find) {
        result.push({
          text: skill,
          value: find.name,
        });
      } else {
        result.push({
          text: skill,
          value: skill,
        });
      }
    });

    setSelectedChips(result);
  };

  const loadData = () => {
    setLoading(true);

    //se é edição, carregar a categoria selecionada e as suas subcategorias
    if (isEdit && profileCategory && profileCategory.categoryId) {
      changeSubCategories(profileCategory.categoryId);

      categoryRepository
        .getById(profileCategory.categoryId)
        .then((getByIdResult) => {
          setCurrentCategory(getByIdResult);
          setCategoryName(getCategoryTranslatedName(getByIdResult, lang));
          setLoading(false);
          setErrorMessage("");
        })
        .catch((error) => {
          setLoading(false);
          setErrorMessage(error.message);
        });
    }

    //se não é edição, carregar todas as categorias mães
    if (!isEdit) {
      categoryRepository
        .getParentCategories()
        .then((getParentCategoriesResult) => {
          const result: Category[] = [];
          const profileCategories = profile?.profileCategories;

          getParentCategoriesResult.forEach((parentCategory) => {
            if (
              !profileCategories?.find(
                (c) => c.categoryId === parentCategory.id
              )
            )
              result.push(parentCategory);
          });

          setParentCategories(getParentCategoriesResult);
          setCategoriesForCombo(result);
          setLoading(false);
          setErrorMessage("");
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
          setErrorMessage(error.message);
        });
    }
  }

  useEffect(() => {
    setLoading(true);

    profileRepository.getById(profileId)
      .then(p => {
        setProfile(p);
        loadData();
      })
      .catch(error => {
        console.error(error)
        setLoading(false);
      });

  }, [profileId]);

  const changeSubCategories = (categoryId: string) => {
    setLoading(true);

    categoryRepository
      .getSubCategories(categoryId)
      .then((getSubCategoriesResult) => {
        setSubCategories(getSubCategoriesResult);

        setSubcategoriesChips(
          getSubCategoriesResult.map<ChipData>((s) => {
            return {
              selected: verifyIfAlreadySuggested(s.name),
              deletable: true,
              isSuggested: true,
              text: getCategoryTranslatedName(s, lang),
              value: s.name,
            };
          })
        );

        if (!isEdit) setSelectedChips([]);
        else getSelectedChipsBySkill(getSubCategoriesResult);

        setLoading(false);
        setErrorMessage("");
      })
      .catch((error) => {
        console.error(error);

        if (!isEdit) setSelectedChips([]);

        setLoading(false);
        setErrorMessage(error.message);
      });
  };

  const verifyIfAlreadySuggested = (value: string) => {
    const find = profileCategory?.subCategories?.find(
      (s) => s.name.toLocaleLowerCase() === value.toLocaleLowerCase()
    );
    //const find = profileCategory?.skills?.find(s => s.toLocaleLowerCase() === value.toLocaleLowerCase());
    return find ? true : false;
  };

  const removeTag = (chipToRemove: ChipData) => {
    setSelectedChips((chip) =>
      chip.filter(
        (s) =>
          s.value.toLocaleLowerCase() !== chipToRemove.value.toLocaleLowerCase()
      )
    );
  };

  const handleClick = (chip: ChipData) => {
    chip.selected = !chip.selected;

    let result = subcategoriesChips.filter(
      (s) => s.value.toLocaleLowerCase() !== chip.value.toLocaleLowerCase()
    );
    let find = subcategoriesChips.find(
      (s) => s.value.toLocaleLowerCase() === chip.value.toLocaleLowerCase()
    );
    if (find) {
      find.selected = chip.selected;
      result.push(find);
    }
    result = result.sort((a, b) => {
      return a.text > b.text ? 1 : -1;
    });

    setSubcategoriesChips(result);

    if (chip.selected) {
      setSelectedChips([...selectedChips, chip]);
    } else {
      removeTag(chip);
    }
  };

  const isSubcategory = (chip: ChipData): boolean => {
    return subcategoriesChips.find(
      (s) =>
        s.text.toLocaleLowerCase() === chip.text.toLocaleLowerCase() ||
        s.value.toLocaleLowerCase() === chip.value.toLocaleLowerCase()
    )
      ? true
      : false;
  };

  const handleAddChip = (chip: ChipData): any => {
    if (isSubcategory(chip)) {
      const c = chip;
      c.selected = false;
      handleClick(c);
    } else {
      setSelectedChips([...selectedChips, chip]);
    }
  };

  const handleRemoveChip = (chip: ChipData): any => {
    if (isSubcategory(chip)) {
      const c = chip;
      c.selected = true;
      handleClick(c);
    } else {
      removeTag(chip);
    }
  };

  const deleteCategory = async () => {
    const profileCategories = profile?.profileCategories?.filter(
      (c) => c.categoryId !== currentCategoryId
    );

    if (profile && profileCategories) {
      try {
        setLoadingSave(true);

        await profileRepository.updateCategories(
          profile?.id,
          profileCategories
        );

        // const caller = functions.httpsCallable("profiles-onCallUpdateProfile");
        // await caller({
        //   profileId: profile?.id,
        //   categories: profileCategories,
        // });

        setLoadingSave(false);

        handleClose();
      } catch (error) {
        setErrorMessage((error as Error).message);
        console.error(error);
        setLoadingSave(false);
      }
    }
  };

  const getSubcategories = () => {
    let result: ProfileCategory[] = [];
    selectedChips.forEach((s) => {
      let found = subCategories.find(
        (subcategory) =>
          subcategory.name.toLocaleLowerCase() === s.value.toLocaleLowerCase()
      );
      if (found) {
        result.push({
          name: found.name,
          categoryId: found.id,
          translatedNames: found.translatedNames,
          parentCategoryId: found.parentCategoryId,
        });
      }
    });
    return result;
  };

  console.debug(selectedChips);


  const hasToModerateSkills = async () => {
    try {
      const values = selectedChips.map(s => {
        return { field: s.value, content: s.text }
      });


      const moderationCaller = functions.httpsCallable("moderation-onCallModerateText");
      const moderationResponse = await moderationCaller({
        values: values
      });

      const moderations = moderationResponse.data.filter(d => d.moderated);
      const hasModerations = moderations.length > 0;
      const errors = moderationResponse.data.filter(d => d.error);
      const hasErrors = errors.length > 0;

      if (hasErrors) {
        console.error(errors);
        setErrorMessage(t("There are errors in this request."));
        return true;
      }


      if (hasModerations) {
        setErrorMessage(t("Some terms may violate our Terms & Conditions."));
        return true;
      }

      return false;


    } catch (error) {
      setErrorMessage((error as Error).message);
      console.error(error);
      return true;

    }


  }

  const save = async () => {
    if (!currentCategoryId) return;

    setLoadingSave(true);

    if (!currentCategory) {
      setLoadingSave(false);
      return;
    }

    if (await hasToModerateSkills()) {
      setLoadingSave(false);
      return;
    }

    const newProfileCategory = new ProfileCategory();
    newProfileCategory.categoryId = currentCategory.id;
    newProfileCategory.name = currentCategory.name;
    newProfileCategory.translatedNames = currentCategory.translatedNames;

    newProfileCategory.subCategories = getSubcategories();

    newProfileCategory.skills = selectedChips.map((s) => {
      return s.text;
    });

    const profileCategories = profile?.profileCategories
      ? profile?.profileCategories.filter(
        (c) => c.categoryId !== currentCategoryId
      )
      : [];
    profileCategories.push(newProfileCategory);

    if (profile) {
      try {
        await profileRepository.updateCategories(
          profile?.id,
          profileCategories
        );

        setLoadingSave(false);
        handleClose();
      } catch (error) {
        setErrorMessage((error as Error).message);
        console.error(error);
        setLoadingSave(false);
      }
    } else {
      setLoadingSave(false);
    }
  };

  const handleAddAutoSuggestChip = (chip: any) => {
    console.debug("handleAddAutoSuggestChip", chip);
    handleAddChip(chip);
  };

  const handleDeleteAutoSuggestChip = (chip: any) => {
    console.debug("handleDeleteAutoSuggestChip", chip);
    handleRemoveChip(chip);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}
      disableEscapeKeyDown
      disableBackdropClick>
      <DialogTitle>{isEdit ? categoryName : t("Categories")}</DialogTitle>
      <DialogContent>
        {message && <span style={{ marginBottom: "8px" }}>{message}</span>}
        <Collapse in={errorMessage !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {errorMessage}
          </Alert>
        </Collapse>

        {!isEdit && (
          <FormControl className={classes.formControl} fullWidth>
            <InputLabel id="category-select-label">{t("Category")}</InputLabel>
            <Select
              labelId="category-select-label"
              id="category-select"
              value={currentCategoryId}
              onChange={handleChange}>
              {categoriesForCombo.map((categorySelect) => (
                <MenuItem key={categorySelect.id} value={categorySelect.id}>
                  {getCategoryTranslatedName(categorySelect, lang)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <div className={classes.rootChips}>
          {subcategoriesChips.map((subcategoryChip) => {
            return (
              <Chip
                className={
                  subcategoryChip.selected ? classes.selected : classes.chip
                }
                color="secondary"
                variant={subcategoryChip.selected ? "default" : "outlined"}
                label={subcategoryChip.text}
                clickable={subcategoryChip.deletable}
                key={subcategoryChip.value}
                onClick={() => handleClick(subcategoryChip)}
              />
            );
          })}
        </div>

        {currentCategoryId && !loading && (
          <SkillsChipsAutoSuggest
            categoryId={currentCategoryId}
            addChip={handleAddAutoSuggestChip}
            deleteChip={handleDeleteAutoSuggestChip}
            selected={selectedChips}
          />
        )}
      </DialogContent>
      <DialogActions>
        {!loadingSave ? (
          <div>
            <Button
              onClick={handleClose}
              color="inherit"
              disabled={loadingSave}>
              {t("Cancel")}
            </Button>
            {isEdit && (
              <Button
                onClick={deleteCategory}
                color="secondary"
                disabled={loadingSave}>
                {t("Delete")}
              </Button>
            )}

            <Button onClick={save} color="primary" disabled={loadingSave}>
              {t("Save")}
            </Button>
          </div>
        ) : (
          <LoadingComponent />
        )}
      </DialogActions>
    </Dialog>
  );
};

const LoadingComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        background: "#c9c9c9",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "4px",
        padding: "12px",
        margin: "4px 0",
      }}>
      <CircularProgress size={14} />
    </div>
  );
};

export default ProfileCategoriesEdit;

import {
  Button,
  CircularProgress,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import firebase from "firebase";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Post from "../../../core/entities/Post";
import PostCarousel from "./PostCarousel/PostCarousel";
import { IPostMedia } from "./IPostMedia";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import getCroppedImg from "../../../utils/cropImage";
import PostController from "./PostController";
import PostRepository from "../../../infra/firebase/PostRepository";
import { useForm } from "react-hook-form";

const useImageEditorStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: 20,
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    buttons: {
      textAlign: "right",
      marginTop: 10,
    },
    dialogCustomizedWidth: {
      "max-width": "80%",
    },
    form: {
      width: "100%",
      paddingBottom: 20,
    },
    errorMessage: {
      color: "red",
    },
    gallery: {
      flexGrow: 1,
    },
    divider: {},
    caption: {
      minWidth: 300,
      [theme.breakpoints.up("md")]: {
        marginLeft: 30,
      },
    },
    dialogRoot: {
      overflow: "visible",
    },
  })
);

export interface IPostFormProps {
  profileId: string;
  items: IPostMedia[];
  handleClose: any;
  post?: Post;
  setSuccess: any;
  open: boolean;
  title: string;
}

const PostForm = ({
  profileId,
  items,
  setSuccess,
  handleClose,
  post,
  open,
  title,
}: IPostFormProps) => {
  const repository = new PostRepository();
  const [photos, setPhotos] = useState<IPostMedia[]>(items);
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");

  const [caption, setCaption] = useState("");

  const classes = useImageEditorStyles();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm<Post>();

  const controller = new PostController();

  const save = async (data: any) => {
    setLoading(true);
    var newPost = new Post();
    if (post) {
      newPost.id = post.id;
      newPost.modifiedDate = new Date();
    } else {
      newPost.publishDate = new Date();
    }
    newPost.caption = caption; //data.caption;
    newPost.profileId = profileId;

    await controller
      .createPost(newPost, photos)
      .then((post) => {
        console.log("saved post", post);
        setSuccess(post);
        handleClose();
        setLoading(false);
      })
      .catch((error) => {
        setError("id", {
          message: error.message,
          type: "validate",
        });
        setLoading(false);
      });
  };

  const handleUpdatedItems = (photos: IPostMedia[]) => {
    setPhotos(photos);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="md"
      fullScreen={smallScreen}
      disableEscapeKeyDown
      disableBackdropClick>
      <DialogContent
        classes={{
          root: classes.dialogRoot,
        }}>
        <Collapse in={errorMessage !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {errorMessage}
          </Alert>
        </Collapse>
        <div className={classes.root}>
          <div className={classes.gallery}>
            {photos && (
              <PostCarousel
                items={photos}
                updatedItems={handleUpdatedItems}
                profileId={profileId}
              />
            )}
          </div>

          <div className={classes.divider}>
            <Divider variant="fullWidth" orientation="vertical" />
          </div>
          <div className={classes.caption}>
            <TextField
              label={t("Write a caption...")}
              value={caption}
              onChange={(e) => setCaption(e.target.value)}
              fullWidth
              variant="standard"
              margin="normal"
              id="title"
              autoFocus
              multiline
              rows={5}
            />
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit" disabled={loading}>
          {t("Cancel")}
        </Button>
        {loading ? (
          <div
            style={{
              display: "flex",
              background: "#c9c9c9",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              padding: "12px",
              margin: "4px 0",
            }}>
            <CircularProgress size={14} />
          </div>
        ) : (
          <Button
            onClick={save}
            color="primary"
            disabled={loading}
            variant="contained">
            {t("Post")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const saveBlob = (
  fileRef: firebase.storage.Reference,
  blob: Blob,
  postMedia: IPostMedia,
  thumb?: boolean
): Promise<IPostMedia> => {
  return fileRef
    .put(blob)
    .then((snapshot) => {
      return fileRef
        .getDownloadURL()
        .then((url) => {
          const result = postMedia;
          if (thumb) result.croppedUrl = url;
          else result.src = url;
          return Promise.resolve(result);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

const savePhoto = (
  fileRef: firebase.storage.Reference,
  postmedia: IPostMedia
): Promise<IPostMedia> => {
  return getCroppedImg(postmedia.src, postmedia.croppedAreaPixels, 0, true)
    .then((croppedImage) => {
      return saveBlob(fileRef, croppedImage, postmedia);
    })
    .catch((error) => {
      return Promise.reject(error);
    });

  // const promises = photos.map(async (photo, idx) => {
  //     return await saveMedia(post, photo);
  // });

  // return await Promise.all(promises);
};

const saveAudioVideo = (
  fileRef: firebase.storage.Reference,
  postMedia: IPostMedia
): Promise<IPostMedia> => {
  return fetch(postMedia.croppedUrl ? postMedia.croppedUrl : "")
    .then((r) =>
      r
        .blob()
        .then((blob) => {
          return saveBlob(fileRef, blob, postMedia);
        })

        .catch((error) => {
          return Promise.reject(error);
        })
    )
    .catch((error) => {
      return Promise.reject(error);
    });
};

const saveMedia = (
  post: Post,
  postMedia: IPostMedia,
  original: boolean
): Promise<IPostMedia> => {
  const storageRef = firebase.storage().ref();
  const prefix = original ? "original" : "thumbnail";

  let fileName = `user_images/${post.profileId}/${post.id}/${prefix}-${postMedia.fileName}`;
  const fileRef = storageRef.child(fileName);

  return postMedia.type === "image"
    ? savePhoto(fileRef, postMedia)
    : saveAudioVideo(fileRef, postMedia);
};

const saveMedias = async (post: Post, photos: IPostMedia[]) => {
  const promises = photos.map(async (photo, idx) => {
    return await saveMedia(post, photo, false);
  });

  return await Promise.all(promises);
};

const savePostMedias = (
  profileId: string,
  postId: string,
  postMedias: IPostMedia[]
) => {
  const postController = new PostController();

  const result = postMedias.forEach((postMedia) => {
    const original = postMedia.src;
    const thumbnail = postMedia.croppedUrl;

    const originalFileRef = postController.getFileRef(
      profileId,
      postId,
      original,
      "original"
    );
    const thumbnailFileRef = postController.getFileRef(
      profileId,
      postId,
      thumbnail,
      "thumbnail"
    );

    if (postMedia.type === "image") {
      return getCroppedImg(postMedia.src, postMedia.croppedAreaPixels, 0, true)
        .then((croppedImage) => {
          return saveBlob(thumbnailFileRef, croppedImage, postMedia, true)
            .then((p) => {
              return fetch(p.src)
                .then((r) =>
                  r
                    .blob()
                    .then((blob) => {
                      return saveBlob(originalFileRef, blob, postMedia, false);
                    })
                    .catch((error) => {
                      return Promise.reject(error);
                    })
                )
                .catch((error) => {
                  return Promise.reject(error);
                });
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    } else {
      return fetch(postMedia.croppedUrl)
        .then((r) =>
          r
            .blob()
            .then((blob) => {
              return saveBlob(thumbnailFileRef, blob, postMedia, false)
                .then((p) => {
                  return fetch(p.src)
                    .then((r) =>
                      r
                        .blob()
                        .then((blob) => {
                          return saveBlob(
                            originalFileRef,
                            blob,
                            postMedia,
                            false
                          );
                        })
                        .catch((error) => {
                          return Promise.reject(error);
                        })
                    )
                    .catch((error) => {
                      return Promise.reject(error);
                    });
                })
                .catch((error) => {
                  return Promise.reject(error);
                });
            })
            .catch((error) => {
              return Promise.reject(error);
            })
        )
        .catch((error) => {
          return Promise.reject(error);
        });
    }
  });

  return result;
};

const uploadThumbnail = (post: Post, photos: IPostMedia[]): Promise<string> => {
  const storageRef = firebase.storage().ref();

  const thumbnail = photos[0];
  let thumbnailFileName = `user_images/${post.profileId}/${post.id}/${thumbnail.fileName}`;
  const fileRef = storageRef.child(thumbnailFileName);

  return getCroppedImg(thumbnail.src, thumbnail.croppedAreaPixels, 0, true)
    .then((croppedImage) => {
      return fileRef
        .put(croppedImage)
        .then((snapshot) => {
          return fileRef
            .getDownloadURL()
            .then((url) => {
              return Promise.resolve(url);
            })
            .catch((error) => {
              return Promise.reject(error);
            });
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    })
    .catch((error) => {
      return Promise.reject(error);
    });

  // return fetch(thumbnail.src)
  //     .then(r => {
  //         return r.blob()
  //             .then(b => {
  //                 return fileRef.put(b)
  //                     .then((snapshot) => {
  //                         return fileRef.getDownloadURL()
  //                             .then((url) => {
  //                                 return Promise.resolve(url);
  //                             })
  //                             .catch((error) => {
  //                                 return Promise.reject(error);
  //                             });
  //                     })
  //                     .catch((error) => {
  //                         return Promise.reject(error);
  //                     });
  //             })
  //             .catch((error) => {
  //                 return Promise.reject(error);
  //             });
  //     })
  //     .catch((error) => {
  //         return Promise.reject(error);
  //     });
};

export default PostForm;

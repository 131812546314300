import React, { useState } from "react";

import { ErrorMessage } from "@hookform/error-message";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  CircularProgress,
} from "@material-ui/core";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";

import firebase from "firebase";

import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import MailOutlineOutlinedIcon from "@material-ui/icons/MailOutlineOutlined";
import Profile from "../../../core/entities/Profile";
import SocialMediaLogon from "../SocialMediaLogon/SocialMediaLogon";
import { CreateProfile } from "../LoginController";
import AnalyticsHelper from "../../../infra/analytics/analyticsHelper";

const analyticsHelper = new AnalyticsHelper();

const useStyles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
    paddingLeft: 20,
    paddingRight: 20,
  },
  errorMessage: {
    color: "red",
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  labelButton: {
    textTransform: "initial",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    margin: theme.spacing(2, 2, 2),
  },
  inputAdornment: {
    color: theme.palette.primary.main,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Register: React.FC = () => {
  let query = useQuery();
  const r = query.get("r");
  const token = query.get("token");
  const { search } = useLocation();

  const classes = useStyles();
  const {
    reset,
    getValues,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { t, i18n } = useTranslation();

  const afterError = (errorMessage: string) => {
    setError("form", {
      type: "manual",
      message: t(errorMessage),
    });

    reset(getValues(), { keepValues: true, keepErrors: true });
    setLoading(false);
  };

  const createUserWithEmailAndPasswordHandler = async (
    email: string,
    password: string,
    name: string
  ): Promise<Profile> => {
    try {
      console.debug("CREATING USER");
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(email.trim(), password);
      console.debug(user, "USER CREATED");
      if (firebase.auth().currentUser) {
        console.debug("SENDING EMAIL");
        await firebase.auth().currentUser?.sendEmailVerification();
      }
      analyticsHelper.logEvent("sign_up");
      return CreateProfile(user, name);
    } catch (error) {
      console.debug(error);
      return Promise.reject(error);
    }
  };

  const onSubmit = (data: any) => {
    try {
      setLoading(true);
      console.debug("ABOUT TO CREATE USER");
      createUserWithEmailAndPasswordHandler(
        data.email.trim(),
        data.password.trim(),
        data.name.trim()
      )
        .then((user) => {
          if(token){
            history.push("/acceptinvite",{token});
          }else{
            history.push(r ? r : "/");
          }
        })
        .catch((error) => {
          afterError(error.message);
        });
    } catch (error: any) {
      afterError(error.message);
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant="standard"
        margin="normal"
        required
        fullWidth
        id="name"
        label={t("Full Name")}
        autoComplete="name"
        autoFocus
        {...register("name", { required: true })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <PermIdentityOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <ErrorMessage
        errors={errors}
        name="name"
        render={({ message }) => (
          <span className={classes.errorMessage}>{message}</span>
        )}
      />

      <TextField
        variant="standard"
        margin="normal"
        required
        fullWidth
        id="email"
        label={t("E-mail")}
        autoComplete="email"
        autoFocus
        {...register("email", { required: true })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <MailOutlineOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <ErrorMessage
        errors={errors}
        name="email"
        render={({ message }) => (
          <span className={classes.errorMessage}>{message}</span>
        )}
      />

      <TextField
        variant="standard"
        margin="normal"
        required
        fullWidth
        label={t("Password")}
        type="password"
        id="password"
        autoComplete="current-password"
        {...register("password", { required: true })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <ErrorMessage
        errors={errors}
        name="password"
        render={({ message }) => (
          <span className={classes.errorMessage}>{message}</span>
        )}
      />

      {loading ? (
        <div
          style={{
            display: "flex",
            background: "#c9c9c9",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            padding: "12px",
            margin: "4px 0",
          }}>
          <CircularProgress size={14} />
        </div>
      ) : (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          classes={{
            root: classes.submit,
            label: classes.labelButton,
          }}
          disableElevation
          disabled={loading}
          onClick={() => clearErrors()}>
          {t("Create account")}
        </Button>
      )}

      <Grid container justifyContent="center">
        <ErrorMessage
          errors={errors}
          name="form"
          render={({ message }) => (
            <span className={classes.errorMessage}>{message}</span>
          )}
        />
      </Grid>

      <SocialMediaLogon clearErrors={clearErrors} setError={afterError} />

      <RouterLink to={`/login${search}`} style={{ textDecoration: "none" }}>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          classes={{
            root: classes.submit,
            label: classes.labelButton,
          }}
          disableElevation>
          {t("Log in")}
        </Button>
      </RouterLink>
    </form>
  );
};

export default Register;

import {
  Box,
  Container,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Post from "../../../core/entities/Post";
import PostRepository from "../../../infra/firebase/PostRepository";
import styles from "./HomeOurComunity.module.scss";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import Profile from "../../../core/entities/Profile";
import ProfileRepository from "../../../infra/firebase/ProfileRepository";
import { Link as LinkRouter } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",

    },
    imageContainer: {
      position: "relative",
      width: "100%",
    },
    fundo: {
      backgroundColor: "#FFF",
      paddingTop: 50,
      paddingBottom: 50,
    },
    gridList: {
      width: "auto",
      height: "auto",
      transform: "translateZ(0)",
    },
    tile: {
      borderRadius: 10,
    },
    titleBar: {
      background: "transparent",
      textTransform: "capitalize",
    },
    icon: {
      color: "white",
    },
    divider: {
      marginBottom: 30,
      backgroundColor: theme.palette.primary.main,
      height: 2,
    },
    itemImage: {
      [theme.breakpoints.up("xs")]: {
        height: "16rem",
        width: "16rem",
      },
      [theme.breakpoints.up("md")]: {
        height: "25rem",
        width: "25rem",
      },
    },
    itemContainer: {
      display: "flex",
      [theme.breakpoints.up("xs")]: {
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-end",
      },

      [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "stretch",
      },
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.up("md")]: {
        paddingRight: "2.5rem",
        justifyContent: "space-evenly",
      },
    },
    itemTitle: {
      [theme.breakpoints.up("xs")]: {
        fontSize: "2rem",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
      },
    },
  })
);

export interface IHomeOurComunityProps {
  postsIds: string[];
}

const toItems = (items: Post[]): ReactImageGalleryItem[] => {
  return items.map((item) => {
    return {
      original: item.thumbnailUrl ? item.thumbnailUrl : "",
      thumbnail: item.thumbnailUrl ? item.thumbnailUrl : "",
      originalAlt: item.id ? item.id : "",
      originalHeight: 400,
      thumbnailHeight: 50,
      thumbnailWidth: 50,
      ...item,
    };
  });
};

const postRepository = new PostRepository();
const profileRepository = new ProfileRepository();

const HomeOurComunity = ({ postsIds }: IHomeOurComunityProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [posts, setPosts] = useState<Post[]>([]);
  const [items, setItems] = useState<ReactImageGalleryItem[]>([]);

  const [profileIds, setProfileIds] = useState<string[]>([]);
  const [profiles, setProfiles] = useState<Profile[]>([]);

  const galleryRef = useRef<any>(null);

  const getPosts = useCallback(
    async (postsIds) => {
      try {
        const newPosts = await postRepository.getPostsForHome(postsIds);
        setPosts(newPosts);
        const newItems = toItems(newPosts);
        setItems(newItems);
        const newProfileIds = newPosts.map((post) => post.profileId);
        setProfileIds(newProfileIds);
      } catch (err) {
        console.error(err);
      }
    },
    [setPosts, setItems, setProfileIds]
  );

  const getProfiles = useCallback(
    async (profileIds) => {
      try {
        const newProfiles = await profileRepository.getProfilesByIds(
          profileIds
        );
        setProfiles(newProfiles);
      } catch (err) {
        console.error(err);
      }
    },
    [setProfiles]
  );

  useEffect(() => {
    if (postsIds) {
      getPosts(postsIds);
    }
  }, [postsIds]);

  useEffect(() => {
    if (profileIds.length > 0) {
      getProfiles(profileIds);
    }
  }, [profileIds, getProfiles]);

  const renderItem = (item: ReactImageGalleryItem) => {
    const post = posts.find((p) => p.id === item.originalAlt);
    const profile = profiles.find((p) => p.id === post?.profileId);
    const url = `/${profile?.nickname}`;

    return (
      <Box className={classes.itemContainer}>
        <Box className={classes.textContainer} style={{ textAlign: "right" }}>
          <Box>
            <LinkRouter to={url}>
              <Typography variant="h6" paragraph color="textPrimary">
                {profile?.name}
              </Typography>
            </LinkRouter>
            <LinkRouter to={url}>
              <Typography variant="body1" paragraph color="textPrimary">
                {profile?.nickname && `@${profile?.nickname}`}
              </Typography>
            </LinkRouter>
          </Box>
        </Box>
        <img
          alt={profile?.name ?? ""}
          src={item.original}
          className={classes.itemImage}
        />
      </Box>
    );
  };

  return (
    <Container className={classes.fundo} maxWidth="xl">
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center">
          {items.length > 0 && <Grid item>
            <Typography variant="h4" paragraph color="primary">
              {t("Our community")}
            </Typography>
            <Divider variant="middle" className={classes.divider} />
          </Grid>
          }
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ width: "100%" }}>
            <div className={classes.imageContainer}>
              {items?.length > 0 && profiles.length > 0 && (
                <ImageGallery
                  ref={galleryRef}
                  items={items}
                  showIndex={false}
                  showBullets
                  showThumbnails={false}
                  showPlayButton={false}
                  showFullscreenButton={false}
                  renderItem={renderItem}
                  renderLeftNav={renderLeftNav}
                  renderRightNav={renderRightNav}
                />
              )}
            </div>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

const renderLeftNav = (onClick, disabled) => {
  return (
    <button
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}>
      <ChevronLeftRoundedIcon fontSize="large" />
    </button>
  );
};

const renderRightNav = (onClick, disabled) => {
  return (
    <button
      className="image-gallery-icon image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}>
      <ChevronRightRoundedIcon fontSize="large" />
    </button>
  );
};

export default HomeOurComunity;

import { Collection } from "fireorm";
import Profile from "./Profile";

@Collection("Chats")
export class Chat {
  id: string;
  lastMessageBody?: any;
  lastMessageDate?: Date;
  lastSenderName?: string;
  name: string;
  membersProfile: ChatProfile[];
  membersId: string[];
  agentId?: string;
  talentId?: string;
}

@Collection("ProfileChats")
export class ProfileChat extends Chat {
  chatId: string;
  profileId: string;
  unread: number;

  lastMessageBody?: any;
  lastMessageDate?: Date;
  lastSenderName?: string;
  name: string;
  membersProfile: ChatProfile[];
  membersId: string[];
}

export class ChatProfile {
  name?: string;
  nickname?: string;
  id: string;
  profileImageUrl?: string;
}

@Collection("Messages")
export class ChatMessage {
  id: string;
  attachments?: Attachment[];
  body: string;
  chatId: string | undefined;
  sendDate: any;
  senderId: any;
  dealReference?: any;
  type: string;
}

export class Attachment {
  name: string;
  size: number;
  type: string;
  url: string;
}

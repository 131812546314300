import { getRepository } from "fireorm";
import { Attribute } from "../../core/entities/Attribute";
import { IRepository } from "../../core/interfaces/IRepository";

export default class AttributeRepository implements IRepository<Attribute> {
  repository = getRepository(Attribute);

  getAll(): Promise<Attribute[]> {
    return this.repository.find();
  }

  getAllByUse(use: string): Promise<Attribute[]> {
    return this.repository
      .whereGreaterOrEqualThan(`uses.${use}` as unknown as "uses", -1)
      .find();
  }

  createOrUpdate(entity: Attribute): Promise<Attribute> {
    return entity.id ? this.update(entity) : this.create(entity);
  }

  create(entity: Attribute): Promise<Attribute> {
    return this.repository.create(entity);
  }
  update(entity: Attribute): Promise<Attribute> {
    return this.repository.update(entity);
  }
  delete(id: string): Promise<void> {
    return this.repository.delete(id);
  }
  getById(id: string): Promise<Attribute> {
    return this.repository.findById(id);
  }
}

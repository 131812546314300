import React, { useEffect, useState } from "react";
import moment from "moment";

import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  ConversationHeader,
  AvatarGroup,
  InputToolbox,
} from "@chatscope/chat-ui-kit-react";
import { Chat, ChatMessage, ChatProfile } from "../../../core/entities/Chat";
import ChatRepository from "../../../infra/firebase/ChatRepository";
import MessageRepository from "../../../infra/firebase/MessageRepository";
import { Link, useParams } from "react-router-dom";
import { getAvatar, timestampToData } from "../../../utils/utils";
import { db, functions } from "../../../infra/firebase/firebasecofig";
import { useTranslation } from "react-i18next";
import ChatProposal from "../ChatProposal/ChatProposal";
import ChatSummaryProposal from "../ChatSummaryProposal/ChatSummaryProposal";
import ChatMessageResolver from "../ChatMessageResolver/ChatMessageResolver";
import DealDetails from "../DealDetails/DealDetails";
import EditDealDialog from "../EditDealDialog/EditDealDialog";
import SelectPaymentDialog from "../SelectPaymentDialog/SelectPaymentDialog";
import OnboardingValidation from "../OnboardingValidation/OnboardingValidation";
import DealsRepository from "../../../infra/firebase/DealsRepository";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "pretty-format";

import { useProfileContext } from "../../../ProfileContext";
import Deal from "../../../core/entities/Deal";

import firebase from "firebase";
import VerifyEmailDialog from "../../shared/VerifyEmailDialog";
import ChatAttachmentUpload from "../ChatAttachmentUpload/ChatAttachmentUpload";
import ProfileRepository from "../../../infra/firebase/ProfileRepository";
import Profile from "../../../core/entities/Profile";

export interface IChatConversationProps {
  profileId: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuList: {
      backgroundColor: "white",
      boxShadow: "0 0 0.5em #c9c9c9",
      position: "absolute",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      bottom: "3.5rem",
    },
    menuItem: {
      padding: "0.25rem 0.5rem",
      listStyleType: "none",
      cursor: "pointer",
      width: "100%",
      "&:hover": {
        backgroundColor: "#141414",
        color: "#f5f5f5",
      },
      "&[aria-disabled=true]": {
        cursor: "not-allowed",
      },
      "&[aria-disabled=true]:hover": {
        backgroundColor: "#7ad0d080",
        color: "#f5f5f5",
      },
    },
  })
);

const profileRepository = new ProfileRepository();
const ChatConversation = ({ profileId }: IChatConversationProps) => {
  const classes = useStyles();
  const { loading: loadinProfile, profile } = useProfileContext();

  const [messageInputValue, setMessageInputValue] = useState("");
  const { t } = useTranslation();
  const [proposalIsOpen, setProposalIsOpen] = useState(false);
  const [summaryIsOpen, setSummaryIsOpen] = useState(false);
  const [dealData, setDealData] = useState<Deal>();
  const [isDealSender, setIsDealSender] = useState(false);
  const [isDealReceiver, setIsDealReceiver] = useState(false);
  const [isOpenDealEditDialog, setIsOpenDealEditDialog] = useState(false);
  const [isDealDetailsOpen, setIsDealDetailsOpen] = useState(false);
  const [isOpenSelectPayment, setIsOpenSelectPayment] = useState(false);
  const [isOnboardingValidOpen, setIsOnboardingValidOpen] = useState(false);
  const [onboardingValidMessage, setOnboardingValidMessage] = useState("");
  const [dealToEdit, setDealToEdit] = useState<Deal>();
  const [chat, setChat] = useState<Chat>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const messagesRef = db.collection("Messages");
  let { chatId } = useParams<{ chatId: string }>();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);
  const [members, setMembers] = useState<ChatProfile[]>([]);
  const [otherMembers, setOtherMembers] = useState<ChatProfile[]>([]);
  const [isUploadFileDialogOpen, setIsUploadFileDialogOpen] = useState(false);
  const [otherMembersData, setOtherMembersData] = useState<any>([]);
  const [onboardingValidRegion, setOnboardingValidRegion] = useState<string>();
  const [otherProfiles, setOtherProfiles] = useState<Profile[]>([]);

  const messageRepository = new MessageRepository();
  const chatRepository = new ChatRepository();
  const dealRepository = new DealsRepository();
  const profileRepository = new ProfileRepository();

  const getMember = (profileId: string) => {
    return members.find((m) => m.id === profileId);
  }; 

  const getOtherMembersData = async () => {
    const membersData: any = [];

    for(const otherMember of otherMembers){
      const currentMember = await profileRepository.getById(otherMember.id);
      membersData.push(currentMember);
      console.log(membersData);
    }
    
    setOtherMembersData(membersData);
  };

  

  const getMembersNames = () => {
    return (
      <div>
        {otherProfiles.map(({ nickname, name }) => {
          return (
            <Link
              to={`/${nickname}`}
              style={{
                marginRight: "8px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              {name}
            </Link>
          );
        })}
      </div>
    );
  };

  const getMembersData = async () => {
    const profiles = await profileRepository.getProfilesByIds(
      otherMembers.map((member) => member.id)
    );
    setOtherProfiles(profiles);
  };

  const handleSendMessage = (
    innerHtml: String,
    textContent: String,
    innerText: String,
    nodes: NodeList
  ) => {
    console.debug(innerHtml, textContent, innerText, nodes);
    const message = new ChatMessage();
    message.body = textContent.toString();
    message.chatId = chat?.id;
    message.senderId = profileId;
    messageRepository.create(message);

    setMessageInputValue("");
  };

  const handleToggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const handleOpenUploadFileDialog = () => {
    setIsUploadFileDialogOpen(true);
    setIsMenuOpen(false);
  };

  const handleOpenDealCreateModal = async () => {
    if (chat && chat.agentId && profileId !== chat.agentId) return;
    setOnboardingValidMessage("");
    setIsMenuOpen(false);
    setIsOnboardingValidOpen(true);

    try {
      if (chat?.agentId) {
        const caller = functions.httpsCallable(
          "payments-onCallValidateAllOnboardingCompleted"
        );

        const response = await caller({
          talentId: chat.talentId,
          agentId: chat.agentId,
        });

        if (response.data.region) {
          setOnboardingValidRegion(response.data.region);
        }

        if (response.data.errors && response.data.errors.length > 0) {
          const errors = response.data.errors;
          if (errors.includes("TalentRegionNotFound")) {
            setOnboardingValidMessage(
              t("The talent has not selected their region")
            );
          } else if (errors.includes("TalentOnboardingNotStarted")) {
            setOnboardingValidMessage(
              t("The talent has not informed their bank data")
            );
          } else if (errors.includes("AgentOnboardingNotStarted")) {
            setOnboardingValidMessage(
              t("The agent has not informed their bank data")
            );
          } else if (errors.includes("AgentOnboardingUncompleted")) {
            setOnboardingValidMessage(
              t("The agent has not completed their bank data")
            );
          } else if (errors.includes("TalentOnboardingUncompleted")) {
            setOnboardingValidMessage(
              t("The talent has not completed their bank data")
            );
          } else {
            setOnboardingValidMessage(
              t("Invalid data provided. Please contact support")
            );
          }
          return;
        }
        setIsOnboardingValidOpen(false);
        setProposalIsOpen(true);
      } else {
        const caller = functions.httpsCallable(
          "payments-onCallValidateOnboardingCompleted"
        );
        const response = await caller({
          id: profileId,
          region: profile?.defaultGateway?.region,
        });
        console.debug(response);
        if (response.data.status === "fail" || response.data.error) {
          setOnboardingValidMessage(
            t(
              response.data.message
                ? response.data.message
                : response.data.error
            )
          );
        } else {
          setIsOnboardingValidOpen(false);
          setProposalIsOpen(true);
        }
      }
    } catch (err) {
      console.debug(err);
      setOnboardingValidMessage(t("Validation error, try again"));
    }
  };

  const handleSendDealMessage = (deal: Deal) => {
    setSummaryIsOpen(false);
    console.debug(deal, "CREATED DEAL");
    const message = new ChatMessage();
    message.body = t("Deal Sent");
    message.chatId = chatId;
    message.senderId = profileId;
    message.dealReference = deal.id;
    messageRepository.create(message);
  };

  const handleOpenDealValidationModal = (data: any) => {
    if (!firebase.auth().currentUser?.emailVerified) {
      setIsVerifyEmailOpen(true);
      return;
    }

    const [{ id: toProfileId }, ...others] = members.filter(
      (member) => member.id !== profileId
    );
    console.debug("handleOpenDealValidationModal", data);

    data.fromProfileId = profileId;
    data.toProfileId = toProfileId;
    data.membersId = [profileId, toProfileId];
    data.status = "WaitingForApproval";
    console.debug(data, "Data from proposal");
    setDealData(data);
    setProposalIsOpen(false);
    setSummaryIsOpen(true);
  };

  const handleViewDetails = (deal: Deal, isSender: boolean) => {
    console.debug(deal, isSender, "DATA FROM VIEW");

    dealRepository
      .getById(deal.id)
      .then((dealGetbyId) => {
        setDealData(dealGetbyId);
        setIsDealSender(isSender);
        setIsDealDetailsOpen(true);
        setIsDealReceiver(dealGetbyId.toProfileId === profileId);
      })
      .catch((error) => console.error(error));
  };

  const handleOpendEditDialog = (deal: Deal) => {
    setDealToEdit(deal);
    setIsOpenDealEditDialog(true);
  };

  const handleOpenSelectPayment = (deal: Deal) => {
    setDealData(deal);
    setIsOpenSelectPayment(true);
  };

  const handleCloseEditDialog = (success: boolean, updatedDeal: Deal) => {
    setIsOpenDealEditDialog(false);
    if (success) {
      setDealData(updatedDeal);
    }
  };

  const getMessage = (dealId: string): ChatMessage | undefined => {
    try {
      const foundMessage = messages.find((message) => {
        const messageDealId =
          typeof message.dealReference === "string"
            ? message.dealReference
            : message.dealReference.id;
        console.debug(messageDealId, "IS MATCH", messageDealId === dealId);

        return messageDealId === dealId;
      });
      return foundMessage;
    } catch (err) {
      console.debug(err);
      return undefined;
    }
  };

  useEffect(() => {
    getOtherMembersData();
  }, []);

  useEffect(() => {
    if (chatId) {
      chatRepository
        .getById(chatId)
        .then((getChatResult) => {
          setChat(getChatResult);

          const membersProfile = getChatResult.membersProfile;
          setMembers(membersProfile);

          const others = membersProfile.filter((m) => m.id !== profileId);
          setOtherMembers(others);

          console.debug(others);

          chatRepository
            .emptyUnreadChat(profileId, getChatResult.id)
            .then(() => {})
            .catch((emptyUnreadChatError) =>
              console.error(emptyUnreadChatError)
            );
        })
        .catch((getByIdError) => {
          setError(getByIdError);
        });
    }
  }, [chatId]);

  useEffect(() => {
    if (chatId) {
      const unsubscribe = messagesRef
        .where("chatId", "==", chatId)
        .orderBy("sendDate", "desc")
        .onSnapshot(
          (snapshot) => {
            const result: ChatMessage[] = [];
            snapshot.forEach((doc) => {
              const {
                body,
                chatId,
                sendDate,
                senderId,
                dealReference,
                type,
                attachments,
              } = doc.data();

              let id = doc.id;

              result.push({
                id,
                body,
                chatId,
                sendDate,
                senderId,
                dealReference,
                type,
                attachments,
              });
            });

            setMessages(result.reverse());
            setLoading(false);
            setError(undefined);
          },
          (error) => {
            setMessages([]);
            setLoading(false);
            setError(error);
          }
        );
      return () => {
        unsubscribe();
      };
    }
  }, [chatId]);

  useEffect(() => {
    if (otherMembers.length > 0) {
      getMembersData();
    }
  }, [otherMembers]);

  return (
    <>
      <div style={{ display: "flex", flexGrow: 1 }}>
        {!loading && chat && (
          <ChatContainer>
            <ConversationHeader>
              {otherMembers.length > 1 && (
                <AvatarGroup size="sm">
                  {otherMembers.map((member) => {
                    return (
                      <Avatar
                        src={
                          member.profileImageUrl !== null
                            ? member.profileImageUrl
                            : getAvatar(member.name)
                        }
                        name={member.name}
                      />
                    );
                  })}
                </AvatarGroup>
              )}

              {otherMembers.length == 1 && (
                <Avatar
                  src={
                    otherMembers[0].profileImageUrl !== null
                      ? otherMembers[0].profileImageUrl
                      : getAvatar(otherMembers[0].name)
                  }
                  name={otherMembers[0].name}
                />
              )}

              <ConversationHeader.Content
                userName={getMembersNames()}
                style={{ color: "#141414" }}
              />
            </ConversationHeader>

            <MessageList>
              {messages?.map((message) => {
                try {
                  const member = getMember(message.senderId);
                  const sentTime =
                    message.sendDate !== null
                      ? timestampToData(message.sendDate)
                      : new Date();
                  const fromNow =
                    sentTime !== null ? moment(sentTime).fromNow() : "";

                  return (
                    <ChatMessageResolver
                      message={message}
                      member={member}
                      sentTime={sentTime}
                      fromNow={fromNow}
                      direction={
                        message.senderId !== profileId ? "incoming" : "outgoing"
                      }
                      profileId={profileId}
                      handleViewDetails={handleViewDetails}
                    />
                  );
                } catch (error) {
                  console.error(error);
                  return <div></div>;
                }
              })}
            </MessageList>

            <MessageInput
              placeholder={t("Type message here")}
              aria-controls="chat-menu"
              value={messageInputValue}
              onChange={(val) => setMessageInputValue(val)}
              attachButton={true}
              onAttachClick={handleToggleMenu}
              autoFocus
              onSend={handleSendMessage}
            />
            <InputToolbox
              open={isMenuOpen}
              style={{
                display: "block",
                position: "relative",
              }}>
              {isMenuOpen && (
                <div role="menu" className={classes.menuList}>
                  <li
                    className={classes.menuItem}
                    onClick={handleOpenDealCreateModal}
                    role="menuitem"
                    aria-disabled={
                      !!(chat?.agentId && profileId !== chat.agentId)
                    }>
                    {t("Create Proposal")}
                  </li>
                  <li
                    className={classes.menuItem}
                    onClick={handleOpenUploadFileDialog}
                    role="menuitem"
                    aria-disabled="false">
                    {t("Upload file")}
                  </li>
                </div>
              )}
            </InputToolbox>
          </ChatContainer>
        )}
      </div>

      {proposalIsOpen && (
        <ChatProposal
          open={proposalIsOpen}
          handleClose={() => setProposalIsOpen(false)}
          action={handleOpenDealValidationModal}
          isAgent={!!(chat?.agentId && profileId === chat?.agentId)}
        />
      )}
      {summaryIsOpen && (
        <ChatSummaryProposal
          open={summaryIsOpen}
          handleClose={() => setSummaryIsOpen(false)}
          action={handleSendDealMessage}
          infosProposal={dealData}
          isAgent={!!(chat?.agentId && profileId === chat?.agentId)}
          agentId={chat?.agentId}
          talentId={chat?.talentId}
        />
      )}
      {isDealDetailsOpen && (
        <DealDetails
          deal={dealData}
          isSender={isDealSender}
          isReceiver={isDealReceiver}
          isOpen={isDealDetailsOpen}
          handleClose={() => setIsDealDetailsOpen(false)}
          handleOpenEditDialog={handleOpendEditDialog}
          handleOpenSelectPayment={handleOpenSelectPayment}
          isAgent={!!(chat?.agentId && profileId === chat?.agentId)}
        />
      )}
      {isOpenDealEditDialog && (
        <EditDealDialog
          deal={dealToEdit}
          isOpen={isOpenDealEditDialog}
          handleClose={handleCloseEditDialog}
          message={getMessage}
          isAgent={profileId === chat?.agentId}
        />
      )}
      {isOpenSelectPayment && (
        <SelectPaymentDialog
          isOpen={isOpenSelectPayment}
          handleClose={() => setIsOpenSelectPayment(false)}
          profileId={profileId}
          deal={dealData}
        />
      )}
      {isOnboardingValidOpen && (
        <OnboardingValidation
          isOpen={isOnboardingValidOpen}
          handleClose={() => setIsOnboardingValidOpen(false)}
          message={onboardingValidMessage}
          region={onboardingValidRegion}
        />
      )}
      <VerifyEmailDialog
        open={isVerifyEmailOpen}
        onClose={() => setIsVerifyEmailOpen(false)}>
        To be able to invites you need to verify your email.
      </VerifyEmailDialog>
      {isUploadFileDialogOpen && (
        <ChatAttachmentUpload
          open={isUploadFileDialogOpen}
          handleClose={() => setIsUploadFileDialogOpen(false)}
          chatId={chatId}
          profileId={profileId}
        />
      )}
    </>
  );
};

export default ChatConversation;

import React from "react";
import styles from "./ChatProposal.module.scss";
import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  createStyles,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker, DateTimePicker } from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { ErrorMessage } from "@hookform/error-message";
import CloseButton from "../../shared/CloseButton";
import { useProfileContext } from "../../../ProfileContext";

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#FFF",
    },
    form: {
      width: "100%",
      paddingBottom: 20,
    },
    errorMessage: {
      color: "red",
    },
    input: {
      display: "none",
    },
    buttons: {
      textAlign: "right",
      marginTop: 10,
    },
    closeButton: {
      position: "absolute",
      right: 5,
      top: 5,
      color: theme.palette.grey[500],
      [theme.breakpoints.down("md")]: {
        position: "relative",
        justifyContent: "flex-end",
        marginLeft: "auto",
      },
    },
  })
);

export interface IChatProposalProps {
  open: any;
  handleClose: any;
  setSuccess?: any;
  action: any;
  isAgent: boolean;
}

const ChatProposal: React.FC<IChatProposalProps> = ({
  open,
  handleClose,
  setSuccess,
  action,
  isAgent,
}: IChatProposalProps) => {
  const { t, i18n } = useTranslation();
  const classes = useFormStyles();
  const { profile } = useProfileContext();

  const [loading, setLoading] = React.useState(false);
  const {
    control,
    handleSubmit,
    reset,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any, event: any) => {
    setLoading(true);
    data.amount = parseFloat(
      (
        Math.round(parseFloat(data.amount.replace(",", ".")) * 100) / 100
      ).toFixed(2)
    );
    console.debug(data, "objeto de proposta");

    if (data.amount > 999999.99) {
      setError("amount", {
        type: "manual",
        message: t("The amount exceeds the maximum value"),
      });
      return;
    }

    if (data.comission > 90) {
      setError("comission", {
        type: "manual",
        message: t("The comission cannot be greater than 90%"),
      });
      return;
    }

    data.details = data.details
      ? data.details.replaceAll("\n", "**linebreak**")
      : "";

    data.comission = Math.round(data.comission || 0);

    data.currency =
      profile?.defaultGateway?.region === "brazil" ? "BRL" : "EUR";

    reset(undefined, { keepValues: false });
    action(data);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="chatProposal">
        <CloseButton handleClose={handleClose} />

        <DialogTitle id="form-dialog-title" className={styles.titleProposal}>
          {t("Create Proposal")}
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="job"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Job")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  required
                  id="job"
                  autoFocus
                />
              )}
            />
            {isAgent && (
              <Controller
                control={control}
                name="comission"
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={t("Comission") + " (%)"}
                    fullWidth
                    variant="standard"
                    margin="normal"
                    required
                    type="number"
                    inputProps={{ step: 1, max: 90 }}
                    id="comission"
                  />
                )}
              />
            )}
            <br />
            <Controller
              control={control}
              name="amount"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Price")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  required
                  id="amount"
                  type="number"
                  inputProps={{
                    min: 0.01,
                    max: 999999.99,
                    step: 0.01,
                  }}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="amount"
              render={({ message }) => (
                <span className={classes.errorMessage}>{message}</span>
              )}
            />

            <Controller
              control={control}
              name="details"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Details")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  id="details"
                  className="proposalDetails"
                  multiline
                  rows={4}
                />
              )}
            />

            <Button
              type="submit"
              color="primary"
              className={styles.proposalButton}>
              {t("Save proposal")}
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ChatProposal;

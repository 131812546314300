import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useTranslation } from "react-i18next";


export interface IDialogConfirmationProps {
    show: boolean;
    title: string;
    content: string;
    handleAgree: any;
    handleDisagree: any;
}

const DialogConfirmation = ({ show, title, content, handleAgree, handleDisagree }: IDialogConfirmationProps) => {

    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(show);

    const handleClose = () => {
        setOpen(false);
    };


    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description"        >
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDisagree} color="primary">
                {t("Cancel")}
                </Button>
                <Button onClick={handleAgree} color="primary" autoFocus>
                {t("Ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );

}

export default DialogConfirmation;
import { getRepository } from "fireorm";
import { Chat, ProfileChat } from "../../core/entities/Chat";
import Profile from "../../core/entities/Profile";
import { IRepository } from "../../core/interfaces/IRepository";
import { db } from "./firebasecofig";

export default class ChatRepository implements IRepository<Chat> {
  repository = getRepository(Chat);

  profileChatrepository = getRepository(ProfileChat);

  profileChatRef = db.collection("ProfileChats");

  emptyUnreadChat = (profileId: string, chatId: string) => {
    return this.profileChatRef
      .doc(profileId)
      .collection("Chats")
      .doc(chatId)
      .update({
        unread: 0,
      });
  };

  getChatsByProfileId = (profileId: string): Promise<Chat[]> => {
    this.profileChatRef
      .doc(profileId)
      .collection("Chats")
      .orderBy("lastMessageDate")
      .orderBy("modified")
      .onSnapshot(
        (snapshot) => {
          const result: Chat[] = [];
          snapshot.forEach((doc) => {
            const {
              membersId,
              membersProfile,
              title,
              lastSenderName,
              lastMessageBody,
              lastMessageDate,
              agentId,
              talentId,
            } = doc.data();

            let id = doc.id;

            result.push({
              id,
              membersId,
              membersProfile,
              name: title,
              lastSenderName,
              lastMessageBody,
              lastMessageDate,
              agentId,
              talentId,
            });
          });

          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );

    return Promise.reject();
  };

  getProfileChatByMember = (
    profileId: string,
    memberId: string
  ): Promise<Chat[]> => {
    return this.profileChatRef
      .doc(profileId)
      .collection("Chats")
      .where("membersId", "array-contains", memberId)
      .get()
      .then((snapshot) => {
        const result: Chat[] = [];

        snapshot.forEach((doc) => {
          const {
            membersId,
            membersProfile,
            title,
            lastMessageBody,
            lastMessageDate,
            agentId,
            talentId,
          } = doc.data();

          let id = doc.id;

          result.push({
            id,
            membersId,
            membersProfile,
            name: title,
            lastMessageBody,
            lastMessageDate,
            agentId,
            talentId,
          });
        });

        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  create(entity: Chat): Promise<Chat> {
    throw new Error("Method not implemented.");
  }
  update(entity: Chat): Promise<Chat> {
    throw new Error("Method not implemented.");
  }
  delete(id: string): Promise<void> {
    throw new Error("Method not implemented.");
  }
  getById(id: string): Promise<Chat> {
    return this.repository.findById(id);
  }

  findByAgentAndTalent(
    agentId: string,
    talentId: string,
    fromId: string
  ): Promise<Chat | null> {
    return this.repository
      .whereEqualTo("agentId", agentId)
      .whereEqualTo("talentId", talentId)
      .whereArrayContains("membersId", fromId)
      .findOne();
  }
}

import {
  Box,
  Chip,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  CurrentProfileProvider,
  useCurrentProfileContext,
} from "../../../CurrentProfileContext";
import DealsRepository from "../../../infra/firebase/DealsRepository";
import { db } from "../../../infra/firebase/firebasecofig";
import { ProfileContext } from "../../../ProfileContext";
import DealDetails from "../../Chat/DealDetails/DealDetails";
import EditDealDialog from "../../Chat/EditDealDialog/EditDealDialog";
import SelectPaymentDialog from "../../Chat/SelectPaymentDialog/SelectPaymentDialog";
import LinkTab from "./components/LinkTab";
import ProposalChip from "./components/ProposalChip";
import Proposals from "./components/Proposals";
import TabPanel from "./components/TabPanel";
import styles from "./SettingsProposalComponent.module.scss";
import { Redirect } from "react-router-dom";
import Deal from "../../../core/entities/Deal";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    padding: 20,
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.up("md")]: {
      marginLeft: 24,
    },
  },
}));

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

const initialProposalStatus: ProposalStatus[] = [
  { label: "WaitingForApproval", selected: false, order: 1 },
  { label: "WaitingToBeDone", selected: false, order: 2 },
  { label: "Accepted", selected: false, order: 3 },
  { label: "Rejected", selected: false, order: 4 },
  { label: "Canceled", selected: false, order: 5 },
  { label: "Accomplished", selected: false, order: 6 },
  { label: "Completed", selected: false, order: 7 },
  { label: "All", selected: true, order: 8 },
];

interface ProposalStatus {
  label: string;
  order: number;
  selected: boolean;
}

const SettingsProposalComponent = () => {
  const [proposalStatus, setProposalStatus] = useState(initialProposalStatus);
  const [isDealDetailsOpen, setIsDealDetailsOpen] = useState(false);
  const [isSelectPaymentOpen, setIsSelectPaymentOpen] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isSender, setIsSender] = useState(true);
  const [dealData, setDealData] = useState<Deal>();
  const [selectedDeal, setSelectedDeal] = useState<Deal>();
  const [activeStatus, setActiveStatus] = useState<ProposalStatus>({
    label: "All",
    selected: true,
    order: 8,
  });
  const { t, i18n } = useTranslation();
  const { nickname } = useParams<{ nickname: string }>();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [success, setSuccess] = useState(true);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleSelectStatus = async (status: ProposalStatus) => {
    const proposals = proposalStatus.map((proposalStatus) => {
      proposalStatus.selected = proposalStatus.label === status.label;
      return proposalStatus;
    });
    setActiveStatus(status);
    setProposalStatus(proposals);
  };

  const handleOpenDealDetails = (deal: Deal, isDealSender: boolean) => {
    setIsSender(isDealSender);
    setDealData(deal);
    setSelectedDeal(deal);
    setIsDealDetailsOpen(true);
    console.debug(isDealSender);
  };

  const handleOpenSelectPayment = (deal: Deal) => {
    setSelectedDeal(deal);
    setIsSelectPaymentOpen(true);
  };

  const handleOpenEditDialog = (deal: Deal) => {
    setSelectedDeal(deal);
    setIsEditDialogOpen(true);
  };

  const handleCloseDetail = (successReturn: boolean) => {
    setIsDealDetailsOpen(false);

    setSuccess(false);
    setSuccess(successReturn);
  };

  const handleCloseEdit = (successReturn: boolean) => {
    setIsEditDialogOpen(false);

    setSuccess(false);
    setSuccess(successReturn);
  };

  return (
    <ProfileContext.Consumer>
      {({ profile, error, loading }) => {
        if (!profile && !loading) {
          return <Redirect to="/" />;
        }
        return (
          <CurrentProfileProvider
            nickname={nickname}
            globalProfile={profile}
            globalError={error}
            globalLoading={loading}>
            <div className={classes.root}>
              <Tabs
                variant="fullWidth"
                value={value}
                onChange={handleChange}
                aria-label="nav tabs profile">
                <LinkTab label={t("Sent")} {...a11yProps(0)} />
                <LinkTab label={t("Received")} {...a11yProps(1)} />
              </Tabs>
              <TabPanel value={value} index={0}>
                {proposalStatus.map((proposalStatusMap: ProposalStatus) => (
                  <ProposalChip
                    label={t(proposalStatusMap.label)}
                    selected={proposalStatusMap.selected}
                    handleClick={() => handleSelectStatus(proposalStatusMap)}
                  />
                ))}
                <Proposals
                  type="sent"
                  profile={profile}
                  status={activeStatus.label}
                  success={success}
                  handleOpenDealDetails={handleOpenDealDetails}
                />
              </TabPanel>
              <TabPanel value={value} index={1}>
                {proposalStatus.map((proposalStatusMap: ProposalStatus) => (
                  <ProposalChip
                    label={t(proposalStatusMap.label)}
                    selected={proposalStatusMap.selected}
                    handleClick={() => handleSelectStatus(proposalStatusMap)}
                  />
                ))}
                <Proposals
                  type="received"
                  profile={profile}
                  status={activeStatus.label}
                  success={success}
                  handleOpenDealDetails={handleOpenDealDetails}
                />
              </TabPanel>
            </div>
            <DealDetails
              isOpen={isDealDetailsOpen}
              handleClose={handleCloseDetail}
              isReceiver={dealData?.toProfileId === profile?.id}
              isSender={isSender}
              deal={selectedDeal}
              handleOpenEditDialog={handleOpenEditDialog}
              handleOpenSelectPayment={handleOpenSelectPayment}
              isAgent={false}
            />

            <EditDealDialog
              isOpen={isEditDialogOpen}
              handleClose={handleCloseEdit}
              deal={selectedDeal}
              message={() => undefined}
            />
            <SelectPaymentDialog
              isOpen={isSelectPaymentOpen}
              handleClose={() => setIsSelectPaymentOpen(false)}
              deal={selectedDeal}
              profileId={profile ? profile.id : ""}
            />
          </CurrentProfileProvider>
        );
      }}
    </ProfileContext.Consumer>
  );
};

export default SettingsProposalComponent;

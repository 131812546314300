import { createContext, useEffect, useState, useContext } from "react";
import Profile from "./core/entities/Profile";
import ProfileRepository from "./infra/firebase/ProfileRepository";

export interface ICurrentProfileContextValue {
    profile: Profile | null | undefined;
    error: any | undefined;
    loading: boolean;
    isCurrent: boolean
}
export const CurrentProfileContext = createContext<ICurrentProfileContextValue>({ profile: undefined, error: undefined, loading: true, isCurrent: false });

interface ICurrentProfileProvider {
    children: any;
    nickname?: string;
    globalProfile: Profile | null | undefined;
    globalError: any | undefined;
    globalLoading: boolean;
}


export const CurrentProfileProvider = ({ nickname, globalProfile, globalError, globalLoading, children }: ICurrentProfileProvider) => {

    const [profile, setProfile] = useState<Profile | null | undefined>(null);
    const [error, setError] = useState<{} | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const [isCurrent, setIsCurrent] = useState(false);
    const profileRepository = new ProfileRepository();

    const value = {
        error,
        loading,
        profile,
        isCurrent,
        globalLoading
    }

    useEffect(() => {
        if(globalProfile === undefined) return;

        setLoading(true);
        setIsCurrent(false);

        async function getProfile() {

            if (nickname) {

                try {
                    const profileResult = await profileRepository.getProfileByNickName(nickname);
                    setError(undefined);
                    setProfile(profileResult);
                    setIsCurrent(globalProfile?.uid === profileResult?.uid);
                    setLoading(false);

                } catch (error) {
                    setError(error as any);
                    setProfile(undefined);                    
                    setIsCurrent(false);
                    setLoading(false);
                }

            } else {
                setIsCurrent(true);
                setLoading(false);
            }
        }

        getProfile();

    }, [globalProfile, nickname]);

    return (
        <CurrentProfileContext.Provider value={value}>{children}</CurrentProfileContext.Provider>
    );
}

export const useCurrentProfileContext = () => useContext(CurrentProfileContext);
import { makeStyles, Theme, createStyles, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "./PostThumbnail.module.scss";
import InfiniteScroll from "react-infinite-scroll-component";
import { IPostMedia } from "../IPostMedia";
import { Height } from "@material-ui/icons";
import Post from "../../../../core/entities/Post";
import AudioThumbnailImage from "../../../../images/audio.jpeg";
import clsx from "clsx";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const useImageStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    image: {
      maxWidth: "100%",
      display: "flex",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      cursor: "pointer",
    },
    imageContainer: {
      padding: 1,
      position: "relative",
    },
    moderated: {
      filter: "blur(0.25rem)",
    },
    play: {
      zIndex: 2,
      color: "#FFF",
      backgroundColor: "#141414",
      borderRadius: "100%",
      position: "absolute",
      right: "8px",
      bottom: "8px",
    },
  })
);

const PostThumbnail = ({ url, identifier, onClick, moderated, hasVideo }) => {
  const classes = useImageStyles();
  const imageClassName = moderated
    ? clsx(classes.image, classes.moderated)
    : classes.image;

  return (
    <Grid
      item
      xl={4}
      lg={4}
      md={4}
      sm={4}
      xs={4}
      className={classes.imageContainer}
      key={`grid-${identifier}`}>
      {hasVideo && (
        <PlayArrowIcon className={classes.play} aria-label="Video" />
      )}
      <img
        src={url}
        className={imageClassName}
        onClick={onClick}
        alt={identifier}
        key={`img-${identifier}`}
      />
    </Grid>
  );
};

export interface IPostGallery {
  getMore: any;
  hasMore: boolean;
  posts: Post[];
  onClick: any;
}

export const PostGallery = ({
  getMore,
  hasMore,
  posts,
  onClick,
}: IPostGallery) => {
  const hasVideo = (post: Post) => {
    return post?.medias && post.medias.some((media) => media.type === "video");
  };
  return (
    <Grid container>
      {posts.map((post, index) => {
        return (
          <PostThumbnail
            moderated={post.moderated}
            url={post.thumbnailUrl ? post.thumbnailUrl : AudioThumbnailImage}
            identifier={`post-image-${post.id}`}
            hasVideo={hasVideo(post)}
            onClick={() => onClick(post)}
          />
        );
      })}
    </Grid>
  );
};

export default PostThumbnail;

import React from "react";
import { Redirect } from "react-router";
import styles from "./Notifications.module.scss";

const Notifications = ({
  isAuthenticated = true,
  path: redirectPath = "",
  isInitializing = true,
}) => {
  if (!isAuthenticated && !isInitializing) {
    return <Redirect to={`/login?r=${redirectPath}`} />;
  }
  return (
    <div className={styles.Notifications} data-testid="Notifications">
      Notifications Component
    </div>
  );
};

export default Notifications;

import { Collapse, Container, Grid, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

import { Redirect,  useParams } from "react-router-dom";
import {
  CurrentProfileProvider, useCurrentProfileContext,
} from "../../CurrentProfileContext";
import { ProfileContext, useProfileContext } from "../../ProfileContext";

import { useStyles } from "../../Theme";
import NotFoundComponent from "../NotFound/NotFoundComponent";

import ProfileCover from "./ProfileCover/ProfileCover";
import ProfileDetails from "./ProfileDetails/ProfileDetails";
import ProfileTabs from "./ProfileTabs/ProfileTabs";

import DocumentMeta from 'react-document-meta';
import { useTranslation } from "react-i18next";

import logo from "../../images/logos/logo_assinatura_bg01.png";
import { GenericFieldModerationAlert, IGenericFieldModerationAlertProps } from "../shared/Alerts/ModerationAlerts";
import ProfileController from "./ProfileController";

import { Alert } from "@material-ui/lab";


const useProfileStyles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFF",
    zIndex: 1000,
    marginTop: -10,
  },
  root: {
    minHeight: "100vh",
    position: "relative",
  },
  cover: {
    backgroundColor: "#F8F8F8",
    zIndex: 4,
  },
  wrap: {
    //padding: '60px 50px 50px',
    position: "relative",
    marginTop: "-150px",
    zIndex: 2,
    pointerEvents: "none",
  },
  detail: {
    zIndex: 3,
    pointerEvents: "all",
  },
  portfolio: {
    //paddingLeft: 10,
    pointerEvents: "all",
    [theme.breakpoints.up("md")]: {
      marginTop: "150px",
      marginLeft: 10,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: "3rem",
    },

  },
}));

const Profile = ({
  isAuthenticated = true,
  path: redirectPath = "",
  isInitializing = true,
}) => {

  const { nickname } = useParams<{ nickname: string }>();

  if (!isAuthenticated && !isInitializing) {
    return <Redirect to={`/login?r=${redirectPath}`} />;
  }

  return (
    <ProfileContext.Consumer>
      {({ profile, error, loading }) => {
        if (!nickname && !loading) {
          return <Redirect to={`/${profile?.nickname}`} />;
        }

        return (
          <CurrentProfileProvider
            nickname={nickname}
            globalProfile={profile}
            globalError={error}
            globalLoading={loading}>
            <CurrentProfile />
          </CurrentProfileProvider>
        );
      }}
    </ProfileContext.Consumer>
  );
};


const CurrentProfile = () => {

  const classes = useProfileStyles();
  const globalClasses = useStyles();

  const { t } = useTranslation();
  const { loading, profile, isCurrent } = useCurrentProfileContext();
  const { profile: globalProfile, isAdmin } = useProfileContext();

  const [meta, setMeta] = useState<any>(null);
  const [warningMessage, setWarningMessage] = useState("");


  const profileController = new ProfileController();

  const isModerated = (field: string) => {
    return profile?.moderations ? profileController.verifyIfExistsModerationOnField(field, profile?.moderations) : false;
  }
 

  const callbackFieldModeration = (field) => {
    console.debug("callbackFieldModeration", field);
  }

  useEffect(() => {


    if (profile) {
      const titleNickname =  !(isModerated("name")) ? `${profile.name} (@${profile.nickname})` : `@${profile.nickname}`;
      const title = `${titleNickname} - Matcheam The Way I Do It Is Easy`;
      const followers = 0;
      const followersText = t("followers");
      const following = 0;
      const followingText = t("following");
      const posts = 0;
      const postsText = t("posts");
      const seePortfolioText = t("See the portfolio of");
      const seePortfolio = `${seePortfolioText} ${titleNickname}`;
      const description = `${followers} ${followersText}, ${following} ${followingText}, ${posts} ${postsText} - ${seePortfolio}`
      const canonical = `https://Matcheam.com/${profile.nickname}`;

      const keywords = profile.profileCategories?.map(profileCategory => {
        return profileCategory.skills;
      }).join(",");

      setMeta({
        title: title,
        description: `${profile.headline}`,
        canonical: canonical,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: `Matcheam,talent,${keywords}`
          },
          property: {
            "og:title": title,
            "og:description": description,
            "og:url": canonical,
            "og:image": profile.profileImageUrl ? profile.profileImageUrl : logo,
            "og:type": "profile",
          }
        }
      })
    }
  }, [profile]);

  useEffect(() => {
    if (isAdmin && profile && profile.id !== globalProfile?.id) {
      setWarningMessage(t(`You are in an admin mode. Editing operations will affect the user profile '${profile.name}(${profile.nickname})'`));
    } else {
      setWarningMessage("");
    }
  }, [globalProfile, profile]);



  return (<div>

    {!loading && (profile === null || profile === undefined)
      ?
      <>

        <NotFoundComponent />
      </>
      :
      <DocumentMeta {...meta} extend>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          className={classes.root}>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ width: "100%" }}>
            <Container
              className={classes.cover}
              maxWidth="xl"
              disableGutters>
              <ProfileCover />
            </Container>
          </Grid>
          <Container maxWidth="lg" className={classes.wrap}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                className={globalClasses.sectionDesktop}
              />
              <Grid
                item
                xl={3}
                lg={3}
                md={3}
                sm={12}
                xs={12}
                className={classes.detail}>
                <ProfileDetails />
              </Grid>
              <Grid
                item
                xl={7}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                className={classes.portfolio}>


                {isCurrent && profile?.moderations?.map(moderation => {
                  return (<GenericFieldModerationAlert moderation={moderation} callback={() => {}} />)
                })}

                <Collapse in={warningMessage !== ""} style={{ marginTop: 5 }}>

                  <Alert severity="warning">
                    {warningMessage}
                  </Alert>
                </Collapse>

                <ProfileTabs />
              </Grid>
              <Grid
                item
                xl={1}
                lg={1}
                md={1}
                sm={1}
                xs={1}
                className={globalClasses.sectionDesktop}
              />
            </Grid>

          </Container>
        </Grid>
      </DocumentMeta>
    }
  </div>);
}



export default Profile;

import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute: React.FC<{
  component: React.FC<any>;
  path: string;
  exact: boolean;
  isAuthenticated: boolean;
  isInitializing: boolean;
}> = ({
  component: Component,
  path,
  exact,
  isAuthenticated,
  isInitializing,
}) => {
  return (
    <div>
      {/* {isAuthenticated ? ( */}
      <Route path={path} exact={exact}>
        <Component
          isAuthenticated={isAuthenticated}
          path={path}
          isInitializing={isInitializing}
        />
      </Route>
      {/*   ) : (
         <Redirect to={`/login?r=${path}`} />
       )} */}
    </div>
  );
};

export default PrivateRoute;

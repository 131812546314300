import {
  createTheme,
  makeStyles,
  Theme,
  createStyles,
  alpha,
} from "@material-ui/core/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#008db9",
    },
    secondary: {
      main: "#99cad9",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      primary: "#454545",
      secondary: "#FFFFFF"
    },

  },
  typography: {
    fontFamily: [
      "PT Sans",
      "Staatliches",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        color: "white",
      },
    },
    MuiButton: {
      root: {
        textTransform: "initial",
        color: "#FFFFFF",
      },
      text: {
        textTransform: "initial"
      },
      containedSecondary: {
        color: "white",
      },
      containedPrimary: {
        "&:disabled": {
          color: "white",
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        color: "#FFFFFF",
      },
    },
  },
});

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paddingtop: {
      paddingTop: 10,
    },
    root: {
      flexGrow: 1,
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    textField: {
      backgroundColor: "white",
    },

    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.primary,
      paddingTop: 10,
    },
    paperPrimary: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },

    search: {},
    location: {},
    textoBranco: {
      color: "white",
    },
    button: {
      textTransform: "initial",
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

import {
  createStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, matchPath, useLocation, useRouteMatch } from "react-router-dom";
import styles from "./SettingsMenu.module.scss";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SettingsIcon from "@material-ui/icons/Settings";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import WorkIcon from "@material-ui/icons/Work";
import LockIcon from "@material-ui/icons/Lock";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      
      backgroundColor: theme.palette.background.paper,
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
    },
    listItemRoot: {
      "&:hover": {
        backgroundColor: "#F8F8F8",
        color: theme.palette.text.primary,
        "& .MuiListItemIcon-root": {
          color: theme.palette.text.primary,
        },
        "& .MuiListItemText-root": {
          color: theme.palette.text.primary,
        },
      },
      "&$selected": {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        "&:hover": {
          backgroundColor: theme.palette.secondary.main,
          color: "#FFF",
          "& .MuiListItemIcon-root": {
            color: "#FFF",
          },
          "& .MuiListItemText-root": {
            color: "#FFF",
          },
        },
        "& .MuiListItemIcon-root": {
          color: "#FFF",
        },
      },
    },
    listItemIcon: {
      color: theme.palette.text.primary,
    },
    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

interface IGetListItemProps {
  to: string;
  selectedIndex: number;
  index: number;
  text: string;
  icon: any;
}

const GetListItem = ({
  to,
  selectedIndex,
  index,
  text,
  icon,
}: IGetListItemProps) => {
  const classes = useStyles();

  return (
    <ListItem
      component={Link}
      to={to}
      button
      selected={selectedIndex === index}
      classes={{
        root: classes.listItemRoot,
        selected: classes.selected,
      }}>
      <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  );
};

const SettingsMenu = () => {
  const { t, i18n } = useTranslation();
  let { path, url } = useRouteMatch();
  let location = useLocation();
  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState(1);

  useEffect(() => {
    const locationPath = location.pathname;

    switch (locationPath) {
      case `${url}/general`:
        setSelectedIndex(0);
        break;
      case `${url}/payment`:
        setSelectedIndex(1);
        break;
      case `${url}/profile`:
        setSelectedIndex(2);
        break;
      case `${url}/proposals`:
        setSelectedIndex(3);
        break;
      case `${url}/privacy`:
        setSelectedIndex(4);
        break;
      case `${url}/invites`:
        setSelectedIndex(5);
        break;
      default:
        setSelectedIndex(0);
        break;
    }
  }, [location]);

  return (
    <List component="nav" aria-label="main mailbox folders">
      <GetListItem
        icon={<SettingsIcon />}
        to={`${url}/general`}
        selectedIndex={selectedIndex}
        index={0}
        text={t("General")}
      />
      <GetListItem
        icon={<AccountCircleIcon />}
        to={`${url}/profile`}
        selectedIndex={selectedIndex}
        index={2}
        text={t("Profile")}
      />
      
      <GetListItem
        icon={<AttachMoneyIcon />}
        to={`${url}/payment`}
        selectedIndex={selectedIndex}
        index={1}
        text={t("Payment")}
      />
      <GetListItem
        icon={<WorkIcon />}
        to={`${url}/proposals`}
        selectedIndex={selectedIndex}
        index={3}
        text={t("Proposals")}
      />
      <GetListItem
        icon={<LockIcon />}
        to={`${url}/privacy`}
        selectedIndex={selectedIndex}
        index={4}
        text={t("Privacy")}
      />
      <GetListItem
        icon={<PersonAddIcon />}
        to={`${url}/invites`}
        selectedIndex={selectedIndex}
        index={5}
        text={t("Invites")}
      />
    </List>
  );
};

export default SettingsMenu;

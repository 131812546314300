import firebase from "firebase";
import { createContext, useEffect, useState, useContext } from "react";
import Profile from "./core/entities/Profile";
import { db, getClaimsLevel } from "./infra/firebase/firebasecofig";
import ProfileRepository from "./infra/firebase/ProfileRepository";

export interface IProfileContextValue {
  profile: Profile | null | undefined;
  error: any | undefined;
  loading: boolean;
  isAdmin: boolean;
}

export const ProfileContext = createContext<IProfileContextValue>({
  profile: undefined,
  error: undefined,
  loading: true,
  isAdmin: false,
});

interface IProfileProvider {
  children: any;
}

export const ProfileProvider = ({ children }: IProfileProvider) => {
  const [profile, setProfile] = useState<Profile | null | undefined>(null);
  const [error, setError] = useState<{} | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const [user, setUser] = useState<firebase.User | null>(null);

  const value = {
    error,
    loading,
    profile,
    isAdmin,
  };

  useEffect(() => {
    setLoading(true);

    const unsubscribe = firebase
      .auth()
      .onAuthStateChanged(async (firebaseUser) => {
        if (firebaseUser !== user) {
          setUser(firebaseUser);
        } else {
          setLoading(false);
          return;
        }

        await getClaimsLevel()
          ?.then((level) => setIsAdmin(level === "admin"))
          .catch((getClaimsLevelError) => {
            console.error(getClaimsLevelError);
            setIsAdmin(false);
          });

        if (firebaseUser !== null) {
          const unsubscribeProfiles = db
            .collection("Profiles")
            .where("uid", "==", firebaseUser.uid)
            .limit(1)
            .onSnapshot(
              (querySnapshot) => {
                const result: Profile[] = [];
                querySnapshot.forEach((doc) => {
                  const {
                    id,
                    bio,
                    created,
                    headline,
                    location,
                    modified,
                    name,
                    nickname,
                    profileCategories,
                    profileCoverImageUrl,
                    profileImageUrl,
                    uid,
                    website,
                    profileChatId,
                    hideOnSearch,
                    _tags,
                    stripeCustomerId,
                    payement,
                    defaultGateway,
                    attributes,
                    occupations,
                    moderations,
                    languageCode,
                    countryCode,
                    birthdate,
                  } = doc.data();

                  result.push({
                    id,
                    bio,
                    created,
                    headline,
                    location,
                    modified,
                    name,
                    nickname,
                    profileCategories,
                    profileCoverImageUrl,
                    profileImageUrl,
                    uid,
                    website,
                    profileChatId,
                    hideOnSearch,
                    _tags,
                    stripeCustomerId,
                    payement,
                    defaultGateway,
                    attributes,
                    occupations,
                    moderations,
                    languageCode,
                    countryCode,
                    birthdate,
                  });
                });

                const userResult = result[0];

                setError(undefined);
                setProfile(userResult);
                setLoading(false);
              },
              (onSnapshotError) => {
                console.error(onSnapshotError);
                setError(onSnapshotError);
                setProfile(null);
                setLoading(false);
              }
            );

          return () => unsubscribeProfiles();
        } else {
          const customError = { message: "The Firebase user is null." };
          console.error(customError);
          setError(customError);
          setProfile(null);
          setLoading(false);
        }
      });

    return () => unsubscribe();
  }, []);

  return (
    <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
  );
};

export interface IUseProfileProps {
  nickname?: string;
}

export const useProfileContext = () => useContext(ProfileContext);

import React, { useEffect, useRef, useState } from "react";
import styles from "./Search.module.scss";
import {
  InstantSearch,
  SearchBox,
  Hits,
  HierarchicalMenu,
  Highlight,
  RefinementList,
  Configure,
  Pagination,
  RangeInput,
  ClearRefinements,
  CurrentRefinements,
  Stats,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  createStyles,
  Divider,
  Grid,
  InputLabel,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useStyles } from "../../Theme";
import { useTranslation } from "react-i18next";
import {
  useLocation,
  useParams,
  Link as LinkRouter,
  useHistory,
} from "react-router-dom";
import { indexName, searchClient } from "../../infra/algolia/config";
import qs from "qs";
import { whiteList } from "../../Settings";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { animateScroll as scroll } from "react-scroll";
import AttributeRepository from "../../infra/firebase/AttributeRepository";
import { Attribute } from "../../core/entities/Attribute";
import NumberFilter from "./AdvancedFilters/NumberFilter";
import { useForm } from "react-hook-form";
import SelectFilter from "./AdvancedFilters/SelectFilter";
import ProfileController from "../Profile/ProfileController";
import { getAvatar } from "../../utils/utils";

const useSearchStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    small: {
      width: theme.spacing(3),
      height: theme.spacing(3),
    },
    large: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    cardRoot: {
      display: "flex",
    },
    cardDetails: {
      display: "flex",
    },
    cardAvatar: {
      display: "flex",
    },
    cardContent: {
      flex: "flex",
      marginLeft: 20,
    },
    pagination: {
      marginTop: 10,
      marginBottom: 10,
    },
  })
);

const DEBOUNCE_TIME = 700;
const createURL = (state) => `?${qs.stringify(state)}`;
const searchStateToUrl = (location, searchState) =>
  searchState ? `${location.pathname}${createURL(searchState)}` : "";
const urlToSearchState = (location) => qs.parse(location.search.slice(1));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Search = () => {
  let query = useQuery();
  const q = query.get("q");
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  const globalClasses = useStyles();
  const classes = useSearchStyles();
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState(q !== null ? q : "");

  const location = useLocation();
  const history = useHistory();
  const [searchState, setSearchState] = useState(urlToSearchState(location));

  const shouldShowAcordeon = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md")
  );

  const setStateId = useRef<any>();

  useEffect(() => {
    const nextSearchState = urlToSearchState(location);

    if (JSON.stringify(searchState) !== JSON.stringify(nextSearchState)) {
      setSearchState(nextSearchState);
    }
  }, [location]);

  function onSearchStateChange(nextSearchState) {
    clearTimeout(setStateId.current);

    setStateId.current = setTimeout(() => {
      history.push(
        searchStateToUrl(location, nextSearchState),
        nextSearchState
      );
    }, DEBOUNCE_TIME);

    setSearchState(nextSearchState);
  }

  const fetchFilters = async () => {
    const filters = await attributeRepository.getAllByUse("profileAttributes");
    setAttributes(
      filters.sort(
        (a, b) => a.uses["profileAttributes"] - b.uses["profileAttributes"]
      )
    );
  };

  useEffect(() => {
    fetchFilters();
  }, []);

  console.log("searchState", searchState);

  return (
    <Container maxWidth="lg" disableGutters>
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        searchState={searchState}
        onSearchStateChange={onSearchStateChange}
        createURL={createURL}>
        <Configure hitsPerPage={10} filters="NOT _tags:hidden" />
        {/* <Configure hitsPerPage={10} filters="isAvailable:true" /> */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start">
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            className={globalClasses.sectionDesktop}></Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <div style={{ marginLeft: 10, marginRight: 10 }}>
              {shouldShowAcordeon ? (
                <AccordionFilters attributes={attributes} />
              ) : (
                <NormalFilters attributes={attributes} />
              )}
            </div>
          </Grid>
          <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
            <div style={{ marginBottom: 10 }}>
              <SearchBox
                showLoadingIndicator
                autoFocus
                defaultRefinement={searchValue}
              />
            </div>
            <Hits hitComponent={Hit} />
            <Grid
              container
              justifyContent="center"
              alignContent="center"
              alignItems="center"
              className={classes.pagination}>
              <Pagination
                showFirst={true}
                showLast={true}
                showPrevious={true}
                showNext={true}
                padding={2}
              />
            </Grid>
          </Grid>
        </Grid>
      </InstantSearch>
    </Container>
  );
};

const useAccordionStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 10,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const AccordionFilters = ({ attributes }) => {
  const classes = useAccordionStyles();
  const { t, i18n } = useTranslation();

  return (
    <Accordion className={classes.root}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Typography className={classes.heading}>{t("Filters")}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Filters attributes={attributes} />
      </AccordionDetails>
    </Accordion>
  );
};

const NormalFilters = ({ attributes }) => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Typography variant="h4" paragraph color="primary">
        {t("Filters")}
      </Typography>
      <Divider variant="fullWidth" style={{ marginBottom: 10 }} />
      <Filters attributes={attributes} />
    </div>
  );
};

const Filters = ({ attributes }) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language.split("-")[0]);

  const [hierarchicalCategorieslvl0, sethierarchicalCategorieslvl0] =
    useState("");
  const [hierarchicalCategorieslvl1, sethierarchicalCategorieslvl1] =
    useState("");

  useEffect(() => {
    let langFilter =
      whiteList.findIndex((w) => w === lang) !== -1 ? lang : "en";

    sethierarchicalCategorieslvl0(
      `translatedHierarchicalCategories.lvl0.${langFilter}`
    );
    sethierarchicalCategorieslvl1(
      `translatedHierarchicalCategories.lvl1.${langFilter}`
    );
  }, []);

  return (
    <div>
      {/* <Stats /> */}
      <ClearRefinements clearsQuery
        translations={{
          reset: t('Clear filters'),
        }} />
      <Typography variant="h6">{t("Categories")}</Typography>
      <HierarchicalMenu
        className="hide-count"
        attributes={[hierarchicalCategorieslvl0, hierarchicalCategorieslvl1]}
      />
      <Typography variant="h6">{t("Locations")}</Typography>
      <RefinementList
        className="hide-count"
        attribute="location.name"
        facetOrdering
        limit={10}
        showMore
        showMoreLimit={30}
        searchable
        translations={{
          showMore(expanded) {
            return expanded ? t("Show less") : t("Show more");
          },
          noResults: t("No results"),
          submitTitle: t("Submit your search query."),
          resetTitle: t("Clear your search query."),
          placeholder: t("Search locations here..."),
        }}
      />

      <Typography variant="h6">{t("Advanced Filters")}</Typography>
      <div style={{ maxWidth: 300, marginBottom: 8 }}>
        <InputLabel>{t("Occupation")}</InputLabel>
        <RefinementList
          className="hide-count"
          attribute="occupations"
          transformItems={(items) =>
            items.map((item) => ({
              ...item,
              label: t(item.label),
            }))
          }
        />
      </div>
      {/* <div style={{ maxWidth: 300, marginBottom: 8 }}>
        <InputLabel style={{ marginBottom: 4 }}>{t("Age")}</InputLabel>
        <RangeInput
          className="hide-count"
          attribute="age"
          translations={{
            submit: t("Search"),
            separator: " - ",
          }}
        />
      </div>
      <AdvancedFilters attributes={attributes} /> */}
    </div>
  );
};

const attributeRepository = new AttributeRepository();
const AdvancedFilters = ({ attributes }) => {
  return (
    <>
      {attributes.map((attribute: Attribute) => {
        if (attribute.type === "number") {
          return <NumberFilter key={attribute.id} attribute={attribute} />;
        } else if (attribute.type === "choice") {
          return <SelectFilter key={attribute.id} attribute={attribute} />;
        }
        return <></>;
      })}
    </>
  );
};

const profileController = new ProfileController();

const isModerated = (hit, field) => {
  return hit.moderations
    ? profileController.verifyIfExistsModerationOnField(field, hit?.moderations)
    : false;
};

const getProfileImage = (hit) => {
  const moderated = isModerated(hit, "profileImageUrl");
  return hit?.profileImageUrl && !moderated
    ? hit?.profileImageUrl
    : getAvatar(hit?.name);
};

const Hit = ({ hit }) => {
  const classes = useSearchStyles();

  return (
    <LinkRouter to={`/${hit.nickname}`}>
      <div className={classes.cardRoot}>
        <div className={classes.cardDetails}>
          <div className={classes.cardAvatar}>
            <Avatar
              alt="Avatar"
              src={getProfileImage(hit)}
              className={classes.large}
            />
          </div>
          <div className={classes.cardContent}>
            <Typography variant="subtitle1">
              {!isModerated(hit, "name") && (
                <Highlight attribute="name" hit={hit} />
              )}
            </Typography>
            <Typography variant="body1">
              <Highlight attribute="nickname" hit={hit} />
            </Typography>
            <Typography variant="body2">
              {!isModerated(hit, "headline") && (
                <Highlight attribute="headline" hit={hit} />
              )}
            </Typography>
          </div>
        </div>
      </div>
    </LinkRouter>
  );
};

export default Search;

import { getRepository } from 'fireorm';
import Experience from "../../core/entities/Experience";
import { IRepository } from "../../core/interfaces/IRepository";
import { CollectionNames } from '../../utils/constants';
import { db } from './firebasecofig';



export default class ExperienceRepository implements IRepository<Experience>{

    
    repository = getRepository(Experience);

    createOrUpdate(entity: Experience): Promise<Experience> {
        return entity.id ? this.repository.update(entity) : this.repository.create(entity);
    }

    create(entity: Experience): Promise<Experience> {
        return this.repository.create(entity);
    }
    update(entity: Experience): Promise<Experience> {
        return this.repository.update(entity);
    }
    delete(id: string): Promise<void> {
        return this.repository.delete(id);
    }
    getById(id: string): Promise<Experience> {
        return this.repository.findById(id);
    }

    getExperiencesByProfile = (userId: string): Promise<Experience[]> => {

         return this.repository
             .whereEqualTo(experience => experience.profileId, userId)
             .orderByDescending(experience => experience.startedDate)
             .find();



    }

    getExperiencesByProfileSnapshot = (profileId: string) => {
        return db.collection(CollectionNames.Experience)
            .where('profileId', '==', profileId)
            .orderBy('startDate', 'desc')
            .onSnapshot(
                snapshot => {
                    const result: Experience[] = [];

                    snapshot.forEach((doc) => {
                        const {
                            id,
                            profileId,
                            company,
                            site,
                            location,
                            title,
                            startedDate,
                            endDate,
                            details,
                            employmentType,
                        } = doc.data();

                        result.push({
                            id,
                            profileId,
                            company,
                            site,
                            location,
                            title,
                            startedDate,
                            endDate,
                            details,
                            employmentType,
                        });

                    });

                    return Promise.resolve(result);

                },
                error => {
                    return Promise.reject(error);
                }
            );
    }
}
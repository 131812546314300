import { Collection } from 'fireorm';

@Collection('Experiences')
export default class Experience {
    id: string;
    profileId: string | null;
    company: string | null;
    site: string | null;
    location: string | null;
    title: string | null;
    startedDate: any | null;
    endDate?: any | null;
    details: string | null;
    employmentType: string | null;
    isCurrent?: boolean;
}

import { Button, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ShowDocument from "./ShowDocument";

const useProfileStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        position: "relative",
    },
}));

export interface IShowDocumentPageProps {
    documentName: string;
}

const ShowDocumentPage = ({ documentName }: IShowDocumentPageProps) => {
    const classes = useProfileStyles();
    const { t, i18n } = useTranslation();

    return (<Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.root}>
        <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ width: "100%" }}>
            <Container maxWidth="lg" >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start" style={{ width: "100%" }}>
                    <Grid item style={{ width: "100%" }}>
                        <ShowDocument documentName={documentName} />
                    </Grid>

                </Grid>
            </Container>
        </Grid>
    </Grid>);

}

export default ShowDocumentPage;
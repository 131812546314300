import { Collection } from "fireorm";

@Collection("Attributes")
export class Attribute {
  id: string;
  name: string;
  displayName: Translated;
  options?: Translated[];
  type: string;
  unit?: string;
  uses: { [key: string]: any };
  multiple?: boolean;
  maxLength?: number;
  minLength?: number;
  decimal?: number;
  others?: boolean;
  min?: number;
  max?: number;
}

export class Translated {
  en: string;
  pt: string;
  es: string;
  de: string;
}

import { ErrorMessage } from "@hookform/error-message";
import {
  makeStyles,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  CircularProgress,
  Theme,
  createStyles,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ChatMessage } from "../../../core/entities/Chat";
import DealsRepository from "../../../infra/firebase/DealsRepository";
import MessageRepository from "../../../infra/firebase/MessageRepository";
import styles from "./EditDealDialog.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import CloseButton from "../../shared/CloseButton";
import Deal from "../../../core/entities/Deal";

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#FFF",
    },
    form: {
      width: "100%",
      paddingBottom: 20,
    },
    errorMessage: {
      color: "red",
    },
    input: {
      display: "none",
    },
    buttons: {
      textAlign: "right",
      marginTop: 10,
    },
    closeButton: {
      position: "absolute",
      right: 5,
      top: 5,
      color: theme.palette.grey[500],
      [theme.breakpoints.down("md")]: {
        position: "relative",
        justifyContent: "flex-end",
        marginLeft: "auto",
      },
    },
  })
);

interface EditDealDialogProps {
  isOpen: boolean;
  deal?: Deal;
  handleClose(success: boolean, updatedDeal: Deal): void;
  message(dealId: string): ChatMessage | undefined;
  isAgent?: boolean;
}

const dealRepository = new DealsRepository();
const messageRepository = new MessageRepository();
// TODO fazer o fetch internamente
export default function EditDealDialog({
  isOpen,
  deal,
  handleClose,
  message,
  isAgent = false,
}: EditDealDialogProps) {
  const { t, i18n } = useTranslation();
  const classes = useFormStyles();
  const [defaultValues, setDefaultValues] = useState<Deal>();
  const [isLoading, setIsLoading] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (deal) {
      console.debug(deal, "DEAL SET");
      setValue("job", deal.job);
      setValue("amount", deal.amount);
      setValue(
        "details",
        deal.details ? deal.details.replaceAll("**linebreak**", "\n") : ""
      );
      setValue("comission", deal.comission);
    }
  }, [deal]);

  if (!deal) {
    return (
      <Dialog open={isOpen} onClose={() => handleClose(false, {} as Deal)}>
        <CircularProgress />
      </Dialog>
    );
  }

  const onSubmit = async (data: any) => {
    console.debug(data, "BASE DATA");
    setIsLoading(true);

    deal.job = data.job;
    deal.amount =
      deal.amount === data.amount
        ? deal.amount
        : parseFloat(
            (
              Math.round(parseFloat(data.amount.replace(",", ".")) * 100) / 100
            ).toFixed(2)
          );
    deal.details = data.details
      ? data.details.replaceAll("\n", "**linebreak**")
      : "";

    if (deal.amount > 999999.99) {
      setError("amount", {
        type: "manual",
        message: t("The amount cannot be greater than R$ 999.999,99"),
      });
      return;
    }

    if (data.comission > 90) {
      setError("comission", {
        type: "manual",
        message: t("The comission cannot be greater than 90%"),
      });
      return;
    }

    deal.comission = Math.round(data.comission || 0);

    console.log(deal);
    const updatedDeal = await dealRepository.update(deal);

    // const baseChatMessage = message(deal.id);
    const baseChatMessage = await messageRepository.repository
      .whereEqualTo("dealReference", updatedDeal.id)
      .findOne();
    if (baseChatMessage) {
      const messageToCreate = new ChatMessage();
      messageToCreate.dealReference = baseChatMessage.dealReference;
      messageToCreate.chatId = baseChatMessage.chatId;
      messageToCreate.body = baseChatMessage.body;
      messageToCreate.senderId = baseChatMessage.senderId;
      messageToCreate.attachments = baseChatMessage.attachments;
      console.debug(messageToCreate, "MESSAGE TO CREATE");
      const newMesasge = await messageRepository.create(messageToCreate);
      console.debug(newMesasge, "newMesasge");
    }
    setIsLoading(false);
    handleClose(true, deal);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => handleClose(false, deal)}
      aria-labelledby="form-dialog-title"
      className="chatProposal">
      <CloseButton handleClose={() => handleClose(false, deal)} />
      <DialogTitle id="form-dialog-title" className={styles.titleProposal}>
        {t("Update Proposal")}
      </DialogTitle>
      <DialogContent>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="job"
            defaultValue={deal.job}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("Job")}
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="job"
                autoFocus
              />
            )}
          />
          <br />
          {isAgent && (
            <Controller
              control={control}
              name="comission"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Comission") + " (%)"}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  required
                  type="number"
                  inputProps={{ step: 1, max: 90 }}
                  id="comission"
                />
              )}
            />
          )}
          <Controller
            control={control}
            name="amount"
            defaultValue={deal.amount}
            render={({ field }) => (
              <TextField
                {...field}
                label={t("Preço")}
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="amount"
                type="number"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="amount"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />

          <Controller
            control={control}
            name="details"
            defaultValue={
              deal.details ? deal.details.replaceAll("**linebreak**", "\n") : ""
            }
            render={({ field }) => (
              <TextField
                {...field}
                label={t("Details")}
                fullWidth
                variant="standard"
                margin="normal"
                id="details"
                className="proposalDetails"
                multiline
                rows={4}
              />
            )}
          />
          <Button
            type="submit"
            color="primary"
            disabled={isLoading}
            onClick={() => clearErrors()}
            className={styles.proposalButton}>
            {isLoading ? "Loading..." : t("Save Proposal")}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}

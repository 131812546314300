import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Divider,
} from "@material-ui/core";
import firebase from "firebase";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { db, functions } from "../../../infra/firebase/firebasecofig";
import { useProfileContext } from "../../../ProfileContext";
import queryString from "query-string";
import VerifyEmailDialog from "../../shared/VerifyEmailDialog";
import Skeleton from "@material-ui/lab/Skeleton";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import GermanyFlag from "../../../images/menu/germany.png";
import BrazilFlag from "../../../images/menu/brasil.png";
import EuaFlag from "../../../images/menu/eua.png";

import AmexLogo from "../../../images/cards/american_express.png";
import ChinaUnionLogo from "../../../images/cards/china_union.png";
import DiscoverLogo from "../../../images/cards/discover.png";
import JcbLogo from "../../../images/cards/jcb.png";
import MastercardLogo from "../../../images/cards/mastercard2.png";
import VisaLogo from "../../../images/cards/visa_electron.png";

const cards = {
  amex: AmexLogo,
  unionpay: ChinaUnionLogo,
  discover: DiscoverLogo,
  jcb: JcbLogo,
  mastercard: MastercardLogo,
  visa: VisaLogo,
};

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
    },
    cardContainer: {
      paddingLeft: 20,
      paddingTop: 16,
      paddingBottom: 16,
      backgroundColor: "#FFF",
      borderColor: theme.palette.primary.main,
      borderRadius: 8,
      marginBottom: 16,
      background: "none",

      textAlign: "left",
      width: "100%",
      // cursor: "pointer",
      border: "1px solid #dcdcdc",
      alignItems: "center",
      transition: "all 200ms ease-in-out",
      margin: "10px 0",
    },
    flag: {
      width: 50,
    },
    button: {
      width: "100%",
      textTransform: "initial",
    },
    countryToggleButton: {
      display:"block", 
      textTransform: "initial"
    },
    countryToggleButtonLabel: {
      fontWeight: 600
    }
  })
);

export default function SettingsPaymentComponent() {
  const { loading, profile } = useProfileContext();
  const { t } = useTranslation();
  const classes = useStyles();
  const [paymentMessage, setPaymentMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { search } = useLocation();
  const [showOnboarding, setShowOnboarding] = useState(true);
  const [region, setRegion] = useState("");
  const [shouldValidate, setShouldValidate] = useState(
    queryString.parse(search).validation === "true"
  );
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);
  const [isRegionLoading, setIsRegionLoading] = useState(false);
  const [isOnboardingLoding, setIsOnboardLoading] = useState(false);
  const [isPaymentMethodsLoading, setIsPaymentMethodsLoading] = useState(false);
  const [addPaymentMethodLoading, setAddPaymentMethodLoading] = useState(false);
  const [isAccountsLoading, setIsAccountsLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  const handleChange = async (event, newRegion) => {
    if (newRegion) {
      setRegion(newRegion);
      await changeRegion(newRegion);
    }
  };

  const handleCallOnBoarding = async () => {
    try {
      if (!firebase.auth().currentUser?.emailVerified) {
        setIsVerifyEmailOpen(true);
        return;
      }
      if (!region) {
        setPaymentMessage(
          t("Please select a region before adding yout bank information")
        );
        return;
      }
      setIsLoading(true);

      const caller = functions.httpsCallable("profiles-onCallOnboarding");
      const response = await caller({
        id: profile?.id,
        region: region,
        returnUrl: `${window.location.href}?validation=true`,
        refreshUrl: window.location.href,
      });

      const url = response.data.url;
      if (url) {
        window.location.href = url;
      } else {
        setPaymentMessage(t("You bank info was already added."));
      }
    } catch (err) {
      console.debug(err);
      setIsLoading(false);
      setPaymentMessage(t("An error occurred, please try again."));
    }
  };

  useEffect(() => {
    if (profile) {
      getDefaultRegion(profile.id);
    }
  }, [profile]);

  useEffect(() => {
    if (profile && region) {
      validate();
      getPaymentMethods();
      getAccounts();
    }
  }, [profile, region]);

  const validate = async () => {
    try {
      setShowOnboarding(false);
      setIsOnboardLoading(true);
      const caller = functions.httpsCallable(
        "payments-onCallValidateOnboardingCompleted"
      );
      const validateOnboardingCompletedResponse = await caller({
        id: profile?.id,
        region: region,
      });

      console.debug(
        "ValidateOnboardingCompletedResponse",
        validateOnboardingCompletedResponse.data
      );

      const result = validateOnboardingCompletedResponse.data.status;
      setShowOnboarding(result !== "success");
    } catch (err) {
      console.debug(err);
      setShowOnboarding(true);
    } finally {
      setIsOnboardLoading(false);
    }
  };

  const getPaymentMethods = async () => {
    setPaymentMethods([]);

    try {
      setIsPaymentMethodsLoading(true);
      if (profile?.defaultGateway?.customerId) {
        const caller = functions.httpsCallable(
          "payments-onCallListPaymentMethods"
        );
        const listPaymentMethodsResponse = await caller({
          stripeCustomerId: profile?.defaultGateway?.customerId,
          region: region,
        });

        console.debug("listPaymentMethodsResponse", listPaymentMethodsResponse);
        setPaymentMethods(listPaymentMethodsResponse.data);
      }
    } catch (err) {
      setPaymentMethods([]);
      console.debug(err);
    } finally {
      setIsPaymentMethodsLoading(false);
    }
  };

  const getDefaultRegion = async (id: string) => {
    try {
      setIsRegionLoading(true);
      console.debug("getDefaultRegion", profile?.defaultGateway);

      if (profile && profile.defaultGateway) {
        setRegion(profile.defaultGateway.region);
      } else {
        setRegion("brazil");
      }
    } catch (err) {
      console.debug(err);
    } finally {
      setIsRegionLoading(false);
    }
  };

  const handleAddPaymentMethod = async () => {
    try {
      if (!firebase.auth().currentUser?.emailVerified) {
        setIsVerifyEmailOpen(true);
        return;
      }

      setAddPaymentMethodLoading(true);
      const customerIdCaller = functions.httpsCallable(
        "profiles-onCallCreateCustomer"
      );

      const createCustomerResponse = await customerIdCaller({
        id: profile?.id,
        region: region,
      });

      console.debug("createCustomerResponse", createCustomerResponse);

      const caller = functions.httpsCallable(
        "payments-onCallCreateCheckoutSession"
      );
      const createCheckoutSessionResponse = await caller({
        stripeCustomerId: createCustomerResponse.data.stripeCustomerId,
        successUrl: window.location.href,
        cancelUrl: window.location.href,
        region: region,
      });

      console.debug(
        "createCheckoutSessionResponse",
        createCheckoutSessionResponse
      );

      window.location.href = createCheckoutSessionResponse.data.url;
    } catch (err) {
      console.debug(err);
    } finally {
      setAddPaymentMethodLoading(false);
    }
  };

  const getAccounts = async () => {
    try {
      setIsAccountsLoading(true);
      const caller = functions.httpsCallable("payments-onCallRetrieveAccount");
      const retrieveAccountResponse = await caller({
        id: profile?.id,
        region: region,
      });

      console.debug("retrieveAccountResponse", retrieveAccountResponse);

      if (retrieveAccountResponse.data === null) return;

      if (retrieveAccountResponse.data.status != "fail") {
        setAccounts(retrieveAccountResponse.data);
      } else {
        setAccounts([]);
        console.error(retrieveAccountResponse.data.message);
      }
    } catch (err) {
      setAccounts([]);
      console.error(err);
    } finally {
      setIsAccountsLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3">{t("Payment")}</Typography>

      <Typography variant="h5" style={{ marginTop: 16 }}>
        {t("Country")}
      </Typography>

      <Typography variant="body1" style={{ marginBottom: 10 }}>
        {t("Choose a country to set up your payment informations")}
      </Typography>

      <ToggleButtonGroup
        size="large"
        value={region}
        color="primary"
        exclusive
        onChange={handleChange}>
        <ToggleButton value="brazil" className={classes.countryToggleButton}>
          <div ><img src={BrazilFlag} alt="Brazil" className={classes.flag} /></div>
          <Typography variant="subtitle1" align="center" display="block" className={classes.countryToggleButtonLabel}>
            {t("Brazil")}
          </Typography>
        </ToggleButton>
        <ToggleButton value="europe" className={classes.countryToggleButton}>
          <img src={GermanyFlag} alt="Europe" className={classes.flag} />

          <Typography variant="subtitle1" align="center" display="block" className={classes.countryToggleButtonLabel}>
            {t("Germany")}
          </Typography>

        </ToggleButton>
      </ToggleButtonGroup>

      <Divider variant="fullWidth" style={{ marginTop: 10 }} />

      <Typography variant="h5" style={{ marginTop: 16 }}>
        {t("Bank Account")}
      </Typography>

      {isAccountsLoading ? (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </>
      ) : (
        <>
          {accounts && accounts.length > 0 && (
            <Typography variant="body1" style={{ marginBottom: 10 }}>
              {t(
                "It is your registered bank account where you will receive payments"
              )}
            </Typography>
          )}

          {accounts.map((account: any) => (
            <div className={classes.cardContainer}>
              <div style={{ display: "flex", gap: 4, flexDirection: "column" }}>
                <Typography
                  variant="body1"
                  style={{ textTransform: "capitalize" }}>
                  {account.bankName.toLowerCase()}
                </Typography>
                <Typography variant="body1">{account.last4}</Typography>
              </div>
            </div>
          ))}
        </>
      )}


      {isOnboardingLoding && (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
        </>
      )}


      {(!isOnboardingLoding && showOnboarding) && (
        <>
          <Typography variant="body1" paragraph>
            {t(
              "To start receiving your payments, you must first include your bank account information."
            )}
          </Typography>
          <Button
            size="large"
            variant="contained"
            color="primary"
            disabled={isLoading}
            className={classes.button}
            onClick={handleCallOnBoarding}>
            {isLoading ? t("Loading...") : t("Add your bank information")}
          </Button>
          <Typography
            variant="body1"
            paragraph
            style={{ color: "red", margin: "1rem 0" }}>
            {paymentMessage}
          </Typography>
        </>
      )}




      <Divider variant="fullWidth" style={{ marginTop: 10 }} />

      <Typography variant="h5" style={{ marginTop: 16 }}>
        {t("Cards")}
      </Typography>
      <Typography variant="body1" style={{ marginBottom: 10 }}>
        {t("Add or edit your cards to make payments")}
      </Typography>
      <>
        {isPaymentMethodsLoading ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </>
        ) : (
          <>
            {paymentMethods.map(({ card }: any) => (
              <div className={classes.cardContainer}>
                <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                  <div>
                    <img src={cards[card.brand]} width="32px" />
                  </div>
                  <Typography
                    variant="body1"
                    style={{ textTransform: "capitalize" }}>
                    {card.brand}
                  </Typography>
                </div>
                <Typography variant="body1" display="inline">
                  .... {card.last4}
                </Typography>
                &nbsp;&nbsp;
                <Typography variant="body1" display="inline">
                  {card.exp_month}/{card.exp_year}
                </Typography>
              </div>
            ))}
            <Button
              size="large"
              variant="contained"
              color="primary"
              disabled={addPaymentMethodLoading}
              onClick={handleAddPaymentMethod}
              className={classes.button}>
              {addPaymentMethodLoading ? t("Loading...") : t("Add a new card")}
            </Button>
          </>
        )}
      </>

      {isVerifyEmailOpen && (
        <VerifyEmailDialog
          open={isVerifyEmailOpen}
          onClose={() => setIsVerifyEmailOpen(false)}>
          {t("To be able to do this you need to verify your email.")}
        </VerifyEmailDialog>
      )}
    </div>
  );

  async function changeRegion(region: string) {
    try {
      const caller = functions.httpsCallable(
        "payments-onCallSetDefaultGateway"
      );
      await caller({
        id: profile?.id,
        region: region,
      });
    } catch (err) {
      console.debug(err);
    }
  }
}

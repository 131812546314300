import { useState } from "react";

import {
  Container,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";

import ImageEditor from "../../shared/ImageEditor";
import { useCurrentProfileContext } from "../../../CurrentProfileContext";
import Skeleton from "@material-ui/lab/Skeleton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import defaultCover from "../../../images/profile/cover-default.jpeg";

import ProfileController from "../ProfileController";

import { useProfileContext } from "../../../ProfileContext";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& > *": {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: "none",
    },
    iconButton: {
      backgroundColor: "#e0e0e0",
      "&:hover, &.Mui-focusVisible": { backgroundColor: "#f3f3f3" },
    },
    // coverCurrentContainer: {
    //   height: 320,
    //   position: 'relative',
    //   padding: 0,
    //   background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,153,153,1) 63%, rgba(0,153,153,1) 100%)',
    //   '&:hover': {
    //     "& $middle": {
    //       opacity: 1
    //     },
    //     "& $coverImage": {
    //       opacity: 0.3
    //     }
    //   },
    // },
    moderatedCoverContainer: {
      height: 320,
      position: "relative",
      padding: 0,
      backgroundColor: "red",
    },
    coverContainer: {
      height: 320,
      position: "relative",
      padding: 0,
    },
    coverImage: {
      transition: ".3s ease-in-out",
      objectFit: "cover",
    },
    moderatedCoverImage: {
      transition: ".3s ease-in-out",
      objectFit: "cover",
      filter: "blur(0.25rem)",
    },
    button: {
      margin: theme.spacing(1),
      textTransform: "initial",
    },
    editCoverButton: {
      textTransform: "initial",
      backgroundColor: "#F8F8F8",
      position: "absolute",
      bottom: "5px",
      right: "25px",
    },

    middle: {
      transition: ".5s ease",
      opacity: 0,
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    },
    editCover: {
      position: "absolute",
      top: 10,
      right: 10,
    },
    textHover: {
      color: "white",
    },
  })
);

const myTheme = {
  // Theme object to extends default dark theme.
};

const ProfileCover = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const { error, loading, profile, isCurrent } = useCurrentProfileContext();
  const {
    error: globalError,
    loading: globalLoading,
    profile: globalProfile,
    isAdmin
  } = useProfileContext();

  const coverImage = ""; //defaultCover;
  const profileController = new ProfileController();

  const [openSuccess, setSuccess] = useState(true);
  const [openEdit, setOpenEdit] = useState(false);
  const handleClickOpen = () => {
    setOpenEdit(true);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };


  const isModerated = () => {
    return profile?.moderations ? profileController.verifyIfExistsModerationOnField("profileCoverImageUrl", profile?.moderations) : false;
  }

  const getProfileCoverImage = () => {

    const moderated = isModerated();
    var url = profile?.profileCoverImageUrl ? profile?.profileCoverImageUrl : coverImage;

    if (moderated) {
      url = (isCurrent || isAdmin) ? url : coverImage;
    }

    return url;

  }


  const getProfileCoverImageClass = () => {

    const moderated = isModerated();
    return moderated && (isCurrent || isAdmin) ? classes.moderatedCoverImage : classes.coverImage;

  }

  const getProfileCoverImageFundoClass = () => {

    const moderated = isModerated();
    return moderated && (isCurrent || isAdmin) ? classes.moderatedCoverContainer : classes.coverContainer;

  }

  return (

    <Container
      className={getProfileCoverImageFundoClass()}>
      {(!loading && profile) ? (
        <>{getProfileCoverImage() !== "" &&
          <img
            src={getProfileCoverImage()}
            width="100%"
            height={320}
            className={getProfileCoverImageClass()}
            alt="cover"
          />
        }</>
      ) : (
        <Skeleton variant="rect" width="100%" height={320} />
      )}

      {(isCurrent || isAdmin) && (
        <div>
          <div className={classes.editCover}>
            <IconButton
              onClick={handleClickOpen}
              color="primary"
              aria-label="upload picture"
              component="span"
              className={classes.iconButton}>
              <PhotoCamera />
            </IconButton>

          </div>
          {openEdit && profile && (
            <ImageEditor
              title={t("Upload Cover")}
              handleClose={handleClose}
              open={openEdit}
              setSuccess={setSuccess}
              image="cover"
              profileId={profile.id}
              cropShape="rect"
              aspect={1280 / 320}
            />
          )}
        </div>
      )}
    </Container>
  );
};

export default ProfileCover;

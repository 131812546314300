import { Collection, ISubCollection, SubCollection } from 'fireorm';
import Profile from './Profile';


export class Accepted{
    id: string;
    date: any;
    profile: Profile;
}

@Collection('Invites')
export default class Invite {
    id: string;
    profileId: string;
    sentDate: any;
    timeToLiveDays?: number;
    type: string;
    expired?: boolean;

    @SubCollection(Accepted, 'Accepted')
    agents?: ISubCollection<Accepted>;
    
}


import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../Theme';
import styles from './JobsResult.module.scss';
import clsx from 'clsx';
import { Grid, Paper } from '@material-ui/core';
import JobsResultCard from './JobsResultCard/JobsResultCard';

const JobsResult: React.FC = () => {
  const classes = useStyles();


  return (
    <div className={clsx(classes.root, styles.JobsResult)} data-testid="JobsResult">
      <Grid container spacing={3} >

        <Grid item xs={12}>
          <Paper className={classes.paper} elevation={1}>
            <JobsResultCard />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
};

export default JobsResult;

import {
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { IPostMedia } from "../IPostMedia";
import styles from "./PostVideo.module.scss";
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined";
import PostVideoCrop from "./PostVideoCrop/PostVideoCrop";
import { useTranslation } from "react-i18next";
import { ViewModuleTwoTone } from "@material-ui/icons";

export interface IPostVideoProps {
  media: IPostMedia;
  updateThumb: any;
  edit: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      width: "100%",
      height: 400,
      background: "#333",
      [theme.breakpoints.up("sm")]: {
        height: 400,
      },
    },
    videoContainer: {
      position: "relative",
    },
    margin: {
      margin: theme.spacing(1),
      color: theme.palette.primary.main,
    },
    thumbContainer: {
      display: "flex",
      position: "absolute",
      top: 60,
      right: 5,
    },
  })
);

const PostVideo = ({ media, updateThumb, edit = true }: IPostVideoProps) => {
  const classes = useStyles();
  const videoRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState<IPostMedia>();
  const handleClose = () => setOpen(false);
  const { t, i18n } = useTranslation();
  const title = t("Click here to capture a thumbnail");

  const setThumbnail = () => {
    const video = document.getElementById(`video-${media.fileName}`)
      ?.childNodes[0];
    console.log("VIDEO", video);
    updateThumb(video, media);
  };

  const updateImage = () => {};

  const saveThumbnailOnReady = () => {
    if (media.thumbUrl === undefined || media.thumbUrl === "") setThumbnail();
  };

  return (
    <div style={{ height: 400 }}>
      <ReactPlayer
        url={media.src}
        width="100%"
        height="100%"
        controls={true}
        ref={videoRef}
        id={`video-${media.fileName}`}
        onReady={saveThumbnailOnReady}
      />
      {edit && (
        <div className={classes.thumbContainer}>
          <Tooltip title={title}>
            <IconButton
              aria-label="delete"
              className={classes.margin}
              size="medium"
              onClick={setThumbnail}>
              <PhotoCameraOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          {open && (
            <PostVideoCrop
              open={open}
              image={image}
              handleClose={handleClose}
              title={t("Crop thumbnail")}
              updateImage={updateImage}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default PostVideo;

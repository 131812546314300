import { Button } from "@material-ui/core";
import React from "react";
import { FaFile } from "react-icons/fa";
import { getAvatar } from "../../../utils/utils";
import { Message, Avatar } from "@chatscope/chat-ui-kit-react";
import { ChatMessage, ChatProfile } from "../../../core/entities/Chat";

interface ChatAttachmentMessageProps {
  direction: "incoming" | "outgoing";
  message: ChatMessage;
  member: ChatProfile | undefined;
  fromNow: String;
}

export default function ChatAttachmentMessage({
  message,
  member,
  direction,
  fromNow,
}: ChatAttachmentMessageProps) {
  return (
    <Message
      model={{
        type: "html",
        direction: direction,
      }}>
      <Message.CustomContent>
        {message.attachments?.map((attachment) => (
          <Button
            color="secondary"
            variant="contained"
            href={attachment.url}
            target="_blank"
            startIcon={<FaFile />}
            size="small"
            style={{ fontSize: "12px", margin: "8px 0", color: "white" }}>
            {attachment.name}
          </Button>
        ))}
      </Message.CustomContent>
      <Message.Footer sender={member?.name} sentTime={fromNow} />
      <Avatar
        name={member?.name}
        src={member?.profileImageUrl || getAvatar(member?.name)}
      />
    </Message>
  );
}

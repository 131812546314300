export function download(fileUrl) {
  var fileName = fileUrl.split("/").pop();
  var a = document.createElement("a");
  a.href = fileUrl;
  a.setAttribute("download", fileName);
  a.setAttribute("target", "_blank");
  a.click();
}

export function timestampToData(timestamp) {

  var t = new Date(timestamp.seconds * 1000);
  return t;
}

export function getAvatarInitials(name) {
  if (name === "") return "TW";

  let split = name.split(" ");
  console.debug(split);

  let initials =
    split.lenght > 1
      ? split[0].charAt(0).toUpperCase() + split[1].charAt(0).toUpperCase()
      : split[0].charAt(0).toUpperCase();
  console.debug(initials);

  return initials;
}

export const cyrb53 = function (str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 =
    Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
    Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 =
    Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
    Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export const getEmailUserName = function (email) {
  // Generates a random number, converts to string and gets the last 4 characters
  return email !== "" && email !== undefined && email !== null
    ? email.trim().replaceAll(" ", "_").split("@")[0] + (Math.random() + 1).toString(36).substring(9)
    : newRandomNickName();
};

export const newRandomNickName = function () {
  return (Math.random() + 1).toString(36).substring(9);
}

export const getAvatar = (name) => {
  return `https://ui-avatars.com/api/?background=141414&color=fff&name=${name}`;
};

export function fileName(fileUrl) {

  return fileUrl.split('/').pop();

}

export function refreshPage() {
  window.location.reload(false);
}

export function IsJsonString(str) {
  try {
    var json = JSON.parse(str);
    return (typeof json === 'object');
  } catch (e) {
    return false;
  }
}

export const getDateFormatPattern = (locale: string) => {
  const getPatternForPart = (part: Intl.DateTimeFormatPart) => {
      switch (part.type) {
          case 'day':
              return 'd'.repeat(part.value.length);
          case 'month':
              return 'm'.repeat(part.value.length);
          case 'year':
              return 'y'.repeat(part.value.length);
          case 'literal':
              return part.value;
          default:
              console.log('Unsupported date part', part);
              return '';
      }
  };

  return new Intl.DateTimeFormat(locale).formatToParts(new Date('2021-01-01'))
      .map(getPatternForPart)
      .join('');
};
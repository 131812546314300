import {
  FormControlLabel,
  Checkbox,
  makeStyles,
  Theme,
  createStyles,
  Button,
  Typography,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { functions } from "../../../infra/firebase/firebasecofig";
import ProfileRepository from "../../../infra/firebase/ProfileRepository";
import { useProfileContext } from "../../../ProfileContext";
import styles from "./SettingsGeneral.module.scss";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import firebase from "firebase";
import VerifyEmailDialog from "../../shared/VerifyEmailDialog";
import SettingsGeneralLanguageComponent from "./SettingsGeneralLanguageComponent";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 20,
    },
  })
);

const profileRepository = new ProfileRepository();
const SettingsGeneral = () => {
  const { loading, profile } = useProfileContext();
  

  const { t } = useTranslation();
  const classes = useStyles();
  const [hidden, setHidden] = useState(false);

  const setHideOnSearch = (value) => {
    if (profile) {
      console.debug(value);
      profileRepository.setHideOnSearch(profile.id, value);
    }
  };

  return (
    <div className={classes.root} data-testid="SettingsGeneral">
      {/* {!loading && profile && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            control={
              <Checkbox
                value="remember"
                color="primary"
                checked={hidden}
                onChange={(event) => setHideOnSearch(event.target.checked)}
              />
            }
            label={t("Hide on search")}
          />
        </div>
      )} */}
      <SettingsGeneralLanguageComponent />
    </div>
  );
};

export default SettingsGeneral;

import { makeStyles, Theme, createStyles, Divider, Grid, IconButton, Typography, Chip, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { Category } from '../../../core/entities/Category';
import Profile, { ProfileCategory } from '../../../core/entities/Profile';
import { useProfileContext } from '../../../ProfileContext';
import ProfileCategoriesEdit from './ProfileCategoriesEdit/ProfileCategoriesEdit';
import CategoryRepository from '../../../infra/firebase/CategoryRepository';
import { useParams } from 'react-router-dom';
import IProfileParams from '../IProfileParams';
import { useCurrentProfileContext } from '../../../CurrentProfileContext';
import AddIcon from '@material-ui/icons/Add';
import ProfileController from '../ProfileController';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20,
    },
    selected: {
      margin: theme.spacing(1),
      color: "#FFF",
    },
    outlined: {
      margin: theme.spacing(1),
    },
  })
);

const ProfileCategories = () => {
  const profileController = new ProfileController();
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language.split("-")[0]);

  const { error, loading, profile, isCurrent } = useCurrentProfileContext();
  const {
    error: globalError,
    loading: globalLoading,
    profile: globalProfile,
    isAdmin
  } = useProfileContext();

  const [openSelectCategories, setOpenSelectCategories] = useState(false);
  const handleClickOpen = () => {
    setOpenSelectCategories(true);
  };
  const handleClose = () => {
    setOpenSelectCategories(false);
  };

  const [profileCategories, setProfileCategories] = useState<
    ProfileCategory[] | null | undefined
  >([]);

  const [currentProfileCategory, setCurrentProfileCategory] = useState<
    ProfileCategory | null | undefined
  >();

  useEffect(() => {
    if (profile) {
      setProfileCategories(profile.profileCategories);
    }
  }, [profile]);

  const handleEditCategory = (category: ProfileCategory) => {
    setCurrentProfileCategory(category);
    handleClickOpen();
  };

  const handleAddCategory = () => {
    setCurrentProfileCategory(null);
    handleClickOpen();
  };

  const getCategoryTranslatedName = (category: ProfileCategory) => {
    if (category.translatedNames === null || category.translatedNames === undefined) {
      return category.name;
    }

    return category.translatedNames[lang]
      ? category.translatedNames[lang]
      : category.name;
  };

  if (loading) {
    return <Skeleton variant="text" />;
  }

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center">
        <Typography variant="subtitle1">{t("Categories")}</Typography>

        {((isCurrent || isAdmin) && openSelectCategories && profile) && (
          <ProfileCategoriesEdit
            profileId={profile.id}
            open={openSelectCategories}
            handleClose={handleClose}
            profileCategory={currentProfileCategory}
          />
        )}

        {(isCurrent || isAdmin) && (
          <div>
            <Tooltip
              title={`${t("Click to add a new category")}`}
              aria-label="add">
              <IconButton
                color="primary"
                aria-label="add category"
                component="span"
                onClick={handleAddCategory}>
                <AddIcon />
              </IconButton>
            </Tooltip>

          </div>
        )}
      </Grid>
      <Divider variant="fullWidth" />

      {profileCategories && (
        <div>
          {profileCategories.map((category) => {
            return (
              <div key={`div-${category.categoryId}`}>
                <Grid
                  key={`grid-${category.categoryId}`}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center">
                  <Chip
                    className={classes.selected}
                    color="secondary"
                    variant="default"
                    label={getCategoryTranslatedName(category)}
                    clickable={false}
                    key={`chip-${category.categoryId}`}
                  />
                  {(isCurrent || isAdmin) && (
                    <Tooltip
                      key={`tooltip-${category.categoryId}`}
                      title={`${t("Click to edit this category")}`}
                      aria-label="add">
                      <IconButton
                        key={`edit-${category.categoryId}`}
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={() => handleEditCategory(category)}>
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
                {
                  category.subCategories?.map(subCategory => {
                    return (
                      <Chip
                        className={classes.outlined}
                        color='secondary'
                        variant="outlined"
                        label={getCategoryTranslatedName(subCategory)}
                        clickable={false}
                        key={subCategory.name}
                      />
                    )
                  })
                }

                {
                  profileController.getOnlySkills(category)?.map(skill => {
                    return (<>
                      <Chip
                        className={classes.outlined}
                        color='secondary'
                        variant="outlined"
                        label={skill}
                        clickable={false}
                        key={skill}
                      />
                    </>);
                  })
                }
                <Divider />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ProfileCategories;

import {
  Button,
  Collapse,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useTranslation } from "react-i18next";
import Skeleton from "@material-ui/lab/Skeleton";
import { useCurrentProfileContext } from "../../../CurrentProfileContext";
import Profile from "../../../core/entities/Profile";
import ProfileRepository from "../../../infra/firebase/ProfileRepository";
import CloseIcon from "@material-ui/icons/Close";
import ProfileController from "../ProfileController";

import { useProfileContext } from "../../../ProfileContext";
import { functions } from "../../../infra/firebase/firebasecofig";
import { Alert } from "@material-ui/lab";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20,
      height: 200,
    },
    moderatedText: {
      textDecoration: "line-through",
      color: "red"
    },
    container: {
      display: "flex",
    },
    bioContainer: {
      [theme.breakpoints.up("md")]: {
        maxWidth: 224,
      },
      [theme.breakpoints.down("sm")]: {
        maxWidth: "85vw",
      },
    },
  })
);

const ProfileAbout = () => {
  const classes = useStyles();
  const { loading, profile, isCurrent } = useCurrentProfileContext();
  const { profile: globalProfile, isAdmin } = useProfileContext();

  const { t } = useTranslation();

  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = () => {
    setOpen((prev) => !prev);
  };


  const profileController = new ProfileController();

  const isModerated = (field: string) => {
    return profile?.moderations ? profileController.verifyIfExistsModerationOnField(field, profile?.moderations) : false;
  }

  const getFieldValue = (field: string) => {

    const moderated = isModerated(field);
    var text = profile && profile[field] ? profile[field] : "";
    var customClass = moderated && isCurrent ? classes.moderatedText : ""

    if (moderated) {
      text = isCurrent ? text : "";
    }

    return { text: text, customClass: customClass };

  }

  const Bio = () => {


    const fieldValue = getFieldValue("bio");

    return (<Typography className={fieldValue.customClass}>
      {!loading ? (
        fieldValue.text.split("**linebreak**").map((str) => (
          <>
            {str}
            <br />
          </>
        ))
      ) : (
        <Skeleton />
      )}
    </Typography>);
  }


  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"

        alignItems="center"
        key={`bio-${profile?.id}`}>
        <Typography variant="subtitle1" key={`bio-text-${profile?.id}`}>{t("About")}</Typography>

        {(isCurrent || isAdmin) && (
          <IconButton
            key={`edit-bio-${profile?.id}`}
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={handleChange}>
            <EditOutlinedIcon />
          </IconButton>
        )}
      </Grid>
      <Divider variant="fullWidth" />
      {profile?.bio && (
        <div key={`container-bio-${profile?.id}`}>
          <Collapse
            key={`collapse-bio-${profile?.id}`}
            in={checked}
            collapsedSize={140}
            className={classes.bioContainer}
            style={{
              overflowWrap: "break-word",
            }}>

            <Bio />
          </Collapse>
          <div style={{ marginTop: 10, display: "block" }} >
            {!isModerated("bio") && <Link onClick={handleChange} style={{ cursor: "pointer" }}>

              {t("Show more")}
            </Link>}
          </div>
        </div>
      )}

      {open && (
        <ShowBio
          bio={profile?.bio}
          open={open}
          handleClose={handleClose}
          profile={profile}
          isCurrent={isCurrent || isAdmin}
        />
      )}
    </div>
  );
};

export interface IShowBioProps {
  bio?: string;
  open: boolean;
  handleClose: any;
  profile?: Profile | null;
  isCurrent: boolean;
}
const ShowBio = ({
  bio,
  profile,
  isCurrent,
  open,
  handleClose,
}: IShowBioProps) => {
  const { t } = useTranslation();
  const profileRepository = new ProfileRepository();
  const [localBio, setLocalBio] = useState(
    bio?.replaceAll("**linebreak**", "\n")
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocalBio(event.target.value);
  };

  const save = async () => {

    if (profile) {

      setLoading(true);

      const moderationCaller = functions.httpsCallable("moderation-onCallModerateText");
      const moderationResponse = await moderationCaller({
        values: [
          {
            field: "bio",
            content: localBio
          }
        ]
      });

      const moderations = moderationResponse.data.filter(d => d.moderated);
      const hasModerations = moderations.length > 0;
      const errors = moderationResponse.data.filter(d => d.error);
      const hasErrors = errors.length > 0;

      if (hasErrors) {
        setErrorMessage(t("There is errors."));
        console.error(errors);
        setLoading(false);
        return;
      }

      if (hasModerations) {

        setErrorMessage(`${t("The field")} ${t(moderations[0].field)} ${t("violates our Terms & Conditions")}`);
        setLoading(false);
        return;

      }

      profileRepository
        .setBio(profile?.id, localBio?.replaceAll("\n", "**linebreak**"))
        .then(() => handleClose())
        .catch((error) => console.error(error));
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"sm"}>
      <DialogTitle>{t("About")}</DialogTitle>
      <DialogContent>

        {isCurrent ? (
          <div>
            <Collapse in={errorMessage !== ""}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorMessage("");
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {errorMessage}
              </Alert>
            </Collapse>

            <TextField
              value={localBio}
              rows={20}
              fullWidth
              multiline
              onChange={handleChange}
            />
          </div>
        ) : (
          <Typography>{bio}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        {isCurrent ? (
          <div>
            <Button onClick={handleClose} color="inherit" disabled={loading}>
              {t("Cancel")}
            </Button>

            <Button onClick={save} color="primary" disabled={loading}>
              {t("Save")}
            </Button>
          </div>
        ) : (
          <Button onClick={handleClose} color="inherit">
            {t("Ok")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ProfileAbout;

import React from "react";
import styles from "./ChatSummaryProposal.module.scss";
import moment from "moment";

import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  createStyles,
  Box,
} from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { KeyboardDatePicker, DateTimePicker } from "@material-ui/pickers";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DealsRepository from "../../../infra/firebase/DealsRepository";
import Deal from "../../../core/entities/Deal";
import { Typography } from "@material-ui/core";
import CloseButton from "../../shared/CloseButton";
import { useProfileContext } from "../../../ProfileContext";

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "#FFF",
    },
    form: {
      width: "100%",
      paddingBottom: 20,
    },
    errorMessage: {
      color: "red",
    },
    input: {
      display: "none",
    },
    buttons: {
      textAlign: "right",
      marginTop: 10,
    },
    closeButton: {
      position: "absolute",
      right: 5,
      top: 5,
      color: theme.palette.grey[500],
      [theme.breakpoints.down("md")]: {
        position: "relative",
        justifyContent: "flex-end",
        marginLeft: "auto",
      },
    },
  })
);

export interface IChatSummaryProposalProps {
  open: any;
  handleClose: any;
  setSuccess?: any;
  action: any;
  infosProposal: any;
  isAgent: boolean;
  agentId: string | undefined;
  talentId: string | undefined;
}

const dealRepository = new DealsRepository();

const ChatSummaryProposal: React.FC<IChatSummaryProposalProps> = ({
  open,
  handleClose,
  setSuccess,
  action,
  infosProposal,
  isAgent,
  agentId,
  talentId,
}: IChatSummaryProposalProps) => {
  const { t, i18n } = useTranslation();
  const classes = useFormStyles();
  const { profile } = useProfileContext();

  const [loading, setLoading] = React.useState(false);

  const handleCreateDeal = async () => {
    setLoading(true);
    console.debug(infosProposal, "INFO PROPOSALS");
    const deal = new Deal();
    deal.agentId = agentId;
    deal.talentId = talentId;
    deal.amount = infosProposal.amount;
    deal.comission = infosProposal.comission;
    deal.currency =
      profile?.defaultGateway?.region === "brazil" ? "BRL" : "EUR";
    deal.details = infosProposal.details;
    deal.fromProfileId = infosProposal.fromProfileId;
    deal.job = infosProposal.job;
    deal.toProfileId = infosProposal.toProfileId;
    deal.status = infosProposal.status;
    deal.membersId = infosProposal.membersId;
    deal.region = profile?.defaultGateway?.region;
    console.debug(deal);

    const dealResult = await dealRepository.create(deal);

    action(dealResult);
    setLoading(false);
  };

  if (!infosProposal) return <></>;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        className="chatProposal">
        <CloseButton handleClose={handleClose} />

        <DialogTitle id="form-dialog-title" className={styles.titleProposal}>
          {t("Proposal Summary")}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                variant="body1"
                display="inline"
                style={{ fontWeight: "bold", color: "#919191" }}>
                {t("job")}:&nbsp;
              </Typography>
              <Typography variant="body1" display="inline">
                {infosProposal.job}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                display="inline"
                style={{ fontWeight: "bold", color: "#919191" }}>
                {t("Price")}:&nbsp;
              </Typography>
              <Typography variant="body1" display="inline">
                {new Intl.NumberFormat(i18n.language, {
                  style: "currency",
                  currency: infosProposal.currency,
                }).format(infosProposal.amount)}
              </Typography>
            </Grid>
            {isAgent && (
              <Grid item xs={12}>
                <Typography
                  variant="body1"
                  display="inline"
                  style={{ fontWeight: "bold", color: "#919191" }}>
                  {t("Comission")}:&nbsp;
                </Typography>
                <Typography variant="body1" display="inline">
                  {infosProposal.comission}%
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} direction="row">
              <Typography
                variant="body1"
                display="inline"
                style={{ fontWeight: "bold", color: "#919191" }}>
                {t("Details")}:&nbsp;
              </Typography>
              <Typography variant="body1" display="inline">
                {infosProposal.details
                  ? infosProposal.details
                      .split("**linebreak**")
                      .map((str, index) => (
                        <>
                          {str}
                          <br />
                        </>
                      ))
                  : ""}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="button"
            color="primary"
            className={styles.proposalButton}
            onClick={handleCreateDeal}
            disabled={loading}>
            {t("Send Proposal")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatSummaryProposal;

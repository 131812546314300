import React from "react";
import firebase from "firebase";

import ProfileRepository from "../../infra/firebase/ProfileRepository";
import Profile from "../../core/entities/Profile";
import { cyrb53, getEmailUserName } from "../../utils/utils";
import InviteController from "./InviteController";
import { functions } from "../../infra/firebase/firebasecofig";
import AnalyticsHelper from "../../infra/analytics/analyticsHelper";
import Registration from "../../infra/mailchimp/Registration";
import RegistrationRepository from "../../infra/mailchimp/RegistrationRepository";
import ProfileController from "../Profile/ProfileController";


const profileController = new ProfileController();
const repository = new ProfileRepository();
const analyticsHelper = new AnalyticsHelper();


export const CreateProfile = async (
  user: firebase.User | null,
  name: string | null,
  token?: string | null
): Promise<Profile> => {
  if (user == null) {
    return Promise.reject();
  }

  try {
    const newProfile = new Profile();
    newProfile.uid = user.uid;
    newProfile.name = name;

    let split = name?.split(" ");
    if (split) {
      newProfile.firstName = split[0];
      newProfile.lastName = split[split.length - 1];
    }

    newProfile.nickname = getEmailUserName(user.email);
    newProfile.emailAddress = user.email;

    var profile = await repository.create(newProfile);
    analyticsHelper.logEvent('profile_created');


    const validateNickname = functions.httpsCallable("profiles-onCallNickname");

    await validateNickname({ nickname: profile.nickname, id: profile.id });

    return profile;
  } catch (e: any) {
    if (e.message === "internal") {
      return Promise.reject("Nickname pode conter termos ofensivos");
    } else {
      return Promise.reject(e);
    }
  }
};

export const VerifyProfileExists = async (uid: string): Promise<boolean> => {
  return repository
    .getProfileByAccountId(uid)
    .then((profile) => {
      return profile !== null;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};

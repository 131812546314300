import { ErrorMessage } from "@hookform/error-message";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, Link as RouterLink } from "react-router-dom";

import firebase from "firebase";

import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import SocialMediaLogon from "../SocialMediaLogon/SocialMediaLogon";
import { CreateProfile, VerifyProfileExists } from "../LoginController";
import InviteController from "../InviteController";

const inviteController = new InviteController();

const useStyles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
    paddingLeft: 20,
    paddingRight: 20,
  },
  errorMessage: {
    color: "red",
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  labelButton: {
    textTransform: "initial",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    margin: theme.spacing(2, 2, 2),
  },
  inputAdornment: {
    color: theme.palette.primary.main,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const LoginForm = () => {
  let query = useQuery();
  const r = query.get("r");
  const token = query.get("token");

  const { search } = useLocation();

  const classes = useStyles();
  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { t } = useTranslation();

  const signInWithEmailAndPassword = (
    email: string,
    password: string,
    rememberme: boolean
  ): Promise<firebase.auth.UserCredential> => {
    const provider = firebase.auth.EmailAuthProvider;

    const authCredential = provider.credential(email, password);

    const persistence = rememberme
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;

    return firebase
      .auth()
      .setPersistence(persistence)
      .then(() => {
        return firebase.auth().signInWithCredential(authCredential);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const afterError = (errorMessage: string) => {
    setError("form", {
      type: "manual",
      message: t(errorMessage),
    });

    setLoading(false);
  };

  const onSubmit = (data: any) => {
    try {
      if (loading) {
        return;
      } else {
        setLoading(true);
      }

      signInWithEmailAndPassword(
        data.email.trim(),
        data.password.trim(),
        data.rememberme
      )
        .then(() => {
          firebase.auth().onAuthStateChanged(async function (user) {
            if (user) {
              if (!user.emailVerified) {
                await user.sendEmailVerification();
              }
              VerifyProfileExists(user.uid)
                .then((exists) => {
                  if (!exists) {
                    CreateProfile(user, user.displayName)
                      .then(async (profile) => {
                        setLoading(false);
                        if (token) {
                          history.push("/acceptinvite", { token });
                        } else {
                          history.push(r ? r : "/");
                        }
                      })
                      .catch((error) => {
                        afterError(error);
                      });
                  } else {
                    setLoading(false);
                    if (token) {
                      history.push("/acceptinvite", { token });
                    } else {
                      history.push(r ? r : "/");
                    }
                  }
                })
                .catch((error) => {
                  afterError(error.message);
                });
            } else {
              afterError(t("error-user-null"));
            }
          });
        })
        .catch((error) => {
          afterError(error.message);
        });
    } catch (error: any) {
      afterError(error.message);
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        variant="standard"
        margin="normal"
        required
        fullWidth
        id="email"
        label={t("E-mail")}
        autoComplete="email"
        autoFocus
        {...register("email", { required: true })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <PermIdentityOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <ErrorMessage
        errors={errors}
        name="email"
        render={({ message }) => (
          <span className={classes.errorMessage}>{message}</span>
        )}
      />

      <TextField
        variant="standard"
        margin="normal"
        required
        fullWidth
        label={t("Password")}
        type="password"
        id="password"
        autoComplete="current-password"
        {...register("password", { required: true })}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.inputAdornment}>
              <LockOutlinedIcon />
            </InputAdornment>
          ),
        }}
      />
      <ErrorMessage
        errors={errors}
        name="password"
        render={({ message }) => (
          <span className={classes.errorMessage}>{message}</span>
        )}
      />

      <FormControlLabel
        control={<Checkbox value="remember" color="primary" />}
        label={t("Remember me")}
        {...register("rememberme")}
      />

      {loading ? (
        <div
          style={{
            display: "flex",
            background: "#c9c9c9",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "4px",
            padding: "12px",
            margin: "4px 0",
          }}>
          <CircularProgress size={14} />
        </div>
      ) : (
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          classes={{
            root: classes.submit,
            label: classes.labelButton,
          }}
          onClick={() => clearErrors()}>
          {t("Log in")}
        </Button>
      )}

      <Grid container justifyContent="center">
        <ErrorMessage
          errors={errors}
          name="form"
          render={({ message }) => (
            <span className={classes.errorMessage}>{message}</span>
          )}
        />
      </Grid>

      <Grid container>
        <Grid item xs>
          <Typography className={classes.link} align="center" paragraph>
            <RouterLink
              to={`/forgotpassword${search}`}
              style={{ textDecoration: "none" }}>
              {t("Forgot password?")}
            </RouterLink>
          </Typography>
        </Grid>
      </Grid>

      <SocialMediaLogon clearErrors={clearErrors} setError={afterError} />

      <RouterLink to={`/register${search}`} style={{ textDecoration: "none" }}>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          classes={{
            root: classes.submit,
            label: classes.labelButton,
          }}
          disableElevation>
          {t("Create a new account")}
        </Button>
      </RouterLink>
    </form>
  );
};

export default LoginForm;

import {
  makeStyles,
  Theme,
  createStyles,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useProfileContext } from "../../../ProfileContext";
import CloseIcon from "@material-ui/icons/Close";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      paddingRight: "8px",
    },
    name: {
      fontSize: "1.2rem",
      margin: 0,
    },
  })
);

export default function ProfileWelcome({ open, handleClose }) {
  const { t } = useTranslation();
  const { push } = useHistory();
  const classes = useStyles();
  const { profile } = useProfileContext();

  const handleGoToProfile = () => {
    push(`/profile/${profile?.nickname}`);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {profile ? (
        <>
          <div className={classes.title}>
            <DialogTitle>{t("All set!")}</DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span className={classes.name}>{profile?.name}</span>
              <br />
              {t("Let's take a look how your profile is looking.")}

              <br />
              <br />
            </DialogContentText>
            <DialogActions>
              <Button
                variant="contained"
                color="primary"
                onClick={handleGoToProfile}
                autoFocus>
                {t("Go to Profile")}
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      ) : (
        <CircularProgress style={{ margin: "16px" }} />
      )}
    </Dialog>
  );
}

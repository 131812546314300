import { getRepository } from 'fireorm';
import { Category } from '../../core/entities/Category';
import { IRepository } from '../../core/interfaces/IRepository';

export default class CategoryRepository implements IRepository<Category>{

    repository = getRepository(Category);


    getAllCategories = () => {
        return this.repository.find();
    }

    getCategoryByName = (name: string) => {
        return this.repository.whereEqualTo('name', name);
    }

    getCategoriesByIds = (ids: string[] | undefined) => {
        return ids ? this.repository.whereIn('id', ids).find() : Promise.reject({ message: 'Nenhuma categoria.' });
    }

    getAll(): Promise<Category[]> {
        return this.repository.find();
    }

    getParentCategories(): Promise<Category[]> {
        return this.repository
            .whereEqualTo('parentCategoryId', '').find()
    }

    getParentCategoriesForHome(): Promise<Category[]> {
        return this.repository
            .whereEqualTo('parentCategoryId', '')
            .whereEqualTo('showAtHome', true)    
            .orderByAscending('order')        
            .find();            
    }

    getSubCategories(categoryId: string): Promise<Category[]> {
        return this.repository
            .whereEqualTo('parentCategoryId', categoryId)
            .find();
    }

    createOrUpdate(entity: Category): Promise<Category> {

        return entity.id ? this.update(entity) : this.create(entity);
    }

    create(entity: Category): Promise<Category> {
        return this.repository.create(entity);
    }
    update(entity: Category): Promise<Category> {
        return this.repository.update(entity);
    }
    delete(id: string): Promise<void> {
        return this.repository.delete(id);
    }
    getById(id: string): Promise<Category> {
        return this.repository.findById(id);
    }

}
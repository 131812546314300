import { analytics } from "../firebase/firebasecofig";

export default class AnalyticsHelper {



    logEvent = (eventName) => {
        analytics.logEvent(eventName);
    }

    logEventParams = (eventName, param) => {
        analytics.logEvent(eventName, param);
    }

    setUserProperties = (property) => {

        analytics.setUserProperties(property);
    }
}
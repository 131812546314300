import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Category } from "../../../core/entities/Category";
import CloseIcon from "@material-ui/icons/Close";
import { ErrorMessage } from "@hookform/error-message";
import CategoryRepository from "../../../infra/firebase/CategoryRepository";
import ChipInput from "material-ui-chip-input";
import Skill from "../../../core/entities/Skill";
import SkillRepository from "../../../infra/firebase/SkillRepository";
import { useTranslation } from "react-i18next";

const useFormStyles = makeStyles({
  buttons: {
    textAlign: "right",
    marginTop: 10,
  },
  form: {
    width: "100%",
    paddingBottom: 20,
  },
  errorMessage: {
    color: "red",
  },
});

export interface ICategoriesEditProps {
  open: boolean;
  handleClose: any;
  setSuccess: any;
  category?: Category | null;
}

const CategoriesEdit = ({
  open,
  handleClose,
  category,
  setSuccess,
}: ICategoriesEditProps) => {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const languages = ["pt-BR", "en-US", "es-ES", "de-DE"];
  const { t, i18n } = useTranslation();
  const [parentCategories, setParentCategories] = useState<Category[]>([]);
  const repository = new CategoryRepository();

  const skillRepository = new SkillRepository();

  const [skills, setSkills] = useState<Skill[]>([]);
  const [showAtHome, setShowAtHome] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: category ? category.name : "",
      description: category ? category.description : "",
      parentCategoryId: category ? category.parentCategoryId : "",
      language: category ? category.language : "",
      order: category ? category.order : 0,
      showAtHome: category ? category.showAtHome : false,
      imageUrl: category ? category.imageUrl : "",
      translatedNames: {
        en: category?.translatedNames?.en,
        pt: category?.translatedNames?.pt,
        de: category?.translatedNames?.de,
        es: category?.translatedNames?.es,
      }
    },
  });

  useEffect(() => {
    repository
      .getParentCategories()
      .then((parentCategories) => {
        console.debug(parentCategories);
        setParentCategories(parentCategories);
      })
      .catch((error) => {
        setErrorMessage(error.message);
      });

    if (category) {
      skillRepository
        .getByCategory(category.id)
        .then((skills) => {
          console.debug(skills);
          setSkills(skills);
        })
        .catch((error) => {
          setErrorMessage(error.message);
        });
    }
  }, []);

  const handleAddChip = (chip: Skill): any => {
    console.debug("handleAddChip", chip);
    const s = skills;
    s.push(chip);
    setSkills(s);

    console.debug(s);
  };

  const handleDeleteChip = (chip: Skill, index: number): any => {
    console.debug("handleDeleteChip", chip);
    if (chip.id !== chip.name) {
      skillRepository.delete(chip.id);
    }

    const s = skills.filter((s) => s.id !== chip.id);
    setSkills(s);
    console.debug(s);
  };

  const onSubmit = (data: any) => {
    setLoading(true);

    const actionMethod = category ? "atualizada" : "criada";

    if (!category) category = new Category();
    category.description = data.description;
    category.language = data.language;
    category.name = data.name;
    category.parentCategoryId = data.parentCategoryId;
    category.order = data.order;
    category.showAtHome = showAtHome;
    category.imageUrl = data.imageUrl;

    category.translatedNames = {
      en: data.translatedNames.en,
      pt: data.translatedNames.pt,
      es: data.translatedNames.es,
      de: data.translatedNames.de,
    }

    console.log(category);

    repository
      .createOrUpdate(category)
      .then((category) => {
        const skillsToCreate = skills.filter(
          (skill) => skill.id === skill.name
        );
        console.debug("skillsToCreate", skillsToCreate);

        if (skillsToCreate.length) {
          skillRepository
            .createBatch(category.id, skillsToCreate)
            .then(() => {
              setSuccess(
                `Categoria '${category?.name}' ${actionMethod} com sucesso.`
              );
              setLoading(false);
              handleClose();
            })
            .catch((error) => {
              console.error(error);
              setLoading(false);
              setErrorMessage(error.message);
            });
        } else {
          setSuccess(
            `Categoria '${category?.name}' ${actionMethod} com sucesso.`
          );
          setLoading(false);
          handleClose();
        }
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setErrorMessage(error.message);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown
      disableBackdropClick>
      <DialogTitle id="form-dialog-title">Categoria</DialogTitle>
      <DialogContent>
        <Collapse in={errorMessage !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {errorMessage}
          </Alert>
        </Collapse>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <InputLabel htmlFor="parentCategoryId">Categoria Mãe</InputLabel>
            <Controller
              control={control}
              name="parentCategoryId"
              render={({ field: props }) => (
                <Select
                  {...props}
                  id="parentCategoryId"
                  fullWidth
                  autoFocus
                  onChange={(e) => {
                    props.onChange(e.target.value);
                  }}>
                  <MenuItem key="" value="">
                    {"Sem categoria mãe"}
                  </MenuItem>
                  {parentCategories.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name="parentCategoryId"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />

          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                {...field}
                label="Nome"
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="name"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />


          <Controller
            control={control}
            name="imageUrl"
            render={({ field }) => (
              <TextField
                {...field}
                label="Image Url"
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="imageUrl"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="imageUrl"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />

          <FormControlLabel
            control={
              <Controller
                name="showAtHome"
                control={control}
                render={(props) => (
                  <Checkbox
                    {...props}
                    checked={showAtHome}
                    onChange={(e) => setShowAtHome(e.target.checked)}
                  />
                )}
              />
            }
            label={t("Show at home")}
          />

          <Controller
            control={control}
            name="description"
            render={({ field }) => (
              <TextField
                {...field}
                label="Descrição"
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="description"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="description"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />


          <Controller
            control={control}
            name="order"
            render={({ field }) => (
              <TextField
                {...field}
                label="Ordem"
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="order"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="order"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />

          <FormControl fullWidth>
            <InputLabel htmlFor="language">Idioma</InputLabel>
            <Controller
              control={control}
              name="language"
              render={({ field: props }) => (
                <Select
                  {...props}
                  id="language"
                  fullWidth
                  required
                  autoFocus
                  onChange={(e) => {
                    props.onChange(e.target.value);
                  }}>
                  {languages.map((language) => (
                    <MenuItem key={language} value={language}>
                      {language}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <ErrorMessage
            errors={errors}
            name="language"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />


          <Controller
            control={control}
            name="translatedNames.en"
            render={({ field }) => (
              <TextField
                {...field}
                label="En"
                fullWidth
                variant="standard"
                margin="normal"

                id="translatedNames.en"
              />
            )}
          />

          <Controller
            control={control}
            name="translatedNames.pt"
            render={({ field }) => (
              <TextField
                {...field}
                label="Pt"
                fullWidth
                variant="standard"
                margin="normal"

                id="translatedNames.pt"
              />
            )}
          />

          <Controller
            control={control}
            name="translatedNames.es"
            render={({ field }) => (
              <TextField
                {...field}
                label="Es"
                fullWidth
                variant="standard"
                margin="normal"

                id="translatedNames.es"
              />
            )}
          />

          <Controller
            control={control}
            name="translatedNames.de"
            render={({ field }) => (
              <TextField
                {...field}
                label="De"
                fullWidth
                variant="standard"
                margin="normal"

                id="translatedNames.de"
              />
            )}
          />




          <ChipInput
            value={skills}
            dataSourceConfig={{
              text: "name",
              value: "id",
            }}
            onAdd={(chip) => handleAddChip(chip)}
            onDelete={(chip, index) => handleDeleteChip(chip, index)}
            fullWidth
            fullWidthInput
            label="Filters"
            allowDuplicates={false}
          />

          <div className={classes.buttons}>
            <Button onClick={handleClose} color="inherit" disabled={loading}>
              {t("Cancel")}
            </Button>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  background: "#c9c9c9",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  padding: "12px",
                  margin: "4px 0",
                }}>
                <CircularProgress size={14} />
              </div>
            ) : (
              <Button
                type="submit"
                color="primary"
                onClick={() => clearErrors()}
                disabled={loading}>
                {t("Save")}
              </Button>
            )}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CategoriesEdit;

import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Attribute, Translated } from "../../../core/entities/Attribute";

interface SelectAttributeProps {
  control: any;
  attribute: Attribute;
}

export default function SelectAttribute({
  control,
  attribute,
}: SelectAttributeProps) {
  const {
    i18n: { languages },
  } = useTranslation();
  const [label, setLabel] = useState("");
  const [options, setOptions] = useState<string[]>([]);
  const [optionsTranslations, setOptionsTranslations] = useState<Translated[]>(
    attribute.options as Translated[]
  );

  useEffect(() => {
    if (languages.includes("pt")) {
      setLabel(attribute.displayName.pt);
      const translated = attribute.options?.map(
        (option: Translated) => option.pt
      );
      setOptions(translated || []);
    } else if (languages.includes("de")) {
      setLabel(attribute.displayName.de);
      const translated = attribute.options?.map(
        (option: Translated) => option.de
      );
      setOptions(translated || []);
    } else if (languages.includes("es")) {
      setLabel(attribute.displayName.es);
      const translated = attribute.options?.map(
        (option: Translated) => option.es
      );
      setOptions(translated || []);
    } else {
      setLabel(attribute.displayName.en);
      const translated = attribute.options?.map(
        (option: Translated) => option.en
      );
      setOptions(translated || []);
    }
    setOptionsTranslations(attribute.options || []);
  }, [attribute, languages]);

  return (
    <FormControl fullWidth>
      <Controller
        control={control}
        name={attribute.name}
        render={({ field }) => (
          <>
            <InputLabel id="select-label">{label}</InputLabel>
            <Select label={label} {...field}>
              {options.map((option, index) => (
                <MenuItem
                  value={JSON.stringify(
                    Object.keys(optionsTranslations[index] as any)
                      .sort()
                      .reduce((obj, key) => {
                        obj[key] = optionsTranslations[index][key];
                        return obj;
                      }, {})
                  )}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      />
    </FormControl>
  );
}

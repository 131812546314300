import firebase from "firebase";
import { createContext, useContext, useState } from "react";
import Profile from "./core/entities/Profile";

export const UserContext = createContext({});

interface IUserProvider {
    user: Profile;
    children: any;
}

export const UserProvider = ({ user, children }: IUserProvider) => {
    const [currentUser, setCurrentUser] = useState(user);
    const value = { currentUser, setCurrentUser }

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );

}

export const useUser = () => useContext(UserContext);

export const logout = () => {
    console.debug('logout');
    return firebase.auth().signOut();
};
import { makeStyles, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ILoginProp } from "./LoginProp";

const loginTextStyles = makeStyles((theme) => ({
  welcomeText: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      fontWeight: "bold",
    },
  },
}));

const LoginText: React.FC<ILoginProp> = ({ step }: ILoginProp) => {
  const classes = loginTextStyles();
  const { t } = useTranslation();
  const [loginText, setLoginText] = useState("");

  useEffect(() => {
    switch (step) {
      case "login":
        setLoginText(t("Search, find and hire a team of freelancers to bring your idea to life"));
        break;
      case "recover-password":
        setLoginText(
          t("Don't worry, we are here help you to recover your password")
        );
        break;
      case "register":
        setLoginText(t("Welcome! Looks like you're new here."));
        break;
    }
  }, [step, setLoginText, t]);

  return (
    <Typography
      variant="h2"
      className={classes.welcomeText}
      paragraph
      style={{ whiteSpace: "pre-wrap" }}>
      {loginText}
    </Typography>
  );
};

export default LoginText;

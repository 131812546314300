import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContentRepository from '../../../infra/firebase/ContentRepository';
import { whiteList } from '../../../Settings';
import ShowPdf from '../../ShowPdf/ShowPdf';
import styles from './ShowDataPolicy.module.scss';

const contentRepository = new ContentRepository();

export interface IShowDataPolicyDialogProps {
  open: boolean;
  handleClose: any;
}

export const ShowDataPolicyDialog = ({ open, handleClose }: IShowDataPolicyDialogProps) => {

  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth={"lg"}
      disableEscapeKeyDown
      disableBackdropClick
    >
      <DialogTitle id="form-dialog-title">{t("Privacy Policy")}</DialogTitle>
      <DialogContent>
        <ShowDataPolicy />

      </DialogContent>
      <DialogActions>

        <Button onClick={handleClose} color="primary">
          {t("Ok")}
        </Button>

      </DialogActions>

    </Dialog>
  );

}


const ShowDataPolicy = () => {
  const { i18n } = useTranslation();
  const [content, setContent] = useState<any>();


  useEffect(() => {
    let lang = i18n.language.split("-")[0];
    let langFilter =
      whiteList.findIndex((w) => w === lang) !== -1 ? lang : "en";

    contentRepository.getContentByLanguage(
      "docs",
      langFilter
    )
      .then(contentResult => {
        console.debug(contentResult?.content.privacyPolicy);
        setContent(contentResult?.content)
      })
      .catch(err => console.error(err))

  }, [i18n.language]);

  return (
    <div className={styles.ShowDataPolicy} data-testid="ShowDataPolicy">
      {content && <ShowPdf url={content.privacyPolicy} />}

    </div>
  )
};

export default ShowDataPolicy;

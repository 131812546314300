import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Divider,
  Avatar,
  Grid,
  IconButton,
  Dialog,
  DialogContent,
  Snackbar,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Post, { Media } from "../../../../core/entities/Post";
import ImageGallery, { ReactImageGalleryItem } from "react-image-gallery";
import Profile from "../../../../core/entities/Profile";
import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import ChevronLeftRoundedIcon from "@material-ui/icons/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import PostAudio from "../PostAudio/PostAudio";
import PostVideo from "../PostVideo/PostVideo";
import { IPostMedia } from "../IPostMedia";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useTranslation } from "react-i18next";
import DialogConfirmation from "../../DialogConfirmation";
import PostRepository from "../../../../infra/firebase/PostRepository";
import CloseButton from "../../CloseButton";
import Alert from "@material-ui/lab/Alert";

import { GenericFieldModerationAlert, PostModerationAlert } from "../../Alerts/ModerationAlerts";

import { getAvatar } from "../../../../utils/utils";
import Moderation from "../../../../core/entities/Moderation";

const usePostShowStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    dialogContent: {
      paddin: 0,
    },
    buttons: {
      textAlign: "right",
      marginTop: 10,
    },
    dialogCustomizedWidth: {
      "max-width": "80%",
    },
    form: {
      width: "100%",
      paddingBottom: 20,
    },
    errorMessage: {
      color: "red",
    },
    galleryContainer: {
      //flexGrow: 1,
      // width: '100%',
      // minHeight: 450,
      // minWidth: 450
    },
    gallery: {
      // width: '100%',
      // height: '100%'
    },
    divider: {},
    caption: {
      marginTop: 10,
      marginLeft: 10,
      marginRight: 10,
    },
    head: {
      marginBottom: 20,

      display: "flex",
    },
    postContainer: {},
    closeButton: {
      position: "absolute",
      right: 5,
      top: 5,
      color: theme.palette.grey[500],
      [theme.breakpoints.down("md")]: {
        position: "relative",
        justifyContent: "flex-end",
        marginLeft: "auto",
      },
    },
    modal: {
      padding: 0,
    },
    overlay: {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.5,
    },
    comment: {
      marginBottom: 10,
      marginTop: 10,
      display: "flex",
    },
    commentContent: {
      marginLeft: 10,
    },
    menuWrapper: {
      [theme.breakpoints.down("md")]: {
        position: "relative",
        justifyContent: "flex-end",
      },
    },
  })
);

export interface IPostShow {
  post: Post;
  profile: Profile;
}

export interface IDialogPostShow {
  post: Post;
  open: boolean;
  handleClose: any;
  profile: Profile;
  isCurrent: boolean;
}

const toImages = (medias: Media[]) => {
  return medias.map((media) => {
    return {
      original: media.url ? media.url : "",
      thumbnail: media.url ? media.url : "",
      originalWidth: 434,
      originalHeight: 434,
      ...media,
    };
  });
};

const ITEM_HEIGHT = 48;

export const DialogPostShow = ({
  post,
  open,
  handleClose,
  profile,
  isCurrent,
}: IDialogPostShow) => {
  const { t, i18n } = useTranslation();
  const classes = usePostShowStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isCopied, setIsCopied] = useState(false);
  const openMenu = Boolean(anchorEl);
  const postRepository = new PostRepository();

  const [showDelete, setShowDelete] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeletePost = () => {
    setShowDelete(true);
  };

  const handleClosePost = () => {
    handleCloseMenu();
    handleClose();
  };

  const handleEditPost = () => {
    handleCloseMenu();
    handleClose();
  };

  const handleCopyPostLink = () => {
    const url = `${window.location.href.split("?")[0]}?post=${post.id}`;
    navigator.clipboard.writeText(url);
    setIsCopied(true);
  };

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 6000);
    }
  }, [isCopied]);

  const deletePost = () => {
    postRepository
      .delete(post.id)
      .then(() => {
        handleCloseMenu();
        handleClose(`post deleted ${post.id}`);
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="md">
        {/* <CloseButton handleClose={handleClose} /> */}
        <div>

          {post.moderated && <GenericFieldModerationAlert
            moderation={{
              field: "post image",
              fieldDescription: "post image",
              moderatedValue: "",
              originalValue: "",
              actionText: "delete your post",
            }
            }
            callback={() => { }} />}
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
            className={classes.closeButton}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            className={classes.menuWrapper}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "20ch",
              },
            }}>
            {/* <MenuItem key='Edit' onClick={handleEditPost}>{t('Edit')}</MenuItem> */}
            {isCurrent && (
              <MenuItem key="Delete" onClick={handleDeletePost}>
                {t("Delete")}
              </MenuItem>
            )}
            <MenuItem key="Close" onClick={handleClosePost}>
              {t("Close")}
            </MenuItem>
            <MenuItem key="Close" onClick={handleCopyPostLink}>
              {t("Share")}
            </MenuItem>
          </Menu>
        </div>
        {showDelete && (
          <DialogConfirmation
            content={t(`Do you really want to delete?`)}
            show={showDelete}
            handleAgree={deletePost}
            title={t("Delete post")}
            handleDisagree={() => setShowDelete(false)}
          />
        )}
        {/* {moderated && <PostModerationAlert callback={unmoderatePost}/>} */}

        <PostShow post={post} profile={profile} />
        <Snackbar
          open={isCopied}
          autoHideDuration={6000}
          onClose={() => setIsCopied(false)}
          style={{ zIndex: 10000 }}>
          <Alert
            onClose={() => setIsCopied(false)}
            severity="success"
            style={{ zIndex: 10000 }}>
            {t("Link copied!")}
          </Alert>
        </Snackbar>
      </Dialog>
    </>
  );
};

const PostShow = ({ post, profile }: IPostShow) => {
  const classes = usePostShowStyles();
  const [photos, setPhotos] = useState(
    post.medias ? toImages(post.medias) : []
  );
  const [medias, setMedias] = useState(post.medias);

  const moderated = post.moderated;

  const [postMedias, setPostMedias] = useState<IPostMedia[] | undefined>(
    post.medias?.map((media) => {
      return {
        croppedUrl: media.url,
        fileName: "",
        height: 0,
        width: 0,
        src: media.url,
        crop: media.crop,
        croppedAreaPixels: media.croppedAreaPixels,
        type: media.type,
        zoom: media.zoom,
        thumbUrl: media.thumbUrl,
      };
    })
  );

  const renderItem = (item: ReactImageGalleryItem) => {
    const media = postMedias?.find(
      (postMedia) => postMedia.croppedUrl === item.thumbnail
    );

    if (media?.type === "audio") return <PostAudio photo={media} />;

    if (media?.type === "video")
      return <PostVideo media={media} updateThumb={() => { }} edit={false} />;

    return (
      <img
        src={item.original}
        alt={item.originalAlt}
        width="100%"
        height="100%"
      />
    );
  };

  const unmoderatePost = () => {
    //handleDeletePost();
  }

  return (
    <div className={classes.root}>

      <Grid container className={classes.postContainer} spacing={0}>
        <Grid item xl={7} lg={7} md={7} sm={12} xs={12}>

          {photos && (
            <ImageGallery
              items={photos}
              showIndex
              showBullets
              showPlayButton={false}
              showFullscreenButton={false}
              showThumbnails={false}
              renderLeftNav={renderLeftNav}
              renderRightNav={renderRightNav}
              renderItem={renderItem}
            />
          )}
        </Grid>

        <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
          <div className={classes.caption}>
            <HeadPost profile={profile} post={post} />

            <Divider
              variant="fullWidth"
              orientation="horizontal"
              style={{ marginBottom: 25 }}
            />

            {post.caption && (
              <RenderComment profile={profile} comment={post.caption} />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const HeadPost = ({ post, profile }: IPostShow) => {
  const classes = usePostShowStyles();

  return (
    <div className={classes.head}>
      <Avatar
        alt={profile.name ? profile.name : ""}

        src={profile.profileImageUrl ? profile.profileImageUrl : getAvatar(profile.name)}

      />
      <div className={classes.commentContent}>
        <Typography
          style={{ fontWeight: "bold" }}
          gutterBottom
          variant="inherit">
          <Link to={`/${profile.nickname}`}>{profile.nickname}</Link>
        </Typography>
      </div>
    </div>
  );
};

const renderLeftNav = (onClick, disabled) => {
  return (
    <button
      className="image-gallery-icon image-gallery-left-nav"
      disabled={disabled}
      onClick={onClick}>
      <ChevronLeftRoundedIcon fontSize="large" />
    </button>
  );
};

const renderRightNav = (onClick, disabled) => {
  return (
    <button
      className="image-gallery-icon image-gallery-right-nav"
      disabled={disabled}
      onClick={onClick}>
      <ChevronRightRoundedIcon fontSize="large" />
    </button>
  );
};

export interface IRenderComment {
  profile: Profile;
  comment: string;
}

const RenderComment = ({ profile, comment }: IRenderComment) => {
  const classes = usePostShowStyles();

  return (
    <div className={classes.comment}>
      <Avatar
        alt={profile.nickname ? profile.nickname : ""}

        src={profile.profileImageUrl ? profile.profileImageUrl : getAvatar(profile.name)}

      />
      <div className={classes.commentContent}>
        <Typography
          style={{ fontWeight: "bold" }}
          gutterBottom
          display="inline"
          variant="inherit">
          <Link to={`/${profile.nickname}`}>{profile.nickname}</Link>
        </Typography>
        <Typography
          gutterBottom
          display="inline"
          style={{ marginLeft: 5 }}
          variant="inherit">
          {comment}
        </Typography>
      </div>
    </div>
  );
};

export default PostShow;

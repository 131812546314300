import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link as LinkRouter } from "react-router-dom";


export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: 20,
        },
    })
);

const SettingsPrivacyComponent = () => {
    const { t, i18n } = useTranslation();

    const classes = useStyles();

    return (<div className={classes.root}>
        <p><LinkRouter to="/impressum">{t("Impressum/Imprint")}</LinkRouter></p>
        <p><LinkRouter to="/privacypolicy">{t("Privacy Policy")}</LinkRouter></p>
        <p><LinkRouter to="/termsandconditions">{t("Terms & Conditions")}</LinkRouter></p>
    </div>)
}

export default SettingsPrivacyComponent;
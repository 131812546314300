import { getRepository } from "fireorm";
import Post, { Media } from "../../core/entities/Post";
import { IRepository } from "../../core/interfaces/IRepository";
import { db } from "./firebasecofig";



export default class PostRepository implements IRepository<Post>{

    repository = getRepository(Post);
    postsRef = db.collection('Posts');
    next: any = null;
    lastVisible: any = null;


    resetPagination = () => {
        this.next = null;
    }

    getAllPostsByProfile = (profileId: string) => {

        let where = this.repository.whereEqualTo(p => p.profileId, profileId);

        return where.orderByDescending(p => p.publishDate).find();

    }

    getPostsForHome = (posts: string[]): Promise<Post[]> => {
        return this.repository.whereIn(p => p.id, posts).find();
    }

    getPostsByProfile = (profileId: string, limit: number): Promise<Post[]> => {

        var query = this.next
            ?
            this.next
            :
            this.postsRef
                .where('profileId', '==', profileId)
                .orderBy('publishDate', 'desc')
                .limit(limit);

        return query.get()
            .then((snapshots) => {

                var newLast = snapshots.docs[snapshots.docs.length - 1];
                console.debug('newLast', newLast.id);
                console.debug('lastVisible', this.lastVisible?.id);

                if (newLast === this.lastVisible) {

                    return Promise.resolve([]);
                } else {

                    this.lastVisible = newLast;
                    console.debug("mew lastVisible", this.lastVisible?.id);

                    this.next = this.postsRef
                        .where('profileId', '==', profileId)
                        .orderBy('publishDate', 'desc')
                        .startAfter(this.lastVisible)
                        .limit(limit);

                    const result: Post[] = [];

                    snapshots.forEach((doc) => {
                        const {
                            id,
                            caption,
                            locationId,
                            medias,
                            modifiedDate,
                            profileId,
                            profileTags,
                            publishDate,
                            thumbnailUrl,
                            type
                        } = doc.data();

                        result.push({
                            id,
                            caption,
                            locationId,
                            medias,
                            modifiedDate,
                            profileId,
                            profileTags,
                            publishDate,
                            thumbnailUrl,
                            type
                        });
                    });

                    console.debug("result", result);

                    return Promise.resolve(result);
                }

            })
            .catch(error => { return Promise.reject(error); })


        // return this.repository
        //     .whereEqualTo(Post => Post.profileId, profileId)
        //     .orderByDescending(Post => Post.publishDate)
        //     .limit(limit)
        //     .find();
    }


    createOrUpdate(entity: Post): Promise<Post> {
        return entity.id ? this.update(entity) : this.create(entity);
    }

    create(entity: Post): Promise<Post> {
        if (entity.medias) {
            const mediasArray = entity.medias.map((obj) => { return Object.assign({}, obj) });
            entity.medias = mediasArray;
        }

        return this.repository.create(entity);
    }

    update(entity: Post): Promise<Post> {

        if (entity.medias) {
            const mediasArray = entity.medias.map((obj) => { return Object.assign({}, obj) });
            entity.medias = mediasArray;
        }

        return this.repository.update(entity);
    }
    delete(id: string): Promise<void> {
        return this.repository.delete(id);
    }
    getById(id: string): Promise<Post> {
        return this.repository.findById(id);
    }

    updateMedias = (postId: string, medias: Media[]): Promise<void> => {

        const mediasArray = medias.map((obj) => { return Object.assign({}, obj) });

        return this.postsRef.doc(postId).update({
            medias: mediasArray
        });
    }
}
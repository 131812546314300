import { useEffect, useState } from "react";
import {
  Collapse,
  IconButton,
  makeStyles,
  TextField,
  createStyles,
  Theme,
  FormControl,
  InputLabel,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { useProfileContext } from "../../../ProfileContext";
import { useTranslation } from "react-i18next";
import ProfileRepository from "../../../infra/firebase/ProfileRepository";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import LocationComponent from "../../shared/LocationComponent/LocationComponent";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { cleanString } from "../../../utils/string";
import { functions } from "../../../infra/firebase/firebasecofig";
import { useHistory, useLocation } from "react-router";
import ProfileController from "../../Profile/ProfileController";
import Profile from "../../../core/entities/Profile";
import Location from "../../../core/entities/Location";
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";
import { getDateFormatPattern, IsJsonString, timestampToData } from "../../../utils/utils";
import AttributeRepository from "../../../infra/firebase/AttributeRepository";
import { Attribute } from "../../../core/entities/Attribute";
import AttributesForm from "../Attributes/AttributesForm";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: 20,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    form: {
      width: "100%", // Fix IE 11 issue.
      //marginTop: theme.spacing(1),
      paddingLeft: 20,
      paddingRight: 20,
      paddingBottom: 20,
      marginTop: "10px",
    },
    label: {
      fontWeight: "bold",
    },
    input: {
      border: "none",
    },
    breadcrumbContainer: {
      backgroundColor: "#F8F8F8",
      height: 60,
    },
    breadcrumb: {
      marginLeft: "20px",
    },
    listItem: {
      borderRadius: 5,
      color: "white",
    },
    buttons: {
      textAlign: "right",
      marginTop: 10,
    },
    errorMessage: {
      color: "red",
    },
    nickname: {
      //textTransform: 'lowercase'
    },
  })
);

const validateChar = (event) => {
  // Prevents single key down special chars Eg.: [] {} %@#
  if (!/^[A-Za-z0-9_\.-]*$/.test(event.key)) {
    event.preventDefault();
  }
};

const attributeRepository = new AttributeRepository();

const SettingsProfileComponent = () => {
  const repository = new ProfileRepository();
  const profileController = new ProfileController();

  const classes = useStyles();

  const { profile: globalProfile, isAdmin } = useProfileContext();

  const locationRouter = useLocation<{ profileId: string }>();

  const [profile, setProfile] = useState<Profile | null>();

  const { t, i18n } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [warningMessage, setWarningMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(true);
  const [location, setLocation] = useState<Location | undefined>();
  const [email, setEmail] = useState<string | null | undefined>("");
  const [oldEmail, setOldEmail] = useState("");
  const history = useHistory();
  const [components, setComponents] = useState<Attribute[]>([]);
  const [formLoading, setFormLoading] = useState(false);
  const [customForm, setCustomForm] = useState<{ [key: string]: any }>({});
  const [formatPattern, setFormatPattern] = useState("");

  const fetchComponents = async () => {
    setFormLoading(true);
    const attributesFields = await attributeRepository.getAllByUse(
      "profileAttributes"
    );

    const filteredAttributes = attributesFields
      .filter((attribute) => attribute.uses["profileAttributes"])
      .sort(
        (a, b) => a.uses["profileAttributes"] - b.uses["profileAttributes"]
      );
    let formAttributes = {};
    let tempAttributes: { [key: string]: any } = profile?.attributes || {};
    Object.keys(tempAttributes).forEach((attribute) => {
      formAttributes[attribute] = JSON.stringify(
        tempAttributes[attribute].value["en"]
          ? Object.keys(tempAttributes[attribute].value)
            .sort()
            .reduce((obj, key) => {
              obj[key] = tempAttributes[attribute].value[key];
              return obj;
            }, {})
          : tempAttributes[attribute].value
      );
    });
    console.debug("formAttributes", formAttributes);
    console.debug("filteredAttributes", filteredAttributes);

    setCustomForm(formAttributes);
    setComponents(filteredAttributes);
    setFormLoading(false);
  };


  useEffect(() => {

    let [lang, country] = i18n.language.split("-");
    console.debug("FormatPattern", lang, getDateFormatPattern(lang));

    setFormatPattern(getDateFormatPattern(lang).toUpperCase());

  }, [i18n.language]);

  useEffect(() => {
    if (profile) {
      fetchComponents();
    }
  }, [profile]);

  const updateEmail = async (email) => {
    try {
      const caller = functions.httpsCallable("account-onCallUpdateEmail");

      const response = await caller({ uid: profile?.uid, email: email });

      if (response.data.error !== null) {
        setErrorMessage(response.data.error.message);
      }

      setEmail(response.data.email);
    } catch (error) {
      console.error(error);
      setEmail(email);
    }
  };

  useEffect(() => {
    if (locationRouter.state === undefined) {
      setProfile(globalProfile);
      return;
    }

    setLoading(true);

    let profileId = locationRouter.state.profileId;
    if (profileId && isAdmin) {
      repository
        .getById(profileId)
        .then((p) => {
          setProfile(p);
          setWarningMessage(
            t(
              `You are in an admin mode. Editing operations will affect the user profile '${p.name}(${p.nickname})'`
            )
          );
          setErrorMessage("");
        })
        .catch((error) => {
          console.error(error);
          setWarningMessage("");
          setErrorMessage(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setProfile(globalProfile);

      setLoading(false);
    }
  }, [globalProfile]);

  useEffect(() => {


    setLocation(profile?.location);

    async function getEmail() {
      try {
        setLoadingEmail(true);
        const caller = functions.httpsCallable("account-onCallGetEmail");

        const response = await caller({ uid: profile?.uid });

        if (response.data.error !== null) {
          setErrorMessage(response.data.error.message);
        }
        setEmail(response.data.email);
        setOldEmail(response.data.email);
        setLoadingEmail(false);
      } catch (error) {


        console.error(error);
        setEmail("");
      }
    }

    if (profile) {
      getEmail();

    }
  }, [profile]);

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: profile?.name,
      nickname: profile?.nickname,
      location: profile?.location?.name,
      website: profile?.website,
      bio: profile?.bio,
      headline: profile?.headline,
      ...customForm,

      birthdate: profile?.birthdate
        ? moment(timestampToData(profile?.birthdate)).toDate()
        : null,
      emailAddress: profile?.emailAddress,
    },
  });

  const getBirthDate = (birthdate: any) => {
    if (birthdate.seconds) {
      const birthTimestamp = timestampToData(birthdate);
      const birthMoment = moment(birthTimestamp);
      const birthDate = birthMoment.toDate();
      return birthDate;
    } else {
      return birthdate;
    }

  }

  useEffect(() => {
    reset({
      name: profile?.name,
      nickname: profile?.nickname,
      location: profile?.location?.name,
      website: profile?.website,
      bio: profile?.bio,
      headline: profile?.headline,
      ...customForm,
      birthdate: profile?.birthdate
        ? getBirthDate(profile?.birthdate)
        : null,
      emailAddress: profile?.emailAddress,
    });

    setLocation(profile?.location);
  }, [profile, customForm]);

  const handleSelect = (selectedValue) => {
    setLoading(true);

    if (!selectedValue) return;

    let terms = selectedValue.value.terms;
    let length = terms.length;

    let city = terms[length - 3] ? terms[length - 3].value : "";
    let state = terms[length - 2] ? terms[length - 2].value : "";
    let country = terms[length - 1] ? terms[length - 1].value : "";

    let name = (city ? city + ", " : "") + country;
    let description = selectedValue.formatted_address;
    let placeId = selectedValue.value.place_id;

    geocodeByAddress(selectedValue.label)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        setLocation({
          city: city,
          state: state,
          country: country,
          name: name,
          latitude: lat,
          longitude: lng,
          placeId: placeId,
          description: description,
        });
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLocation({
          city: city,
          state: state,
          country: country,
          name: name,

          placeId: placeId,
          description: description,
        });
        setLoading(false);
      });
  };

  const onSubmit = async (data: any) => {
    setLoading(true);

    const {
      website: websiteTemp,
      nickname: nicknameTemp,
      bio: bioTemp,
      headline: headlineTemp,
      location: locationTemp,
      name: nameTemp,
      ...attributesData
    } = data;

    try {

      const formatedAttributes = {};


      components.forEach(component => {
        const attName = component.name;
        const valueData = isNaN(data[attName]) ? data[attName] : Number(data[attName]);
        console.debug(valueData);
        if (valueData) {
          formatedAttributes[attName] = {
            value: IsJsonString(valueData) ? JSON.parse(valueData) : valueData,
            displayName: component.displayName,
            unit: component.unit
          };
        }
      })


      console.debug("onSubmit", "formatedAttributes", formatedAttributes);

      if (!/^[A-Za-z0-9_\.-]*$/.test(data.nickname)) {

        setError("nickname", {
          message: t("Invalid characters in nickname"),
          type: "validate",
        });
        setLoading(false);
      }

      if (profileController.nicknameIsAReserverdWord(data.nickname)) {

        setError("nickname", {
          message: t("You can not use this nickname."),
          type: "validate",
        });
        setLoading(false);
        return;
      }

      if (data.birthdate) {
        const verifydate = moment(data.birthdate);

        if (verifydate.isSameOrAfter(moment())) {
          setError("birthdate", {
            message: t("Birthdate must be in the past"),
            type: "validate",
          });
          setLoading(false);
          return;
        }
      }

      if (email !== oldEmail) {
        await updateEmail(email);
      }

      if (profile) {
        repository
          .getById(profile.id)
          .then(async (profileForEditing) => {
            profileForEditing.name = data.name;
            profileForEditing.nickname = cleanString(
              data.nickname.toLowerCase().trim()
            );
            profileForEditing.location = location;
            profileForEditing.headline = data.headline;
            profileForEditing.attributes = formatedAttributes;

            if (data.birthdate) {
              profileForEditing.birthdate = data.birthdate._isAMomentObject
                ? data.birthdate.toDate()
                : data.birthdate;
            } else {
              profileForEditing.birthdate = null;
            }

            if (profile.nickname !== profileForEditing.nickname) {
              const validateNickname = functions.httpsCallable(
                "profiles-onCallNickname"
              );

              console.debug("onSubmit", "validating nickname");

              await validateNickname({
                nickname: profileForEditing.nickname,
                id: profileForEditing.id,
              });
            }

            const caller = functions.httpsCallable("account-onCallGetEmail");
            const response = await caller({ uid: profile?.uid });
            profileForEditing.emailAddress = response.data.email;

            const moderationCaller = functions.httpsCallable(
              "moderation-onCallModerateText"
            );
            const moderationResponse = await moderationCaller({
              values: [
                {
                  field: "headline",
                  content: profileForEditing.headline,
                },
                {
                  field: "name",
                  content: profileForEditing.name,
                },
              ],
            });

            const moderations = moderationResponse.data.filter(
              (d) => d.moderated
            );
            const hasModerations = moderations.length > 0;
            const errors = moderationResponse.data.filter((d) => d.error);
            const hasErrors = errors.length > 0;

            if (hasErrors) {
              setErrorMessage(t("There is errors."));
              console.error(errors);
              setLoading(false);
              return;
            }

            if (hasModerations) {
              for (var moderation of moderations) {
                setError(moderation.field, {
                  message: `${t("The field")} ${t(moderation.field)} ${t(
                    "violates our Terms & Conditions"
                  )}`,
                  type: "validate",
                });
              }

              setLoading(false);
              return;
            }

            repository
              .update(profileForEditing)
              .then(async () => {
                setErrorMessage("");
                setSuccessMessage(t("Information updated successfully"));

                console.debug("onSubmit", "updating profile");
                await profileController.updateProfileRegistration(
                  profileForEditing
                );

                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                setSuccessMessage("");
                setErrorMessage(error.message);
                console.error(error);
              });
          })
          .catch((error) => {
            setLoading(false);
            if (error.message === "internal") {
              setErrorMessage("Nickname pode conter termos ofensivos");
            } else {
              setErrorMessage(error.message);
            }
            console.error(error);
          });
      }
    } catch (err: any) {
      setLoading(false);

      setErrorMessage(err.message);
      console.error(err.message);
    }
  };

  return (
    <div className={classes.root}>
      <Collapse in={warningMessage !== ""}>
        <Alert severity="warning">{warningMessage}</Alert>
      </Collapse>

      <Collapse in={errorMessage !== ""}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorMessage("");
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {errorMessage}
        </Alert>
      </Collapse>

      <Collapse in={successMessage !== ""}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccessMessage("");
              }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }>
          {successMessage}
        </Alert>
      </Collapse>
      {formLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "4rem",
          }}>
          <CircularProgress />
        </div>
      ) : (
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="name"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("Name")}
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="name"
                autoFocus
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />

          <Controller
            control={control}
            name="nickname"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("Nickname")}
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="nickname"
                onKeyDown={validateChar}
                className={classes.nickname}
                inputProps={{ style: { textTransform: "lowercase" } }}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="nickname"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />

          <TextField
            value={email}
            label={t("E-mail")}
            fullWidth
            variant="standard"
            margin="normal"
            required
            id="emailAddress"
            // onKeyDown={validateChar}
            className={classes.nickname}
            inputProps={{ style: { textTransform: "lowercase" } }}
            onChange={(event) => setEmail(event.target.value)}
          />
          <ErrorMessage
            errors={errors}
            name="emailAddress"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />


          <Controller
            control={control}
            name="headline"
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                label={t("Headline")}
                fullWidth
                variant="standard"
                margin="normal"
                id="headline"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="headline"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          {/* <Controller
            control={control}
            name="birthdate"
            render={({ field }) => (
              <KeyboardDatePicker
                {...field}
                color="primary"
                label={t("Date of birth")}
                fullWidth
                maxDate={new Date()}
                id="birthdate"
                format={formatPattern}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="birthdate"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          /> */}

          <FormControl variant="standard" fullWidth>
            <InputLabel
              htmlFor="select-location"
              style={{
                position: "static",
                transform: "translate(0, 1.5px) scale(0.75)",
                transformOrigin: "top left",
                paddingBottom: 5,
                paddingTop: 10,
              }}>
              {t("Location")}
            </InputLabel>
            <LocationComponent
              id="select-location"
              defaultValue={location?.placeId}
              handleSelect={handleSelect}
            />
          </FormControl>
          <div style={{ marginTop: 16 }}>
            <AttributesForm control={control} components={components} />
          </div>

          <div className={classes.buttons}>
            {loading ? (
              <div
                style={{
                  display: "inline-block",
                  background: "#c9c9c9",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  padding: "6px 22px",
                  margin: "4px 8px",
                }}>
                <CircularProgress size={14} />
              </div>
            ) : (
              <Button
                type="submit"
                color="primary"
                variant="contained"
                onClick={() => clearErrors()}
                disabled={loading}>
                {t("Save")}
              </Button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default SettingsProfileComponent;

import firebase from "firebase";
import { getRepository } from "fireorm";
import { ChatMessage } from "../../core/entities/Chat";
import { IRepository } from "../../core/interfaces/IRepository";
import { db } from "./firebasecofig";

export default class ChatMessageRepository implements IRepository<ChatMessage> {
  repository = getRepository(ChatMessage);
  chatMessagesRef = db.collection("Messages");

  create(entity: ChatMessage): Promise<ChatMessage> {
    entity.sendDate = firebase.firestore.FieldValue.serverTimestamp();
    console.debug(entity, "ENTITY");

    return this.repository.create(entity);
  }
  update(entity: ChatMessage): Promise<ChatMessage> {
    throw new Error("Method not implemented.");
  }
  delete(id: string): Promise<void> {
    throw new Error("Method not implemented.");
  }
  getById(id: string): Promise<ChatMessage> {
    throw new Error("Method not implemented.");
  }
}

import { useState } from "react";

import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import ProfileAbout from "../ProfileAbout/ProfileAbout";
import firebase from "firebase";
import PhotoCamera from "@material-ui/icons/PhotoCamera";
import { useProfileContext } from "../../../ProfileContext";
import ImageEditor from "../../shared/ImageEditor";
import ProfileCategories from "../ProfileCategories/ProfileCategories";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCurrentProfileContext } from "../../../CurrentProfileContext";
import Skeleton from "@material-ui/lab/Skeleton";
import ProfileController from "../ProfileController";
import { getAvatar, refreshPage } from "../../../utils/utils";
import ProfileAgents from "../ProfileAgents/ProfileAgents";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import VerifyEmailDialog from "../../shared/VerifyEmailDialog";
import ProfileAttributes from "../ProfileAttributes/ProfileAttributes";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    zIndex: 1000,
    backgroundColor: "transparent",
  },
  moderatedText: {
    textDecoration: "line-through",
    color: "red",
  },
  editphoto: {},
  iconButton: {
    backgroundColor: "#e0e0e0",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#f3f3f3" },
  },
  editPhotoContainer: {
    position: "absolute",
    bottom: 10,
    right: 10,
  },
  button: {
    textTransform: "initial",
    marginBottom: 10,
  },
  containerCurrentAvatar: {
    position: "relative",
    marginBottom: 5,
  },
  containerAvatar: {
    position: "relative",
    marginBottom: 5,
  },
  avatar: {
    width: theme.spacing(30),
    height: theme.spacing(30),
  },
  moderatedAvatar: {
    width: theme.spacing(30),
    height: theme.spacing(30),
    filter: "blur(0.25rem)",
  },
  avatarFundo: {
    width: theme.spacing(32),
    height: theme.spacing(32),
    backgroundColor: "white",
  },
  avatarFundoModerated: {
    width: theme.spacing(32),
    height: theme.spacing(32),
    backgroundColor: "red",
  },
  middle: {
    transition: ".5s ease",
    opacity: 0,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  },
  input: {
    display: "none",
  },
  profileData: {
    // [theme.breakpoints.up("md")]: {
    //   maxWidth: 224,
    // },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "85vw",
    },
  },
}));

const ProfileDetails = () => {
  const classes = useStyles();

  const {
    error: globalError,
    loading: globalLoading,
    profile: globalProfile,
    isAdmin,
  } = useProfileContext();
  const profileController = new ProfileController();
  const { loading, profile, isCurrent } = useCurrentProfileContext();
  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const [openEdit, setOpenEdit] = useState(false);
  const handleEditProfile = () => {
    history.push("/settings/profile", {
      profileId: profile?.id,
    });
  };
  const [success, setSuccess] = useState("");

  const [chatId, setChatId] = useState<string | null>(null);

  const [openEditImage, setOpenEditImage] = useState(false);
  const handleClickOpenImage = () => {
    setOpenEditImage(true);
  };
  const handleCloseImage = () => {
    setOpenEditImage(false);
  };

  const [loadingChat, setLoadingChat] = useState(false);

  const handleCreateChat = async () => {
    if (firebase.auth().currentUser?.emailVerified) {
      setLoadingChat(true);

      history.push({
        pathname: "/chats",
        state: { profileId: profile?.id },
      });
    } else {
      setIsVerifyEmailOpen(true);
    }
  };

  const handleShowAtHome = async () => {
    if (profile) {
      try {
        await profileController.setShowAtHome(profile.id, !profile.showAtHome);
        refreshPage();
      } catch (error) {
        alert(error);
      }
    }
  };

  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  const isModerated = (field: string) => {
    return profile?.moderations
      ? profileController.verifyIfExistsModerationOnField(
        field,
        profile?.moderations
      )
      : false;
  };

  const getProfileImage = () => {
    const moderated = isModerated("profileImageUrl");
    var url = profile?.profileImageUrl
      ? profile?.profileImageUrl
      : getAvatar(profile?.name);

    if (moderated) {
      url = isCurrent ? url : getAvatar(profile?.name);
    }

    return url;
  };

  const getProfileImageClass = () => {
    const moderated = isModerated("profileImageUrl");
    return moderated && isCurrent ? classes.moderatedAvatar : classes.avatar;
  };

  const getProfileImageFundoClass = () => {
    const moderated = isModerated("profileImageUrl");
    return moderated && isCurrent
      ? classes.avatarFundoModerated
      : classes.avatarFundo;
  };

  const getFieldValue = (field: string) => {
    const moderated = isModerated(field);
    var text = profile && profile[field] ? profile[field] : "";
    var customClass = moderated && isCurrent ? classes.moderatedText : "";

    if (moderated) {
      text = isCurrent ? text : "";
    }

    return { text: text, customClass: customClass };
  };

  const Headline = () => {
    const fieldValue = getFieldValue("headline");

    return (
      <Typography
        variant="body1"
        align="center"
        className={fieldValue.customClass}>
        {loading ? <Skeleton variant="text" /> : fieldValue.text}
      </Typography>
    );
  };

  const Name = () => {
    const fieldValue = getFieldValue("name");

    return (
      <Typography
        color="primary"
        variant="h5"
        align="center"
        className={fieldValue.customClass}>
        {loading ? <Skeleton variant="text" /> : fieldValue.text}
      </Typography>
    );
  };

  return (
    <>
      <Paper elevation={0} className={classes.paper}>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}>
          <Grid item xl={12}>
            <div
              className={
                isCurrent || isAdmin
                  ? classes.containerCurrentAvatar
                  : classes.containerAvatar
              }>
              <Avatar alt="Avatar" className={getProfileImageFundoClass()}>
                {!loading && profile ? (
                  <Avatar
                    alt="Avatar"
                    src={getProfileImage()}
                    className={getProfileImageClass()}
                  />
                ) : (
                  <Skeleton
                    width={240}
                    height={240}
                    variant="circle"
                    animation="wave"
                  />
                )}
              </Avatar>

              {(isCurrent || isAdmin) && (
                <div className={classes.editPhotoContainer}>
                  <IconButton
                    onClick={handleClickOpenImage}
                    color="primary"
                    aria-label="upload picture"
                    component="span"
                    className={classes.iconButton}>
                    <PhotoCamera />
                  </IconButton>

                  {openEditImage && (
                    <ImageEditor
                      title="Upload Photo"
                      handleClose={handleCloseImage}
                      open={openEditImage}
                      setSuccess={setSuccess}
                      image="photo"
                      profileId={profile ? profile.id : ""}
                      cropShape="round"
                      aspect={1}
                    />
                  )}
                </div>
              )}
            </div>
          </Grid>

          <div
            className={classes.profileData}
            style={{
              overflowWrap: "break-word",
              minWidth: "200px",
            }}>
            <Name />
            <Typography variant="h5" align="center" color="primary">
              {loading ? <Skeleton variant="text" /> : `@${profile?.nickname}`}
            </Typography>

            <ProfileAgents />

            <Headline />

            {profile?.location && (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={{ marginBottom: 10 }}>
                <LocationOnIcon />
                <Typography variant="body1" align="center">
                  {loading ? (
                    <Skeleton variant="text" />
                  ) : (
                    profile?.location.name
                  )}
                </Typography>
              </Grid>
            )}

            {/* <Divider variant='fullWidth' style={{marginBottom: 8}}/>

            <ProfileAgents /> */}
          </div>

          {profile && !isCurrent && (
            <div style={{ width: "100%" }}>
              {loadingChat ? (
                <div
                  style={{
                    display: "flex",
                    background: "#c9c9c9",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    padding: "12px",
                    margin: "4px 0",
                  }}>
                  <CircularProgress size={14} />
                </div>
              ) : (
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  disableElevation
                  className={classes.button}
                  onClick={handleCreateChat}>
                  {t("Contact")}
                </Button>
              )}
            </div>
          )}

          {profile && (isCurrent || isAdmin) && (
            <div style={{ width: "100%" }}>
              <Button
                onClick={handleEditProfile}
                color="secondary"
                variant="contained"
                fullWidth
                disableElevation
                className={classes.button}>
                {t("Edit profile")}
              </Button>
            </div>
          )}

          {profile && isAdmin && (
            <div style={{ width: "100%" }}>
              <Button
                onClick={handleShowAtHome}
                color="secondary"
                variant="contained"
                fullWidth
                disableElevation
                className={classes.button}>
                {profile.showAtHome ? t("Remove from home") : t("Show at home")}
              </Button>
            </div>
          )}

          <Grid item xl={12}>
            <Grid
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{ marginBottom: 10 }}>
              <Grid item />
            </Grid>
          </Grid>
        </Grid>

        {!isSmall && <ProfileInformation />}
      </Paper>
      <VerifyEmailDialog
        open={isVerifyEmailOpen}
        onClose={() => setIsVerifyEmailOpen(false)}>
        {t(
          "To be able to chat with other users, you need to verify your email."
        )}
      </VerifyEmailDialog>
    </>
  );
};

export const ProfileInformation = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch">
      {/* <Grid item>
        <ProfileAttributes />
      </Grid> */}
      <Grid item>
        <ProfileAbout />
      </Grid>

      <Grid item>
        <ProfileCategories />
      </Grid>
    </Grid>
  );
};

export default ProfileDetails;

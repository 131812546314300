import { useCallback, useEffect, useState } from "react";
import {
  Container,
  createStyles,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  GridListTileBar,
  ImageListItemBar,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import CategoryRepository from "../../../infra/firebase/CategoryRepository";
import { useTranslation } from "react-i18next";
import { Category } from "../../../core/entities/Category";
import { Link as LinkRouter } from "react-router-dom";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
    },
    fundo: {
      backgroundColor: "#F8F8F8",
      paddingTop: 50,
      paddingBottom: 50,
    },
    gridList: {
      width: "auto",
      height: "auto",
      transform: "translateZ(0)",
    },
    tile: {
      borderRadius: 10,
      "& $image": {
        transition: "transform .5s ease",
      },
      "&:hover": {
        "& $image": {
          transform: "scale(1.2)",
        },
      },
    },
    titleBar: {
      background: "transparent",
      textTransform: "capitalize",
    },
    icon: {
      color: "white",
    },
    divider: {
      marginBottom: 30,
      backgroundColor: theme.palette.primary.main,
      height: 2,
    },
    image: {
      flex: 1,
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    imageSkeleton: {
      marginRight: "1rem",
    },
    tileSkeleton: {
      width: "16rem",
      padding: "0 2rem",
    },
  })
);

const getCategoryTranslatedName = (categoryParam: Category, lang: string) => {
  if (categoryParam.translatedNames === undefined) {
    return categoryParam.name;
  }

  return categoryParam.translatedNames[lang]
    ? categoryParam.translatedNames[lang]
    : categoryParam.name;
};

const getCategoryUrl = (category: Category, lang: string) => {
  return `${process.env.REACT_APP_SEARCH_CATEGORIES_URL?.replace(
    "[lang]",
    lang
  )}${getCategoryTranslatedName(category, lang)}`;
};

const categoryRepository = new CategoryRepository();

const HomeCategories = (props) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language.split("-")[0]);
  const [isLoading, setIsLoading] = useState(true);

  const classes = useStyles();
  const [categories, setCategories] = useState<Category[]>([]);

  const getCategories = useCallback(async () => {
    try {
      const newCategories =
        await categoryRepository.getParentCategoriesForHome();
      if (newCategories.length !== categories.length) {
        setCategories(newCategories);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }, [setCategories, categories]);

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  const getGridListCols = () => {
    if (isWidthUp("xl", props.width)) {
      return 6;
    }

    if (isWidthUp("lg", props.width)) {
      return 6;
    }

    if (isWidthUp("md", props.width)) {
      return 3;
    }

    return 1;
  };

  return (
    <Container className={classes.fundo} maxWidth="xl">
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center">
          {(!isLoading && categories.length > 0) && <Grid item>
            <Typography variant="h4" paragraph color="primary">
              {t("Find your team")}
            </Typography>
            <Divider variant="middle" className={classes.divider} />
          </Grid>
          }

          <Grid item>
            <ImageList
              rowHeight={300}
              gap={10}
              className={classes.gridList}
              cols={getGridListCols()}>
              {isLoading
                ? Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <ImageListItem
                      key={index}
                      cols={1}
                      rows={1}
                      classes={{ item: classes.tileSkeleton }}>
                      <Skeleton variant="rect" className={classes.image} />
                    </ImageListItem>
                  ))
                : categories.map((category) => (
                  <ImageListItem
                    key={category.id}
                    cols={1}
                    rows={1}
                    classes={{ item: classes.tile }}>
                    <LinkRouter to={getCategoryUrl(category, lang)}>
                      <img
                        src={category.imageUrl}
                        alt={category.name}
                        className={classes.image}
                      />
                    </LinkRouter>

                    <ImageListItemBar
                      title={category.name}
                      position="bottom"
                      className={classes.titleBar}
                    />
                  </ImageListItem>
                ))}
            </ImageList>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default withWidth()(HomeCategories);

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentRepository from "../../../infra/firebase/ContentRepository";
import { whiteList } from "../../../Settings";
import ShowPdf from "../../ShowPdf/ShowPdf";

const contentRepository = new ContentRepository();


export interface IShowDocumentDialogProps {
    open: boolean;
    handleClose: any;
    title: string;
    documentName: string;
}

export const ShowDocumentDialog = ({ open, handleClose, title, documentName }: IShowDocumentDialogProps) => {

    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
            maxWidth={"lg"}
            disableEscapeKeyDown
            disableBackdropClick
        >
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <ShowDocument documentName={documentName} />

            </DialogContent>
            <DialogActions>

                <Button onClick={handleClose} color="primary">
                    {t("Ok")}
                </Button>

            </DialogActions>

        </Dialog>
    );

}

export interface IShowDocumentProps {
    documentName: string;
}

const ShowDocument = ({ documentName }: IShowDocumentProps) => {
    const { i18n } = useTranslation();
    const [content, setContent] = useState<any>();


    useEffect(() => {
        let lang = i18n.language.split("-")[0];
        let langFilter =
            whiteList.findIndex((w) => w === lang) !== -1 ? lang : "en";

        contentRepository.getContentByLanguage(
            "docs",
            langFilter
        )
            .then(contentResult => {
                console.debug(contentResult?.content[documentName]);
                setContent(contentResult?.content[documentName])
            })
            .catch(err => console.error(err))

    }, [i18n.language]);

    return (<div>
        {content && <ShowPdf url={content} />}
    </div>);

}

export default ShowDocument;
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Collapse,
  IconButton,
  DialogActions,
  Button,
  CircularProgress,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { DropzoneArea } from "material-ui-dropzone";
import { title } from "process";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import firebase from "firebase";
import ChatMessageRepository from "../../../infra/firebase/MessageRepository";
import { ChatMessage } from "../../../core/entities/Chat";

const chatMessageRepository = new ChatMessageRepository();
export default function ChatAttachmentUpload({
  open,
  handleClose,
  chatId,
  profileId,
}) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [isLoading, setIsloaging] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const onFileDrop = (inputFiles: File[]) => {
    setFiles([...files, ...inputFiles]);
  };

  const onFileDelete = (file: File) => {
    setFiles(files.filter((f) => f.name !== file.name));
  };

  const handleUpload = async () => {
    setIsUploading(true);
    try {
      for (const file of files) {
        const extension = file ? file.name.split(".").pop() : "";
        const fileName = `attachments/${chatId}/${generateUuidV4()}`;
        const storageRef = firebase
          .storage()
          .refFromURL(process.env.REACT_APP_ATTACHMENT_BUCKET || "");
        const fileRef = storageRef.child(fileName);
        fileRef.put(file).then(async () => {
          setIsloaging(true);
          const url = await fileRef.getDownloadURL();
          const message = new ChatMessage();
          message.chatId = chatId;
          message.body = file.name;
          message.attachments = [
            {
              url: url,
              name: file.name,
              size: file.size,
              type: extension || "",
            },
          ];
          message.senderId = profileId;
          message.type = "attachment";
          await chatMessageRepository.create(message);
          setIsloaging(false);
          setIsUploading(false);
          handleClose();
        });
      }
      setIsloaging(false);
    } catch (e: any) {
      console.debug(e);
      setErrorMessage(e.message);
      setIsloaging(false);
      setIsUploading(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="lg"
      disableBackdropClick>
      <DialogTitle id="form-dialog-title">{t("Upload file")}</DialogTitle>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "64px",
          }}>
          <CircularProgress size={64} />
        </div>
      ) : (
        <>
          <DialogContent>
            <Collapse in={errorMessage !== ""}>
              <Alert
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setErrorMessage("");
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {errorMessage}
              </Alert>
            </Collapse>

            <Dropzone
              onChange={onFileDrop}
              onDelete={onFileDelete}
              maxFiles={1}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="inherit">
              {t("Cancel")}
            </Button>
            {isUploading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "8px 16px",
                }}>
                <CircularProgress size={32} />
              </div>
            ) : (
              <Button
                onClick={handleUpload}
                color="inherit"
                disabled={files.length === 0}>
                {t("Upload")}
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

interface IDropzoneProps {
  onChange: any;
  maxFiles: number;
  onDelete: any;
}

function Dropzone({ onChange, onDelete }: IDropzoneProps) {
  const [fileNames, setFileNames] = useState([]);
  const { t } = useTranslation();

  const handleDrop = (acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name));

    onChange(acceptedFiles);
  };

  const handleDelete = (file) => {
    console.debug(file);
    onDelete(file);
  };

  return (
    <DropzoneArea
      acceptedFiles={[".pdf", ".doc", ".docx"]}
      dropzoneText={t("Drag and drop an file here or click")}
      onChange={handleDrop}
      onDelete={handleDelete}
      filesLimit={1}
      maxFileSize={10000000}
    />
  );
}

const generateUuidV4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

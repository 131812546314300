import { Container, Grid, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";

import { useChatStyles } from "./ChatTheme";

import { MainContainer, Sidebar } from "@chatscope/chat-ui-kit-react";
import ChatList from "./ChatList/ChatList";
import ChatConversation from "./ChatConversation/ChatConversation";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { useProfileContext } from "../../ProfileContext";
import { useStyles } from "../../Theme";
import ProfileController from "../Profile/ProfileController";
import SplashScreen from "../SplashScreen/SplashScreen";
import ChatListSkeleton from "./ChatList/ChatListSkeleton";

export interface IChatProps {
  profileId?: string;
}

const Chat = ({
  isAuthenticated = true,
  path: redirectPath = "",
  isInitializing = true,
}) => {
  const globalClasses = useStyles();
  const location = useLocation<{ profileId: string }>();
  let history = useHistory();
  const [loadingChat, setLoadingChat] = useState(false);
  const { loading, profile } = useProfileContext();
  const classes = useChatStyles();
  const profileController = new ProfileController();



  const createOrLoadChat = async (receiverProfileId) => {
    try {
      if (profile) {
        let senderProfileId = profile.id;

        if (senderProfileId !== undefined && receiverProfileId !== undefined) {
          setLoadingChat(true);

          const chat = await profileController.verifyIfAChatExistis(
            senderProfileId,
            receiverProfileId
          );

          if (chat === undefined) {
            profileController
              .createChat(senderProfileId, receiverProfileId)
              .then((newChatId) => {
                setLoadingChat(false);
                history.push(`/chats/${newChatId}`);
              })
              .catch((error) => {
                console.error(error);
                setLoadingChat(false);
              });
          } else {
            setLoadingChat(false);
            history.push(`/chats/${chat.id}`);
          }
        }
      }
    } catch (error) {
      console.error(error);
      setLoadingChat(false);
    }
  };

  useEffect(() => {
    if (location.state === undefined) return;

    let receiverProfileId = location.state.profileId;

    createOrLoadChat(receiverProfileId);
  }, [location]);


  if (!isAuthenticated && !isInitializing) {
    return <Redirect to={`/login?r=${redirectPath}`} />;
  }

  return (
    <div>
      <Container maxWidth="lg" fixed className={classes.root}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start">
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            className={globalClasses.sectionDesktop}></Grid>
          <Grid
            item
            xl={10}
            lg={10}
            md={10}
            sm={12}
            xs={12}
            className={classes.chatGridContainer}
            style={{
              position: "relative",
              width: "100%",
            }}>
            <MainContainer>
              <Sidebar position="left" scrollable={true}>
                {profile ? (
                  <ChatList
                    profileId={profile.id}
                    isInitializing={isInitializing || loading || loadingChat}
                  />
                ) : (
                  <ChatListSkeleton />
                )}
              </Sidebar>
              {profile && <ChatConversation profileId={profile.id} />}
            </MainContainer>
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            className={globalClasses.sectionDesktop}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Chat;

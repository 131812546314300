import { Badge, makeStyles, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import { Link as LinkRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useProfileContext } from "../../../ProfileContext";
import { ProfileChat } from "../../../core/entities/Chat";
import { db } from "../../../infra/firebase/firebasecofig";
import logoMatcheam from "../../../images/marca/footer-logo.png";

const useStyles = makeStyles((theme) => ({
  badge: {
    marginLeft: 10,
  },
  text: {
    color: "#868686",
  },
  buttonRoot: {
    textTransform: "initial",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "inherit",
    },
  },
}));

const TopBarChat = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { error, loading, profile } = useProfileContext();
  const [chats, setChats] = useState<ProfileChat[]>([]);
  const profileChatRef = db.collection("ProfileChats");

  const [unreads, setUnreads] = useState(0);

  useEffect(() => {
    if (profile?.id) {
      const unsubscribe = profileChatRef
        .doc(profile?.id)
        .collection("Chats")
        .orderBy("lastMessageDate", "desc")
        .onSnapshot(
          (snapshot) => {
            const result: ProfileChat[] = [];
            snapshot.forEach((doc) => {
              const {
                membersId,
                membersProfile,
                name,
                lastSenderName,
                lastMessageBody,
                lastMessageDate,
                chatId,
                profileId,
                unread,
              } = doc.data();

              let id = doc.id;

              result.push({
                id,
                membersId,
                membersProfile,
                name,
                lastSenderName,
                lastMessageBody,
                lastMessageDate,
                chatId,
                profileId,
                unread,
              });
            });

            setChats(result);
            let unreadChats = result?.filter((c) => c.unread > 0).length;
            setUnreads(unreadChats);
            result?.forEach((message) => {
              if (message.unread) {
                new Notification(
                  `${
                    message.lastMessageBody.split(":")[0]
                  } sent you a message!`,
                  {
                    body: message.lastMessageBody,
                    icon: logoMatcheam,
                    image: logoMatcheam,
                    badge: logoMatcheam,
                    requireInteraction: true,
                  }
                ).onclick = (event) => {
                  event.preventDefault();
                  window.location.href = `/chats/${message.id}`;
                };
              }
            });
          },
          (errorChats) => {
            setChats([]);
            console.error(errorChats);
          }
        );

      return () => {
        unsubscribe();
      };
    }
  }, [profile]);

  return (
    <div style={{ display: "flex" }}>
      <Badge
        badgeContent={unreads}
        color="primary"
        className={classes.badge}
        showZero={false}>
        <Button
          component={LinkRouter}
          to={"/chats"}
          startIcon={<ChatOutlinedIcon />}
          classes={{
            //label: classes.label,
            root: classes.buttonRoot,
          }}>
          {" "}
          {t("Chats")}{" "}
        </Button>
      </Badge>
    </div>
  );
};

export default TopBarChat;

export const CollectionNames = {
    Experience: 'Experiences',
    Profile: 'Profiles',
    Jobs: 'Jobs',    
};

export const Fields = {
    Profile: {
        NickName: 'nickName',
        Uid: 'uid'
    }
};
import {
  Dialog,
  DialogTitle,
  DialogContent,
  CircularProgress,
  Typography,
  Button,
} from "@material-ui/core";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

interface OnboardingValidationProps {
  isOpen: boolean;
  handleClose(): void;
  message: string;
  region?: string | undefined;
}

export default function OnboardingValidation({
  isOpen,
  handleClose,
  message,
  region,
}: OnboardingValidationProps) {
  const history = useHistory();
  const { t, i18n } = useTranslation();

  const handleRedirect = () => {
    history.push("/settings/payment");
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle id="form-dialog-title" className="titleProposal">
        {!message ? t("Validating") : t("Oops something happened")}
      </DialogTitle>
      <DialogContent>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            //margin: "0 2rem 2rem 2rem",
          }}>
          {!message ? (
            <CircularProgress />
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "280px",
              }}>
              {/* <Typography variant="h5" style={{ marginBottom: 16 }}>
                {message}
              </Typography> */}
              <Typography>
                {message ||
                  t(
                    "To start receiving your payments, you must first include your bank account information."
                  )}
              </Typography>
              {region && <Typography>{t("Region")}: {t(region)}</Typography>}
              <Button
                size="small"
                variant="contained"
                color="primary"
                style={{ marginTop: "1rem" }}
                onClick={handleRedirect}>
                {t("Add your bank information")}
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

import firebase from "firebase";
import { getRepository } from "fireorm";
import Deal from "../../core/entities/Deal";
import Profile from "../../core/entities/Profile";
import { IRepository } from "../../core/interfaces/IRepository";
import { db } from "./firebasecofig";

export default class DealsRepository implements IRepository<Deal> {
  repository = getRepository(Deal);

  profileDealtRef = db.collection("Deals");

  getDealsByRef = (profileId: string): Promise<Deal[]> => {
    this.profileDealtRef
      .doc(profileId)
      .collection("Deals")
      .onSnapshot(
        (snapshot) => {
          const result: Deal[] = [];
          snapshot.forEach((doc) => {
            const {
              acceptanceDate,
              amount,
              currency,
              date,
              details,
              fromProfileId,
              paymentIntentId,
              job,
              local,
              toProfileId,
              membersId,
              status,
              createdAt,
              modifiedAt,
              region,
            } = doc.data();

            let id = doc.id;

            result.push({
              id,
              acceptanceDate,
              amount,
              currency,
              date,
              details,
              fromProfileId,
              paymentIntentId,
              job,
              local,
              toProfileId,
              membersId,
              status,
              createdAt,
              modifiedAt,
              region,
            });
          });

          return Promise.resolve(result);
        },
        (error) => {
          return Promise.reject(error);
        }
      );

    return Promise.reject();
  };

  getDealsById = (id: string): Promise<Deal[]> => {
    this.profileDealtRef.where("id", "==", id).onSnapshot(
      (snapshot) => {
        const result: Deal[] = [];
        snapshot.forEach((doc) => {
          const {
            acceptanceDate,
            amount,
            currency,
            date,
            details,
            fromProfileId,
            paymentIntentId,
            job,
            local,
            toProfileId,
            membersId,
            status,
            createdAt,
            modifiedAt,
            region,
          } = doc.data();

          let id = doc.id;

          result.push({
            id,
            acceptanceDate,
            amount,
            currency,
            date,
            details,
            fromProfileId,
            paymentIntentId,
            job,
            local,
            toProfileId,
            membersId,
            status,
            createdAt,
            modifiedAt,
            region,
          });
        });

        return Promise.resolve(result);
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return Promise.reject();
  };

  create(entity: Deal): Promise<Deal> {
    entity.createdAt = firebase.firestore.FieldValue.serverTimestamp();

    return this.repository.create(entity);
  }
  update(entity: Deal): Promise<Deal> {
    entity.modifiedAt = firebase.firestore.FieldValue.serverTimestamp();
    return this.repository.update(entity);
  }
  delete(id: string): Promise<void> {
    throw new Error("Method not implemented.");
  }
  getById(id: string): Promise<Deal> {
    return this.repository.findById(id);
  }
}

import {
  makeStyles,
  Theme,
  createStyles,
  Container,
  Grid,
  Typography,
  Divider,
  ImageList,
  ImageListItem,
  Avatar,
  Box,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Profile from "../../../core/entities/Profile";
import ProfileRepository from "../../../infra/firebase/ProfileRepository";
import { Link as LinkRouter } from "react-router-dom";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
    },
    fundo: {
      backgroundColor: "#FFF",
      paddingTop: 50,
      paddingBottom: 50,
    },
    gridList: {
      width: "auto",
      height: "auto",
      transform: "translateZ(0)",
    },
    tile: {
      borderRadius: 10,
      backgroundColor: "#F8F8F8",
      textAlign: "center",
    },
    titleBar: {
      background: "transparent",
      textTransform: "capitalize",
    },
    icon: {
      color: "white",
    },
    divider: {
      marginBottom: 30,
      backgroundColor: theme.palette.primary.main,
      height: 2,
    },
    avatar: {
      display: "inline-block",
      marginTop: 20,
      width: "6rem",
      height: "6rem",
    },
    textContainer: {
      textAlign: "left",
      padding: "0 1rem",
      "& > *": {
        marginTop: "0.25rem",
      },
    },
    bio: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 1,
      "-webkit-box-orient": "vertical",
    },
    name: {
      fontWeight: "bold",
      color: theme.palette.primary.main
    },
    nickname: {
      color: theme.palette.secondary.main
    }
  })
);

const profileRepository = new ProfileRepository();

const HomeLastProfiles = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [profiles, setProfiles] = useState<Profile[]>([]);

  const getGridListCols = () => {
    if (isWidthUp("xl", props.width)) {
      return 5;
    }

    if (isWidthUp("lg", props.width)) {
      return 3;
    }

    if (isWidthUp("md", props.width)) {
      return 2;
    }

    return 1;
  };

  const getProfiles = useCallback(async () => {
    try {
      const newProfiles = await profileRepository.getProfilesForHome();
      if (newProfiles.length !== profiles.length) {
        setProfiles(newProfiles);
      }
    } catch (err) {
      console.error(err);
    }
  }, [setProfiles, profiles]);

  useEffect(() => {
    getProfiles();
  }, [getProfiles]);

  return (
    <Container className={classes.fundo} maxWidth="xl">
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          alignContent="center">

          {profiles.length > 0 && <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" paragraph color="primary">
              {t("Latest highlights")}
            </Typography>
            <Divider variant="middle" className={classes.divider} />
          </Grid>
          }

          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ width: "100%" }}>
            <ImageList
              rowHeight={240}
              gap={10}
              className={classes.gridList}
              cols={getGridListCols()}>
              {profiles.map((profile) => (
                <ImageListItem
                  key={profile.id}
                  cols={1}
                  rows={1}
                  classes={{

                    item: classes.tile,
                  }}>
                  <LinkRouter to={`/${profile.nickname}`}>
                    <Avatar
                      alt={profile?.name ?? ""}
                      src={profile?.profileImageUrl ?? ""}
                      className={classes.avatar}
                    />
                    <Box className={classes.textContainer}>
                      <Typography
                        variant="body1"
                        display="block"
                        className={classes.name}>
                        {profile.name}
                      </Typography>
                      <Typography variant="body1" display="block"
                        className={classes.nickname}>
                        {profile.nickname && `@${profile.nickname}`}
                      </Typography>
                      <Typography

                        variant="body1"
                        display="block"
                        className={classes.bio}>
                        {profile.bio}
                      </Typography>
                    </Box>
                  </LinkRouter>
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default withWidth()(HomeLastProfiles);

import { Grid } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { useStyles } from "../../Theme";
import styles from "./Jobs.module.scss";
import JobsResult from "./JobsResult/JobsResult";
import { useTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";

const Jobs = ({
  isAuthenticated = true,
  path: redirectPath = "",
  isInitializing = true,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  if (!isAuthenticated && !isInitializing) {
    return <Redirect to={`/login?r=${redirectPath}`} />;
  }

  return (
    <div className={clsx(classes.paddingtop, styles.Jobs)} data-testid="Jobs">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <JobsResult />
        </Grid>
      </Grid>
    </div>
  );
};

export default Jobs;

//<Paper className={classes.paperPrimary} elevation={0}></Paper>

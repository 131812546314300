import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Search,
  ConversationList,
  Conversation,
  Avatar,
  AvatarGroup,
} from "@chatscope/chat-ui-kit-react";
import { useChatStyles } from "../ChatTheme";
import { Chat, ChatProfile, ProfileChat } from "../../../core/entities/Chat";
import moment from "moment";
import { getAvatar, timestampToData } from "../../../utils/utils";
import { db } from "../../../infra/firebase/firebasecofig";
import Skeleton from "@material-ui/lab/Skeleton";

export interface IChatListProps {
  profileId: string;
  isInitializing: boolean;
}

export interface IAvatarConversationProps {
  members: ChatProfile[];
}

interface IChatConversationProps {
  chat: Chat;
  handleChangeChat: any;
  profileId: string;
  className: string;
  currentChat: string;
}

const find = (items: ProfileChat[] | null | undefined, searchValue: string) => {
  let text = searchValue.toLowerCase().split(" ");
  return items?.filter(function (item) {
    return text.every(function (el) {
      return item.name ? item.name.toLowerCase().indexOf(el) > -1 : false;
    });
  });
};

const getMembers = (profileId: string, chat: ProfileChat) => {
  return chat.membersProfile.filter((member) => member.id !== profileId);
};

const getFromNow = (lastMessageDate: Date | undefined) => {
  const sentTime =
    lastMessageDate !== null ? timestampToData(lastMessageDate) : null;
  const fromNow = sentTime !== null ? moment(sentTime).fromNow() : "";
};

const ChatList = ({ profileId, isInitializing }: IChatListProps) => {
  let history = useHistory();
  let { chatId } = useParams<{ chatId: string }>();

  const classes = useChatStyles();

  const [searchValue, setSearchValue] = useState("");
  //const { chats, error, loading } = useChatsContext();

  const profileChatRef = db.collection("ProfileChats");
  const [chats, setChats] = useState<ProfileChat[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>();

  const [filteredChats, setFilteredChats] = useState<
    ProfileChat[] | null | undefined
  >([]);

  const handleSearchChanged = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const handleChangeChat = (chatId: string) => {
    history.push(`/chats/${chatId}`);
  };

  useEffect(() => {
    const unsubscribe = profileChatRef
      .doc(profileId)
      .collection("Chats")
      .orderBy("lastMessageDate", "desc")
      .onSnapshot(
        (snapshot) => {
          const result: ProfileChat[] = [];
          snapshot.forEach((doc) => {
            const {
              membersId,
              membersProfile,
              name,
              lastSenderName,
              lastMessageBody,
              lastMessageDate,
              chatId,
              profileId,
              unread,
            } = doc.data();

            let id = doc.id;

            result.push({
              id,
              membersId,
              membersProfile,
              name,
              lastSenderName,
              lastMessageBody,
              lastMessageDate,
              chatId,
              profileId,
              unread,
            });
          });

          setChats(result);
          setLoading(false);
          setError(undefined);
        },
        (error) => {
          setChats([]);
          setLoading(false);
          setError(error);
          console.error(error);
        }
      );

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (searchValue === "") setFilteredChats(chats);
  }, [chats]);

  useEffect(() => {
    if (searchValue === "") {
      setFilteredChats(chats);
    } else {
      setFilteredChats(find(chats, searchValue));
    }
  }, [searchValue]);

  return (
    <div>
      <Search
        placeholder="Search..."
        value={searchValue}
        onChange={(v) => handleSearchChanged(v)}
        onClearClick={() => setSearchValue("")}
      />
      <ConversationList>
        {isInitializing &&
          Array(7)
            .fill(0)
            .map((_, i) => (
              <Skeleton
                variant="rect"
                key={i}
                style={{
                  margin: 10,
                  borderRadius: 10,
                  maxWidth: 300,
                  height: 64,
                  width: "100%",
                }}
              />
            ))}
        {filteredChats?.map((chat) => {
          const members = getMembers(profileId, chat);

          return (
            <Conversation
              key={chat.id}
              // name={chat.name}
              // lastSenderName={chat.lastSenderName}
              // info={chat.lastMessageBody}
              lastActivityTime={getFromNow(chat.lastMessageDate)}
              className={classes.conversation}
              active={chat.id === chatId}
              unreadCnt={chat.unread}
              onClick={() => {
                handleChangeChat(chat.id);
              }}>
              {members.length > 1 && (
                <AvatarGroup size="sm">
                  {members.map((member) => {
                    return (
                      <Avatar
                        src={
                          member.profileImageUrl !== null
                            ? member.profileImageUrl
                            : getAvatar(member.name)
                        }
                        name={member.name}
                      />
                    );
                  })}
                </AvatarGroup>
              )}
              {members.length == 1 && (
                <Avatar
                  src={
                    members[0].profileImageUrl !== null
                      ? members[0].profileImageUrl
                      : getAvatar(members[0].name)
                  }
                  name={members[0].name}
                />
              )}
              <Conversation.Content
                className={chat.unread ? classes.contentUnread : ""}
                lastSenderName={chat.lastSenderName}
                name={chat.name}
                info={chat.lastMessageBody}></Conversation.Content>
            </Conversation>
          );
        })}
      </ConversationList>
    </div>
  );
};

export default ChatList;

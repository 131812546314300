import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Collapse,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Invite from "../../../../core/entities/Invite";
import InviteRepository from "../../../../infra/firebase/InviteRepository";
import styles from "./SettingsNewInvite.module.scss";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  dialogPaper: {

    width: '400px'
  },
}));

const SettingsNewInvite = ({ open, handleClose, setSuccess, profileId }) => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("common");
  const [errorMessage, setErrorMessage] = useState("");
  const classes = useStyles();

  const inviteRepository = new InviteRepository();

  const handleNewInvite = () => {
    const invite = new Invite();
    invite.type = type;
    invite.profileId = profileId;

    inviteRepository
      .create(invite)
      .then((i) => {
        setSuccess(`Invite ${i.id} created.`);
        handleClose();
      })
      .catch((error) => setErrorMessage(error));
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogPaper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      disableEscapeKeyDown
      disableBackdropClick>
      <DialogTitle id="form-dialog-title">{t("New Invite")}</DialogTitle>
      <DialogContent>
        <Collapse in={errorMessage !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {errorMessage}
          </Alert>
        </Collapse>
        <FormControl fullWidth>
          <InputLabel htmlFor="parentCategoryId">{t("Type")}</InputLabel>
          <Select
            id="type"
            fullWidth
            autoFocus
            onChange={(e) => setType(e.target.value as string)}
            value={type}>
            <MenuItem key="common" value="common">
              {t("Common invite")}
            </MenuItem>
            <MenuItem key="agency" value="agency">
              {t("Team invite")}
            </MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="inherit" disabled={loading}>
          {t("Cancel")}
        </Button>
        {loading ? (
          <div
            style={{
              display: "flex",
              background: "#c9c9c9",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              padding: "12px",
              margin: "4px 0",
            }}>
            <CircularProgress size={14} />
          </div>
        ) : (
          <Button
            type="submit"
            color="primary"
            onClick={handleNewInvite}
            disabled={loading}>
            {t("Create")}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default SettingsNewInvite;

import { Container, createStyles, Grid, Link, makeStyles, Theme, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            minHeight: "100vh",
            position: "relative",
            marginTop: 40
        },
        link: {
            color: theme.palette.secondary.main
        }
    })
);

const NotFoundComponent = () => {
    const { t, i18n } = useTranslation();
    const classes = useStyles();



    return (
        <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
            className={classes.root}>
            <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                alignItems="center"
                justifyContent="center"
            >
                <Container
                    maxWidth="xl"
                    disableGutters>

                    <Typography
                        variant="h4"
                        display="block"
                        paragraph
                        style={{ fontWeight: "bold", color: "#919191", textAlign: "center" }}
                    >
                        {t("This content is not available.")}
                    </Typography>
                    <Typography
                        variant="body1"
                        display="inline"
                        paragraph
                        
                    >
                        {t("The content on this page is not available or the link you tried to access is not working.")}&nbsp;
                    </Typography>
                    <Link component={RouterLink} to="/" className={classes.link} style={{ color: "#454545" }} >{t("Back to Matcheam")}</Link>

                </Container>
            </Grid>
        </Grid>
    );
}

export default NotFoundComponent;

import {
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  Avatar,
  ConversationHeader,
  AvatarGroup,
} from "@chatscope/chat-ui-kit-react";
import React, { useEffect, useState } from "react";
import { ChatMessage, ChatProfile } from "../../../core/entities/Chat";
import Deal from "../../../core/entities/Deal";
import DealsRepository from "../../../infra/firebase/DealsRepository";
import { getAvatar } from "../../../utils/utils";
import ChatProposalMessage from "../ChatProposalMessage/ChatProposalMessage";
import { FaFile } from "react-icons/fa";
import { Button } from "@material-ui/core";
import ChatAttachmentMessage from "../ChatAttachmentMessage/ChatAttachmentMessage";

const dealRepository = new DealsRepository();


interface ChatMessageResolverProps {
  message: ChatMessage;
  direction: "incoming" | "outgoing";
  member?: ChatProfile | undefined;
  fromNow: String;
  sentTime: Date;
  profileId: String;
  handleViewDetails(deal: Deal, isSender: Boolean): void;
}

export default function ChatMessageResolver({
  message,
  direction,
  member,
  fromNow,
  sentTime,
  profileId,
  handleViewDetails,
}: ChatMessageResolverProps) {
  const [messageDeal, setMessageDeal] = useState<Deal>();

  const getDeal = async (dealId: string): Promise<void> => {
    const deal = await dealRepository.getById(dealId);
    console.debug(deal, "DEAL FOUND", dealId);
    setMessageDeal(deal);
  };

  useEffect(() => {
    if (message.dealReference) {
      console.debug(message.dealReference);
      const dealId: string =
        typeof message.dealReference === "string"
          ? message.dealReference
          : message.dealReference.id;
      getDeal(dealId);
    }
  }, [message.dealReference]);

  if (message.attachments && message.attachments.length > 0) {
    return (
      <ChatAttachmentMessage
        direction={direction}
        message={message}
        member={member}
        fromNow={fromNow}
      />
    );
  }

  if (message.dealReference) {
    return (
      <ChatProposalMessage
        direction={direction}
        message={message}
        avatar={member?.profileImageUrl || getAvatar(member?.name)}
        sender={member?.name || ""}
        sentTime={sentTime.toLocaleDateString()}
        isSender={message.senderId === profileId}
        deal={messageDeal}
        handleViewDetails={handleViewDetails}
        type={message.type}
      />
    );
  }

  return (
    <Message
      model={{
        message: message.body,
        sentTime: sentTime.toLocaleDateString(),
        sender: member?.name,
        direction: direction,
        position: "single",
      }}>
      <Message.Footer sender={member?.name} sentTime={fromNow} />
      <Avatar
        name={member?.name}
        src={member?.profileImageUrl || getAvatar(member?.name)}
      />
    </Message>
  );
}

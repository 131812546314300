import { Container, Grid, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useStyles } from "../../../Theme";

const useHomeBetaStyles = makeStyles((theme) => ({
  background: {
    paddingBottom: 40,
    paddingTop: 50,
  },
  beta: {
    color: theme.palette.secondary.main,
    marginBottom: 30,
  },
  betaMainText: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  betaText: {
    color: theme.palette.primary.main,
  },
  betaVideo: {
    // paddingBottom: 40,
    // paddingTop: 30,
  },
  betaContent: {
    paddingRight: 20,
  },
}));

export interface IHomeBetaProps {
  content: any;
}

const HomeBeta = ({ content }: IHomeBetaProps) => {
  const classes = useHomeBetaStyles();
  const globalClasses = useStyles();

  const [videoUrl, setVideoUrl] = useState("");
  const videoRef = useRef(null);
  const [playing, setPlaying] = useState(true);

  useEffect(() => {
    setVideoUrl(content?.videoUrl);
  }, [content]);

  return (
    <Container component="main" className={classes.background} maxWidth="xl">
      <Container maxWidth="lg">
        <Grid container>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            className={globalClasses.sectionDesktop}
          />
          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={12}
            xs={12}
            className={classes.betaContent}>
            <Typography variant="h4" className={classes.beta} paragraph>
              {content?.title}
            </Typography>
            <Typography variant="h6" className={classes.betaMainText} paragraph>
              {content?.mainText}

            </Typography>

            <Typography variant="body1" className={classes.betaText} paragraph>
              {content?.secondaryText}

            </Typography>


          </Grid>

          <Grid
            item
            xl={5}
            lg={5}
            md={5}
            sm={12}
            xs={12}
            className={classes.betaVideo}>
            <ReactPlayer
            
              url={videoUrl}
              width="100%"
              controls={true}
              ref={videoRef}
              muted={true}
              playing
              // config={{
              //   file: {
              //     attributes: {preload: "auto"},
              //     forceAudio:true,
              //   },
              // }}
            />
          </Grid>
          <Grid
            item
            xl={1}
            lg={1}
            md={1}
            sm={1}
            xs={1}
            className={globalClasses.sectionDesktop}
          />
        </Grid>
      </Container>
    </Container>
  );
};

export default HomeBeta;

import {
  HierarchicalCategory,
  ProfileCategory,
  TranslatedHierarchicalCategory,
} from "../../core/entities/Profile";
import { IRepository } from "../../core/interfaces/IRepository";
import { Collection, getRepository } from "fireorm";
import Profile from "../../core/entities/Profile";
import { db } from "./firebasecofig";
import { CollectionNames } from "../../utils/constants";
import firebase from "firebase";
import {
  TranslatedNames,
  TranslatedNamesArray,
} from "../../core/entities/Category";
import Location from "../../core/entities/Location";

export default class ProfileRepository implements IRepository<Profile> {
  repository = getRepository(Profile);
  profileRef = db.collection(CollectionNames.Profile);
  profileChatsRef = db.collection("ProfileChats");


  getHiddenProfiles() {
    return this.repository.whereEqualTo("_tags", "hidden").find();
  }

  updateProfile(id: string, updates: any) {
    updates.modified = firebase.firestore.FieldValue.serverTimestamp();

    return this.profileRef.doc(id).update(updates);
  }

  getAll = () => {
    return this.repository.find();
  };

  setHideOnSearch = (profileId: string, value: boolean) => {
    return this.profileRef.doc(profileId).update({
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      _tags: value ? "hidden" : "",
    });
  };

  isCurrentUser = (profileUid: string): boolean => {
    return true;
  };

  addInviter = (profileId: string, inviterId: string): Promise<void> => {
    return this.profileRef.doc(profileId).update({
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      inviter: {
        id: inviterId,
      },
    });
  };

  getAgents = (profileId: string): Promise<Profile[]> => {
    return this.profileRef
      .doc(profileId)
      .collection("Agents")
      .get()
      .then((querySnapshot) => {
        const result: Promise<Profile>[] = [];

        querySnapshot.forEach(async (doc) => {
          const { id } = doc.data();

          result.push(this.getById(id));
        });

        return Promise.all(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  addAgent = (profileId: string, inviterId: string): Promise<void> => {
    return this.profileRef
      .doc(profileId)
      .collection("Agents")
      .doc(inviterId)
      .set({
        id: inviterId,
        date: firebase.firestore.FieldValue.serverTimestamp(),
      });
  };

  setOccupations = async (
    profileId: string,
    occupations: string[]
  ): Promise<void> => {
    await this.profileRef.doc(profileId).update({ occupations: occupations });
  };

  getProfilesByIds = (profiles: string[]): Promise<Profile[]> => {
    return this.repository.whereIn((p) => p.id, profiles).find();
  };

  getProfilesForHome = (): Promise<Profile[]> => {
    return this.repository.whereEqualTo((p) => p.showAtHome, true).find();
  };

  getUnreadChats = (profileId: string, observer: any, onError) => {
    return this.profileChatsRef.doc(profileId).onSnapshot(
      (querySnapshot) => {
        observer(querySnapshot.data());
      },
      (error) => {
        console.error(error);
        onError(error);
      }
    );
  };
  setBio = (profileId: string, bio?: string): Promise<void> => {
    return this.profileRef.doc(profileId).update({
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      bio: bio,
    });
  };

  getProfilesWithLocation = (): Promise<Profile[]> => {
    return this.repository.whereNotEqualTo("location", "").find();
  };

  setLocation = (profileId: string, location?: Location): Promise<void> => {
    return this.profileRef.doc(profileId).update({
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      location: Object.assign({}, location),
    });
  };

  updateProfileEmail = (profileId: string, email: string): Promise<void> => {
    return this.profileRef.doc(profileId).update({
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      emailAddress: email,
    });
  };

  setProfileCover = (
    profileId: string,
    profileCoverImageUrl: string
  ): Promise<void> => {
    return this.profileRef.doc(profileId).update({
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      profileCoverImageUrl: profileCoverImageUrl,
    });
  };

  setProfilePhoto = (
    profileId: string,
    profileImageUrl: string
  ): Promise<void> => {
    return this.profileRef.doc(profileId).update({
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      profileImageUrl: profileImageUrl,
    });
  };

  getProfileByNickName = (nickname: string): Promise<Profile | null> => {
    return this.repository.whereEqualTo("nickname", nickname).findOne();
  };

  getProfileByAccountId(uid: string): Promise<Profile | null> {
    return this.repository.whereEqualTo("uid", uid).findOne();
  }

  create(entity: Profile): Promise<Profile> {
    entity.created = firebase.firestore.FieldValue.serverTimestamp();
    entity.modified = firebase.firestore.FieldValue.serverTimestamp();

    return this.repository.create(entity);
  }

  updateCategories = (
    profileId: string,
    categories: ProfileCategory[]
  ): Promise<void> => {
    const categoriesArray = categories.map((obj) => {
      obj.subCategories = obj.subCategories?.map((obj) => {
        return Object.assign({}, obj);
      });
      return Object.assign({}, obj);
    });

    const translatedHierarchicalCategory =
      this.getTranslatedCategories(categories);
    const translatedHierarchicalCategoryObj = Object.assign(
      {},
      translatedHierarchicalCategory
    );

    return this.profileRef.doc(profileId).update({
      modified: firebase.firestore.FieldValue.serverTimestamp(),
      profileCategories: categoriesArray,
      translatedHierarchicalCategories: translatedHierarchicalCategoryObj,
    });
  };

  getTranslatedCategories = (
    profileCategories: ProfileCategory[]
  ): TranslatedHierarchicalCategory => {
    const languages = ["pt", "en", "es", "de"];
    let translatedHierarchicalCategories: TranslatedHierarchicalCategory;
    let lvl0: TranslatedNamesArray = {
      de: [],
      en: [],
      es: [],
      pt: [],
    };
    let lvl1: TranslatedNamesArray = {
      de: [],
      en: [],
      es: [],
      pt: [],
    };

    profileCategories.forEach((profileCategory) => {
      languages.forEach((language) => {
        if (profileCategory.translatedNames)
          lvl0[language].push(
            profileCategory.translatedNames[language]
              ? profileCategory.translatedNames[language]
              : profileCategory.name
          );
      });

      profileCategory.subCategories?.forEach((subCategory) => {
        languages.forEach((language) => {
          if (subCategory.translatedNames && profileCategory.translatedNames)
            lvl1[language].push(
              `${profileCategory.translatedNames[language]
                ? profileCategory.translatedNames[language]
                : profileCategory.name
              } > ${subCategory.translatedNames[language]
                ? subCategory.translatedNames[language]
                : subCategory.name
              }`
            );
        });
      });
    });

    translatedHierarchicalCategories = {
      lvl0: lvl0,
      lvl1: lvl1,
    };

    return translatedHierarchicalCategories;
  };

  getHierarchicalCategories = (
    categories: ProfileCategory[]
  ): HierarchicalCategory => {
    let hierarchicalCategories = new HierarchicalCategory();
    let lvl0: string[] = [];
    let lvl1: string[] = [];

    categories.forEach((category) => {
      let name = category.name;
      lvl0.push(name);

      if (category.subCategories)
        category.subCategories.forEach((subcategory) => {
          lvl1.push(`${name} > ${subcategory.name}`);
        });
    });

    hierarchicalCategories.lvl0 = lvl0;
    hierarchicalCategories.lvl1 = lvl1;

    return hierarchicalCategories;
  };

  update(entity: Profile): Promise<Profile> {
    const categoriesArray = entity.profileCategories;

    if (categoriesArray) {
      const categories = categoriesArray.map((obj) => {
        return Object.assign({}, obj);
      });
      entity.profileCategories = categories;

      const hierarchicalCategories =
        this.getHierarchicalCategories(categoriesArray);
      const hierarchicalCategoriesObj = Object.assign(
        {},
        hierarchicalCategories
      );
      entity.hierarchicalCategories = hierarchicalCategoriesObj;
    }

    entity.modified = firebase.firestore.FieldValue.serverTimestamp();

    return this.repository.update(entity);
  }

  delete(id: string): Promise<void> {
    return this.repository.delete(id);
  }

  getById(id: string): Promise<Profile> {
    return this.repository.findById(id);
  }
}

import firebase from "firebase";
import Post, { Media } from "../../../core/entities/Post";
import PostRepository from "../../../infra/firebase/PostRepository";
import getCroppedImg from "../../../utils/cropImage";
import { IPostMedia } from "./IPostMedia";

export default class PostController {
  postRepository = new PostRepository();

  generateUuidV4 = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  getFileRef = (
    profileId: string,
    postId: string,
    filename: string,
    prefix: string
  ) => {
    const storageRef = firebase.storage().ref();

    const name = `user_images/${profileId}/${postId}/${prefix}-${filename}`;

    const fileRef = storageRef.child(name);

    return fileRef;
  };

  createPost = async (post: Post, postMedias: IPostMedia[]): Promise<Post> => {
    post.id = post.id ? post.id : this.generateUuidV4();
    const medias: Media[] = [];
    post.medias = await Promise.all(
      postMedias.map(async (postMedia, index) => {
        const media = await this.saveMedia(post.profileId, post.id, postMedia);
        return media;
      })
    );

    const thumb = post.medias.find((p) => p.thumbUrl !== "")?.thumbUrl;
    if (thumb) post.thumbnailUrl = thumb;
    return this.postRepository.create(post);
  };

  saveMedia = async (
    profileId: string,
    postId: string,
    postMedia: IPostMedia
  ) => {
    const thumbnailFileName = postMedia.fileName?.split(".")[0].concat(".jpeg");

    const originalref = this.getFileRef(
      profileId,
      postId,
      postMedia.fileName,
      "original"
    );
    const thumbnailref = this.getFileRef(
      profileId,
      postId,
      thumbnailFileName,
      "thumbnail"
    );
    const media = new Media();
    media.type = postMedia.type;
    media.crop = postMedia.crop;
    media.croppedAreaPixels = postMedia.croppedAreaPixels;
    // media.croppedAreaPixels = {
    //   width: postMedia.croppedAreaPixels.width ?? 500,
    //   height: postMedia.croppedAreaPixels.height ?? 500,
    //   x: postMedia.croppedAreaPixels.x ?? 0,
    //   y: postMedia.croppedAreaPixels.y ?? 500,
    // };
    media.zoom = postMedia.zoom;

    if (postMedia.type === 'image') {
      media.originalUrl = await this.saveFile(originalref, postMedia.src);
    }
    switch (postMedia.type) {
      case "image":
        media.url = await this.saveCroppedImage(thumbnailref, postMedia);
        media.thumbUrl = media.url;
        break;
      case "video":
        media.url = postMedia.src;
        media.originalUrl = postMedia.src;
        media.thumbUrl = "";
        media.thumbUrl =
          postMedia.thumbUrl !== "" && postMedia.thumbUrl !== undefined
            // ? await this.saveFile(thumbnailref, postMedia.thumbUrl)
            ? postMedia.thumbUrl
            : "";
        break;
      default:
        media.url = media.originalUrl;
        break;
    }
    return media;
  };

  saveBlob = (
    fileRef: firebase.storage.Reference,
    blob: Blob
  ): Promise<string> => {
    return fileRef
      .put(blob)
      .then((snapshot) => {
        return fileRef
          .getDownloadURL()
          .then((url) => {
            return Promise.resolve(url);
          })
          .catch((error) => {
            return Promise.reject(error);
          });
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  saveCroppedImage = (
    fileRef: firebase.storage.Reference,
    postmedia: IPostMedia
  ): Promise<string> => {
    return getCroppedImg(postmedia.src, postmedia.croppedAreaPixels, 0, true)
      .then((croppedImage) => {
        return this.saveBlob(fileRef, croppedImage);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  saveFile = (
    fileRef: firebase.storage.Reference,
    url: string
  ): Promise<string> => {
    return fetch(url)
      .then((r) =>
        r
          .blob()
          .then((blob) => {
            return this.saveBlob(fileRef, blob);
          })
          .catch((error) => {
            return Promise.reject(error);
          })
      )
      .catch((error) => {
        return Promise.reject(error);
      });
  };
}

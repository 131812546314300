import { Collection } from "fireorm";
import { TranslatedNames, TranslatedNamesArray } from "./Category";
import Location from "./Location";
import Moderation from "./Moderation";

export class Agent {
  public id: string;
}

@Collection("Profiles")
export default class Profile {
  public id: string;
  public uid?: string | null;
  public emailAddress?: string | null;

  public name?: string | null;
  public firstName?: string | null;
  public lastName?: string | null;

  public bio?: string;
  public headline?: string | null;
  public nickname?: string;
  public profileImageUrl?: string | null;
  public profileCoverImageUrl?: string | null;
  public website?: string | null;
  public locationId?: string | null;
  public location?: Location;
  public created?: any;
  public modified?: any;
  public profileCategories?: ProfileCategory[] | null;
  public profileChatId?: string;
  public stripeCustomerId?: string;
  public hierarchicalCategories?: HierarchicalCategory;
  public showAtHome?: boolean;
  public payement?: {
    isCompleted?: boolean;
    token?: string;
  };
  public inviter?: Profile;
  public defaultGateway?: {
    accountId?: string;
    customerId?: string;
    flag?: string;
    id: string;
    provider: string;
    region: string;
  };
  public attributes?: { [key: string]: any };
  public agents?: Agent[];
  public occupations?: string[];
  public languageCode: string;
  public countryCode: string;
  public birthdate?: any;

  public translatedHierarchicalCategories?: TranslatedHierarchicalCategory;

  public hideOnSearch?: boolean;

  public _tags?: string;

  //Moderation
  public moderatedProfileImageUrl?: string;
  public moderatedProfileImage?: boolean;
  public moderatedProfileCoverImageUrl?: string;
  public moderatedProfileCoverImage?: boolean;

  public moderations?: Moderation[];

  public isAvailable?: boolean;
}

export class HierarchicalCategory {
  lvl0?: string[];
  lvl1?: string[];
  lvl2?: string[];
}

export class TranslatedHierarchicalCategory {
  lvl0?: TranslatedNamesArray;
  lvl1?: TranslatedNamesArray;
}

export class ProfileCategory {
  name: string;
  categoryId?: string;
  skills?: string[];
  subCategories?: ProfileCategory[];

  translatedNames?: TranslatedNames;

  parentCategoryId?: string;
}

export class Email {
  public type: string;
  public address: string;
}

export class Phone {
  public type: string;
  public number: string;
}

export class Social {
  public type: string;
  public profile: string;
  public followers: number;
}

export class Address {
  public description: string;
  public areaCode: string;
  public postCode: string;
  public country: string;
  public city: string;
  public state: string;
  public street: string;
  public neighborhood: string;
  public streetNumber: string;
  public complement: string;
  public lat: string;
  public lng: string;
}

import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import HomeBeta from "./HomeBeta/HomeBeta";
import HomeCategories from "./HomeCategories/HomeCategories";
import HomeLastProfiles from "./HomeLastProfiles/HomeLastProfiles";
import HomeOurComunity from "./HomeOurComunity/HomeOurComunity";
import ContentRepository from "../../infra/firebase/ContentRepository";
import { useTranslation } from "react-i18next";
import HomeTestimony from "./HomeTestimony/HomeTestimony";
import { whiteList } from "../../Settings";
import ProfileOnboarding from "./ProfileOnboarding/ProfileOnboarding";
import { useProfileContext } from "../../ProfileContext";
import ImageEditor from "../shared/ImageEditor";
import SelectProfileOccupations from "./SelectProfileOccupations/SelectProfileOccupations";
import ProfileCategoriesEdit from "../Profile/ProfileCategories/ProfileCategoriesEdit/ProfileCategoriesEdit";
import ProfileWelcome from "./ProfileWelcome/ProfileWelcome";
import { db } from "../../infra/firebase/firebasecofig";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    home: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  })
);

const contentRepository = new ContentRepository();

const Home = () => {
  const classes = useStyles();
  const { location } = useHistory();
  const { i18n, t } = useTranslation();
  const [content, setContent] = useState<any>();
  const { profile } = useProfileContext();
  const [isProfileOnboardingOpen, setIsProfileOnboardingOpen] = useState(false);
  const [openEditImage, setOpenEditImage] = useState(false);
  const [isSelectProfileOccupationsOpen, setIsSelectProfileOccupationsOpen] =
    useState(false);
  const [isSelectCategoriesOpen, setIsSelectCategoriesOpen] = useState(false);
  const [isProfileWelcomeOpen, setIsProfileWelcomeOpen] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);

  // useEffect(() => {
  //   if (profile && !hasStarted && location.pathname === "/") {
  //     setIsProfileOnboardingOpen(
  //       !profile?.occupations || profile?.occupations.length === 0
  //     );
  //     setHasStarted(true);
  //   }

  // }, [profile, hasStarted, location]);


  const handleClickOpenImage = () => {
    setOpenEditImage(true);
  };
  const handleCloseImage = () => {
    setOpenEditImage(false);
    setIsSelectProfileOccupationsOpen(true);
  };

  const handleOpenCategories = () => {
    setIsSelectCategoriesOpen(true);
  };

  const handleCloseCategories = () => {
    setIsSelectCategoriesOpen(false);
    setIsProfileWelcomeOpen(true);
  };

  const getContentFromRepository = async (langFilter: string) => {
    try {
      const content = await contentRepository.getContentByLanguage(
        "Home",
        langFilter
      );
      if (content) {
        setContent(content.content);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const saveLanguageCode = async (langCode: string, profileId: string) => {
    await db.collection("Profiles").doc(profileId).update({
      languageCode: langCode,
    });
  };

  const saveCountryCode = async (countryCode: string, profileId: string) => {
    await db.collection("Profiles").doc(profileId).update({
      countryCode: countryCode,
    });
  };

  useEffect(() => {
    if (profile) {
      let [lang, country] = i18n.language.split("-");
      if (!profile.languageCode && lang) saveLanguageCode(lang, profile.id);
      if (!profile.countryCode && country) saveCountryCode(country, profile.id);
    }
  }, [i18n.language, profile]);

  useEffect(() => {
    let lang = i18n.language.split("-")[0];
    let langFilter =
      whiteList.findIndex((w) => w === lang) !== -1 ? lang : "en";

    getContentFromRepository(langFilter);
  }, [i18n.language]);

  return (
    <div data-testid="Home" className={classes.home}>
      <HomeBeta content={content} />
      <HomeCategories />
      <HomeLastProfiles />
      <HomeOurComunity postsIds={content?.posts} />
      {/* <HomeTestimony content={content} /> */}
      {isProfileOnboardingOpen && (
        <ProfileOnboarding
          handleClose={() => setIsProfileOnboardingOpen(false)}
          open={isProfileOnboardingOpen}
          action={handleClickOpenImage}
        />
      )}
      {openEditImage && (
        <ImageEditor
          title="Upload Photo"
          handleClose={handleCloseImage}
          open={openEditImage}
          image="photo"
          profileId={profile ? profile.id : ""}
          cropShape="round"
          aspect={1}
        />
      )}
      {isSelectProfileOccupationsOpen && (
        <SelectProfileOccupations
          open={true || isSelectProfileOccupationsOpen}
          handleClose={() => setIsSelectProfileOccupationsOpen(false)}
          action={handleOpenCategories}
        />
      )}
      {isSelectCategoriesOpen && profile && (
        <ProfileCategoriesEdit
          profileId={profile.id}
          open={isSelectCategoriesOpen}
          handleClose={handleCloseCategories}
          message={t(
            "Select the categories and skills that best describe you."
          )}
        />
      )}
      {isProfileWelcomeOpen && (
        <ProfileWelcome
          open={isProfileWelcomeOpen}
          handleClose={() => setIsProfileWelcomeOpen(false)}
        />
      )}
    </div>
  );
};

export default Home;

import React, { useState } from "react";

import { ErrorMessage } from "@hookform/error-message";
import {
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  CircularProgress,
} from "@material-ui/core";

import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import firebase from "firebase";

import PermIdentityOutlinedIcon from "@material-ui/icons/PermIdentityOutlined";
import SocialMediaLogon from "../SocialMediaLogon/SocialMediaLogon";
import AnalyticsHelper from "../../../infra/analytics/analyticsHelper";
const useStyles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
    paddingLeft: 20,
    paddingRight: 20,
  },
  errorMessage: {
    color: "red",
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  labelButton: {
    textTransform: "initial",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    margin: theme.spacing(2, 2, 2),
  },
  inputAdornment: {
    color: theme.palette.primary.main,
  },
}));

const analyticsHelper = new AnalyticsHelper();

const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const {
    reset,
    getValues,
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { t } = useTranslation();

  const afterError = (errorMessage: string) => {
    setError("form", {
      type: "manual",
      message: t(errorMessage),
    });

    reset(getValues(), { keepValues: true, keepErrors: true });
    setLoading(false);
  };

  const passwordReset = async (email: string): Promise<void> => {
    analyticsHelper.logEvent('password_reset');
    return firebase.auth().sendPasswordResetEmail(email);
  };

  const onSubmit = (data: any) => {
    try {
      setLoading(true);
      passwordReset(data.email.trim())
        .then(() => {
          setLoading(false);
          history.push("/login?afterforgotpassword");
        })
        .catch((error) => {
          afterError(error.message);
        });
    } catch (error: any) {
      afterError(error.message);
    }
  };

  return (
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <TextField
          variant="standard"
          margin="normal"
          required
          fullWidth
          id="email"
          label={t("E-mail")}
          autoComplete="email"
          autoFocus
          {...register("email", { required: true })}
          InputProps={{
            startAdornment: (
              <InputAdornment
                position="start"
                className={classes.inputAdornment}>
                <PermIdentityOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
        <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
            <span className={classes.errorMessage}>{message}</span>
          )}
        />

        {loading ? (
          <div
            style={{
              display: "flex",
              background: "#c9c9c9",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              padding: "12px",
              margin: "4px 0",
            }}>
            <CircularProgress size={14} />
          </div>
        ) : (
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            classes={{
              root: classes.submit,
              label: classes.labelButton,
            }}
            disableElevation
            disabled={loading}
            onClick={() => clearErrors()}>
            {t("Recover password")}
          </Button>
        )}

        <Grid container justifyContent="center">
          <ErrorMessage
            errors={errors}
            name="form"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
        </Grid>

        <SocialMediaLogon clearErrors={clearErrors} setError={afterError} />

        <RouterLink to="register" style={{ textDecoration: "none" }}>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="secondary"
            classes={{
              root: classes.submit,
              label: classes.labelButton,
            }}
            disableElevation>
            {t("Create a new account")}
          </Button>
        </RouterLink>
      </form>
  );
};

export default ForgotPassword;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './hotsite.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";



ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n} >
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();
//serviceWorkerRegistration.unregister();

serviceWorkerRegistration.register({
  onUpdate: registration => {
    registration.unregister().then(() => {
      window.location.reload(); // it will automatically reload when there is a new version, or just disable this line to get new version next time they access
    });
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


import { Collection, getRepository } from 'fireorm';
import Content from '../../core/entities/Content';



export default class ContentRepository{

    repository = getRepository(Content);

    getContentByLanguage = (identifier: string, language: string): Promise<Content | null> => {
    
        return this.repository
            .whereEqualTo('identifier', identifier)
            .whereEqualTo('language', language)
            .findOne();
    
    }

}

import { useEffect, useState } from "react";
import clsx from "clsx";
import styles from "./Categories.module.scss";
import { DataGrid, GridColDef, GridRowData } from "@material-ui/data-grid";
import { Category } from "../../core/entities/Category";
import CategoryRepository from "../../infra/firebase/CategoryRepository";
import {
  makeStyles,
  Container,
  Link,
  Collapse,
  IconButton,
  Button,
} from "@material-ui/core";
import CategoriesEdit from "./CategoriesEdit/CategoriesEdit";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import DialogConfirmation from "../shared/DialogConfirmation";
import DeleteIcon from "@material-ui/icons/Delete";
import { Redirect } from "react-router-dom";
import { useProfileContext } from "../../ProfileContext";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: "initial",
    color: "white",
  },
  delete: {
    backgroundColor: "#9A0036",
  },
}));

const Categories = ({
  isAuthenticated = true,
  path: redirectPath = "",
  isInitializing = true,
}) => {
  const classes = useStyles();
  const [categories, setCategories] = useState<Category[]>([]);
  const [parentCategories, setParentCategories] = useState<
    Category[] | undefined
  >([]);

  const [currentCategoryId, setCurrentCategoryId] = useState("");
  const repository = new CategoryRepository();

  const [alert, setAlert] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [showDelete, setShowDelete] = useState(false);

  const [currentCategory, setCurrentCategory] = useState<Category>();

  const { loading: globalLoading, profile: globalProfile, isAdmin } = useProfileContext();

  const editarCategoria = (category: Category | undefined) => {
    setCurrentCategory(category);
    handleClickOpen();
  };

  const editar = (row: GridRowData) => {
    let category = new Category();
    category.id = row.id;
    category.description = row.description;
    category.language = row.language;
    category.name = row.name;
    category.parentCategoryId = row.parentCategoryId;
    category.order = row.order;
    category.showAtHome = row.showAtHome;

    category.translatedNames = {
      en: row.translatedNames?.en,
      pt: row.translatedNames?.pt,
      de: row.translatedNames?.de,
      es: row.translatedNames?.es,
    };

    editarCategoria(category);
  };

  const editarById = (id: string) => {
    let category = categories.find((c) => c.id === id);
    editarCategoria(category);
  };

  const getParentCategory = (id) => {
    return parentCategories?.find((p) => p.id === id);
  };

  const getParentCategoryName = (id) => {
    let find = getParentCategory(id);
    return find ? find : "";
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Ações",
      width: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Button
            size="small"
            variant="contained"
            color="default"
            className={clsx(classes.button, classes.delete)}
            startIcon={<DeleteIcon />}
            onClick={() => {
              handleDeleteCategory(params.row.id);
            }}>
            Excluir
          </Button>
        );
      },
    },
    {
      field: "parentCategory",
      headerName: "Categoria Mãe",
      width: 200,
      valueGetter: (params) => {
        return getParentCategoryName(params.row.parentCategoryId);
      },
      sortComparator: (v1, v2) => v1!.toString().localeCompare(v2!.toString()),
      renderCell: (params) => {
        return (
          <Link
            href="#"
            onClick={() => {
              editarById(params.row.parentCategoryId);
              setOpen(true);
            }}>
            {params.row.parentCategory ? params.row.parentCategory.name : ""}
          </Link>
        );
      },
    },
    {
      field: "name",
      headerName: "Nome",
      width: 200,
      renderCell: (params) => {
        return (
          <Link
            href="#"
            onClick={() => {
              editar(params.row);
              setOpen(true);
            }}>
            {params.row.name}
          </Link>
        );
      },
    },
    { field: "description", headerName: "Descrição", width: 200 },
    { field: "language", headerName: "Idioma", width: 150 },
    { field: "order", headerName: "Ordem", width: 150 },
    { field: "showAtHome", headerName: "Mostrar na home", width: 150 },
    { field: "imageUrl", headerName: "Image Url", width: 150 },
  ];

  const handleDeleteCategory = (id: any) => {
    console.debug("", id);
    setCurrentCategoryId(id);
    setShowDelete(true);
  };

  const deleteCategory = () => {
    console.debug(currentCategoryId);

    if (currentCategoryId) {
      repository
        .delete(currentCategoryId)
        .then(() => {
          setSuccess(`Categoria ${currentCategoryId} excluída com sucesso`);
          setError("");
          setShowDelete(false);
        })
        .catch((error) => {
          console.error(error);
          setShowDelete(false);
        });
    }
  };

  useEffect(() => {
    repository
      .getAll()
      .then((categories) => {
        setParentCategories(
          categories.filter((c) => c.parentCategoryId === "")
        );

        setCategories(categories);
      })
      .catch((error) => {
        setError(error.message);
      });
  }, [success]);

  if (!isAuthenticated && !isInitializing) {
    return <Redirect to={`/login?r=${redirectPath}`} />;
  }

  return (
    <div>
      {!globalLoading && isAdmin ?
        <div className={styles.Categories} data-testid="Categories">
          <Container maxWidth="lg">
            <Collapse in={alert !== ""}>
              <Alert
                style={{ marginBottom: 10 }}
                severity="warning"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setAlert("");
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {alert}
              </Alert>
            </Collapse>
            <Collapse in={success !== ""}>
              <Alert
                style={{ marginBottom: 10 }}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setSuccess("");
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {success}
              </Alert>
            </Collapse>
            <Collapse in={error !== ""}>
              <Alert
                style={{ marginBottom: 10 }}
                severity="error"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setError("");
                    }}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }>
                {error}
              </Alert>
            </Collapse>
            <Button
              onClick={() => {
                setCurrentCategory(undefined);
                handleClickOpen();
              }}
              variant="contained"
              color="primary"
              style={{ marginBottom: 10, textTransform: "initial" }}
              startIcon={<AddIcon />}>
              Nova Categoria
            </Button>

            <DataGrid rows={categories} columns={columns} pageSize={5} autoHeight />
            {open && (
              <CategoriesEdit
                open={open}
                handleClose={handleClose}
                setSuccess={setSuccess}
                category={currentCategory}
              />
            )}
          </Container>

          {showDelete && (
            <DialogConfirmation
              content={`Deseja realmente excluir?`}
              show={showDelete}
              handleAgree={deleteCategory}
              title="Excluir Categoria"
              handleDisagree={() => setShowDelete(false)}
            />
          )}
        </div>
        :
        <></>
      }
    </div>
  );
};

export default Categories;

import clsx from "clsx";

import {
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core/styles";

import {
  AppBar,
  Toolbar,
  Container,
  Divider,
  Grid,
} from "@material-ui/core";

import { useLocation } from "react-router-dom";

import logoMatcheam from "../../images/marca/footer-logo.png";

import WorkIcon from "@material-ui/icons/Work";
import NotificationsIcon from "@material-ui/icons/Notifications";
import ChatIcon from "@material-ui/icons/Chat";
import TopBarSearch from "./TopBarSearch/TopBarSearch";

import { useTranslation } from "react-i18next";

import { Link as LinkRouter } from "react-router-dom";
import TopBarMenu from "./TopBarMenu/TopBarMenu";
import TopBarChat from "./TopBarChat/TopBarChat";
import { useProfileContext } from "../../ProfileContext";
import { matchPath } from "react-router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
      color: theme.palette.primary.main,
    },
    navbarDisplayFlex: {
      display: `flex`,
      justifyContent: `space-between`,
    },
    linkText: {
      textDecoration: `none`,
      textTransform: `uppercase`,
      color: `white`,
    },
    appbar: {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    },
    imageRoot: {
      height: "50px",
      maxHeight: "100px",
      padding: 0,
      "border-radius": "3px",
      [theme.breakpoints.down("md")]: {
        maxHeight: "5rem",
      },
    },
    toolBar: {
      color: theme.palette.text.secondary,
      width: "100%",
    },

    grow: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },

    sectionDesktop: {
      display: "none",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
  })
);

const MenuItems = [
  {
    to: "/chats",
    title: "Chats",
    icon: <ChatIcon color="primary" />,
    badgeContent: 10,
  },
  {
    to: "/jobs",
    title: "Jobs",
    icon: <WorkIcon color="primary" />,
    badgeContent: 1,
  },
  {
    to: "/notifications",
    title: "Notifications",
    icon: <NotificationsIcon color="primary" />,
    badgeContent: 5,
  },
];

const TopBar = () => {
  const { t, i18n } = useTranslation();
  const { error, loading, profile } = useProfileContext();
  const location = useLocation();

  const matchSearch = matchPath(location.pathname, {
    path: "/search",
    exact: false,
    strict: false,
  });

  const classes = useStyles();

  return (
    <AppBar position="sticky" className={classes.appbar} elevation={0}>
      <Container maxWidth="lg">
        <Toolbar className={clsx(classes.toolBar, classes.navbarDisplayFlex)}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}>
            <Grid
              item
              xl={1}
              lg={1}
              md={1}
              sm={1}
              xs={1}
              className={classes.sectionDesktop}></Grid>

            <Grid
              item
              xl={3}
              lg={3}
              md={3}
              sm={3}
              xs={3}
              style={{ textAlign: "center" }}>
              <LinkRouter to="/">
                <img
                  src={logoMatcheam}
                  className={classes.imageRoot}
                  alt="Matcheam"
                />
              </LinkRouter>
            </Grid>

            <Grid item xl={4} lg={4} md={3} sm={7} xs={7}>
              {!matchSearch && <TopBarSearch />}
            </Grid>
            <Grid
              item
              style={{ flexGrow: 1 }}
              className={classes.sectionDesktop}></Grid>

            <Grid
              item
              xl={1}
              lg={1}
              md={1}
              sm={1}
              xs={1}
              className={classes.sectionDesktop}>
              <Grid container justifyContent="space-between">
                <TopBarChat />

                {/* <Divider orientation="vertical" flexItem />
                <TopBarLogout /> */}
                <Divider orientation="vertical" flexItem />
              </Grid>
            </Grid>

            {/* <Grid
              item
              xl={1}
              lg={1}
              md={1}
              sm={1}
              xs={1}
              className={classes.sectionDesktop}>
              <TopBarSettings />
            </Grid> */}

            <Grid
              item
              xl={1}
              lg={1}
              md={1}
              sm={1}
              xs={1}
              // className={classes.sectionMobile}
              >
              <TopBarMenu />
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default TopBar;

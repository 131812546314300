import { Container, Grid, makeStyles, Paper } from "@material-ui/core";



import logoMatcheam from "../../images/marca/logo.png";

import LoginText from "./LoginText";
import { ILoginProp } from "./LoginProp";
import LoginForm from "./LoginForm/LoginForm";
import Register from "./Register/Register";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import bgLogin from "../../images/bg_home.jpg";
import ReadTermsAndPrivacyPolicy from "./ReadTermsAndPrivacyPolicy/ReadTermsAndPrivacyPolicy";


import AppStoreBadge from "../../images/store_badges/apple_app_store_badge.svg";
import PlayStoreBadge from "../../images/store_badges/google_play_store_badge.png";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "100vh",
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url("${bgLogin}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  googlebadge: {
    //width: "100%",
    maxWidth: "100%",
    maxHeight: 100
  },
  applebadge: {
    //width: "100%",
    maxWidth: "100%",
    height: 52,
    [theme.breakpoints.up("sm")]: {
      height: 65
    },
  },
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
  },
  loginBackground: {
    backgroundColor: "gray",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  social: {
    margin: theme.spacing(2, 0, 0),
    fontWeight: "bold",
  },
  divider: {
    margin: theme.spacing(2, 2, 2),
  },
  errorMessage: {
    color: "red",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  socialLogo: {
    maxWidth: "100%",
  },
  logoMatcheam: {
    width: 200,
  },
  texto: {
    color: "white",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  space: {
    height: 100,
  },
  ico: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

const Login = ({ step }: ILoginProp) => {
  const classes = useStyles();

  console.debug("current step: ", step);

  return (
    <Container component="main" className={classes.container} maxWidth="xl">
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          component="main"
          style={{ minHeight: "100vh" }}
          alignItems="stretch"
          justifyContent="center">
          <Grid item xs={12}>
            <img src={logoMatcheam} className={classes.logoMatcheam} alt="Matcheam" />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignContent="space-between"
              alignItems="center">
              <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                <LoginText step={step} />
                <Grid
                  container
                  justifyContent="space-between"
                  alignContent="space-between"
                  alignItems="center"
                  style={{visibility: "hidden"}}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <a href={`https://play.google.com/store/apps/details?id=com.Matcheam.app&utm_source=site&utm_campaign=home`} target="_blank" >
                      <img src={PlayStoreBadge} alt="Get it on Google Play" className={classes.googlebadge}></img>
                    </a>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                    <a href={`https://apps.apple.com/br/app/Matcheam/id1597208693?id=com.Matcheam.app&utm_source=site&utm_campaign=home`} target="_blank">
                      <img src={AppStoreBadge} alt="Download on App Store" className={classes.applebadge}></img>
                    </a>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <Paper className={classes.paper} elevation={0}>
                  {step === "login" && <LoginForm />}
                  {step === "recover-password" && <ForgotPassword />}
                  {step === "register" && <Register />}
                  <ReadTermsAndPrivacyPolicy />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
};

export default Login;

import React, { useMemo } from "react";
import { Message, Avatar } from "@chatscope/chat-ui-kit-react";
import { ChatMessage } from "../../../core/entities/Chat";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import logoMatcheam from "../../../images/marca/footer-logo.png";
import Deal from "../../../core/entities/Deal";

interface ChatProposalMessageProps {
  direction: "incoming" | "outgoing";
  isSender: Boolean;
  sender: String;
  avatar: String;
  sentTime: String;
  deal?: Deal;
  handleViewDetails(deal: Deal, isSender: Boolean): void;
  type: string;
  message: ChatMessage;
}

export default function ChatProposalMessage({
  direction,
  sender,
  isSender,
  avatar,
  sentTime,
  deal,
  handleViewDetails,
  type,
  message,
}: ChatProposalMessageProps) {
  const { t, i18n } = useTranslation();


  if (!deal) {
    return (
      <Message
        model={{
          type: "html",
          direction: direction,
        }}>
        <Message.CustomContent>
          <CircularProgress />
        </Message.CustomContent>
        <Message.Footer sender={sender} sentTime={sentTime} />
        <Avatar name={sender} src={avatar} />
      </Message>
    );
  }

  return (
    <Message
      model={{
        type: "html",
        direction: direction,
      }}>
      <Message.CustomContent>
        {type === "Notification" ? (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "10rem",
              }}>
              <span style={{ fontSize: "12px" }}>{message.body}</span>
            </div>
            <span
              style={{
                fontSize: "10px",
                float: "right",
                marginTop: "5px",
              }}>
              {sentTime}
            </span>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                maxWidth: "10rem",
              }}>
              <span style={{ fontSize: "12px" }}>{deal.job || "Job"}</span>
              <small style={{ margin: "0.5rem 0" }}>
                {t("Offer price")}:&nbsp;{new Intl.NumberFormat(i18n.language, { style: 'currency', currency: deal.currency }).format(deal.amount)}

              </small>
              <small>{`${t("Status")}: ${t(deal.status)}`}</small>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}>
              <button
                style={{
                  borderRadius: "5px",
                  backgroundColor: ["Completed", "Canceled"].includes(
                    deal.status
                  )
                    ? "#C9C9C9"
                    : "#454545",
                  width: "100%",
                  padding: "4px 4px",
                  border: "4px solid #fff",
                  fontSize: "11px",
                  color: "#fff",
                  cursor: "pointer",
                  marginTop: "0.75rem",
                }}
                onClick={() => handleViewDetails(deal, isSender)}>
                {t("View Details")}
              </button>
            </div>
            <span
              style={{
                fontSize: "10px",
                float: "right",
                marginTop: "5px",
              }}>
              {sentTime}
            </span>
          </div>
        )}
      </Message.CustomContent>
      <Message.Footer sender={sender} sentTime={sentTime} />
      <Avatar
        name={sender}
        src={type === "Notification" ? logoMatcheam : avatar}
      />
    </Message>
  );
}

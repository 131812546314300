import {
  makeStyles,
  useTheme,
  InputBase,
  Button,
  Box,
  Avatar,
  useMediaQuery,
} from "@material-ui/core";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./TopBarSearch.module.scss";
import SearchIcon from "@material-ui/icons/Search";
import { useHistory } from "react-router-dom";
import algoliasearch from "algoliasearch";
import {
  connectSearchBox,
  Highlight,
  Hits,
  InstantSearch,
} from "react-instantsearch-dom";
import SearchAutoComplete, {
  Autocomplete,
} from "../../Search/SearchAutocomplete";
import { createQuerySuggestionsPlugin } from "@algolia/autocomplete-plugin-query-suggestions";
import { createLocalStorageRecentSearchesPlugin } from "@algolia/autocomplete-plugin-recent-searches";
import qs from "qs";
import { SearchOptions } from "@algolia/client-search";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import { indexName, searchClient } from "../../../infra/algolia/config";
import { useTranslation } from "react-i18next";
import ProfileController from "../../Profile/ProfileController";
import { getAvatar } from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
  search: {
    flexGrow: 1,
    position: "relative",
    borderRadius: "10px",

    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: "100%",
    height: 50,
    justifyContent: "flex-start",
    [theme.breakpoints.down("md")]: {
      width: "3rem",
      height: "3rem",
      marginLeft: "auto",
    },
  },

  searchIcon: {
    color: "#868686",
    height: "100%",
    padding: theme.spacing(0, 1),
  },

  inputRoot: {
    color: "inherit",
    flexGrow: 1,
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "black",
  },

  button: {
    marginRight: -2,
    textTransform: "capitalize",
  },
}));

const profileController = new ProfileController();

function createURL(searchState) {
  return qs.stringify(searchState, { addQueryPrefix: true });
}

function searchStateToUrl({ location }, searchState) {
  if (Object.keys(searchState).length === 0) {
    return "";
  }

  return `${location.pathname}${createURL(searchState)}`;
}

function urlToSearchState({ search }) {
  return qs.parse(search.slice(1));
}

export interface ITopBarSearchProps {
  light?: boolean;
}

const VirtualSearchBox = connectSearchBox(() => null);

const TopBarSearch = ({ light = false }: ITopBarSearchProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState("");

  const [searchState, setSearchState] = useState(() =>
    urlToSearchState(window.location)
  );
  const timerRef = useRef(null);

  const redirectSearch = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === "Enter") {
      history.push(`/search?q=${searchValue}`);
    }
  };

  const gotoSearch = (query) => {
    history.push(`/search?q=${query}`);
  };

  // useEffect(() => {
  //   clearTimeout(timerRef.current);

  //   timerRef.current = setTimeout(() => {
  //     window.history.pushState(
  //       searchState,
  //       null,
  //       searchStateToUrl({ location: window.location }, searchState)
  //     );
  //   }, 400);
  // }, [searchState]);

  const onSubmit = useCallback(({ state }) => {
    setSearchState((searchState) => ({
      ...searchState,
      query: state.query,
    }));
    gotoSearch(state.query);
  }, []);

  const onReset = useCallback(() => {
    setSearchState((searchState) => ({
      ...searchState,
      query: "",
    }));
    setSearchValue("");
  }, []);

  const plugins = useMemo(() => {
    const recentSearchesPlugin = createLocalStorageRecentSearchesPlugin({
      key: "search",
      limit: 3,
      transformSource({ source }) {
        return {
          ...source,
          onSelect(params) {
            setSearchState((searchState) => ({
              ...searchState,
              query: params.item.label,
            }));
            gotoSearch(params.item.label);
          },
          templates: {
            ...source.templates,
            // Update the default `item` template to wrap it with a link
            // and plug it to the InstantSearch router.
            item(params) {
              const { children } = (source.templates.item(params) as any).props;

              return (
                <ItemWrapper
                  query={params.item.label}
                  category={params.item.category}>
                  {children}
                </ItemWrapper>
              );
            },
          },
        };
      },
    });

    const querySuggestionsPlugin = createQuerySuggestionsPlugin({
      searchClient,
      indexName: indexName,
      getSearchParams() {
        return recentSearchesPlugin.data
          ? recentSearchesPlugin.data.getAlgoliaSearchParams({ hitsPerPage: 5 })
          : {};
      },
      transformSource({ source }) {
        return {
          ...source,
          onSelect(params) {
            setSearchState((searchState) => ({
              ...searchState,
              query: params.item.query,
            }));
            gotoSearch(params.item.query);
          },
          templates: {
            ...source.templates,
            // Update the default `item` template to wrap it with a link
            // and plug it to the InstantSearch router.
            item(params) {
              const { children } = (source.templates.item(params) as any).props;

              return (
                <ItemWrapper
                  query={params.item.label}
                  category={params.item.category}>
                  {children}
                </ItemWrapper>
              );
            },
          },
        };
      },
    });

    return [
      recentSearchesPlugin,
      //querySuggestionsPlugin,
    ];
  }, []);

  return (
    <Box
      className={classes.search}
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
      style={{ backgroundColor: light ? "#fff" : "#F8F8F8" }}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        searchState={searchState}
        onSearchStateChange={setSearchState}
        createURL={createURL}>
        <VirtualSearchBox />
        <Autocomplete
          disablePortal
          placeholder={isMobile ? null : t("Search here...")}
          detachedMediaQuery=""
          initialState={{
            query: searchState.query,
          }}
          openOnFocus={true}
          onSubmit={onSubmit}
          onReset={onReset}
          plugins={plugins}
          getSources={({ query }) => [
            {
              sourceId: "profiles",
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: indexName,
                      query,
                      params: {
                        hitsPerPage: 5,
                        filters: "NOT _tags:hidden"
                      },
                    },
                  ],
                });
              },
              templates: {
                item({ item, components }) {
                  return <ProfileItem hit={item} components={components} />;
                },
              },
            },
          ]}
        />
      </InstantSearch>
    </Box>
  );
};


const isModerated = (hit, field) => {
  return hit.moderations ? profileController.verifyIfExistsModerationOnField(field, hit?.moderations) : false;
}

const getProfileImage = (hit) => {

  const moderated = isModerated(hit, "profileImageUrl");
  return hit?.profileImageUrl && !moderated ? hit?.profileImageUrl : getAvatar(hit?.name);

}

const getName = (hit) => {

  const moderated = isModerated(hit, "name");
  return hit?.name && !moderated ? hit?.name : "";

}


const ProfileItem = ({ hit, components }) => {
  const url = `/${hit.nickname}`;

  return (
    <a href={url} className="aa-ItemLink">
      <div className="aa-ItemContent">
        <div className="aa-ItemIcon--noBorder">
          <Avatar alt={hit.name} src={getProfileImage(hit)} />
        </div>
        <div className="aa-ItemTitle">
          <components.Highlight hit={hit} attribute="name" /> <br />
          <components.Highlight hit={hit} attribute="nickname" />
        </div>
      </div>
    </a>
  );
};

function ItemWrapper({ children, query, category }) {


  return (
    <a
      className="aa-ItemLink"
      //href={getInstantSearchUrl(uiState)}
      onClick={(event) => {
        console.debug(event);
      }}>
      {children}
    </a>
  );
}

export default TopBarSearch;

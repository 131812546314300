import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Button,
  Divider,
  Link,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { DataGrid, GridColDef, GridRowId } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Invite, { Accepted } from "../../../core/entities/Invite";
import InviteRepository from "../../../infra/firebase/InviteRepository";
import { useProfileContext } from "../../../ProfileContext";
import clsx from "clsx";
import DeleteIcon from "@material-ui/icons/Delete";
import { timestampToData } from "../../../utils/utils";
import Profile from "../../../core/entities/Profile";
import moment from "moment";
import DialogConfirmation from "../../shared/DialogConfirmation";
import AddIcon from "@material-ui/icons/Add";
import SettingsNewInvite from "./SettingsNewInvite/SettingsNewInvite";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PeopleIcon from "@material-ui/icons/People";

import { MdContentCopy } from "react-icons/md";
import { Link as RouterLink } from "react-router-dom";
import firebase from "firebase";
import VerifyEmailDialog from "../../shared/VerifyEmailDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: 20,
      width: "100%",
      backgroundColor: theme.palette.background.paper,
    },
    button: {
      margin: theme.spacing(1),
      textTransform: "initial",
      color: "white",
    },
    iconbutton: {
      margin: theme.spacing(1),
      textTransform: "initial",
      color: theme.palette.primary.main,
    },
    deleteiconbutton: {
      margin: theme.spacing(1),
      textTransform: "initial",
      color: "red",
    },
    delete: {
      backgroundColor: "#9A0036",
    },
  })
);

const SettingsInvites = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const inviteRepository = new InviteRepository();
  const [invites, setInvites] = useState<Invite[]>([]);
  const [inviteId, setInviteId] = useState("");
  const [accepteds, setAccepteds] = useState<Accepted[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [changedInvite, setChangedInvite] = useState("");
  const [showExpire, setShowExpire] = useState(false);
  const [showReactivate, setShowReactivate] = useState(false);
  const [inviteIdToExpire, setInviteIdToExpire] = useState("");
  const [inviteIdToReactivate, setInviteIdToReactivate] = useState("");
  const [showNewInvite, setShowNewInvite] = useState(false);
  const [success, setSuccess] = useState("");
  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);
  const { profile } = useProfileContext();

  const [copy, setCopy] = useState({ text: "", copied: false });

  const [copied, setCopied] = useState("");

  const handleCopy = (text) => {
    setCopy({ text: text, copied: true });
    setCopied(t("Link to invitation copied to clipboard"));
  };

  const handleCloseNewInvite = () => {
    setShowNewInvite(false);
  };

  const handleExpireInvite = () => {
    if (inviteIdToExpire)
      inviteRepository
        .expire(inviteIdToExpire)
        .then(() => {
          setChangedInvite(`${inviteIdToExpire} expired`);
          setShowExpire(false);
        })
        .catch((error) => {
          console.error(error);
          setShowExpire(false);
        });
  };

  const handleReactivateInvite = () => {
    if (inviteIdToReactivate)
      inviteRepository
        .reactivate(inviteIdToReactivate)
        .then(() => {
          setChangedInvite(`${inviteIdToReactivate} reactivated`);
          setShowReactivate(false);
        })
        .catch((error) => {
          console.error(error);
          setShowReactivate(false);
        });
  };

  const handleNewInvite = () => {
    if (!firebase.auth().currentUser?.emailVerified) {
      setIsVerifyEmailOpen(true);
      return;
    }
    setShowNewInvite(true);
  };

  const columns: GridColDef[] = [
    {
      field: "",
      headerName: t("Actions"),
      width: 200,
      disableColumnMenu: true,
      sortable: false,

      renderCell: (params) => {
        const expired = params.row.expired ? true : false;
        let url = `${window.location.origin}/register?token=${params.row.id}`;

        return (
          <div>
            {!expired && (
              <div>
                <Tooltip arrow title={<>{t("Expire")}</>}>
                  <IconButton
                    aria-label="delete"
                    className={classes.deleteiconbutton}
                    size="medium"
                    color="default"
                    onClick={() => {
                      setInviteIdToExpire(params.row.id);
                      setShowExpire(true);
                    }}>
                    <DeleteIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>

                <Tooltip arrow title={<>{t("Copy")}</>}>
                  <CopyToClipboard onCopy={handleCopy} text={url}>
                    <IconButton
                      aria-label="copy"
                      className={classes.iconbutton}
                      size="medium"
                      color="primary"
                      onClick={() => {
                        handleCopy(url);
                      }}>
                      <MdContentCopy fontSize="inherit" />
                    </IconButton>
                  </CopyToClipboard>
                </Tooltip>

                <Tooltip arrow title={<>{t("View accepted invitations")}</>}>
                  <IconButton
                    aria-label="copy"
                    className={classes.iconbutton}
                    size="medium"
                    color="primary"
                    onClick={() => {
                      setInviteIdToExpire(params.row.id);
                    }}>
                    <PeopleIcon fontSize="inherit" />
                  </IconButton>
                </Tooltip>
              </div>
            )}

            {expired && (
              <Button
                size="small"
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<DeleteIcon />}
                onClick={() => {
                  setInviteIdToReactivate(params.row.id);
                  setShowReactivate(true);
                }}>
                {t("Reactivate")}
              </Button>
            )}
          </div>
        );
      },
    },
    {
      field: "id",
      headerName: t("Code"),
      width: 400,
      renderCell: (params) => {
        let url = `${window.location.origin}/register?token=${params.row.id}`;
        return (
          <CopyToClipboard onCopy={handleCopy} text={url}>
            <span>{url} </span>
          </CopyToClipboard>
        );
      },
    },
    { field: "type", headerName: t("Type"), width: 200 },
    {
      field: "sentDate",
      headerName: t("Created at"),
      width: 200,
      valueFormatter: ({ value }) => moment(value as Date).format("LLL"),
    },
    { field: "expired", headerName: t("Expired"), width: 150 },
  ];

  const acceptedColumns: GridColDef[] = [
    {
      field: "nickname",
      headerName: t("Nickname"),
      width: 200,
      renderCell: (param) => {
        return (
          <>
            {" "}
            {param.row.profile && (
              <Link
                component={RouterLink}
                to={`/${param.row.profile.nickname}`}>
                {param.row.profile.nickname}
              </Link>
            )}{" "}
          </>
        );
      },
    },
    {
      field: "profile",
      headerName: t("Name"),
      width: 200,
      valueFormatter: ({ value }) => {
        const profileRow = value as Profile;
        return profileRow ? profileRow.name : "";
      },
    },
    {
      field: "date",
      headerName: t("Accepted at"),
      width: 200,
      valueFormatter: ({ value }) =>
        moment(timestampToData(value)).format("LLL"),
    },
  ];

  useEffect(() => {
    console.debug(profile);

    if (profile) {
      inviteRepository
        .getByProfile(profile.id)
        .then((invitesResult) => {
          setInvites(invitesResult);
          if (invitesResult.length > 0) {
            setInviteId(invitesResult[0].id);
            //getAcceptedInvitesByInviteId(inviteId);
          }
        })
        .catch((error) => console.error(error));
    }
  }, [profile, changedInvite, success]);

  useEffect(() => {
    console.debug("useEffect inviteId", inviteId);

    if (inviteId !== "") {
      getAcceptedInvitesByInviteId(inviteId);
    }
  }, [inviteId]);

  const getAcceptedInvitesByInviteId = (inviteId) => {
    inviteRepository
      .getAcceptedInvitesByInvite(inviteId)
      .then((acceptedsResult) => setAccepteds(acceptedsResult))
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (selectionModel.length > 0) setInviteId(selectionModel[0].toString());
  }, [selectionModel]);

  return (
    <div className={classes.root} data-testid="SettingsInvites">
      <Typography variant="h6">{t("Invites")}</Typography>
      <Button
        onClick={() => handleNewInvite()}
        variant="contained"
        color="primary"
        style={{ marginBottom: 10, textTransform: "initial" }}
        startIcon={<AddIcon />}>
        {t("New invite")}
      </Button>

      <DataGrid
        rows={invites}
        columns={columns}
        pageSize={5}
        autoHeight
        onSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection);
        }}
        selectionModel={selectionModel}
      />

      <br />
      <span>{copied}</span>
      <Divider
        orientation="horizontal"
        variant="fullWidth"
        style={{ marginTop: 10, marginBottom: 30 }}
      />

      <Typography variant="h6">{`${t("Accepted Invitations")} - ${t(
        "invite"
      )} ${inviteId}`}</Typography>

      <DataGrid
        rows={accepteds}
        columns={acceptedColumns}
        pageSize={5}
        autoHeight
      />

      {showExpire && (
        <DialogConfirmation
          content={t("Do you want to expire this invite?")}
          show={showExpire}
          handleAgree={handleExpireInvite}
          title={t("Expire invite")}
          handleDisagree={() => setShowExpire(false)}
        />
      )}

      {showReactivate && (
        <DialogConfirmation
          content={t("Do you want to reactivate this invite?")}
          show={showReactivate}
          handleAgree={handleReactivateInvite}
          title={t("Reactivate invite")}
          handleDisagree={() => setShowReactivate(false)}
        />
      )}

      {showNewInvite && (
        <SettingsNewInvite
          open={showNewInvite}
          handleClose={handleCloseNewInvite}
          setSuccess={setSuccess}
          profileId={profile?.id}
        />
      )}
      <VerifyEmailDialog
        open={isVerifyEmailOpen}
        onClose={() => setIsVerifyEmailOpen(false)}>
        {t("To be able to do this you need to verify your email.")}
      </VerifyEmailDialog>
    </div>
  );
};

export default SettingsInvites;

import { Avatar, Card, CardActions, CardContent, CardHeader, IconButton, makeStyles, Typography } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../../Theme';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import ShareIcon from '@material-ui/icons/Share';


const thisUseStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: red[500],
  },
});

const JobsResultCard: React.FC = () => {
  const classes = thisUseStyles();


  return (
      <Card className={classes.root} variant="outlined">
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              R
          </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <BookmarkBorderIcon />
            </IconButton>
          }
          title="Agency or Agent name"
          subheader="May 4, 2021"
        />
        <CardContent>
          <Typography variant="h5" component="h2">
            Campaing Name
          </Typography>
          <Typography className={classes.pos} color="textSecondary">
            Campaing Location
        </Typography>
          <Typography variant="body2" component="p">
            Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...
          </Typography>
        </CardContent>
        <CardActions disableSpacing>
          <IconButton aria-label="add to favorites">
            <FavoriteBorderIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
        </CardActions>
      </Card>
  )
};

export default JobsResultCard;

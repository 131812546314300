import { makeStyles, Theme, createStyles, Divider, Grid, IconButton, Typography, Button } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Post from '../../../core/entities/Post';

import PostRepository from '../../../infra/firebase/PostRepository';
import { useStyles } from '../../../Theme';
import AddIcon from '@material-ui/icons/Add';

import Gallery from "react-photo-gallery";
import PostImageUploader from '../../shared/Post/PostImageUploader';
import { useCurrentProfileContext } from '../../../CurrentProfileContext';
import { IPostMedia } from '../../shared/Post/IPostMedia';
import PostForm from '../../shared/Post/PostForm';
import { PostGallery } from '../../shared/Post/PostThumbnail/PostThumbnail';
import { DialogPostShow } from '../../shared/Post/PostShow/PostShow';
import Modal from 'react-responsive-modal';
import { useProfileContext } from '../../../ProfileContext';

const useSpecificStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20
    },

  }),
);




const ProfilePortfolio = () => {

  const {
    error: globalError,
    loading: globalLoading,
    profile: globalProfile,
    isAdmin
  } = useProfileContext();

  const { error, loading, profile, isCurrent } = useCurrentProfileContext();

  const globalClasses = useStyles();
  const classes = useSpecificStyles();
  const { t } = useTranslation();

  const [openEdit, setOpenEdit] = useState(false);
  const handleClickOpen = () => { setOpenEdit(true); };
  const handleClose = () => { setOpenEdit(false); };

  const [isLoading, setIsLoading] = useState(true);

  const [openShowPost, setOpenShowPost] = useState(false);
  const handleCloseShowPost = (wasDeleted) => {
    setwasDeleted(wasDeleted);
    setOpenShowPost(false);


  };
  const [wasDeleted, setwasDeleted] = useState(undefined);

  const [currentPost, setCurrentPost] = useState<Post | undefined>(undefined);
  const [posts, setPosts] = useState<Post[]>([]);
  const [photos, setPhotos] = useState<IPostMedia[]>([]);
  const [hasMore, setHasMore] = useState(true);

  const [uploadedPhotos, setUploadedPhotos] = useState<IPostMedia[]>([]);


  const [openNewPost, setOpenNewPost] = useState(false);
  const handleClickOpenNewPost = () => { setOpenNewPost(true); };
  const handleCloseNewPost = () => { setOpenNewPost(false); };

  const repository = new PostRepository();

  const handleSuccessPost = (post: Post) => {
    posts.unshift(post);
    setPosts(posts);
  }

  const setSuccessPhotos = (postMedias: IPostMedia[]) => {
    setUploadedPhotos(postMedias);
    handleClickOpenNewPost();
  }

  const showPost = (post: Post) => {
    setCurrentPost(post);
    setOpenShowPost(true);
  }



  useEffect(() => {
    document.body.style.overflow = "visible";
  });

  useEffect(() => {

    if (profile && !loading && !globalLoading) {
      repository.getAllPostsByProfile(profile.id)
        .then((getAllPostsByProfileResult) => {

          if (!isCurrent || isAdmin) {
            getAllPostsByProfileResult = getAllPostsByProfileResult.filter(p => p.moderated !== true);
          }

          const photosByPosts = getAllPostsByProfileResult.map((post) => {
            return {
              src: post.thumbnailUrl,
              width: 1,
              height: 1
            } as IPostMedia;
          });



          setPhotos(photosByPosts);

          setPosts(getAllPostsByProfileResult);
          setIsLoading(false);

        })
        .catch((getAllPostsByProfileError) => {
          setIsLoading(false);
          console.error(getAllPostsByProfileError);
        });
    }


  }, [loading, globalLoading, wasDeleted]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <div>
      {(profile && !isLoading) &&
        (<div>
          {posts.length > 0
            ?
            (<div className={classes.root}>

              <Grid container direction="row" justifyContent="space-between" alignItems="center"        >
                <Grid item xl={11} lg={11} md={11} sm={11} xs={11} >
                  <Typography variant='subtitle1'>
                    {t('Portfolio')}
                  </Typography>
                </Grid>

                {(isCurrent || isAdmin) &&
                  <div>
                    <Grid item xl={1} lg={1} md={1} sm={1} xs={1} >
                      <IconButton onClick={() => { setCurrentPost(undefined); handleClickOpen(); }}
                        color="primary" aria-label="upload picture" component="span">
                        <AddIcon />
                      </IconButton>
                    </Grid>

                    {openEdit &&
                      <PostImageUploader
                        title={t('New Post')}
                        handleClose={handleClose}
                        open={openEdit}
                        setSuccess={setSuccessPhotos}
                        profileId={profile.id} />}

                    {openNewPost &&
                      <PostForm open={openNewPost} profileId={profile.id} items={uploadedPhotos} handleClose={handleCloseNewPost} setSuccess={handleSuccessPost} title={t('New Post')} />}
                  </div>
                }

              </Grid>
              <Divider variant='fullWidth' />

            </div>)
            :

            (<div>
              {(isCurrent || isAdmin) &&
                (<div>
                  <Grid container direction='column' justifyContent='center' alignItems='center' style={{ marginTop: 20 }}>

                    <Button onClick={() => { setCurrentPost(undefined); handleClickOpen(); }}
                      variant='contained' color='primary' className={globalClasses.button}>{t('Add a new Post')}</Button>


                  </Grid>

                  {openEdit &&
                    (<PostImageUploader
                      title={t('New Post')}
                      handleClose={handleClose}
                      open={openEdit}
                      setSuccess={setSuccessPhotos}
                      profileId={profile.id} />)
                  }

                  {openNewPost &&
                    (<PostForm open={openNewPost} profileId={profile.id} items={uploadedPhotos} handleClose={handleCloseNewPost} setSuccess={handleSuccessPost} title={t('New Post')} />)
                  }
                </div>)
              }
            </div>)

          }
        </div>)
      }

      {(openShowPost && currentPost && profile) &&
        (<DialogPostShow post={currentPost} open={openShowPost} handleClose={handleCloseShowPost} profile={profile} isCurrent={(isCurrent || isAdmin)} />)
      }

      {(!isLoading && posts.length > 0) &&

        (<div><PostGallery hasMore={hasMore} getMore={() => { }} posts={posts} onClick={showPost} /></div>)
      }


    </div>
  )
};

export default ProfilePortfolio;

import { getRepository } from 'fireorm';
import Skill from '../../core/entities/Skill';
import { IRepository } from '../../core/interfaces/IRepository';


export default class SkillRepository implements IRepository<Skill>{

    repository = getRepository(Skill);

    getByCategory = (categoryId: string): Promise<Skill[]> => {
        return this.repository.whereEqualTo('categoryId', categoryId).find();
    }


    getByText = (categoryId: string, text: string): Promise<Skill[]> => {
        return this.repository
            .whereEqualTo('categoryId', categoryId)
            .whereGreaterOrEqualThan('name', text)
            .whereLessOrEqualThan('name', text + '~')
            .find();
    }

    createBatch = (categoryId: string, skills: Skill[]): Promise<void> => {
        const batch = this.repository.createBatch();

        skills.forEach(skill => {
            skill.categoryId = categoryId;
            skill.id = '';
            batch.create(skill);
            return null;
        });

        return batch.commit();
    }


    createOrUpdate(entity: Skill): Promise<Skill> {
        return entity.id ? this.update(entity) : this.create(entity);
    }

    create(entity: Skill): Promise<Skill> {
        return this.repository.create(entity);
    }
    update(entity: Skill): Promise<Skill> {
        return this.repository.update(entity);
    }
    delete(id: string): Promise<void> {
        return this.repository.delete(id);
    }
    getById(id: string): Promise<Skill> {
        return this.repository.findById(id);
    }

}
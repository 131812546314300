import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Attribute } from "../../../core/entities/Attribute";

interface NumberAttributeProps {
  control: any;
  attribute: Attribute;
}

export default function NumberAttribute({
  control,
  attribute,
}: NumberAttributeProps) {
  const {
    i18n: { languages },
  } = useTranslation();
  const [label, setLabel] = useState("");
  const [unit, setUnit] = useState("");

  useEffect(() => {
    if (languages.includes("pt")) {
      setLabel(attribute.displayName.pt);
    } else if (languages.includes("de")) {
      setLabel(attribute.displayName.de);
    } else if (languages.includes("es")) {
      setLabel(attribute.displayName.es);
    } else {
      setLabel(attribute.displayName.en);
    }

    setUnit(attribute.unit ? `(${attribute.unit})` : "")

  }, [attribute.displayName, languages]);



  return (
    <Controller
      control={control}
      name={attribute.name}
      render={({ field }) => (
        <TextField
          {...field}
          label={`${label} ${unit}`}
          fullWidth
          type="number"
          variant="standard"
          margin="normal"
          id={attribute.id}
          inputProps={{
            step: 1 / 10 ** (attribute.decimal || 0),
            min: attribute.min,
            max: attribute.max,
          }}
        />
      )}
    />
  );
}

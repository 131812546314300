import React from 'react';
import styles from './ShowPdf.module.scss';
import { Document } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import { fileName } from '../../utils/utils';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.js';

const ShowPdf = ({url}) => {


  return (

    <div className={styles.ShowPdf} data-testid="ShowPdf" style={{ width: "100%", height: "100%" }}>
      {/* <Document file={url} /> */}

      {navigator.platform === 'Android'
        ? <div> <a href={url} style={{ color: '#F05900' }} >Leia aqui</a> o Termo de Adesão </div>
        :
        <object width="100%" height="700" type="application/pdf" data={url} name={fileName(url)}>
          <a href={url}>Baixe o PDF aqui</a>.
        </object>
      }

    </div>
  )
};

export default ShowPdf;

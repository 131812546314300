import { makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import React from "react";

const useStyles = makeStyles((theme) => ({
  search: {
    margin: "0.5rem",
    marginBottom: "1rem",
    height: "2rem",
    borderRadius: 10,
  },
  chat: {
    margin: "0.5rem",
    height: "3.5rem",
    borderRadius: 10,
  },
}));

export default function ChatListSkeleton() {
  const classes = useStyles();
  return (
    <div>
      <Skeleton variant="rect" className={classes.search} />
      {Array(6)
        .fill(0)
        .map((_, i) => (
          <Skeleton variant="rect" className={classes.chat} key={`skeleton-${i}`}/>
        ))}
    </div>
  );
}

import { makeStyles, Theme, createStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, animateScroll as scroll } from "react-scroll";
import styles from './Footer.module.scss';
import { Link as LinkRouter } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    home: {
      padding: theme.spacing(2),
      color: theme.palette.text.secondary,
    },
  })
);

const smoothscroll = () => {

}


const Footer: React.FC = () => {

  const { t, i18n } = useTranslation();

  return (
    <footer>
      <div className="social-wrap">
        <div>
          <ul className="footer-social-list">
            <li><a href="https://www.facebook.com/Matcheam-106418301972548" rel="noreferrer" target="_blank">
              <i className="fa fa-facebook"></i><span>Facebook</span>
            </a></li>
            <li><a href="https://twitter.com/matcheam_com" rel="noreferrer" target="_blank">
              <i className="fa fa-twitter"></i><span>Twitter</span>
            </a></li>

            <li><a href="https://www.instagram.com/matcheam" rel="noreferrer" target="_blank">
              <i className="fa fa-instagram"></i><span>Instagram</span>
            </a></li>

            <li><a href="https://www.linkedin.com/company/79064890/" rel="noreferrer" target="_blank">
              <i className="fa fa-linkedin"></i><span>LinkedIn</span>
            </a></li>

          </ul>

        </div>
      </div>
      <div className="footer-bottom">

        <div className="footer-logo">
          <img src="images/footer-logo.png" alt="" />
        </div>

        <div className="copyright">
          <span>©Copyright Matcheam</span>
          <span><LinkRouter to="/impressum">{t("Impressum/Imprint")}</LinkRouter></span>
          <span><LinkRouter to="/privacypolicy">{t("Privacy Policy")}</LinkRouter></span>
          <span><LinkRouter to="/termsandconditions">{t("Terms & Conditions")}</LinkRouter></span>


        </div>

      </div>

      <div className="back-to-top">
        <Link
          activeClass="active"
          to="top"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >{t("Back to Top")}
        </Link>
      </div>
    </footer>
  )
};

export default Footer;

import React, { useEffect, useRef, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByPlaceId, geocodeByAddress } from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import { whiteList } from '../../../Settings';
import axios from 'axios';


export interface ILocationComponentProps {
  handleSelect: any;
  defaultValue: any;
  id: string;
}


const LocationComponent = ({ id, handleSelect, defaultValue }: ILocationComponentProps) => {
  const [value, setValue] = useState<any>(null);
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState(i18n.language.split("-")[0]);
  const [langFilter, setLangFilter] = useState(whiteList.findIndex((w) => w === lang) !== -1 ? lang : "en");

  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {

    setIsLoading(true);

    if (defaultValue) {
      geocodeByPlaceId(defaultValue)
        .then(results => {
          let result = results[0];

          if (result)
            setValue({
              label: result.formatted_address,
              value: {
                description: result.formatted_address,

                place_id: result.place_id,
                reference: result.place_id,
              }
            });
          setIsLoading(false);

        })
        .catch(error => {
          console.error("geocodeByPlaceId", error);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }

  }, [defaultValue]);

  return (
    <div >
      {!isLoading &&
        <GooglePlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API}
          minLengthAutocomplete={3}
          apiOptions={{ language: langFilter, region: lang }}
          selectProps={{
            id: id,
            defaultValue: value,
            onChange: handleSelect,
          }}
        />
      }
    </div>
  )
};




export default LocationComponent;

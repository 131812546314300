import { DialogActions } from "@material-ui/core";
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Theme,
  createStyles,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ChatMessage } from "../../../core/entities/Chat";
import DealsRepository from "../../../infra/firebase/DealsRepository";
import { functions } from "../../../infra/firebase/firebasecofig";
import styles from "./DealDetails.module.scss";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import CloseButton from "../../shared/CloseButton";
import MessageRepository from "../../../infra/firebase/MessageRepository";
import Deal from "../../../core/entities/Deal";

const useFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: 5,
      top: 5,
      color: theme.palette.grey[500],
      [theme.breakpoints.down("md")]: {
        position: "relative",
        justifyContent: "flex-end",
        marginLeft: "auto",
      },
    },
  })
);

interface DealDetailsProps {
  isOpen: boolean;
  handleClose(success: boolean): void;
  isSender: boolean;
  isReceiver: boolean;
  deal?: Deal;
  handleOpenEditDialog(deal?: Deal): void;
  handleOpenSelectPayment(deal: Deal): void;
  isAgent: boolean;
}

const dealRepository = new DealsRepository();
const messageRepository = new MessageRepository();
export default function DealDetails({
  isOpen,
  handleClose,
  isSender,
  handleOpenEditDialog,
  deal: initialDeal,
  handleOpenSelectPayment,
  isAgent,
  isReceiver,
}: DealDetailsProps) {
  const { t, i18n } = useTranslation();
  const [deal, setDeal] = useState(initialDeal);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useFormStyles();
  useEffect(() => {
    if (initialDeal) {
      fetchDeal(initialDeal.id);
    }
  }, [initialDeal]);

  const fetchDeal = async (dealId: string) => {
    const updatedDeal = await dealRepository.getById(dealId);
    setDeal(updatedDeal);
  };

  if (!deal) {
    return (
      <Dialog open={isOpen} onClose={() => handleClose(true)}>
        <CircularProgress style={{ margin: "2rem" }} />
      </Dialog>
    );
  }

  const handleCancelDeal = async () => {
    deal.status = "Canceled";
    await dealRepository.update(deal);
    handleClose(true);
  };

  const handleEditProposal = () => {
    console.debug(deal, "DEAL to EDIT");
    handleOpenEditDialog(deal);
    handleClose(true);
  };

  const handleStartAcceptWorkflow = () => {
    handleOpenSelectPayment(deal);
    handleClose(true);
  };

  const handleInformDone = async () => {
    setIsLoading(true);
    deal.status = "Accomplished";
    await dealRepository.update(deal);
    // await sendUpdateMessage(deal.id);
    setIsLoading(false);
    handleClose(true);
  };

  const handleConfirmDone = async () => {
    try {
      console.debug(deal.paymentIntentId);
      setIsLoading(true);
      const caller = functions.httpsCallable("payments-onCallCaptureIntent");
      const response = await caller({
        paymentIntent: deal.paymentIntentId,
        region: deal.region,
      });
      deal.status = "Completed";

      await dealRepository.update(deal);
      handleClose(true);
      setIsLoading(false);
    } catch (err) {
      console.debug(err);
      setIsLoading(false);
    }
  };
  return (
    <Dialog open={isOpen} onClose={() => handleClose(true)}>
      <CloseButton handleClose={() => handleClose(false)} />
      <DialogTitle id="form-dialog-title" className="titleProposal">
        {t("Proposal Summary")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              display="inline"
              style={{ fontWeight: "bold", color: "#919191" }}>
              {t("Job")}:
            </Typography>
            <Typography variant="body1" display="inline">
              {" "}
              {deal.job}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              display="inline"
              style={{ fontWeight: "bold", color: "#919191" }}>
              {t("Price")}:&nbsp;
            </Typography>
            <Typography variant="body1" display="inline">
              {new Intl.NumberFormat(i18n.language, {
                style: "currency",
                currency: deal.currency,
              }).format(deal.amount)}
            </Typography>
          </Grid>
          {isAgent && (
            <Grid item xs={12}>
              <Typography
                variant="body1"
                display="inline"
                style={{ fontWeight: "bold", color: "#919191" }}>
                {t("Comission")}:
              </Typography>
              <Typography variant="body1" display="inline">
                {" "}
                {deal.comission}%
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography
              variant="body1"
              display="inline"
              style={{ fontWeight: "bold", color: "#919191" }}>
              {t("Status")}:
            </Typography>
            <Typography variant="body1" display="inline">
              {" "}
              {t(deal.status)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              variant="body1"
              display="block"
              style={{ fontWeight: "bold", color: "#919191" }}>
              {t("Details")}:
            </Typography>
            <Typography variant="body1" display="inline">
              {deal.details
                ? deal.details.split("**linebreak**").map((str, index) => (
                    <>
                      {str}
                      <br />
                    </>
                  ))
                : ""}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {deal.status === "WaitingForApproval" && isSender && (
          <>
            <Button
              type="button"
              color="primary"
              className={styles.proposalButton}
              onClick={handleEditProposal}>
              {t("Edit")}
            </Button>
            <Button
              type="button"
              color="primary"
              className={styles.proposalButtonSecondary}
              onClick={handleCancelDeal}>
              {t("Cancel Proposal")}
            </Button>
          </>
        )}
        {deal.status === "WaitingForApproval" && isReceiver && (
          <Button
            type="button"
            color="primary"
            className={styles.proposalButton}
            onClick={handleStartAcceptWorkflow}>
            {t("Accept Proposal")}
          </Button>
        )}
        {deal.status === "WaitingToBeDone" && isSender && (
          <Button
            type="button"
            color="primary"
            className={styles.proposalButton}
            onClick={handleInformDone}
            disabled={isLoading}>
            {!isLoading ? t("Inform that it was done") : "Loading..."}
          </Button>
        )}
        {deal.status === "Accomplished" && isReceiver && (
          <Button
            type="button"
            color="primary"
            className={styles.proposalButton}
            onClick={handleConfirmDone}
            disabled={isLoading}>
            {!isLoading ? t("Confirm that it was done") : "Loading..."}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

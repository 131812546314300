import { Box, Button, createStyles, Divider, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import ProfileExperienceEdit from './ProfileExperienceEdit/ProfileExperienceEdit';
import Experience from '../../../core/entities/Experience';
import ExperienceRepository from '../../../infra/firebase/ExperienceRepository';

import Profile from '../../../core/entities/Profile';
import { useStyles } from '../../../Theme';
import Skeleton from '@material-ui/lab/Skeleton';
import { useParams } from 'react-router-dom';
import { useCurrentProfileContext } from '../../../CurrentProfileContext';

const useSpecificStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: 20
    },
    rootList: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export interface IProfileExperienceProps {
  profile: Profile;
}

const ProfileExperience = () => {

  const { nickname } = useParams<{ nickname: string }>();
  const { error, loading, profile, isCurrent } = useCurrentProfileContext();

  const globalClasses = useStyles();
  const classes = useSpecificStyles();
  const { t, i18n } = useTranslation();

  const [openEdit, setOpenEdit] = useState(false);
  const handleClickOpen = () => { setOpenEdit(true); };
  const handleClose = () => { setOpenEdit(false); };
  const [success, setSuccess] = useState('');

  const [isLoading, setIsLoading] = useState(true);

  const [currentExperience, setcurrentExperience] = useState<Experience | undefined>(undefined);
  const [experiences, setExperiences] = useState<Experience[]>([]);

  const repository = new ExperienceRepository();

  useEffect(() => {
    setIsLoading(true);
    if (profile)
      repository.getExperiencesByProfile(profile?.id)
        .then((experiencesResult) => {
          setExperiences(experiencesResult);
          setIsLoading(false);

        })
        .catch((errorGetExperiences) => {
          console.debug(errorGetExperiences);
          setIsLoading(false);
        });
  }, [success, profile])


  const getBox = (experience: Experience | undefined) => {

    return (<div>
      <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" key={experience?.id}    >

        <Grid item xl={11} lg={11} md={11} sm={11} xs={11} >
          <Box>
            <Typography variant='h6' style={{ fontWeight: 'bold' }} gutterBottom>
              {experience ? experience.company : <Skeleton width='80%' />}
            </Typography>

            <Typography variant='body1'>
              {experience ? experience.title : <Skeleton />}
            </Typography>

            <Typography variant='body2' gutterBottom>
              {experience ? experience.employmentType : <Skeleton />}
            </Typography>

            <Typography variant='body2' display='inline'>
              {experience ? experience.startedDate.toLocaleDateString() : <Skeleton />}
            </Typography>

            <Typography variant='body2' display='inline'>
              {experience ? experience.endDate && " - " + experience.endDate.toLocaleDateString() : <Skeleton />}
            </Typography>

            <Typography variant='body2'>
              {experience ? experience.location : <Skeleton />}
            </Typography>

            <Typography variant='body2' paragraph>
              {experience ? experience.details : <Skeleton height={200} />}
            </Typography>
          </Box>
        </Grid>

        <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
          <Box alignItems='right'>
            {(experience && isCurrent) &&
              <IconButton onClick={() => { setcurrentExperience(experience); handleClickOpen(); }}
                color="primary" aria-label="upload picture" component="span">
                <EditOutlinedIcon />
              </IconButton>
            }
          </Box>
        </Grid>
      </Grid>

      <Divider style={{ marginBottom: 10 }} />
    </div>);
  }

  return (
    <div>

      {(!isLoading && experiences.length > 0) &&
        <div className={classes.root}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center"        >
            <Grid item xl={11} lg={11} md={11} sm={11} xs={11} >
              <Typography variant='subtitle1'>
                {t("Experience")}
              </Typography>
            </Grid>

            {isCurrent &&
              <Grid item xl={1} lg={1} md={1} sm={1} xs={1}>
                <IconButton onClick={() => { setcurrentExperience(undefined); handleClickOpen(); }}
                  color="primary" aria-label="upload picture" component="span">
                  <AddIcon />
                </IconButton>
              </Grid>
            }

          </Grid>
          <Divider variant='fullWidth' />
        </div>
      }

      {(openEdit && profile) && <ProfileExperienceEdit handleClose={handleClose} open={openEdit} setSuccess={setSuccess} experience={currentExperience} profileId={profile?.id} />}

      {(!isLoading && experiences.length === 0 && isCurrent) &&
        
          <Grid container direction='column' justifyContent='center' alignItems='center' alignContent='center'>
            <Typography variant='h5' align='center' gutterBottom paragraph display='block'>
              {t("Hi! Include experiences and let everyone know what you're capable of.")}
            </Typography>

            <Button onClick={() => { setcurrentExperience(undefined); handleClickOpen(); }}
              variant='contained' color='primary' className={globalClasses.button}>Add a new Experience</Button>
          </Grid>
      }

      {isLoading &&
        [1, 2, 3].map(() => {
          return (
            getBox(undefined)
          )
        })
      }

      {!isLoading && experiences.map((experience) => {
        return (
          getBox(experience)
        )
      })}




    </div>
  )
};



export default ProfileExperience;


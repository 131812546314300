import {
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  TextField,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Experience from "../../../../core/entities/Experience";
import CloseIcon from "@material-ui/icons/Close";
import { ErrorMessage } from "@hookform/error-message";
import ExperienceRepository from "../../../../infra/firebase/ExperienceRepository";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { timestampToData } from "../../../../utils/utils";
import DialogConfirmation from "../../../shared/DialogConfirmation";

const useFormStyles = makeStyles({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#FFF",
  },
  form: {
    width: "100%",
    paddingBottom: 20,
  },
  errorMessage: {
    color: "red",
  },
  input: {
    display: "none",
  },
  buttons: {
    textAlign: "right",
    marginTop: 10,
  },
});

export interface IProfileExperienceEditProps {
  experience: Experience | undefined;
  open: any;
  handleClose: any;
  setSuccess: any;
  profileId: string;
}

const ProfileExperienceEdit: React.FC<IProfileExperienceEditProps> = ({
  open,
  handleClose,
  setSuccess,
  experience,
  profileId,
}: IProfileExperienceEditProps) => {
  const { t, i18n } = useTranslation();
  const classes = useFormStyles();

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const [showDelete, setShowDelete] = useState(false);

  const [isCurrent, setIsCurrent] = useState(false);

  const repository = new ExperienceRepository();
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { errors },
  } = useForm<Experience>({
    defaultValues: {
      company: experience ? experience.company : "",
      site: experience ? experience.site : "",
      isCurrent: experience ? experience.isCurrent : false,
      location: experience ? experience.location : "",
      title: experience ? experience.title : "",
      startedDate: experience ? experience.startedDate : new Date(),
      endDate: experience ? experience.endDate ? experience.endDate : null : null,
      details: experience ? experience.details : "",
      employmentType: experience ? experience.employmentType : "",
    },
  });

  const handleDelete = () => {
    setLoading(true);
    if (experience)
      repository
        .delete(experience?.id)
        .then(() => {
          setSuccess(experience?.id);
          setLoading(false);
          handleClose();
        })
        .catch((error) => {
          setErrorMessage(error.message);
          setLoading(false);
        });
  };

  const handleChangeIsCurrent = (checked) => {
    console.debug(checked);
    setIsCurrent(checked);
    if (checked)
      setValue("endDate", null)

  };

  useEffect(() => {
    if (experience?.isCurrent)
      setIsCurrent(true);
  }, [experience]);

  const onSubmit = (data: any) => {
    setLoading(true);

    if (data.endDate !== null && data.startedDate > data.endDate) {
      setError("startedDate", {
        message: t("Start Date must be less than End Date"),
        type: "validate",
      });
      setLoading(false);
      return;
    }



    var newexperience = new Experience();
    if (experience) {
      newexperience.id = experience.id;
    }
    newexperience.profileId = profileId;
    newexperience.company = data.company;
    newexperience.details = data.details;
    newexperience.employmentType = data.employmentType;
    if (data.endDate) newexperience.endDate = new Date(data.endDate);
    else newexperience.endDate = null;
    newexperience.isCurrent = data.isCurrent;
    newexperience.location = data.location;
    newexperience.site = data.site;
    newexperience.startedDate = new Date(data.startedDate);
    newexperience.title = data.title;

    console.debug(newexperience, data);

    repository
      .createOrUpdate(newexperience)
      .then((result) => {
        setSuccess(`${result.id} - ${new Date().toLocaleTimeString()}`);
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        setLoading(false);
      });
  };

  return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">
          {experience ? t("Edit experience") : t("Add experience")}
        </DialogTitle>
        <DialogContent>
          <Collapse in={errorMessage !== ""}>
            <Alert
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setErrorMessage("");
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              {errorMessage}
            </Alert>
          </Collapse>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Title")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  required
                  id="title"
                  autoFocus
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="title"
              render={({ message }) => (
                <span className={classes.errorMessage}>{message}</span>
              )}
            />

            <Controller
              control={control}
              name="company"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Company")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  required
                  id="company"
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="company"
              render={({ message }) => (
                <span className={classes.errorMessage}>{message}</span>
              )}
            />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start">
              <Grid item>
                <Controller
                  control={control}
                  name="startedDate"
                  render={({ field }) => (
                    <KeyboardDatePicker
                      required
                      key="startDate"
                      margin="normal"
                      id="startDate"
                      label={t("Start Date")}
                      format="DD/MM/yyyy"
                      {...field}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  )}
                />
                <br />
                <ErrorMessage
                  errors={errors}
                  name="startedDate"
                  render={({ message }) => (
                    <span className={classes.errorMessage}>{message}</span>
                  )}
                />
              </Grid>

              <Grid item>
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field }) => (
                    <KeyboardDatePicker
                      disabled={isCurrent}
                      style={{ marginLeft: 20 }}
                      key="endDate"
                      margin="normal"
                      id="endDate"
                      label={t("End Date")}
                      format="DD/MM/yyyy"
                      {...field}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  )}
                />
                <br />
                <ErrorMessage
                  errors={errors}
                  name="endDate"
                  render={({ message }) => (
                    <span className={classes.errorMessage}>{message}</span>
                  )}
                />
              </Grid>
            </Grid>

            <Controller
              control={control}
              name="isCurrent"

              render={({ field }) => (
                <FormControlLabel
                  value="isCurrent"
                  control={<Checkbox color="primary"
                    onChange={(e) => { field.onChange(e.target.checked); handleChangeIsCurrent(e.target.checked) }}
                    checked={field.value}
                  />}
                  label={t("Is current")}
                  labelPlacement="end"
                />
              )}
            />


            <Controller
              control={control}
              name="details"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Details")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  id="details"
                  multiline
                  rows={4}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="details"
              render={({ message }) => (
                <span className={classes.errorMessage}>{message}</span>
              )}
            />

            <Controller
              control={control}
              name="location"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Location")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  id="location"
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="location"
              render={({ message }) => (
                <span className={classes.errorMessage}>{message}</span>
              )}
            />

            <Controller
              control={control}
              name="site"
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t("Site")}
                  fullWidth
                  variant="standard"
                  margin="normal"
                  id="site"
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="site"
              render={({ message }) => (
                <span className={classes.errorMessage}>{message}</span>
              )}
            />

            {showDelete && (
              <DialogConfirmation
                content={t("Do you really want to delete?")}
                show={showDelete}
                handleAgree={handleDelete}
                title={t("Delete Experience")}
                handleDisagree={() => setShowDelete(false)}
              />
            )}

            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              style={{ marginTop: 10 }}>
              <div>
                {experience && (
                  <Button onClick={() => setShowDelete(true)} color="secondary">
                    {t("Delete")}
                  </Button>
                )}
              </div>
              <div>
                <Button onClick={handleClose} color="inherit">
                  {t("Cancel")}
                </Button>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      background: "#c9c9c9",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "4px",
                      padding: "12px",
                      margin: "4px 0",
                    }}>
                    <CircularProgress size={14} />
                  </div>
                ) : (
                  <Button
                    type="submit"
                    color="primary"
                    onClick={() => clearErrors()}
                    disabled={loading}>
                    {t("Save")}
                  </Button>
                )}
              </div>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
  );
};

export default ProfileExperienceEdit;

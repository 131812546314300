import { makeStyles } from "@material-ui/core";

export const useChatStyles = makeStyles((theme) => ({
  root: {
    height: "calc(100vH - 106px)",
    display: "flex",
    flexDirection: "row",
  },
  rootChat: {
    display: "flex",
    flexDirection: "column",
  },

  flexSectionContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    backgroundColor: "#F8F8F8",
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
    minWidth: 380,
    maxWidth: 380,
  },
  flexSection: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
  },
  flexColScroll: {
    flexgrow: 1,
    overflow: "auto",
    minheight: "100%",
  },

  rootMessages: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  chatMessagesflexSectionContainer: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    backgroundColor: "#fff",
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
    minWidth: "100%",
  },
  chatMessagesHeader: {
    width: "100%",
  },
  chatMessagesScroll: {
    flexgrow: 1,
    overflow: "auto",
    minheight: "100%",
  },
  chatMessagesInput: {
    width: "100%",
  },

  chatContainer: {
    position: "relative",
    display: "flex",

    minHeight: "100%",
    height: "100%",
  },
  chatListContainer: {
    position: "relative",
    height: "100%",
    backgroundColor: "#F8F8F8",
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
    minWidth: 380,
    maxWidth: 380,
    display: "flex",
  },
  chatHeader: {
    width: "100%",
  },
  chatList: {
    overflow: "auto",
  },

  sidebar: {
    backgroundColor: "#F8F8F8",
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
  },
  title: {
    fontWeight: "bold",
    color: "#000",
    width: "100%",
  },
  conversation: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 10,
  },
  contentUnread: {
    fontWeight: "bold",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  chatGridContainer: {
    height: "calc(100vh - 106px)",
    [theme.breakpoints.down("md")]: {
      height: "calc(100vh - 280px)",
    },
  },
}));

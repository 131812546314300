import { Button, Container, Grid, makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useProfileStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        position: "relative",
    },
}));

const Support = () =>{
    const classes = useProfileStyles();
    const { t, i18n } = useTranslation();

    return(<Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
        className={classes.root}>
        <Grid
            item
            xl={12}
            lg={12}
            md={12}
            sm={12}
            xs={12}
            style={{ width: "100%" }}>
            <Container maxWidth="lg" >
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start">
                    <Grid item>
                        <Typography paragraph variant="h4">
                            {t("Do you need help?")}
                        </Typography>
                        <Button variant="contained"
                            size="large"
                            color="primary"
                            target="_top"
                            rel="noopener noreferrer"
                            href={`mailto:info@Matcheam.com`}>
                            <Typography variant="button" style={{ fontSize: '0.69rem', color: 'white' }}>
                                {t("Contact us")}
                            </Typography>
                        </Button>
                    </Grid>

                </Grid>
            </Container>
        </Grid>
    </Grid>);
}

export default Support;
import { Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { Category } from "../../core/entities/Category";
import Profile from "../../core/entities/Profile";
import CategoryRepository from "../../infra/firebase/CategoryRepository";
import ProfileRepository from "../../infra/firebase/ProfileRepository";
import ProfileController from "../Profile/ProfileController";

import { geocodeByAddress } from 'react-google-places-autocomplete';
import Location from "../../core/entities/Location";
import { useProfileContext } from "../../ProfileContext";

const ProfileUpdateComponent = () => {

    const profileRepository = new ProfileRepository();
    const profileController = new ProfileController();

    const categoryRepository = new CategoryRepository();

    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [results, setResults] = useState<any[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, setLoading] = useState(true);

    const { loading: globalLoading, profile: globalProfile, isAdmin } = useProfileContext();

    useEffect(() => {
        categoryRepository.getAllCategories()
            .then(getAllCategoriesResult => {
                setCategories(getAllCategoriesResult);
                setLoading(false);
            }).catch(error => {
                console.error(error)
                setLoading(false);
            });
    }, []);

    const handleUpdate = () => {
        profileRepository.getAll().then(getAllResult => {
            setProfiles(getAllResult);
            updateProfiles(getAllResult);
        })
    }

    const handleUpdateLocation = () => {
        setResults([]);

        profileRepository.getProfilesWithLocation().then(getProfilesWithLocationResult => {
            setProfiles(getProfilesWithLocationResult);
            getProfilesForUpdateLocation(getProfilesWithLocationResult);
        })
    }

    function sleep(milliSeconds) {
        console.debug("sleeping");
        var startTime = new Date().getTime();
        while (new Date().getTime() < startTime + milliSeconds);
    }

    const getProfilesForUpdateLocation = async (profilesToUpdate: Profile[]) => {

        let profiles = profilesToUpdate.filter(profile => profile.location && profile.location.placeId === undefined);

        await updateProfileLocation(profiles);

    }

    const getLocationFromGeocode = (geocodeParam) => {
        let geocode = geocodeParam[0];

        let terms = geocode.address_components;
        let length = terms.length;

        let city = terms[length - 3] ? terms[length - 3].long_name : "";
        let state = terms[length - 2] ? terms[length - 2].long_name : "";
        let country = terms[length - 1] ? terms[length - 1].long_name : "";

        let name = (city ? city + ", " : "") + country;
        let description = geocode.formatted_address;

        const location = new Location();
        location.name = name;
        location.placeId = geocode.place_id;
        location.description = description;
        location.city = city;
        location.state = state;
        location.country = country;

        return location;

    }

    const updateProfileLocation = async (profilesToUpdate: Profile[]) => {

        const locations = profilesToUpdate.map(profile => {
            const profileLocation = profile.location as any;

            return {
                name: typeof profileLocation === "string" ? profileLocation : profileLocation.name,
                profileId: profile.id
            };
        })

        let message = "";

        setResults(results => [...results, `${locations.length} locations found`]);

        for await (const location of locations) {
            try {

                let geocode = await geocodeByAddress(location.name);

                let newLocation = getLocationFromGeocode(geocode);
                await profileRepository.setLocation(location.profileId, newLocation);

                message = `new geocode for ${location.profileId}`;
                console.debug(message);

                setResults(results => [...results, message]);
            } catch (err) {
                message = `Error ${location.profileId} - ${err}`;
                console.error(err);

                setResults(results => [...results, message]);
            }
        }





    }

    const handleShowAtSearch = () => {

        profileRepository.getHiddenProfiles().then(getAllResult => {
            setProfiles(getAllResult);
            showProfilesAtSearch(getAllResult);
        })
    }

    const showProfilesAtSearch = async (profilesToRegister: Profile[]) => {
        console.debug("Profiles to update", profilesToRegister.length);
        let count = 0;

        for await (const profile of profilesToRegister) {

            try {
                await profileRepository.setHideOnSearch(profile.id, false);
                count++;

                console.debug(`${profile.id} ${profile.nickname} updated`);
            } catch (error) {
                console.error(error);
            }

        }

        console.debug(`${count} profiles updated`);
    }


    const handleRegister = () => {
        profileRepository.getAll().then(getAllResult => {
            setProfiles(getAllResult);
            registerProfiles(getAllResult);
        })
    }

    const registerProfiles = async (profilesToRegister: Profile[]) => {
        profilesToRegister.forEach(async (profile) => {
            console.debug(`get registrations for ${profile.id}`);
            let registrations = await profileController.getRegistrations(profile.id);
            if (registrations.length == 0) {
                console.debug(`creating welcome registration for ${profile.id}`);
                profileController.createWelcomeRegistration(profile);
                console.debug(`welcome registration created for ${profile.id}`);
            }
        })
    }

    const getTranslatedNames = (categoryId) => {
        return categories.find(c => c.id === categoryId)?.translatedNames;
    }

    const getTranslatedSubCategoryName = (name, parentId) => {
        return getSubCategoryByName(name, parentId)?.translatedNames;
    }

    const getSubCategoryByName = (name, parentId) => {
        return categories.find(c => c.name === name && c.parentCategoryId === parentId);
    }




    const updateProfiles = async (profiles: Profile[]) => {

        setResults([]);

        profiles.forEach(async (profile) => {

            try {
                let profileCategories = profile.profileCategories?.map(profileCategory => {

                    profileCategory.translatedNames = getTranslatedNames(profileCategory.categoryId);

                    let skillsSubcategories: Category[] = [];

                    profileCategory.skills?.forEach(skill => {
                        let s = getSubCategoryByName(skill, profileCategory.categoryId)
                        if (s) {
                            skillsSubcategories.push({
                                id: s.id,
                                name: s.name,
                                translatedNames: s.translatedNames,
                                parentCategoryId: s.parentCategoryId
                            });
                        }
                    });

                    profileCategory.subCategories = skillsSubcategories;

                    return profileCategory;
                });

                console.debug(`atualizando ${profile.id}`);

                if (profileCategories && profileCategories.length > 0) {

                    await profileRepository.updateCategories(profile?.id, profileCategories).then(async () => {
                        console.debug('atualizado');
                    });

                }



                setResults(results => [...results, profile.id]);
            } catch (err) {
                setResults(results => [...results, `${profile.id} - ${err.message}`]);
            }


        });


    }


    return (
        <div>
            {isAdmin ?
                <div>
                    {!loading && <Button onClick={handleUpdate} >Update Profiles Categories</Button>}

                    {!loading && <Button onClick={handleUpdateLocation} >Update Profiles Locations</Button>}

                    {!loading && <Button onClick={handleRegister} >Register Profiles</Button>}

                    {!loading && <Button onClick={handleShowAtSearch} >Show profiles at search</Button>}

                    {results.map(result => {
                        return (<p key={result} >{result}</p>)

                    })}
                </div>
                :
                <></>
            }
        </div>
    );
}

export default ProfileUpdateComponent;



import { Collection } from "fireorm";

@Collection("Deals")
export default class Deal {
  id: string;

  fromProfileId: any;
  toProfileId: string;

  date: any;
  acceptanceDate: string;

  amount: number;
  currency: string;
  status: string;
  details: string;
  paymentIntentId: string;
  membersId: [string, string];
  //   invoiceId: string;
  job: string;
  local: string;
  region?: string;

  createdAt?: any;
  modifiedAt?: any;
  agentId?: string;
  talentId?: string;
  comission?: number;
}

import InviteRepository from "../../infra/firebase/InviteRepository";
import ProfileRepository from "../../infra/firebase/ProfileRepository";
import { Route, Redirect } from 'react-router'
import Profile from "../../core/entities/Profile";
import Invite from "../../core/entities/Invite";


export default class InviteController {

    inviteRepository = new InviteRepository();
    profileRepository = new ProfileRepository();


    accept = async (profileId: string, inviteId: string): Promise<boolean> => {
        try {
            const invite = await this.inviteRepository.getById(inviteId).then(invite => { return invite; });
            const profile = await this.profileRepository.getById(profileId).then(profile => { return profile; });

            if(invite.expired){
                return Promise.reject(`The token ${inviteId} is expired.`);
            }

            switch (invite.type) {
                case "agency":
                    const agent = await profile.agents?.find(a => a.id === invite.profileId);
                    console.debug('agent', agent);
                    if (agent) return Promise.resolve(false);
                    break;
                case "commom":
                default:
                    if (profile.inviter) return Promise.resolve(false);
                    break;
            }

            const accepted = await this.inviteRepository.accept(inviteId, profile)
                .then(result => console.debug(result))

            console.debug('accepted', accepted);

            switch (invite.type) {
                case "agency":
                    await this.profileRepository.addAgent(profileId, invite.profileId);
                    break;
                case "commom":
                default:
                    await this.profileRepository.addInviter(profileId, invite.profileId);
                    break;
            }

            return Promise.resolve(true);
        } catch (error) {
            return Promise.reject(error);
        }

    }
    getInfo = async (inviteId: string): Promise<{profile:Profile,invite:Invite}> => {
        try {
            const invite = await this.inviteRepository.getById(inviteId).then(invite => { return invite; });
            if(invite.expired){
                return Promise.reject(`The token ${inviteId} is expired.`);
            }
            const profile = await this.profileRepository.getById(invite.profileId).then(profile => { return profile; });
            return {
                profile,
                invite
            }
        } catch (error) {
            return Promise.reject(error);
        }
    }

}
import { Collection } from "fireorm";

@Collection("Registrations")
export default class Registration {
    id: string;

    emailAddress?: string | null;
    profileId?: string | null;

    name?: string | null;
    firstName?: string | null;
    lastName: string | null | undefined;

    audienceTag?: string | null;
    activity?: string[];

    nickname?: string | null;

    languageCode?: string | null;
    countryCode?: string | null;
    birthday: string | null;

}
import React, { useState } from 'react';
import styles from './TopBarMenu.module.scss';

import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { Avatar, Badge, Box, Button, Divider, Grid, IconButton, makeStyles, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { logout } from '../../../UserContext';
import firebase from 'firebase';
import { useProfileContext } from '../../../ProfileContext';
import { Link as LinkRouter, useHistory } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { getAvatar } from '../../../utils/utils';


const useStyles = makeStyles((theme) => ({
  avatar: {
    cursor: 'pointer'
  },
  badge: {
    marginLeft: 10
  },
  text: {
    color: '#868686'
  },
  label: {
    textTransform: 'initial',
    color: theme.palette.text.primary

  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const TopBarMenu = () => {
  const { error, loading, profile } = useProfileContext();
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    handleClose();
    history.push('/login');
  }

  const handleProfile = () => {
    handleClose();
    history.push('/profile');
  }

  return (


    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {
        (!loading && profile) &&
        <div>

          <Avatar alt="Avatar" src={profile.profileImageUrl ? profile.profileImageUrl : getAvatar(profile?.name)} onClick={handleClick} className={classes.avatar} />


          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            style={{marginTop: 50}}
          >
            <MenuItem component={RouterLink} to='/chats' className={classes.sectionMobile} onClick={handleClose}>{t("Chats")}</MenuItem>
            {(!loading && profile) && <MenuItem component={RouterLink} to={`/${profile.nickname}`} onClick={handleClose}>{t("Profile")}</MenuItem>}
            <MenuItem component={RouterLink} to='/settings' onClick={handleClose}>{t("Settings")}</MenuItem>
            <Divider orientation='horizontal' variant='fullWidth' />
            <MenuItem onClick={handleLogout} >{t("Logout")}</MenuItem>
          </Menu>

        </div>
      }
    </Grid>

  )
};

export default TopBarMenu;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Category } from "../../../core/entities/Category";
import CloseIcon from "@material-ui/icons/Close";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { Attribute } from "../../../core/entities/Attribute";
import AttributeRepository from "../../../infra/firebase/AttributeRepository";
import { FiPlusCircle, FiMinusCircle } from "react-icons/fi";

const useFormStyles = makeStyles({
  buttons: {
    textAlign: "right",
    marginTop: 10,
  },
  form: {
    width: "100%",
    paddingBottom: 20,
  },
  errorMessage: {
    color: "red",
  },
});

export interface IAttributesEditProps {
  open: boolean;
  handleClose: any;
  setSuccess: any;
  attribute?: Attribute | null;
}

const repository = new AttributeRepository();

const AttributesEdit = ({
  open,
  handleClose,
  attribute,
  setSuccess,
}: IAttributesEditProps) => {
  const classes = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const types = ["text", "select", "number", "checkbox"];
  const { t, i18n } = useTranslation();
  const [options, setOptions] = useState(attribute?.options?.length || 0);
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: attribute?.name || "",
      displayName: {
        pt: attribute?.displayName.pt || "",
        en: attribute?.displayName.en || "",
        es: attribute?.displayName.es || "",
        de: attribute?.displayName.de || "",
      },
      options: attribute?.options || [],
      type: attribute?.type,
      unit: attribute?.unit,
      order: attribute?.uses?.profileAttributes || 0,
      multiple: attribute?.multiple || false,
      maxLength: attribute?.maxLength,
      minLength: attribute?.minLength,
      decimal: attribute?.decimal,
      others: attribute?.others || false,
      min: attribute?.min,
      max: attribute?.max,
    },
  });

  const onSubmit = (data: any) => {
    console.log(data);
    setLoading(true);

    const actionMethod = attribute ? "atualizada" : "criada";

    if (!attribute) attribute = new Attribute();
    attribute.name = data.name;
    attribute.displayName = data.displayName;
    attribute.options = data?.options || [];
    attribute.maxLength = data?.maxLength;
    attribute.minLength = data?.minLength;
    attribute.decimal = data?.decimal;
    attribute.others = data?.others;
    attribute.type = data?.type;
    attribute.unit = data?.unit;
    attribute.multiple = data?.multiple;
    attribute.uses = { profileAttributes: Number(data.order) };
    attribute.min = Number(data?.min);
    attribute.max = Number(data?.max);

    repository
      .createOrUpdate(attribute)
      .then((result) => {
        setSuccess(
          `Categoria '${attribute?.name}' ${actionMethod} com sucesso.`
        );
        setLoading(false);
        handleClose();
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
        setErrorMessage(error.message);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown
      disableBackdropClick>
      <DialogTitle id="form-dialog-title">Atributo</DialogTitle>
      <DialogContent>
        <Collapse in={errorMessage !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {errorMessage}
          </Alert>
        </Collapse>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <TextField
                {...field}
                label="Nome"
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="name"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <Controller
            control={control}
            name="displayName.en"
            render={({ field }) => (
              <TextField
                {...field}
                label="Display name (en)"
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="displayName.en"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="displayName.en"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <Controller
            control={control}
            name="displayName.es"
            render={({ field }) => (
              <TextField
                {...field}
                label="Display name (es)"
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="displayName.es"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="displayName.es"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <Controller
            control={control}
            name="displayName.de"
            render={({ field }) => (
              <TextField
                {...field}
                label="Display name (de)"
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="displayName.de"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="displayName.de"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <Controller
            control={control}
            name="displayName.pt"
            render={({ field }) => (
              <TextField
                {...field}
                label="Display name (pt)"
                fullWidth
                variant="standard"
                margin="normal"
                required
                id="displayName.pt"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="displayName.pt"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <Controller
            control={control}
            name="unit"
            render={({ field }) => (
              <TextField
                {...field}
                label="Unidade"
                fullWidth
                variant="standard"
                margin="normal"
                id="unit"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="unit"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <FormControl fullWidth>
            <InputLabel htmlFor="language">Tipo</InputLabel>
            <Controller
              control={control}
              name="type"
              render={({ field: props }) => (
                <Select
                  {...props}
                  id="type"
                  fullWidth
                  required
                  autoFocus
                  onChange={(e) => {
                    props.onChange(e.target.value);
                  }}>
                  {types.map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <Controller
            control={control}
            name="maxLength"
            render={({ field }) => (
              <TextField
                {...field}
                label="Tamanho máximo"
                fullWidth
                variant="standard"
                margin="normal"
                type="number"
                id="maxLength"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="maxLength"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <Controller
            control={control}
            name="minLength"
            render={({ field }) => (
              <TextField
                {...field}
                label="Tamanho mínimo"
                fullWidth
                variant="standard"
                margin="normal"
                type="number"
                id="minLength"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="minLength"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <Controller
            control={control}
            name="max"
            render={({ field }) => (
              <TextField
                {...field}
                label="Valor máximo"
                fullWidth
                variant="standard"
                margin="normal"
                type="number"
                id="max"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="max"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <Controller
            control={control}
            name="min"
            render={({ field }) => (
              <TextField
                {...field}
                label="Valor mínimo"
                fullWidth
                variant="standard"
                margin="normal"
                type="number"
                id="min"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="min"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <Controller
            control={control}
            name="decimal"
            render={({ field }) => (
              <TextField
                {...field}
                label="Casas decimais"
                fullWidth
                variant="standard"
                margin="normal"
                type="number"
                id="decimal"
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="decimal"
            render={({ message }) => (
              <span className={classes.errorMessage}>{message}</span>
            )}
          />
          <FormControlLabel
            label="Outras respostas"
            control={
              <Controller
                control={control}
                name="others"
                render={({ field }) => <Checkbox {...field} />}
              />
            }
          />
          <FormControlLabel
            label="Seleção de multipla escolha"
            control={
              <Controller
                control={control}
                name="multiple"
                render={({ field }) => <Checkbox {...field} />}
              />
            }
          />
          <br />
          <span>Opções</span>
          {new Array(options).fill(0).map((_, i: number) => (
            <>
              <Controller
                control={control}
                name={`options.${i}.pt` as `options.${number}.pt`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={`Opção ${i + 1} (pt)`}
                    fullWidth
                    variant="standard"
                    margin="normal"
                    required
                    id={`option${i}pt`}
                  />
                )}
              />
              <Controller
                control={control}
                name={`options.${i}.en` as `options.${number}.en`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={`Opção ${i + 1} (en)`}
                    fullWidth
                    variant="standard"
                    margin="normal"
                    required
                    id={`option${i}en`}
                  />
                )}
              />
              <Controller
                control={control}
                name={`options.${i}.es` as `options.${number}.es`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={`Opção ${i + 1} (es)`}
                    fullWidth
                    variant="standard"
                    margin="normal"
                    required
                    id={`option${i}es`}
                  />
                )}
              />
              <Controller
                control={control}
                name={`options.${i}.de` as `options.${number}.de`}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label={`Opção ${i + 1} (de)`}
                    fullWidth
                    variant="standard"
                    margin="normal"
                    required
                    id={`option${i}de`}
                  />
                )}
              />
            </>
          ))}
          <IconButton color="primary" onClick={() => setOptions(options + 1)}>
            <FiPlusCircle />
          </IconButton>
          <IconButton color="primary" onClick={() => setOptions(options - 1)}>
            <FiMinusCircle />
          </IconButton>
          {/* <br /> */}
          {/* Refactor USES */}
          {/* <span>Usos</span> */}
          {/* {new Array(uses).fill(0).map((_, i) => (
            <> */}
          <Controller
            control={control}
            name="order"
            render={({ field }) => (
              <TextField
                {...field}
                label={`Ordem`}
                fullWidth
                variant="standard"
                margin="normal"
                type="number"
                required
                // value={order}
                // onChange={(e) => setOrder(e.target.value)}
                id={`order`}
              />
            )}
          />
          {/* </>
          ))}
          <IconButton color="primary" onClick={() => setUses(uses + 1)}>
            <FiPlusCircle />
          </IconButton>
          <IconButton color="primary" onClick={() => setUses(uses - 1)}>
            <FiMinusCircle />
          </IconButton> */}
          <div className={classes.buttons}>
            <Button onClick={handleClose} color="inherit" disabled={loading}>
              {t("Cancel")}
            </Button>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  background: "#c9c9c9",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "4px",
                  padding: "12px",
                  margin: "4px 0",
                }}>
                <CircularProgress size={14} />
              </div>
            ) : (
              <Button
                type="submit"
                color="primary"
                onClick={() => clearErrors()}
                disabled={loading}>
                {t("Save")}
              </Button>
            )}
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AttributesEdit;

import { makeStyles, Theme, createStyles, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";




const useFormStyles = makeStyles((theme: Theme) =>
    createStyles({

        closeButton: {
            position: "absolute",
            right: 5,
            top: 5,
            color: theme.palette.grey[500],
            [theme.breakpoints.down("md")]: {
                position: "relative",
                justifyContent: "flex-end",
                marginLeft: "auto",
            },
        },
    })
);

export interface ICloseButtonProps {
    handleClose: any;
}

const CloseButton = ({ handleClose }: ICloseButtonProps) => {
    const classes = useFormStyles();

    return (
        <IconButton
            className={classes.closeButton}
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}>
            <CloseIcon fontSize="inherit" />
        </IconButton>
    )
}

export default CloseButton;
import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { useProfileContext } from "../../../ProfileContext";
import { useState } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ProfileRepository from "../../../infra/firebase/ProfileRepository";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      paddingRight: "8px",
    },
    name: {
      fontSize: "1.2rem",
      margin: 0,
    },
  })
);

const profileRepository = new ProfileRepository();
export default function SelectProfileOccupations({
  open,
  handleClose,
  action,
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { profile } = useProfileContext();
  const [occupations, setOccupations] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const handleSaveOccupation = async () => {
    setErrorMessage("");
    if (profile) {
      setIsLoading(true);
      await profileRepository.setOccupations(profile?.id, occupations);
      handleClose();
      action();
    } else {
      setErrorMessage(
        t("Unable to save occupations, please refresh the page and try again.")
      );
    }
    setIsLoading(false);
  };

  const handleChange = (event, newOccupations) => {
    setOccupations(newOccupations);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {profile ? (
        <>
          <div className={classes.title}>
            <DialogTitle>{t("Select your occupations")}</DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span className={classes.name}>{profile?.name}</span>
              <br />
              {t("Let us know what occupations better suit you.")}
              {errorMessage && (
                <span style={{ color: "red" }}>{errorMessage}</span>
              )}
              <br />
              <br />
              <ToggleButtonGroup
                color="primary"
                value={occupations}
                onChange={handleChange}>
                <ToggleButton value="Talent">{t("Talent")}</ToggleButton>
                <ToggleButton value="Agent">{t("Agent")}</ToggleButton>
                <ToggleButton value="Employer">{t("Employer")}</ToggleButton>
                <ToggleButton value="Crew">{t("Crew")}</ToggleButton>
              </ToggleButtonGroup>
            </DialogContentText>
            <DialogActions>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    background: "#c9c9c9",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    padding: "12px",
                    margin: "4px 0",
                  }}>
                  <CircularProgress size={14} />
                </div>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSaveOccupation}
                  autoFocus>
                  {t("Save")}
                </Button>
              )}
            </DialogActions>
          </DialogContent>
        </>
      ) : (
        <CircularProgress style={{ margin: "16px" }} />
      )}
    </Dialog>
  );
}

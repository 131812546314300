import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  withRouter,
} from "react-router-dom";

import TopBar from "./components/TopBar/TopBar";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "./Theme";
import { CssBaseline } from "@material-ui/core";

import firebase from "firebase/app";
import "firebase/auth";
import { FirebaseAuthProvider } from "@react-firebase/auth";

import { FirestoreProvider } from "@react-firebase/firestore";

import { config } from "./infra/firebase/firebasecofig";
import Login from "./components/Login/Login";

import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ProfileProvider } from "./ProfileContext";

import { useEffect, useState } from "react";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import PrivateRoute from "./infra/PrivateRoute";
import Search from "./components/Search/Search";
import Profile from "./components/Profile/Profile";
import Notifications from "./components/Notifications/Notifications";
import Jobs from "./components/Jobs/Jobs";
import Categories from "./components/Categories/Categories";
import Chat from "./components/Chat/Chat";
import Settings from "./components/Settings/Settings";
import { Link, animateScroll as scroll } from "react-scroll";
import ProfileUpdateComponent from "./components/adm/ProfileUpdateComponent";
import DocumentMeta from "react-document-meta";
import TermsAndConditions from "./components/Support/TermsAndConditions";
import PrivacyPolicy from "./components/Support/PrivacyPolicy";
import Support from "./components/Support/Support";
import ShowDocumentPage from "./components/Support/ShowDocument/ShowDocumentPage";
import AcceptInvite from "./components/Login/AcceptInvite";
import Attributes from "./components/Attributes/Attributes";

const meta = {
  title: "Matcheam",
  description:
    "Actors, models, musicians, athletes, photographers, extras, influencers, and many others. Enter and feel at ease in the biggest global showcase of talent and opportunities.",
  canonical: "https://matcheam.com",
  meta: {
    charset: "utf-8",
    name: {
      keywords:
        "Matcheam,talent,actors,models,musicians,athletes,photographers,extras,influencers,agent,agency,contractor",
    },
  },
};

const Main = withRouter(({ location }) => {
  const [authentication, setAuthState] = useState({
    authenticated: false,
    initializing: true,
  });

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {

      if (user) {
        setAuthState({
          authenticated: true,
          initializing: false,
        });
      } else {
        setAuthState({
          authenticated: false,
          initializing: false,
        });
      }
    });

    return () => unsubscribe();
  }, [setAuthState]);

  const requestNotification = async () => {
    if (Notification.permission !== "granted") {
      const response = await Notification.requestPermission();

      if (response !== "granted") {
        requestNotification();
      }
    }
  };

  const homeUnsigned =
    location.pathname === "/" && !authentication.authenticated;
  const homeSigned = location.pathname !== "/" && authentication.authenticated;
  const whatever = location.pathname !== "" && authentication.authenticated;

  return (
    <DocumentMeta {...meta}>
      <div>
        {location.pathname !== "/login" &&
          location.pathname !== "/register" &&
          location.pathname !== "/forgotpassword" &&
          (!homeUnsigned || whatever) && <TopBar />}

        {location.pathname === "/" &&
          !authentication.authenticated &&
          !authentication.initializing && <Login step="register" />}

        <Switch>
          <Route exact path="/login">
            <Login step="login" />
          </Route>
          <Route exact path="/register">
            <Login step="register" />
          </Route>
          <Route exact path="/forgotpassword">
            <Login step="recover-password" />
          </Route>
          <Route exact path="/acceptinvite">
            <AcceptInvite />
          </Route>

          <Route path="/search" exact component={Search} />

          <PrivateRoute
            exact
            path="/profile"
            isAuthenticated={authentication.authenticated}
            isInitializing={authentication.initializing}
            component={Profile}
          />
          <PrivateRoute
            exact
            path="/notifications"
            isAuthenticated={authentication.authenticated}
            isInitializing={authentication.initializing}
            component={Notifications}
          />
          <PrivateRoute
            exact
            path="/jobs"
            isAuthenticated={authentication.authenticated}
            isInitializing={authentication.initializing}
            component={Jobs}
          />
          <PrivateRoute
            exact
            path="/categories"
            isAuthenticated={authentication.authenticated}
            isInitializing={authentication.initializing}
            component={Categories}
          />
          <PrivateRoute
            exact
            path="/attributes"
            isAuthenticated={authentication.authenticated}
            isInitializing={authentication.initializing}
            component={Attributes}
          />
          <PrivateRoute
            exact
            path="/chats"
            isAuthenticated={authentication.authenticated}
            isInitializing={authentication.initializing}
            component={Chat}
          />
          <PrivateRoute
            exact
            path="/chats/:chatId"
            isAuthenticated={authentication.authenticated}
            isInitializing={authentication.initializing}
            component={Chat}
          />
          <PrivateRoute
            exact={false}
            path="/settings"
            isAuthenticated={authentication.authenticated}
            isInitializing={authentication.initializing}
            component={Settings}
          />

          <PrivateRoute
            exact={false}
            path="/update-all"
            isAuthenticated={authentication.authenticated}
            isInitializing={authentication.initializing}
            component={ProfileUpdateComponent}
          />

          {/* <PrivateRoute exact={false} path="/update-all" isInitializing={authentication.initializing} isAuthenticated={authentication.authenticated} component={ProfileUpdateComponent} /> */}

          <Route path="/support" children={<Support />} exact />
          {/* <Route path="/privacypolicy" children={<PrivacyPolicy />} exact />
          <Route path="/termsandconditions" children={<TermsAndConditions />} exact /> */}

          <Route
            path="/termsandconditions"
            children={<ShowDocumentPage documentName="termsAndConditions" />}
            exact
          />
          <Route
            path="/privacypolicy"
            children={<ShowDocumentPage documentName="privacyPolicy" />}
            exact
          />
          <Route
            path="/impressum"
            children={<ShowDocumentPage documentName="impressum" />}
            exact
          />

          <Route path="/profile/:nickname" children={<Profile />} exact />
          <Route path="/:nickname" children={<Profile />} exact />
        </Switch>

        {(location.pathname === "/" ||
          location.pathname === "/login" ||
          location.pathname === "/register" ||
          location.pathname === "/forgotpassword") && (
          <div>
            <Home />
            <Footer />
          </div>
        )}
      </div>
    </DocumentMeta>
  );
});

function App() {
  return (
    <FirebaseAuthProvider firebase={firebase} {...config}>
      <FirestoreProvider {...config} firebase={firebase}>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
              <ScrollToTop />
              <CssBaseline />
              <ProfileProvider>
                <Main />
              </ProfileProvider>
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </FirestoreProvider>
    </FirebaseAuthProvider>
  );
}

export function ScrollToTop() {
  const { pathname, key, search } = useLocation();

  useEffect(() => {
    //window.scrollTo(0, 0);
    scroll.scrollToTop();
  }, [key]);

  return null;
}

export default App;

import {
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { useProfileContext } from "../../../ProfileContext";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      paddingRight: "8px",
    },
    name: {
      fontSize: "1.2rem",
      margin: 0,
    },
  })
);

export default function ProfileOnboarding({ open, handleClose, action }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { profile } = useProfileContext();

  const handleUploadPhoto = () => {
    handleClose();
    action();
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      {profile ? (
        <>
          <div className={classes.title}>
            <DialogTitle>{t("Welcome to Matcheam")}</DialogTitle>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <span className={classes.name}>{profile?.name}</span>
              <br />
              {t(
                "Let's get started with your profile. First you can add your profile picture, so people can better tell who you are."
              )}
            </DialogContentText>
            <DialogActions>
              <Button onClick={handleClose}>{t("Not now")}</Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleUploadPhoto}
                autoFocus>
                {t("Select profile picture")}
              </Button>
            </DialogActions>
          </DialogContent>
        </>
      ) : (
        <CircularProgress style={{ margin: "16px" }} />
      )}
    </Dialog>
  );
}

import {
  makeStyles,
  Theme,
  createStyles,
  Typography,
  Slider,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import { IPostMedia } from "../IPostMedia";
import styles from "./PostPhotoCroper.module.scss";

const useImageEditorStyles = makeStyles((theme: Theme) =>
  createStyles({
    cropContainer: {
      position: "relative",
      width: "100%",
      // height: 400,
      background: "#333",
      // [theme.breakpoints.up("sm")]: {
      //   height: 100,
      //   width: 100,
      // },
      // [theme.breakpoints.up("md")]: {
      //   height: 300,
      //   width: 300,
      // },
    },
    cropButton: {
      flexShrink: 0,
      marginLeft: 16,
    },
    controls: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
      },
    },
    sliderContainer: {
      display: "flex",
      flex: "1",
      alignItems: "center",
    },
    sliderLabel: {
      [theme.breakpoints.down("xs")]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: "22px 0px",
      marginLeft: 16,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
        margin: "0 16px",
      },
    },
    buttons: {
      textAlign: "right",
      marginTop: 10,
    },
    dialogCustomizedWidth: {
      "max-width": "80%",
    },
  })
);

export interface IPostPhotoCroperProps {
  updateImage: any;
  image: IPostMedia | undefined;
}

const PostPhotoCroper = ({ image, updateImage }: IPostPhotoCroperProps) => {
  const classes = useImageEditorStyles();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [file, setFile] = useState<File>();

  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [imageSrc, setImageSrc] = useState(image?.src);
  const [crop, setCrop] = useState(image?.crop ? image?.crop : { x: 0, y: 0 });
  const [rotation, setRotation] = useState<any>(0);
  const [zoom, setZoom] = useState<any>(image?.zoom ? image?.zoom : 1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(
    image?.croppedAreaPixels ? image?.croppedAreaPixels : null
  );
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback(
    (croppedArea, croppedAreaPixels) => {
      updateImage(imageSrc, croppedAreaPixels, zoom, crop);
      setCroppedAreaPixels(croppedAreaPixels);
    },
    [updateImage, imageSrc, zoom, crop]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        minWidth: 280,
        minHeight: 350,
      }}>
      <div className={classes.cropContainer} style={{ minHeight: 350 }}>
        <Cropper
          style={{
            containerStyle: { minHeight: 280, minWidth: 280, maxWidth: 500 },
            cropAreaStyle: { minHeight: 280, minWidth: 280, maxWidth: 500 },
          }}
          image={imageSrc}
          crop={crop}
          cropShape="rect"
          rotation={rotation}
          zoom={zoom}
          aspect={1 / 1}
          onCropChange={setCrop}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>

      <div className={classes.controls}>
        <div className={classes.sliderContainer}>
          <Typography
            variant="overline"
            classes={{ root: classes.sliderLabel }}>
            Zoom
          </Typography>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            classes={{ root: classes.slider }}
            onChange={(e, zoom) => setZoom(zoom)}
          />
        </div>
      </div>
    </div>
  );
};

export default PostPhotoCroper;

import Profile, {
  HierarchicalCategory,
  TranslatedHierarchicalCategory,
  ProfileCategory,
} from "./../../core/entities/Profile";
import { Chat } from "../../core/entities/Chat";
import ChatRepository from "../../infra/firebase/ChatRepository";
import firebase, { functions } from "../../infra/firebase/firebasecofig";
import {
  TranslatedNames,
  TranslatedNamesArray,
} from "../../core/entities/Category";
import Registration from "../../infra/mailchimp/Registration";
import RegistrationRepository from "../../infra/mailchimp/RegistrationRepository";
import ProfileRepository from "../../infra/firebase/ProfileRepository";
import Moderation from "../../core/entities/Moderation";

export default class ProfileController {
  chatRepository = new ChatRepository();
  profileRepository = new ProfileRepository();
  registrationRepository = new RegistrationRepository();

  setShowAtHome = (profileId: string, showAtHome: boolean) => {
    return this.profileRepository.updateProfile(profileId, {
      showAtHome: showAtHome,
    });
  };

  verifyIfExistsModerationOnField = (
    field: string,
    moderations: Moderation[]
  ): boolean => {
    return moderations.findIndex((m) => m.field === field) !== -1;
  };

  //deve ser migrado para uma function
  nicknameIsAReserverdWord = (nickname: string): boolean => {
    const reservedWords = [
      "login",
      "register",
      "forgotpassword",
      "search",
      "profile",
      "notifications",
      "jobs",
      "categories",
      "chats",
      "settings",
      "support",
      "privacypolicy",
      "impressum",
    ];

    return reservedWords.findIndex((r) => r === nickname) !== -1;
  };

  getAgents = (profileId: string): Promise<Profile[]> => {
    return this.profileRepository.getAgents(profileId);
  };

  updateProfileRegistration = (profile: Profile) => {
    return this.createRegistration(profile, "update");
  };

  updateEmail = (profileId: string, email: string) => {
    return this.profileRepository.updateProfileEmail(profileId, email);
  };

  createWelcomeRegistration = (profile: Profile) => {
    return this.createRegistration(profile, "send_welcome_email");
  };

  getRegistrations = (profileId) => {
    return this.registrationRepository.getByProfileId(profileId);
  };

  createRegistration = (profile: Profile, activity: string) => {
    const registration = new Registration();
    registration.audienceTag = process.env.REACT_APP_AUDIENCE_TAG;
    registration.emailAddress = profile.emailAddress;
    registration.firstName = profile.firstName;
    registration.name = profile.name;
    registration.lastName = profile.lastName;
    registration.nickname = profile.nickname;
    registration.activity = [activity];

    return this.registrationRepository.create(registration);
  };

  createChat = (
    fromProfileId: string,
    toProfileId: string
  ): Promise<string> => {
    var chatCreateFunction = functions.httpsCallable("chats-onCallChatCreate");

    return chatCreateFunction({ from: fromProfileId, to: toProfileId })
      .then((result) => {
        return result.data.id;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  verifyIfAChatExistis = async (
    fromProfileId: string,
    toProfileId: string
  ): Promise<Chat | undefined> => {
    const toProfileAgents = await this.profileRepository.getAgents(toProfileId);

    if (toProfileAgents.length > 0) {
      const chatWithAgent = await this.chatRepository.findByAgentAndTalent(
        toProfileAgents[0].id,
        toProfileId,
        fromProfileId
      );

      return chatWithAgent || undefined;
    }

    return this.chatRepository
      .getProfileChatByMember(fromProfileId, toProfileId)
      .then((chats) => {
        const result = chats.find((chat) => chat.membersId.length === 2);

        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  getOnlySkills = (category: ProfileCategory) => {
    const subcategories = category.subCategories;
    const skills = category.skills;

    let onlySkills: string[] = [];

    skills?.forEach((skill) => {
      if (
        !subcategories?.some(
          (subcategory) =>
            subcategory.name.toLocaleLowerCase() ===
            skill.toLocaleLowerCase() ||
            subcategory.translatedNames?.de?.toLocaleLowerCase() ===
            skill.toLocaleLowerCase() ||
            subcategory.translatedNames?.en?.toLocaleLowerCase() ===
            skill.toLocaleLowerCase() ||
            subcategory.translatedNames?.es?.toLocaleLowerCase() ===
            skill.toLocaleLowerCase() ||
            subcategory.translatedNames?.pt?.toLocaleLowerCase() ===
            skill.toLocaleLowerCase()
        )
      )
        onlySkills.push(skill);
    });

    return onlySkills;
  };
}

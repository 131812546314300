import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Button,
  Box,
  Typography,
  makeStyles,
  CircularProgress,
} from "@material-ui/core";
import styles from "./SelectPaymentDialog.module.scss";
import { useEffect, useState } from "react";
import ProfileRepository from "../../../infra/firebase/ProfileRepository";
import { functions } from "../../../infra/firebase/firebasecofig";
import DealsRepository from "../../../infra/firebase/DealsRepository";
import { useTranslation } from "react-i18next";
import firebase from "firebase";
import VerifyEmailDialog from "../../shared/VerifyEmailDialog";
import Deal from "../../../core/entities/Deal";
import { useProfileContext } from "../../../ProfileContext";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";

import AmexLogo from "../../../images/cards/american_express.png";
import ChinaUnionLogo from "../../../images/cards/china_union.png";
import DiscoverLogo from "../../../images/cards/discover.png";
import JcbLogo from "../../../images/cards/jcb.png";
import MastercardLogo from "../../../images/cards/mastercard2.png";
import VisaLogo from "../../../images/cards/visa_electron.png";
import Skeleton from "@material-ui/lab/Skeleton";
import Profile from "../../../core/entities/Profile";

interface SelectPaymentDialogProps {
  isOpen: boolean;
  handleClose(): void;
  profileId: string;
  deal?: Deal;
}

interface Card {
  id: string;
  name: string;
  brand: string;
  expirationMonth: number;
  expirationYear: number;
  last4: number;
}

const cardsImages = {
  amex: AmexLogo,
  unionpay: ChinaUnionLogo,
  discover: DiscoverLogo,
  jcb: JcbLogo,
  mastercard: MastercardLogo,
  visa: VisaLogo,
};

const useStyles = makeStyles((theme) => ({
  toogleCard: {
    width: "100%",
    border: "none",
  },
  cardSelect: {
    marginBottom: "0.5rem",
    padding: "1rem",
    borderRadius: "1rem",
    background: "#94949444",
    cursor: "pointer",
    transition: "all 0.3s",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "&:hover": {
      background: "#94949499",
    },
  },
  brandContainer: {
    display: "flex",
    alignItems: "center",
  },
  buttonAddCard: {
    backgroundColor: "#FFF",
    borderColor: theme.palette.text.disabled,
    borderRadius: 8,
    background: "none",
    width: "100%",
    cursor: "pointer",
    border: "1px solid #dcdcdc",
    alignItems: "center",
    transition: "all 200ms ease-in-out",
  },
  buttonCreatePaymentIntent: {
    width: "100%",
    margin: "0.5rem 0",
  },
  cardContainer: {
    paddingLeft: 20,
    paddingTop: 16,
    paddingBottom: 16,
    backgroundColor: "#FFF",
    borderColor: theme.palette.text.disabled,
    borderRadius: 8,
    background: "none",
    textAlign: "left",
    width: "100%",
    cursor: "pointer",
    border: "1px solid #dcdcdc",
    alignItems: "center",
    transition: "all 200ms ease-in-out",
  },

  toogleCardRoot: {
    backgroundColor: "#FFF",
    border: "none",
    padding: 0,
    margin: 0,
    marginBottom: 5,
    "& $cardContainer": {
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        color: "#FFF",
      },
    },
  },
  toogleCardSelected: {
    backgroundColor: "#FFF",
    "&:hover": {
      backgroundColor: "#FFF",
    },
    "& $cardContainer": {
      backgroundColor: "#FFF",
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      border: "1px solid",

      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}));

const profileRepository = new ProfileRepository();
const dealRepository = new DealsRepository();
export default function SelectPaymentDialog({
  isOpen,
  handleClose,
  deal,
}: SelectPaymentDialogProps) {
  const classes = useStyles();
  const [cards, setCards] = useState<Card[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);
  const { loading, profile } = useProfileContext();
  useEffect(() => {
    console.debug("fetchCards");

    fetchCards();
  }, []);

  const { t, i18n } = useTranslation();
  const [isVerifyEmailOpen, setIsVerifyEmailOpen] = useState(false);

  const [selectedCard, setSelectedCard] = useState("");

  const createCustomer = async (profile: Profile): Promise<string> => {
    const caller = functions.httpsCallable("profiles-onCallCreateCustomer");
    const response = await caller({
      id: profile.id,
      region: deal?.region,
    });
    const customerId = response.data.stripeCustomerId;
    return customerId;
  };

  const fetchCards = async () => {
    if (!profile) return;

    setIsCardLoading(true);

    try {
      const customerId = await createCustomer(profile);

      const listPaymentMethods = functions.httpsCallable(
        "payments-onCallListPaymentMethods"
      );

      const listPaymentMethodsResponse = await listPaymentMethods({
        stripeCustomerId: customerId,
        region: deal?.region,
      });

      const cardsList = listPaymentMethodsResponse.data.map((pm) => ({
        id: pm.id,
        name: pm.billing_details.name,
        brand: pm.card.brand,
        expirationMonth: pm.card.exp_month,
        expirationYear: pm.card.exp_year,
        last4: pm.card.last4,
      }));
      setCards(cardsList);
    } catch (err) {
      console.error(err);
    } finally {
      setIsCardLoading(false);
    }
  };

  const handleCreatePaymentIntent = async () => {
    if (!profile) return;

    try {
      if (deal) {
        setIsLoading(true);
        const customerId = await createCustomer(profile);

        deal.status = "Accepted";

        await dealRepository.update(deal);

        const caller = functions.httpsCallable("payments-onCallCreateIntent");
        const response = await caller({
          stripeCustomerId: customerId,
          paymentMethod: selectedCard,
          dealId: deal.id,
          region: deal.region,
        });

        setIsAccepted(true);
        setIsLoading(false);
      }
    } catch (err) {
      console.debug(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaywithBoleto = async () => {
    if (!profile) return;

    if (!firebase.auth().currentUser?.emailVerified) {
      setIsVerifyEmailOpen(true);
      return;
    }

    try {
      if (deal) {
        setIsLoading(true);
        const customerId = await createCustomer(profile);

        deal.status = "Accepted";

        await dealRepository.update(deal);

        const caller = functions.httpsCallable(
          "payments-onCallCreateBoletoSession"
        );
        const response = await caller({
          deal: deal,
          successUrl: window.location.href,
          cancelUrl: window.location.href,
        });

        const url = response.data.url;
        window.open(url, "_blank");
        setIsAccepted(true);
      }
    } catch (err) {
      console.debug(err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddCard = async () => {
    if (!profile) return;

    if (!firebase.auth().currentUser?.emailVerified) {
      setIsVerifyEmailOpen(true);
      return;
    }

    try {
      setIsLoading(true);
      const customerId = await createCustomer(profile);

      const caller = functions.httpsCallable(
        "payments-onCallCreateCheckoutSession"
      );
      const response = await caller({
        stripeCustomerId: customerId,
        successUrl: window.location.href,
        cancelUrl: window.location.href,
        region: deal?.region,
      });

      console.debug(response, "PAYMENT RESPONSE");

      const url = response.data.url;
      window.open(url, "_blank");
      setIsLoading(false);
    } catch (err) {
      console.debug(err);
    }
  };

  const handleCloseAndReset = () => {
    setIsAccepted(false);
    handleClose();
  };

  const handleChangeCard = async (event, newCard) => {
    setSelectedCard(newCard);
  };

  const SelectPaymentMethod = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {!isCardLoading ? (
            <ToggleButtonGroup
              size="large"
              value={selectedCard}
              exclusive
              onChange={handleChangeCard}
              orientation="vertical"
              className={classes.toogleCard}>
              {cards.map((card) => (
                <ToggleButton
                  value={card.id}
                  classes={{
                    selected: classes.toogleCardSelected,
                    root: classes.toogleCardRoot,
                  }}>
                  <Box
                    // onClick={() => handleCreatePaymentIntent(card.id)}
                    className={classes.cardContainer}
                    key={card.id}>
                    <div
                      style={{
                        display: "flex",
                        gap: 16,
                        alignItems: "center",
                      }}>
                      <div>
                        <img src={cards[card.brand]} width="32px" />
                      </div>
                      <Typography
                        variant="body1"
                        style={{ textTransform: "capitalize" }}>
                        {card.brand}
                      </Typography>
                    </div>
                    <Typography variant="body1" display="inline">
                      .... {card.last4}
                    </Typography>
                    &nbsp;&nbsp;
                    <Typography variant="body1" display="inline">
                      {card.expirationMonth}/{card.expirationYear}
                    </Typography>
                  </Box>
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          ) : (
            <>
              <Skeleton
                variant="rect"
                style={{
                  borderRadius: 10,
                  height: 64,
                  width: "100%",
                  marginBottom: 2,
                }}
              />
              <Skeleton
                variant="rect"
                style={{
                  borderRadius: 10,
                  height: 64,
                  width: "100%",
                }}
              />
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          {/* <span
          style={{ textJustify: "inter-word", textAlign: "justify" }}>
          By clicking on <u>Add Card</u> you will be redirected to a
          external page, where you will be able to register a credit
          card. When this process is finished you will be brought back
          to the chat, please select the proposal againg and select the
          desired card to proceed with the accpetance of the proposal.
        </span> */}
          <Button
            type="button"
            color="primary"
            className={classes.buttonAddCard}
            onClick={handleAddCard}>
            {t("Add a new card")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            type="button"
            color="primary"
            variant="contained"
            disabled={selectedCard === ""}
            className={classes.buttonCreatePaymentIntent}
            onClick={handleCreatePaymentIntent}>
            {t("Accept proposal and pay with card")}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            className={classes.buttonCreatePaymentIntent}
            onClick={handlePaywithBoleto}>
            {t("Pay via boleto")}
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog open={isOpen} onClose={handleCloseAndReset}>
      <DialogTitle id="form-dialog-title" className="titleProposal">
        {t("Select a payment method")}
      </DialogTitle>
      <DialogContent>
        {isVerifyEmailOpen && (
          <VerifyEmailDialog
            open={isVerifyEmailOpen}
            onClose={() => setIsVerifyEmailOpen(false)}>
            {t("To be able to do this you need to verify your email.")}
          </VerifyEmailDialog>
        )}

        {!isAccepted ? (
          !isLoading ? (
            <SelectPaymentMethod />
          ) : (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "16px",
              }}>
              <CircularProgress style={{ margin: "0 auto" }} />
            </Box>
          )
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "16px",
            }}>
            <Typography variant="h5">
              {t("Proposal accepted successfully!")}
            </Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}

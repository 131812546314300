import { Avatar, makeStyles, Theme, Typography } from "@material-ui/core";
import { useEffect, useState } from "react";
import Deal from "../../../../core/entities/Deal";
import Profile from "../../../../core/entities/Profile";
import { useCurrentProfileContext } from "../../../../CurrentProfileContext";
import { db } from "../../../../infra/firebase/firebasecofig";
import { getAvatar, timestampToData } from "../../../../utils/utils";
import ProposalChip from "./ProposalChip";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";

const useProposalsStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: 10,
  },
  proposal: {
    background: "#ababab2e",
    width: "100%",
    minHeight: "4rem",
    margin: "1rem 0",
    borderRadius: "0.5rem",
    padding: "0.8rem 0.8rem",
    transition: "all 0.3s",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "&:hover": {
      background: "#ababab5f",
    },
  },
  proposalJob: {
    margin: "0 0 0.5rem 0",
  },
  proposalData: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  proposalDataItem: {
    margin: "0 0.5rem",
  },
  avatar: {
    marginRight: "1rem",
  },
}));

interface ProposalsProps {
  profile: Profile | null | undefined;
  type: "sent" | "received";
  status: string;
  handleOpenDealDetails(deal: Deal, isSender: boolean): void;
  success: boolean;
}

interface DealWithProfile extends Deal {
  associatedProfile: Profile;
}

const Proposals = ({
  profile,
  type,
  status,
  handleOpenDealDetails,
  success,
}: ProposalsProps) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [deals, setDeals] = useState<DealWithProfile[]>([]);
  const [initialDeals, setInitialDeals] = useState<DealWithProfile[]>([]);
  const classes = useProposalsStyles();

  const filterDeals = (dealsToFilter: DealWithProfile[]) => {
    if (status === "All") {
      setDeals(dealsToFilter);
    } else {
      const filteredDeals = dealsToFilter.filter(
        (deal) => deal.status === status
      );
      setDeals(filteredDeals);
    }
  };

  const fetchDeals = async (profileId: string) => {
    const dealsSnapshot = await db
      .collection("Deals")
      .where(type === "sent" ? "fromProfileId" : "toProfileId", "==", profileId)
      .get();

    const dealsDocs = dealsSnapshot.docs.map((doc) => doc.data()) as Deal[];
    const dealsWithProfilesPromise = dealsDocs.map(async (deal) => {
      const profileSnapshot = await db
        .collection("Profiles")
        .doc(type === "sent" ? deal.toProfileId : deal.fromProfileId)
        .get();

      const profile = profileSnapshot.data() as Profile;
      return { ...deal, associatedProfile: { ...profile } };
    });
    const dealsWithProfiles: DealWithProfile[] = await Promise.all(
      dealsWithProfilesPromise
    );
    console.debug(dealsWithProfiles);

    filterDeals(dealsWithProfiles);
    setInitialDeals(dealsWithProfiles);
    setIsLoading(false);
  };

  useEffect(() => {
    console.debug(success);
    if (profile && success) {
      fetchDeals(profile.id);
    }
  }, [profile, success]);

  useEffect(() => {
    filterDeals(initialDeals);
  }, [status, initialDeals]);

  if (isLoading) {
    return (
      <div className={classes.root}>
        {Array(12)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              variant="rect"
              className={classes.proposal}
              height={110}
              key={index}
            />
          ))}
      </div>
    );
  }

  return (
    <div className={classes.root}>
      {deals.map((deal) => (
        <div
          className={classes.proposal}
          key={deal.id}
          onClick={() => {
            const { associatedProfile, ...rest } = deal;
            handleOpenDealDetails(rest as Deal, type === "sent");
          }}>
          <Avatar
            className={classes.avatar}
            alt={deal.associatedProfile.name || undefined}
            src={
              deal.associatedProfile.profileImageUrl ||
              getAvatar(deal.associatedProfile.name)
            }
          />
          <div>
            <Typography
              className={classes.proposalJob}
              variant="h6"
              color="secondary">
              {deal.job}
            </Typography>
            <div className={classes.proposalData}>
              <span className={classes.proposalDataItem}>
                {t("Sent to")}: {deal.associatedProfile.name}
              </span>
              <span className={classes.proposalDataItem}>
                {t("Amount")}:{" "}
                {new Intl.NumberFormat(i18n.language, {
                  style: "currency",
                  currency: deal.currency,
                }).format(deal.amount)}
              </span>
              <span className={classes.proposalDataItem}>
                {t("Created at")}:{" "}
                {deal.createdAt &&
                  moment(timestampToData(deal.createdAt)).format("LLL")}
              </span>
              <span className={classes.proposalDataItem}>
                {t("Status")}: {t(deal.status)}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Proposals;

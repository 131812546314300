import { Collection } from 'fireorm';

@Collection('Categories')
export class Category {
  id: string;
  name: string;
  description?: string;
  parentCategoryId?: string;
  language?: string;
  imageUrl?: string;
  showAtHome?: boolean;

  translatedNames?: TranslatedNames;
  order?: number;
}


export class TranslatedNames{
  en?: string;
  pt?: string;
  es?: string;
  de?: string;
}

export class TranslatedNamesArray{
  en?: string[];
  pt?: string[];
  es?: string[];
  de?: string[];
}



import { Box, makeStyles, Tab, Tabs, Theme, useMediaQuery } from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Profile from "../../../core/entities/Profile";
import { useCurrentProfileContext } from "../../../CurrentProfileContext";
import { useProfileContext } from "../../../ProfileContext";
import IProfileParams from "../IProfileParams";
import { ProfileInformation } from "../ProfileDetails/ProfileDetails";
import ProfileExperience from "../ProfileExperience/ProfileExperience";
import ProfilePortfolio from "../ProfilePortfolio/ProfilePortfolio";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,

  },
}));

function a11yProps(index: any) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

interface LinkTabProps {
  label?: string;
  href?: string;
}

function LinkTab(props: LinkTabProps) {
  return (
    <Tab
      component="a"
      onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}>
      {value === index && <div>{children}</div>}
    </div>
  );
}

const ProfileTabs = () => {
  const { error, loading, profile, isCurrent } = useCurrentProfileContext();
  const { t, i18n } = useTranslation();
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const index = isSmall ? 1 : 0;

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        variant="fullWidth"
        value={value}
        onChange={handleChange}
        aria-label="nav tabs profile">
        {isSmall && <LinkTab label={t("Information")} href="/information" {...a11yProps(0)} />}
        <LinkTab label={t("Portfolio")} href="/portfolio" {...a11yProps(index)} />
        <LinkTab label={t("Experience")} href="/experience" {...a11yProps(index + 1)} />
      </Tabs>

      {isSmall && <TabPanel value={value} index={0}>
        <ProfileInformation />
      </TabPanel>}
      <TabPanel value={value} index={index}>
        <ProfilePortfolio />
      </TabPanel>
      <TabPanel value={value} index={index + 1}>
        <ProfileExperience />
      </TabPanel>

    </div>
  );
};

export default ProfileTabs;

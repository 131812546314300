import React, { useCallback, useState } from "react";
import {
  Button,
  CircularProgress,
  Collapse,
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Dropzone from "react-dropzone";

import Slider from "@material-ui/core/Slider";
import Cropper from "react-easy-crop";
import { Point, Area } from "react-easy-crop/types";

import getCroppedImg from "../../utils/cropImage";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import ProfileRepository from "../../infra/firebase/ProfileRepository";
import firebase from "firebase";
import { readFile } from "../../utils/file";
import { useTranslation } from "react-i18next";

const useImageEditorStyles = makeStyles((theme: Theme) =>
  createStyles({
    cropContainer: {
      position: "relative",
      width: "100%",
      height: 400,
      background: "#333",
      [theme.breakpoints.up("sm")]: {
        height: 400,
        width: "100%",
      },
    },
    cropButton: {
      flexShrink: 0,
      marginLeft: 16,
    },
    controls: {
      padding: 16,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
      },
    },
    sliderContainer: {
      display: "flex",
      flex: "1",
      alignItems: "center",
    },
    sliderLabel: {
      [theme.breakpoints.down("xs")]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: "22px 0px",
      marginLeft: 16,
      [theme.breakpoints.up("sm")]: {
        flexDirection: "row",
        alignItems: "center",
        margin: "0 16px",
      },
    },
    buttons: {
      textAlign: "right",
      marginTop: 10,
    },
    dialogCustomizedWidth: {
      "max-width": "80%",
    },
  })
);

interface IImageEditorProps {
  open: any;
  handleClose: any;
  setSuccess?: any;
  image: "cover" | "photo";
  profileId: string;
  cropShape: "rect" | "round";
  aspect: number;
  title: string;
}

const ImageEditor = ({
  open,
  handleClose,
  setSuccess,
  title,
  image,
  profileId,
  cropShape,
  aspect,
}: IImageEditorProps) => {
  const classes = useImageEditorStyles();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t, i18n } = useTranslation();

  const [file, setFile] = useState<File>();

  const [imageSrc, setImageSrc] = useState("");
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState<any>(0);
  const [zoom, setZoom] = useState<any>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onFileDroped = async (file: File) => {
    let imageDataUrl = await readFile(file);
    setImageSrc(imageDataUrl);
    setFile(file);
  };

  const save = async () => {
    setLoading(true);

    const extension = file ? file.name.split(".").pop() : "";
    const fileName = `user_images/${profileId}/${image}.${extension}`;

    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(fileName);

    await getCroppedImg(imageSrc, croppedAreaPixels, rotation).then(
      (croppedImage) => {
        fetch(croppedImage)
          .then((r) => {
            r.blob().then((b) => {
              fileRef
                .put(b)
                .then((snapshot) => {
                  fileRef
                    .getDownloadURL()
                    .then((url) => {
                      const setImage =
                        image == "cover"
                          ? new ProfileRepository().setProfileCover(
                              profileId,
                              url
                            )
                          : new ProfileRepository().setProfilePhoto(
                              profileId,
                              url
                            );

                      setImage
                        .then(() => {
                          setLoading(false);
                          handleClose();
                        })
                        .catch((error) => {
                          console.debug(error);
                          setLoading(false);
                          setErrorMessage(error.message);
                        });
                    })
                    .catch((error) => {
                      console.debug(error);
                      setLoading(false);
                      setErrorMessage(error.message);
                    });
                })
                .catch((error) => {
                  console.debug(error);
                  setLoading(false);
                  setErrorMessage(error.message);
                });
            });
          })
          .catch((error) => {
            console.debug(error);
            setLoading(false);
            setErrorMessage(error.message);
          });
      }
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
      classes={{ paperFullWidth: classes.dialogCustomizedWidth }}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Collapse in={errorMessage !== ""}>
          <Alert
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setErrorMessage("");
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {errorMessage}
          </Alert>
        </Collapse>

        {imageSrc ? (
          <div style={{ width: "100%" }}>
            <div className={classes.cropContainer}>
              <Cropper
                image={imageSrc}
                crop={crop}
                cropShape={cropShape}
                rotation={rotation}
                zoom={zoom}
                aspect={aspect}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
            <div className={classes.controls}>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}>
                  Zoom
                </Typography>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  classes={{ root: classes.slider }}
                  onChange={(e, zoom) => setZoom(zoom)}
                />
              </div>
              <div className={classes.sliderContainer}>
                <Typography
                  variant="overline"
                  classes={{ root: classes.sliderLabel }}>
                  Rotation
                </Typography>
                <Slider
                  value={rotation}
                  min={0}
                  max={360}
                  step={1}
                  aria-labelledby="Rotation"
                  classes={{ root: classes.slider }}
                  onChange={(e, rotation) => setRotation(rotation)}
                />
              </div>
            </div>
            <div className={classes.buttons}>
              <Button onClick={handleClose} color="inherit">
                {t("Cancel")}
              </Button>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    background: "#c9c9c9",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "4px",
                    padding: "12px",
                    margin: "4px 0",
                  }}>
                  <CircularProgress size={14} />
                </div>
              ) : (
                <Button
                  onClick={() => save()}
                  type="submit"
                  color="primary"
                  disabled={loading}>
                  {t("Save")}
                </Button>
              )}
            </div>
          </div>
        ) : (
          <div>
            {/* <input type="file" onChange={onFileChange} accept="image/*" /> */}

            <MyDropzone onChange={onFileDroped} />
            <div className={classes.buttons}>
              <Button onClick={handleClose} color="inherit">
                {t("Cancel")}
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

const useDropZoneStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropzone: {
      // textAlign: 'center',
      // padding: '20px',
      // border: '3px dashed #eeeeee',
      // backgroundColor: '#fafafa',
      // color: '#bdbdbd',

      textAlign: "center",
      padding: "20px",
      border: "2px dashed",
      borderColor: theme.palette.primary.main,
      backgroundColor: "#fafafa",
      color: theme.palette.text.primary,
    },
  })
);

interface IMyDropzoneProps {
  onChange: any;
}

function MyDropzone({ onChange }: IMyDropzoneProps) {
  const classes = useDropZoneStyles();
  const [fileNames, setFileNames] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const { t, i18n } = useTranslation();

  const handleDrop = (acceptedFiles) => {
    setFileNames(acceptedFiles.map((file) => file.name));
    onChange(acceptedFiles[0]);
  };

  const handleDropRejected = (fileRejections, event) => {
    setErrorMessage(fileRejections[0].errors[0].message);
  };

  return (
    <Dropzone
      onDropAccepted={handleDrop}
      onDropRejected={handleDropRejected}
      accept="image/*"
      minSize={1024}
      maxSize={3072000}
      maxFiles={1}
      multiple={false}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />
          <Typography paragraph variant="body1">
            {t("Drag'n'drop a image or click to select a file")}
          </Typography>
          <Typography paragraph variant="body1" style={{ color: "red" }}>
            {errorMessage}
          </Typography>
        </div>
      )}
    </Dropzone>
  );
}

export default ImageEditor;

import { getRepository } from "fireorm";
import { IRepository } from "../../core/interfaces/IRepository";
import Registration from "./Registration";


export default class RegistrationRepository implements IRepository<Registration>{

    registrationRepository = getRepository(Registration);

    getByEmail(email : string): Promise<Registration[]> {
        return this.registrationRepository.whereEqualTo('emailAddress', email).find();
    }

    getByProfileId(profileId : string): Promise<Registration[]> {
        return this.registrationRepository.whereEqualTo('profileId', profileId).find();
    }

    create(entity: Registration): Promise<Registration> {
        return this.registrationRepository.create(entity);
    }
    update(entity: Registration): Promise<Registration> {
        throw new Error("Method not implemented.");
    }
    delete(id: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
    getById(id: string): Promise<Registration> {
        throw new Error("Method not implemented.");
    }

}
import { Link, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ShowDataPolicyDialog } from '../../Support/ShowDataPolicy/ShowDataPolicy';
import { ShowTermsAndConditionsDialog } from '../../Support/ShowTermsAndConditions/ShowTermsAndConditions';
import styles from './ReadTermsAndPrivacyPolicy.module.scss';

const useStyles = makeStyles((theme) => ({

  root: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  }


}));

const ReadTermsAndPrivacyPolicy = () => {

  const { t, i18n } = useTranslation();
  const [openTerms, setOpenTerms] = useState(false);
  const [openPrivacyPolicy, setPrivacyPolicy] = useState(false);
  const classes = useStyles();


  return (
    <div className={classes.root} data-testid="ReadTermsAndPrivacyPolicy">
      {t("By continuing you accept our")}&nbsp;
      <Link onClick={() => setOpenTerms(true)} style={{ cursor: "pointer" }} >{t("Terms & Conditions")}</Link>
      &nbsp;{t("and")}&nbsp;
      <Link onClick={() => setPrivacyPolicy(true)} style={{ cursor: "pointer" }}>{t("Privacy Policy")}</Link>

      {openPrivacyPolicy && <ShowDataPolicyDialog open={openPrivacyPolicy} handleClose={() => setPrivacyPolicy(false)} />}
      {openTerms && <ShowTermsAndConditionsDialog open={openTerms} handleClose={() => setOpenTerms(false)} />}

    </div>



  )
};

export default ReadTermsAndPrivacyPolicy;

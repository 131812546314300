import { useEffect, useState } from "react";
import { Attribute } from "../../../core/entities/Attribute";
import AttributeRepository from "../../../infra/firebase/AttributeRepository";
import NumberAttribute from "./NumberAttribute";
import SelectAttribute from "./SelectAttribute";


export default function AttributesForm({ control, components }) {
  return (
    <>
      {components.map((component) => {
        if (component.type === "number")
          return <NumberAttribute control={control} attribute={component} />;
        if (component.type === "choice")
          return <SelectAttribute control={control} attribute={component} />;
        return <></>;
      })}
    </>
  );
}

import React from 'react';
import { IPostMedia } from '../IPostMedia';
import styles from './PostAudio.module.scss';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/src/styles.scss'
import { makeStyles, Theme, createStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative'
    },
  }),
);

export interface IPostAudioProps {
  photo: IPostMedia;
}

const PostAudio = ({ photo }: IPostAudioProps) => {
  const classes = useStyles();

  return (
    <div >
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '400px' }}
      >

        <Grid item >
          <AudioPlayer src={photo.src} onPlay={e => console.debug("onPlay")} />
        </Grid>

      </Grid>

    </div>
  )
};

export default PostAudio;

import { Collection } from "fireorm";

//@Collection('Locations')
export default class Location {

    name: string; //Nome do Local
    city: string | null;
    state: string | null;
    country: string | null;
    

    link?: string | null; 
    latitude?: number | null;
    longitude?: number | null;
    street?: string | null;
    zip?: string | null;

    placeId?: string;

    defaultValue?: any;
    description?: string;
}
import React, { useState } from "react";

import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
  CircularProgress,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import firebase from "firebase";


import { CreateProfile, VerifyProfileExists } from "../LoginController";
import AnalyticsHelper from "../../../infra/analytics/analyticsHelper";
import InviteController from "../InviteController";

const analyticsHelper = new AnalyticsHelper();
const inviteController = new InviteController();

const useStyles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: "#F8F8F8",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    //marginTop: theme.spacing(1),
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  errorMessage: {
    color: "red",
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
  },
  labelButton: {
    textTransform: "initial",
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  divider: {
    margin: theme.spacing(2, 2, 2),
  },
  inputAdornment: {
    color: theme.palette.primary.main,
  },
}));

function getProviderForProviderId(id: string) {
  switch (id) {
    case firebase.auth.TwitterAuthProvider.PROVIDER_ID:
      return new firebase.auth.TwitterAuthProvider();
    case firebase.auth.FacebookAuthProvider.PROVIDER_ID:
      return new firebase.auth.FacebookAuthProvider();
    default:
      return new firebase.auth.GoogleAuthProvider();
  }
}

interface ISocialMediaLogonProps {
  clearErrors: any;
  setError: any;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}



const SocialMediaLogon: React.FC<ISocialMediaLogonProps> = ({
  clearErrors,
  setError,
}: ISocialMediaLogonProps) => {
  let query = useQuery();
  const token = query.get("token");

  const r = query.get("r");

  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const afterError = (error: any) => {
    let errorMessage = error.message;
    console.debug(error);

    setLoading(false);
    setError(errorMessage);
  };

  function goToProfile() {
    history.push("/profile");
  }

  function goToLogin() {
    history.push("/login");
  }

  const continueWithSocialMedia = async (social: string) => {
    setLoading(true);

    let provider: firebase.auth.AuthProvider;

    switch (social) {
      case "facebook":
        provider = new firebase.auth.FacebookAuthProvider();
        break;
      case "twitter":
        provider = new firebase.auth.TwitterAuthProvider();
        break;
      case "google":
        provider = new firebase.auth.GoogleAuthProvider();
        break;
      default:
        provider = new firebase.auth.GoogleAuthProvider();
        break;
    }
    try {
      await firebase
        .auth()
        .signInWithPopup(provider)
        .then(({ user }) => {
          if (user !== null) {

            analyticsHelper.logEvent('social_logon');

            VerifyProfileExists(user.uid)
              .then((exists) => {
                if (!exists) {
                  CreateProfile(user, user.displayName)
                    .then(async(profile) => {
                      setLoading(false);
                      if (token) {
                        history.push('/acceptinvite', { token })                        
                      }else{
                        history.push(r ? r : "/");
                      }
                    })
                    .catch((error) => {
                      afterError(error);
                    });
                } else {
                  if (token) {
                    history.push('/acceptinvite', { token })                        
                  }else{
                    history.push(r ? r : "/");
                  }
                }
              })
              .catch((error) => {
                afterError(error);
              });
          } else {
            afterError({ message: t("error-user-null") });
          }
        })
        .catch((error) => {
          afterError(error);

          if (error.code === "auth/account-exists-with-different-credential") {
            var pendingCred = error.credential;
            var email = error.email;

            firebase
              .auth()
              .fetchSignInMethodsForEmail(email)
              .then((methods) => {
                if (methods[0] === "password") {
                  //indo para o login antes de decidir como tratar
                  goToLogin();

                  var password = ""; //get password

                  firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                    .then((result) => {
                      if (result !== null && result.user !== null) {
                        return result.user.linkWithCredential(pendingCred);
                      } else {
                        afterError({ message: "" });
                      }
                    })
                    .then(() => {
                      goToProfile();
                    });
                }

                var provider = getProviderForProviderId(methods[0]);
                firebase
                  .auth()
                  .signInWithPopup(provider)
                  .then((result) => {
                    if (result !== null && result.user !== null) {
                      result.user.linkWithCredential(pendingCred).then(() => {
                        goToProfile();
                      });
                    }
                  });
              });
          }
        });
    } catch (error) {
      afterError(error);
    }
  };

  return (
    <div>
      <Typography
        variant="subtitle1"
        color="textPrimary"
        align="center"
        paragraph>
        {t("OR")}
      </Typography>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={3}>
        <Grid item>
          {loading ? (
            <div
              style={{
                display: "flex",
                background: "#c9c9c9",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                padding: "20px 24px",
                margin: "4px 0",
              }}>
              <CircularProgress size={14} />
            </div>
          ) : (
            <Button
              className={classes.large}
              onClick={() => {
                clearErrors();
                continueWithSocialMedia("twitter");
              }}
              color="primary"
              variant="contained"
              disabled
              disableElevation>
              <i className="fa fa-twitter" aria-hidden="true" />
            </Button>
          )}
        </Grid>

        <Grid item>
          {loading ? (
            <div
              style={{
                display: "flex",
                background: "#c9c9c9",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                padding: "20px 24px",
                margin: "4px 0",
              }}>
              <CircularProgress size={14} />
            </div>
          ) : (
            <Button
              className={classes.large}
              onClick={() => {
                clearErrors();
                continueWithSocialMedia("facebook");
              }}
              color="primary"
              variant="contained"
              disabled
              disableElevation>
              <i className="fa fa-facebook" aria-hidden="true" />
            </Button>
          )}
        </Grid>

        <Grid item>
          {loading ? (
            <div
              style={{
                display: "flex",
                background: "#c9c9c9",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                padding: "20px 24px",
                margin: "4px 0",
              }}>
              <CircularProgress size={14} />
            </div>
          ) : (
            <Button
              className={classes.large}
              onClick={() => {
                clearErrors();
                continueWithSocialMedia("google");
              }}
              color="primary"
              variant="contained"
              disabled={loading}
              disableElevation>
              <i className="fa fa-google" aria-hidden="true" />
            </Button>
          )}
        </Grid>

        <Grid item>
          {loading ? (
            <div
              style={{
                display: "flex",
                background: "#c9c9c9",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "4px",
                padding: "20px 24px",
                margin: "4px 0",
              }}>
              <CircularProgress size={14} />
            </div>
          ) : (
            <Button
              className={classes.large}
              onClick={() => {
                clearErrors();
                continueWithSocialMedia("linkedin");
              }}
              color="primary"
              variant="contained"
              disabled
              disableElevation>
              <i className="fa fa-linkedin" aria-hidden="true" />
            </Button>
          )}
        </Grid>
      </Grid>

      <Divider className={classes.divider} />
    </div>
  );
};

export default SocialMediaLogon;

import { Button, makeStyles, Theme } from "@material-ui/core";
import { Alert, AlertTitle, Color } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import Moderation from "../../../core/entities/Moderation";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 5,
        marginBottom: 5

    },
}));

export interface IModerationAlertProps {
    callback: any;
    callbackText: string;
    showAction?: boolean;
    children: any;
}

export const ModerationAlert = ({ callback, callbackText, children, showAction }: IModerationAlertProps) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation();

    return (<div className={classes.root}>
        <Alert severity="warning" variant="outlined" action={
            showAction && <>
                <Button color="primary" size="small" onClick={callback}>
                    {callbackText}
                </Button>
            </>
        }>
            <AlertTitle>{"Violation of Terms & Conditions"}</AlertTitle>
            {children}
        </Alert>
    </div>);
}

export interface IGenericFieldModerationAlertProps {
    moderation: Moderation;
    callback: any;
}

export const GenericFieldModerationAlert = ({ moderation, callback } : IGenericFieldModerationAlertProps) => {
    const { t, i18n } = useTranslation();
    const getMessage = () => {
        return  `${t("Your")} ${t(moderation.fieldDescription)} ${t("violates our Terms & Conditions. Please,")} ${t(moderation.actionText)} ${t("or")} `;
    }
    return (
        <ModerationAlert callback={callback} callbackText={t("Accept")} >
            {getMessage()}<a href="mailto:info@Matcheam.com"><b>{t("contact us")}</b></a>.
        </ModerationAlert>
    );
}



export const ProfileImageModerationAlert = ({ callback }) => {
    const { t, i18n } = useTranslation();
    return (
        <ModerationAlert callback={callback} callbackText={t("Accept")} >
            {t("Your profile image violates our Terms & Conditions. Please, upload a new profile image or")}&nbsp;<a href="mailto:info@Matcheam.com"><b>{t("contact us")}</b></a>.
        </ModerationAlert>
    );
}




export const ProfileCoverImageModerationAlert = ({ callback }) => {
    const { t, i18n } = useTranslation();
    return (
        <ModerationAlert callback={callback} callbackText={t("Accept")} >
            {t("Your profile cover image violates our Terms & Conditions. Please, upload a new cover image or")}&nbsp;<a href="mailto:info@Matcheam.com"><b>{t("contact us")}</b></a>.
        </ModerationAlert>
    );
}


export const PostModerationAlert = ({ callback }) => {
    const { t, i18n } = useTranslation();
    return (
        <ModerationAlert callback={callback} callbackText={t("Delete Post")} showAction={false}>
            {t("Your post violates our Terms & Conditions and will not be displayed to other users. Please, delete your post or")}&nbsp;<a href="mailto:info@Matcheam.com"><b>{t("contact us")}</b></a>.
        </ModerationAlert>
    );
}
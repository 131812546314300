import { Collection } from 'fireorm';

@Collection('Posts')
export default class Post {
    id: string;

    profileId: string; //Perfil do usuário

    publishDate: Date | null; //Data de publicação
    modifiedDate: Date | null; //Data de modificação

    caption: string | null; //Legenda do post

    type: PostType | null; //Tipo de publicação

    thumbnailUrl: string | null; //Url da capa

    medias: Media[] | null; //Lista de mídias

    locationId: string | null; //Localização (check-in)

    profileTags: ProfileTag[] | null; //Perfis marcados
    
    moderated?: boolean;

}

export class PostType {
    id: string;
    description: string;
}

export class ProfileTag {
    profileId: string;
}

export class Media {
    id?: string;

    type?: string; //Tipo de Mídia

    url: string; //cropped photo url

    crop?: any; //x,y crop Ex.: { x: 0, y: 0 }
    croppedAreaPixels?: any; //photo croppedarea Ex.: { height: 667, width: 667, x: 0, y:0 }
    zoom?: number; //photo zoom Ex.: 1
    originalUrl: string; //original photo url

    thumbUrl?: string; //thumbnail dos vídeos

}


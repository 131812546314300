import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const availableLanguages = [
  {
    value: 'en',
    name: "English"
  },
  {
    value: 'es',
    name: "Spanish"
  },
  {
    value: 'pt',
    name: "Portuguese"
  },
  {
    value: 'de',
    name: "Deutschland"
  }];



const detectionOptions = {
  order: ["cookie", "localStorage", "navigator", "querystring", "sessionStorage", "htmlTag", "path", "subdomain"],

  lookupQuerystring: "lng",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLng",
  lookupSessionStorage: "i18nextLng",
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,
  caches: ["localStorage", "cookie"],
  excludeCacheFor: ["cimode"],
  cookieMinutes: 10,
  cookieDomain: "Matcheam.com",
}


const options = {
  // we init with resources
  resources: {
    en: {
      translations: {
        "Hey! Your new job is here!": "Hey! Your new job is here!",
        "Who saw my profile": "Who saw my profile",
        "See our Premium Features": "See our Premium Features",
        "Chats": "Chats",
        "Jobs": "Jobs",
        "Notifications": "Notifications",
        "Profile": "Profile",
        "Register": "Register",
        "Login": "Login",
        "Email Address": "Email Address",
        "Password": "Password",
        "Remember me": "Remember me",
        "Sign In": "Sign In",
        "Don't have an account? Sign Up": "Don't have an account? Sign Up",
        "Forgot password?": "Forgot password?",
        "OR": "OR",
        "Sign in": "Sign in",
        "Continue with Facebook": "Continue with Facebook",
        "Continue with Google": "Continue with Google",
        "Continue with Twitter": "Continue with Twitter",
        "The password is invalid or the user does not have a password.": "The password is invalid or the user does not have a password.",
        "Email already associated with another account.": "Email already associated with another account.",
        "The email address is badly formatted.": "The email address is badly formatted.",
        "Do you already have an account? Sign In": "Do you already have an account? Sign In",
        "Sign Up": "Sign Up",
        "Full Name": "Full Name",
        "Welcome, looks like you're new here!": "Welcome, looks like you're new here!",
        "Don't worry, we are here help you to recover your password": "Don't worry, we are here help you to recover your password",
        "Create a page for a company or agency": "Create a page for a company or agency",
        "Welcome!": "Welcome!",
        "Welcome! We are glad to see you again.": "Welcome! We are glad to see you again.",
        "Welcome! Looks like you're new here.": "Welcome! Looks like you're new here.",
        "Recover password": "Recover password",
        "Create a new account": "Create a new account",
        "Create ": "Create ",
        "a page for a company or agency": "a page for a company or agency",
        "E-mail": "E-mail",
        "Log in": "Log in",
        "Welcome! Looks like you are new here.": "Welcome! Looks like you are new here.",
        "Create account": "Create account",
        "error-user-null": "error-user-null",
        "Edit photo cover": "Edit photo cover",
        "Social Media": "Social Media",
        "Bio": "Bio",        
        "About": "About",
        "Experience": "Experience",
        "Details": "Details",
        "Employment Type": "Employment Type",
        "Add experience": "Add experience",
        "Cancel": "Cancel",
        "Save": "Save",
        "Title": "Title",
        "Company": "Company",
        "Site": "Site",
        "Location": "Location",
        "Portfolio": "Portfolio",
        "Categories": "Categories",
        "Hi! Include Jobs and let everyone know what you're capable of.": "Hi! Include Jobs and let everyone know what you're capable of.",
        "Edit profile": "Edit profile",
        "Sign Out": "Sign Out",
        "Follow": "Follow",
        "Replace banner image": "Replace banner image",
        "Ideal dimensions 1280px x 320px": "Ideal dimensions 1280px x 320px",
        "Change": "Change",
        "Upload Cover": "Upload Cover",
        "Remove": "Remove",
        "Click to add a new category": "Click to add a new category",
        "Click to edit this category": "Click to edit this category",
        "Delete": "Delete",
        "Drag'n'drop images, audios and videos or click to select files": "Drag'n'drop images, audios and videos or click to select files",
        "Add a new Post": "Add a new Post",
        "Write a caption...": "Write a caption...",
        "New Post": "New Post",
        "Drag and drop an image here or click": "Drag and drop an image here or click",
        "Post": "Post",
        "Next": "Next",
        "Click here to add new medias": "Click here to add new medias",
        "Click here to delete this media": "Click here to delete this media",
        "Click here to capture a thumbnail": "Click here to capture a thumbnail",
        "Drag'n'drop a image or click to select a file": "Drag'n'drop a image or click to select a file",
        "Log Out": "Log Out",
        "Edit your profile cover": "Edit your profile cover",
        "Contact": "Contact",
        "Edit": "Edit",
        "Close": "Close",
        "Delete post": "Delete post",
        "Do you really want to delete?": "Do you really want to delete?",
        "Start Date must be less than End Date": "Start Date must be less than End Date",
        "Show less": "Show less",
        "Show more": "Show more",
        "No results": "No results",
        "Submit your search query.": "Submit your search query.",
        "Clear your search query.": "Clear your search query.",
        "Search here...": "Search here...",
        "Search locations here...": "Search locations here...",
        "Find your talent": "Find your talent",
        "Our community": "Our community",
        "Latest profiles": "Latest profiles",
        "Beta - Welcome to the Matcheam family": "Beta - Welcome to the Matcheam family",
        "The first complete search, scheduling, payment and career management platform for talent and contractors in the media and entertainment market.": "The first complete search, scheduling, payment and career management platform for talent and contractors in the media and entertainment market.",
        "Actors, models, musicians, athletes, photographers, extras, influencers, and many others. Enter and feel at ease in the biggest global showcase of talent and opportunities.": "Actors, models, musicians, athletes, photographers, extras, influencers, and many others. Enter and feel at ease in the biggest global showcase of talent and opportunities.",
        "Hi! Include experiences and let everyone know what you're capable of.": "Hi! Include experiences and let everyone know what you're capable of.",
        "Delete Experience": "Delete Experience",
        "Edit experience": "Edit experience",
        "Start Date": "Start Date",
        "End Date": "End Date",
        "Latest highlights": "Latest highlights",
        "Testimony": "Testimony",
        "Logout": "Logout",
        "Accepted Invitations": "Accepted Invitations",
        "General": "General",
        "Invites": "Invites",
        "Actions": "Actions",
        "Code": "Code",
        "Created at": "Created at",
        "Expire": "Expire",
        "Do you want to expire this invite?": "Do you want to expire this invite?",
        "Do you want to reactivate this invite?": "Do you want to reactivate this invite?",
        "Reactivate": "Reactivate",
        "Expired": "Expired",
        "Accepted at": "Accepted at",
        "Invited": "Invited",
        "Type": "Type",
        "New invite": "New invite",
        "Link to invitation copied to clipboard": "Link to invitation copied to clipboard",
        "By continuing, you agree to our ": "By continuing, you agree to our ",
        "Terms": "Terms",
        "Data Use Policy": "Data Use Policy",
        " and ": " and ",
        "Cookie Use": "Cookie Use",
        "Select at least one media.": "Select at least one media.",
        "Filters": "Filters",
        "Locations": "Locations",
        "Category": "Category",
        "Privacy Policy": "Privacy Policy",
        "Terms & Conditions": "Terms & Conditions",
        "By continuing you accept our": "By continuing you accept our",
        "and": "and",
        "Name": "Name",
        "Nickname": "Nickname",
        "Headline": "Headline",
        "Settings": "Settings",
        "WaitingForApproval": "Waiting for approval",
        "WaitingToBeDone": "Waiting to be done",
        "Accepted": "Accepted",
        "Rejected": "Rejected",
        "Canceled": "Canceled",
        "Accomplished": "Accomplished",
        "Completed": "Completed",
        "Waiting for approval": "Waiting for approval",
        "Waiting to be done": "Waiting to be done",
        "Copy": "Copy",
        "View accepted invitations": "View accepted invitations",
        "Proposals": "Proposals",
        "invite": "invite",
        "Go to onboarding": "Go to onboarding",
        "In order to create deal proposals you need to go through our payments platform onboarding. Please register your bank data inorder to proceed.": "In order to create deal proposals you need to go through our payments platform onboarding. Please register your bank data inorder to proceed.",
        "Validation error, try again": "Validation error, try again",
        "Validating": "Validating",
        "This content is not available.": "This content is not available.",
        "The content on this page is not available or the link you tried to access is not working.": "The content on this page is not available or the link you tried to access is not working.",
        "Back to Matcheam": "Back to Matcheam",
        "Informations": "Informations",
        "To start your onboarding verify your e-mail first": "To start your onboarding verify your e-mail first",
        "followers": "followers",
        "following": "following",
        "posts": "posts",
        "See the portfolio of": "See the portfolio of",
        "Invalid characters in nickname": "Invalid characters in nickname",
        "You can not use this nickname.": "You can not use this nickname.",
        "Do you need help?": "Do you need help?",
        "Contact us": "Contact us",
        "Privacy": "Privacy",
        "Type message here": "Type message here",
        "Create Proposal": "Create Proposal",
        "View Details": "View Details",
        "Status": "Status",
        "Offer price": "Offer price",
        "Impressum/Imprint": "Impressum/Imprint",
        "Back to Top": "Back to Top",
        "Upload file": "Upload file",
        "Upload": "Upload",
        "Managed by": "Managed by",
        "Congratulations, you have been invited to be managed!": "Congratulations, you have been invited to be managed!",
        "Accept agency": "Accept agency",
        "Descline": "Descline",
        "wants to arrange you! To accept the proposal click on the button below": "wants to arrange you! To accept the proposal click on the button below",
        "How does agency work on Matcheam?": "How does agency work on Matcheam?",
        "To be able to start the add your bank information you need to verify your email.": "To be able to start the add your bank information you need to verify your email.",
        "Please select a country before adding yout bank information": "Please select a country before adding yout bank information",
        "To be able to do this you need to verify your email.": "To be able to do this you need to verify your email.",
        "Request verification email": "Request verification email",
        "Verify your email": "Verify your email",
        "Add a new card": "Add a new card",
        "Payment methods": "Payment methods",
        "Proposal accepted successfully!": "Proposal accepted successfully!",
        "Select a payment method": "Select a payment method",
        "To start receiving your payments, you must first include your bank account information.": "To start receiving your payments, you must first include your bank account information.",
        "Germany": "Germany",
        "Country": "Country",
        "Choose a country to set up your payment informations": "Choose a country to set up your payment informations",
        "Drag and drop an file here or click": "Drag and drop an file here or click",
        "- A link to your agent's profile will appear on your profile home page": "- A link to your agent's profile will appear on your profile home page",
        "Accept invite": "Accept invite",
        "Attributes": "Attributes",
        "Advanced Filters": "Advanced Filters",
        "See All": "See All",
        "English": "English",
        "Spanish": "Spanish",
        "Portuguese": "Portuguese",
        "Deutschland": "German",
        "Payment": "Payment",
        "Hide on search": "Hide on search",
        "Cards": "Cards",
        "Add or edit your cards to make payments": "Add or edit your cards to make payments",
        "Add your bank information": "Add your bank information",
        "Paste your video link": "Paste your video link (Ex: https://www.youtube.com/...)",
        "Comission": "Comission",
        "The comission cannot be greater than 90%": "The comission cannot be greater than 90%",
        "The amount exceeds the maximum value": "The amount exceeds the maximum value",
        "The talent has not selected their region": "The talent has not selected their region",
        "The talent has not informed their bank data": "The talent has not informed their bank data",
        "The agent has not informed their bank data": "The agent has not informed their bank data",
        "The agent has not completed their bank data": "The agent has not completed their bank data",
        "The talent has not completed their bank data": "The talent has not completed their bank data",
        "Invalid data provided. Please contact support": "Invalid data provided. Please contact support",
        "On boarding not completed, please complete it before proceeding.": "On boarding not completed, please complete it before proceeding.",
        "On boarding not started.": "On boarding not started.",
        "Oops something happened": "Oops something happened",
        "Let's get started with your profile. First you can add your profile picture, so people can better tell who you are.": "Let's get started with your profile. First you can add your profile picture, so people can better tell who you are.",
        "Welcome to Matcheam": "Welcome to Matcheam",
        "Select profile picture": "Select profile picture",
        "Not now": "Not now",
        "Let us know what occupations better suit you.": "Let us know what occupations better suit you.",
        "Select the categories and skills that best describe you.": "Select the categories and skills that best describe you.",
        "Select your occupations": "Select your occupations",
        "Let's take a look how your profile is looking.": "Let's take a look how your profile is looking.",
        "All set!": "All set!",

        "Your": "Your",
        "violates our Terms & Conditions. Please,": "violates our Terms & Conditions. Please,",
        "or": "or",
        "profile image": "profile image",
        "upload a new profile image": "upload a new profile image",
        "profile cover": "profile cover",
        "upload a new cover": "upload a new cover",

        "Region": "Region",
        "europe": "Europe",
        "brazil": "Brazil",
        "Id is required": "Id is required",
        "Please select your region.": "Please select your region.",

        "Accept proposal and pay": "Accept proposal and pay",
        "Date of birth": "Date of birth",
        "Birthdate must be in the past": "Birthdate must be in the past",
        "Birthdate is required": "Birthdate is required",
        "Pay via boleto": "Pay via boleto",
        "Accept proposal and pay with card": "Accept proposal and pay with card",
        "To be able to chat with other users, you need to verify your email.": "To be able to chat with other users, you need to verify your email.",
        "change your bio": "change your bio",
        "bio": "bio",
        "contact us": "contact us",
        "Accept": "Accept",
        "Share": "Share",
        "Link copied!": "Link copied!",
        "post image": "post image",
        "delete your post": "delete your post",
        "Talent": "Talent",
        "Agent": "Agent",
        "Employer": "Contractor",
        "Crew": "Crew",
        "Age": "Age",
        "The field": "The field",
        "headline": "headline",
        "violates our Terms & Conditions": "violates our Terms & Conditions",
        "change your headline": "change your headline",
        "Remove from home": "Remove from home",
        "Clear filters":"Clear filters",

        "Find your team":"Find your team",
      }
    },
    pt: {
      translations: {
        "Hey! Your new job is here!": "Ei! Seu novo trabalho está aqui!",
        "Who saw my profile": "Quem viu o meu perfil",
        "See our Premium Features": "Veja nossos recursos Premium",
        "Chats": "Chats",
        "Jobs": "Empregos",
        "Notifications": "notificações",
        "Profile": "Perfil",
        "Register": "Registro",
        "Login": "Login",
        "Email Address": "Endereço de e-mail",
        "Password": "Senha",
        "Remember me": "Lembre de mim",
        "Sign In": "Entrar",
        "Don't have an account? Sign Up": "Não tem uma conta? Inscrever-se",
        "Forgot password?": "Esqueceu sua senha?",
        "OR": "OU",
        "Sign in": "Entrar",
        "Continue with Facebook": "Continue com Facebook",
        "Continue with Google": "Continue com Google",
        "Continue with Twitter": "Continue com Twitter",
        "The password is invalid or the user does not have a password.": "A senha é inválida ou o usuário não tem uma senha.",
        "Email already associated with another account.": "E-mail já associado a outra conta.",
        "The email address is badly formatted.": "O endereço de email está mal formatado.",
        "Do you already have an account? Sign In": "Você já tem uma conta? Entre",
        "Sign Up": "Inscrever-se",
        "Full Name": "Nome completo",
        "Welcome, looks like you're new here!": "Bem-vindo, parece que você é novo aqui!",
        "Don't worry, we are here help you to recover your password": "Não se preocupe, estamos aqui ajudá-lo a recuperar sua senha",
        "Create a page for a company or agency": "Criar uma página para uma empresa ou agência",
        "Welcome!": "Bem-vindo!",
        "Welcome! We are glad to see you again.": "Bem-vindo! Nós estamos contentes de vê-lo novamente.",
        "Welcome! Looks like you're new here.": "Bem-vindo! Parece que você é novo aqui.",
        "Recover password": "Recuperar senha",
        "Create a new account": "Criar uma nova conta",
        "Create ": "Crie",
        "a page for a company or agency": "uma página para uma empresa ou agência",
        "E-mail": "E-mail",
        "Log in": "Login",
        "Create account": "Criar Conta",
        "error-user-null": "error-user-nulo",
        "Edit photo cover": "Editar foto de capa",
        "Social Media": "Mídia social",
        "Bio": "Bio",
        "About": "Sobre",
        "Experience": "Experiência",
        "Details": "Detalhes",
        "Employment Type": "Tipo de Emprego",
        "Add experience": "Nova experiência",
        "Cancel": "Cancelar",
        "Save": "Salvar",
        "Title": "Título",
        "Company": "Empresa",
        "Site": "Local",
        "Location": "Localização",
        "Portfolio": "Portfolio",
        "Categories": "Categorias",
        "Hi! Include Jobs and let everyone know what you're capable of.": "Oi! Incla Jobs e deixe todo mundo saber o que você é capaz.",
        "Edit profile": "Editar Perfil",
        "Sign Out": "Sair",
        "Follow": "Seguir",
        "Replace banner image": "Substituir imagem de capa",
        "Ideal dimensions 1280px x 320px": "dimensões ideais 1280px x 320px",
        "Change": "Mudar",
        "Upload Cover": "Carregar tampa",
        "Remove": "Retirar",
        "Click to add a new category": "Clique para adicionar uma nova categoria",
        "Click to edit this category": "Clique para editar esta categoria",
        "Delete": "Excluir",
        "Drag'n'drop images, audios and videos or click to select files": "Drag'n'Drop imagens, áudios e vídeos ou clique para selecionar arquivos",
        "Add a new Post": "Adicionar um novo post",
        "Write a caption...": "Escreva uma legenda...",
        "New Post": "Nova postagem",
        "Drag and drop an image here or click": "Arrastar e soltar uma imagem aqui ou clique",
        "Post": "Publicar",
        "Next": "Próximo",
        "Click here to add new medias": "Clique aqui para adicionar novas mídias",
        "Click here to delete this media": "Clique aqui para apagar essa mídia",
        "Click here to capture a thumbnail": "Clique aqui para capturar uma miniatura",
        "Drag'n'drop a image or click to select a file": "Drag'n'Drop uma imagem ou clique para selecionar um arquivo",
        "Log Out": "Sair",
        "Edit your profile cover": "Editar o seu perfil de cobertura",
        "Contact": "Contato",
        "Edit": "Editar",
        "Close": "Fechar",
        "Delete post": "Apague a postagem",
        "Do you really want to delete?": "Você realmente quer apagar?",
        "Start Date must be less than End Date": "A data inicial deve ser inferior a Data de Término",
        "Show less": "Mostre menos",
        "Show more": "Mostre mais",
        "No results": "nenhum resultado",
        "Submit your search query.": "Submeter sua pesquisa.",
        "Clear your search query.": "Limpar a sua consulta de pesquisa.",
        "Search here...": "Busque aqui...",
        "Search locations here...": "Pesquisar locais aqui ...",
        "Find your talent": "Encontre o seu talento",
        "Our community": "Nossa comunidade",
        "Latest profiles": "Últimos perfis cadastrados",
        "Beta - Welcome to the Matcheam family": "Beta - Bem-vindo à família Matcheam",
        "The first complete search, scheduling, payment and career management platform for talent and contractors in the media and entertainment market.": "A plataforma de gestão de primeira completa de pesquisa, programação de pagamento e carreira para o talento e empreiteiros na mídia e entretenimento mercado.",
        "Actors, models, musicians, athletes, photographers, extras, influencers, and many others. Enter and feel at ease in the biggest global showcase of talent and opportunities.": "Atores, modelos, músicos, atletas, fotógrafos, extras, influenciadores, e muitos outros. Entrar e se sentir à vontade na maior vitrine global de talentos e oportunidades.",
        "Hi! Include experiences and let everyone know what you're capable of.": "Olá! Inclua experiências e diga a todos do que você é capaz.",
        "Delete Experience": "Excluir experiência",
        "Edit experience": "Alterar experiência",
        "Start Date": "Data de início",
        "End Date": "Data de término",
        "Latest highlights": "Últimos destaques",
        "Testimony": "Depoimentos",
        "Logout": "Sair",
        "Accepted Invitations": "Convites Aceitos",
        "General": "Gerais",
        "Invites": "Convites",
        "Actions": "Ações",
        "Code": "Código",
        "Created at": "Criado em",
        "Expire": "Expirar",
        "Do you want to expire this invite?": "Desaja expirar este convite?",
        "Do you want to reactivate this invite?": "Deseja reativar este convite?",
        "Reactivate": "Reativar",
        "Expired": "Expirado",
        "Accepted at": "Aceito em",
        "Invited": "Convidado",
        "Type": "Tipo",
        "New invite": "Novo convite",
        "Link to invitation copied to clipboard": "Link para o convite copiado para a área de transferência",
        "By continuing, you agree to our ": "Continuando, você concorda com nossos ",
        "Terms": "Termos",
        "Data Use Policy": "Política de Uso de Dados",
        " and ": " e ",
        "Cookie Use": "Uso de Cookies",
        "Select at least one media.": "Selecione pelo menos uma mídia.",
        "Filters": "Filtros",
        "Locations": "Localidades",
        "Category": "Categoria",
        "Privacy Policy": "Política de Privacidade",
        "Terms & Conditions": "Termos & Condições",
        "By continuing you accept our": "Ao continuar, você aceita nossos",
        "and": "e",
        "Name": "Nome",
        "Nickname": "Nickname",
        "Headline": "Headline",
        "Settings": "Configurações",
        "WaitingForApproval": "Aguardando aprovação",
        "WaitingToBeDone": "Aguardando ser feito",
        "Accepted": "Aceito",
        "Rejected": "Rejeitada",
        "Canceled": "Cancelada",
        "Accomplished": "Realizado",
        "Completed": "Completa",
        "Waiting for approval": "Aguardando aprovação",
        "Waiting to be done": "Aguardando ser feito",
        "Copy": "Copiar",
        "View accepted invitations": "Ver convites aceitos",
        "Proposals": "Propostas",
        "invite": "convite",
        "Go to onboarding": "Fazer onboarding",
        "In order to create deal proposals you need to go through our payments platform onboarding. Please register your bank data inorder to proceed.": "Para criar propostas de negócios, você precisa passar por nossa plataforma de pagamentos onboarding. Por favor, registre seus dados bancários para prosseguir.",
        "Validation error, try again": "Erro de validação, tente novamente",
        "Validating": "Validando",
        "This content is not available.": "Esse conteúdo não está disponível",
        "The content on this page is not available or the link you tried to access is not working.": "O conteúdo desta página não está disponível ou o link que você tentou acessar não está funcionando.",
        "Back to Matcheam": "Voltar para a Matcheam",
        "Information": "Informações",
        "followers": "seguidores",
        "following": "seguindo",
        "posts": "publicações",
        "See the portfolio of": "Veja o portfolio de",
        "Invalid characters in nickname": "Caracteres inválidos no nickname",
        "You can not use this nickname.": "Você não pode usar esse nickname.",
        "Do you need help?": "Precisa de ajuda?",
        "Contact us": "Fale conosco",
        "Privacy": "Privacidade",
        "Type message here": "Digite a mensagem aqui",
        "Create Proposal": "Criar proposta",
        "View Details": "Ver Detalhes",
        "Status": "Status",
        "Offer price": "Preço da oferta",
        "Impressum/Imprint": "Impressum/Imprint",
        "Back to Top": "Voltar ao topo",
        "Upload file": "Enviar arquivo",
        "Upload": "Enviar",
        "Managed by": "Agenciado por",
        "Congratulations, you have been invited to be managed!": "Meus parabéns, você foi convidado a ser agenciado!",
        "Accept agency": "Aceitar agenciamento",
        "Descline": "Recusar",
        "wants to arrange you! To accept the proposal click on the button below": "deseja agenciar você! Para aceitar a proposta clique no botão abaixo",
        "How does agency work on Matcheam?": "Como funcionam os agencimentos na Matcheam?",
        "To be able to start the add your bank information you need to verify your email.": "Para poder iniciar a adição de suas informações bancárias, você precisa verificar seu e-mail.",
        "Please select a country before adding yout bank information": "Selecione uma região antes de adicionar suas informações bancárias",
        "To be able to do this you need to verify your email.": "Para fazer isso, você precisa verificar seu e-mail.",
        "Request verification email": "Solicitar e-mail de verificação",
        "Verify your email": "Verifique seu e-mail",
        "Add a new card": "Adicionar novo cartão",
        "Payment methods": "Método de pagamento",
        "Proposal accepted successfully!": "Proposta aceita com sucesso!",
        "Select a payment method": "Selecione um método de pagamento",
        "To start receiving your payments, you must first include your bank account information.": "Para começar a receber seus pagamentos, é necessário primeiro incluir as informações de sua conta bancária.",
        "Germany": "Alemanha",
        "Country": "País",
        "Choose a country to set up your payment informations": "Escolha um país para configurar suas informações de pagamento",
        "Drag and drop an file here or click": "Arraste e solte um arquivo aqui ou clique",
        "- A link to your agent's profile will appear on your profile home page": "- Na home do seu perfil aparece um link para o perfil do seu agente",
        "Accept invite": "Aceitar convite",
        "Attributes": "Atributos Físicos",
        "Advanced Filters": "Filtros Avançados",
        "See All": "Ver Todos",
        "English": "Inglês",
        "Spanish": "Espanhol",
        "Portuguese": "Português",
        "Deutschland": "Alemão",
        "Payment": "Pagamento",
        "Hide on search": "Ocultar na pesquisa",
        "Cards": "Cartões",
        "Add or edit your cards to make payments": "Adicione ou edite seus cartões para fazer pagamentos",
        "Add your bank information": "Adicione suas informações bancárias",
        "Paste your video link": "Cole o link do seu vídeo (Ex: https://www.youtube.com/...)",
        "Comission": "Comissão",
        "The comission cannot be greater than 90%": "A comissão não pode ser maior que 90%",
        "The amount exceeds the maximum value": "O valor excede o valor máximo",
        "The talent has not selected their region": "O talento não selecionou sua região",
        "The talent has not informed their bank data": "O talento não informou seus dados bancários",
        "The agent has not informed their bank data": "O agente não informou seus dados bancários",
        "The agent has not completed their bank data": "O agente não completou seus dados bancários",
        "The talent has not completed their bank data": "O talento não completou seus dados bancários",
        "Invalid data provided. Please contact support": "Dados inválidos. Por favor, entre em contato com o suporte",
        "On boarding not completed, please complete it before proceeding.": "On boarding não completado, por favor, complete-o antes de prosseguir.",
        "On boarding not started.": "On boarding não iniciado.",
        "Oops something happened": "Oops, algo deu errado",
        "Let's get started with your profile. First you can add your profile picture, so people can better tell who you are.": "Vamos começar com o seu perfil. Primeiro você pode adicionar sua foto de perfil, para que as pessoas possam saber quem é você.",
        "Welcome to Matcheam": "Bem-vindo à Matcheam",
        "Select profile picture": "Selecione uma foto de perfil",
        "Not now": "Agora não",
        "Let us know what occupations better suit you.": "Diga-nos qual ocupação você melhor se encaixa.",
        "Select the categories and skills that best describe you.": "Selecione as categorias e habilidades que melhor descrevem você.",
        "Unable to save occupations, please refresh the page and try again.": "Não foi possível salvar as ocupações, por favor, atualize a página e tente novamente.",
        "Select your occupations": "Selecione suas ocupações",
        "Let's take a look how your profile is looking.": "Vamos ver como seu perfil está.",
        "All set!": "Tudo pronto!",

        "Your": "Seu/sua",
        "violates our Terms & Conditions. Please,": "viola nossos Termos e Condições. Por favor,",
        "or": "ou",
        "profile image": "imagem de perfil",
        "upload a new profile image": "carregue uma nova imagem de perfil",
        "profile cover": "capa",
        "upload a new cover": "carregue uma nova capa",

        "Region": "Região",
        "europe": "Europa",
        "brazil": "Brasil",
        "Please select your region.": "Por favor selecione sua região.",
        "Id is required": "Id é obrigatório",
        "Accept proposal and pay": "Aceitar proposta e pagar",

        "Date of birth": "Data de nascimento",
        "Birthdate must be in the past": "A data de nascimento deve estar no passado",
        "Birthdate is required": "A data de nascimento é obrigatória",
        "Pay via boleto": "Pagar via boleto",
        "Accept proposal and pay with card": "Aceitar proposta e pagar com cartão",
        "To be able to chat with other users, you need to verify your email.": "Antes de contactar outros usuários, verifique seu e-mail.",
        "change your bio": "altere sua bio",
        "bio": "bio",
        "contact us": "contacte-nos",
        "Accept": "Aceitar",

        "Share": "Compartilhar",
        "Link copied!": "Link copiado!",
        "post image": "imagem do post",
        "delete your post": "exclua seu post",
        "Talent": "Talento",
        "Agent": "Agente",
        "Employer": "Contratante",
        "Crew": "Equipe Técninca",
        "Age": "Idade",
        "The field": "O campo",
        "headline": "headline",
        "violates our Terms & Conditions": "viola nossos Termos & Condições",
        "change your headline": "altere a sua headline",
        "Remove from home": "Remover da home",
        "Clear filters": "Limpar filtros",
        "Find your team":"Encontre seu time",
      }
    },
    es: {
      translations: {
        "Hey! Your new job is here!": "¡Oye! Su nuevo trabajo está aquí!",
        "Who saw my profile": "Quien vio mi perfil",
        "See our Premium Features": "Ver nuestras características Premium",
        "Chats": "chats",
        "Jobs": "Trabajos",
        "Notifications": "Notificaciones",
        "Profile": "Perfil",
        "Register": "Registrarse",
        "Login": "Acceso",
        "Email Address": "Dirección de correo electrónico",
        "Password": "Contraseña",
        "Remember me": "Recuérdame",
        "Sign In": "Registrarse",
        "Don't have an account? Sign Up": "¿No tienes una cuenta? Inscribirse",
        "Forgot password?": "¿Se te olvidó tu contraseña?",
        "OR": "O",
        "Sign in": "Registrarse",
        "Continue with Facebook": "Continuar con Facebook",
        "Continue with Google": "Continuar con Google",
        "Continue with Twitter": "Continuar con Twitter",
        "The password is invalid or the user does not have a password.": "La contraseña es inválida o el usuario no tiene contraseña.",
        "Email already associated with another account.": "Correo electrónico están asociados con otra cuenta.",
        "The email address is badly formatted.": "La dirección de correo electrónico está mal formateado.",
        "Do you already have an account? Sign In": "¿Ya tienes una cuenta? Registrarse",
        "Sign Up": "Inscribirse",
        "Full Name": "Nombre completo",
        "Welcome, looks like you're new here!": "Bienvenido, Parece que eres nuevo aquí!",
        "Don't worry, we are here help you to recover your password": "No se preocupe, estamos aquí ayudarle a recuperar su contraseña",
        "Create a page for a company or agency": "Crear una página para una compañía o agencia",
        "Welcome! We are glad to see you again.": "¡Bienvenido! Estamos encantados de verte de nuevo.",
        "Welcome! Looks like you're new here.": "¡Bienvenido! Parece que eres nuevo aquí.",
        "Recover password": "Recuperar contraseña",
        "Create a new account": "Crea una cuenta nueva",
        "Create ": "Crear",
        "a page for a company or agency": "una página para una compañía o agencia",
        "E-mail": "Correo electrónico",
        "Log in": "Acceso",
        "Welcome! Looks like you are new here.": "¡Bienvenido! Parece que son nuevos aquí.",
        "Create account": "Crear una cuenta",
        "error-user-null": "error-user-nulo",
        "Edit photo cover": "portada de edición de fotos",
        "Social Media": "Medios de comunicación social",
        "Bio": "Bio",
        "Experience": "Experiencia",
        "Details": "Detalles",
        "Employment Type": "Tipo de empleo",
        "Add experience": "Añadir experiencia",
        "Cancel": "Cancelar",
        "Save": "Ahorrar",
        "Title": "Título",
        "Company": "Empresa",
        "Site": "Sitio",
        "Location": "Localización",
        "Portfolio": "portafolio",
        "Categories": "categorías",
        "Hi! Include Jobs and let everyone know what you're capable of.": "¡Hola! Incluyen trabajos y que todos sepan lo que eres capaz de hacer.",
        "Edit profile": "Editar perfil",
        "Sign Out": "Desconectar",
        "Follow": "Seguir",
        "Replace banner image": "Reemplazar imagen de banner",
        "Ideal dimensions 1280px x 320px": "dimensiones ideales 1280px x 320px",
        "Change": "Cambio",
        "Upload Cover": "Subir cubierta",
        "Remove": "Eliminar",
        "Click to add a new category": "Haga clic para añadir una nueva categoría",
        "Click to edit this category": "Haga clic para editar esta categoría",
        "Delete": "Borrar",
        "Drag'n'drop images, audios and videos or click to select files": "Arrastrar y soltar imágenes, audios y videos o haga clic para seleccionar los archivos",
        "Add a new Post": "Añadir un nuevo puesto",
        "Write a caption...": "Escribir un subtitulo...",
        "New Post": "Nueva publicación",
        "Drag and drop an image here or click": "Arrastrar y soltar una imagen o haga clic aquí",
        "Post": "Correo",
        "Next": "próximo",
        "Click here to add new medias": "Haga clic aquí para añadir nuevos medios",
        "Click here to delete this media": "Haga clic aquí para eliminar estos medios",
        "Click here to capture a thumbnail": "Haga clic aquí para capturar una imagen en miniatura",
        "Drag'n'drop a image or click to select a file": "Arrastrar y soltar una imagen o haga clic para seleccionar un archivo",
        "Log Out": "Cerrar sesión",
        "Edit your profile cover": "Editar su perfil de cubierta",
        "Contact": "Contacto",
        "Edit": "Editar",
        "Close": "Cerrar",
        "Delete post": "Eliminar mensaje",
        "Do you really want to delete?": "¿De verdad quiere eliminar?",
        "Start Date must be less than End Date": "Fecha de inicio debe ser inferior a fecha de finalización",
        "Show less": "Muestra menos",
        "Show more": "Mostrar más",
        "No results": "No hay resultados",
        "Submit your search query.": "Enviar su consulta de búsqueda.",
        "Clear your search query.": "Despeje su consulta de búsqueda.",
        "Search here...": "Busca aquí...",
        "Search locations here...": "Buscar ubicaciones aquí ...",
        "Find your talent": "Encuentra tu talento",
        "Our community": "Nuestra comunidad",
        "Latest profiles": "perfiles recientes",
        "Beta - Welcome to the Matcheam family": "Beta - Bienvenido a la familia Matcheam",
        "The first complete search, scheduling, payment and career management platform for talent and contractors in the media and entertainment market.": "La primera plataforma completa de gestión de búsqueda, la programación, el pago y la carrera por el talento y contratistas en el mercado de medios de comunicación y entretenimiento.",
        "Actors, models, musicians, athletes, photographers, extras, influencers, and many others. Enter and feel at ease in the biggest global showcase of talent and opportunities.": "Actores, modelos, músicos, deportistas, fotógrafos, extras, personas influyentes, y muchos otros. Entrar y sentirse a gusto en el mayor escaparate mundial de talento y oportunidades.",
        "Hi! Include experiences and let everyone know what you're capable of.": "¡Hola! Incluya experiencias y deje que todos sepan de qué eres capaz.",
        "Delete Experience": "Eliminar experiencia",
        "Edit experience": "Editar Experiencia",
        "Start Date": "Fecha de inicio",
        "End Date": "Fecha final",
        "Latest highlights": "Últimos puntos destacados",
        "Testimony": "Testimonio",
        "Logout": "Cerrar sesión",
        "Accepted Invitations": "Invitaciones aceptadas",
        "General": "General",
        "Invites": "Invitaciones",
        "Actions": "Comportamiento",
        "Code": "Código",
        "Created at": "Creado en",
        "Expire": "Expirar",
        "Do you want to expire this invite?": "¿Quieres expirar esta invitación?",
        "Do you want to reactivate this invite?": "¿Quieres reactivar esta invitación?",
        "Reactivate": "Reactivar",
        "Expired": "Caducado",
        "Accepted at": "Aceptado en",
        "Invited": "Invitado",
        "Type": "Escribe",
        "New invite": "Nueva invitación",
        "Link to invitation copied to clipboard": "Enlace a la invitación copiada al portapapeles.",
        "By continuing, you agree to our ": "Al continuar, usted acepta nuestro",
        "Terms": "Condiciones",
        "Data Use Policy": "Política de uso de datos",
        " and ": "y",
        "Cookie Use": "Uso de cookies",
        "Select at least one media.": "Seleccione al menos un medio.",
        "Filters": "Filtros",
        "Locations": "Ubicaciones",
        "Category": "Categoría",
        "Privacy Policy": "Política de privacidad",
        "Terms & Conditions": "Términos y condiciones",
        "By continuing you accept our": "Al continuar aceptando nuestro",
        "and": "y",
        "Name": "Nombre",
        "Nickname": "Apodo",
        "Headline": "Titular",
        "Settings": "Ajustes",
        "WaitingForApproval": "A la espera de la aprobación",
        "WaitingToBeDone": "WAKENTOBEDONE",
        "Accepted": "Aceptado",
        "Rejected": "Rechazado",
        "Canceled": "Cancelado",
        "Accomplished": "Logrado",
        "Completed": "Terminado",
        "Waiting for approval": "A la espera de la aprobación",
        "Waiting to be done": "Esperando ser hecho",
        "Copy": "Dupdo",
        "View accepted invitations": "Ver invitaciones aceptadas",
        "Proposals": "Propuestas",
        "invite": "invitación",
        "Go to onboarding": "Ir a la vuelta",
        "In order to create deal proposals you need to go through our payments platform onboarding. Please register your bank data inorder to proceed.": "Para crear propuestas de ofertas que necesita para pasar por nuestra plataforma de pagos a bordo. Por favor, registre sus datos bancarios para continuar.",
        "Validation error, try again": "Error de validación, inténtalo de nuevo.",
        "Validating": "Validación",
        "This content is not available.": "Este contenido no está disponible.",
        "The content on this page is not available or the link you tried to access is not working.": "El contenido de esta página no está disponible o el enlace que intentó acceder no funciona.",
        "Back to Matcheam": "Volver a Matcheam",
        "Informations": "Informaciones",
        "To start your onboarding verify your e-mail first": "Para iniciar su ondulación, verifique su correo electrónico primero",
        "followers": "seguidores",
        "following": "siguiente",
        "posts": "publicación",
        "See the portfolio of": "Ver el portafolio de",
        "Invalid characters in nickname": "Caracteres inválidos en apodo",
        "You can not use this nickname.": "No puedes usar este apodo.",
        "Do you need help?": "¿Necesitas ayuda?",
        "Contact us": "Contáctenos",
        "Privacy": "Intimidad",
        "Type message here": "Escriba el mensaje aquí",
        "Create Proposal": "Crear propuesta",
        "View Details": "Ver detalles",
        "Status": "Estado",
        "Offer price": "Precio de oferta",
        "Impressum/Imprint": "Impressum / Impresión",
        "Back to Top": "Volver arriba",
        "Upload file": "Subir archivo",
        "Upload": "Subir",
        "Managed by": "Gestionado por",
        "Congratulations, you have been invited to be managed!": "Felicidades, ¡has sido invitado a ser manejado!",
        "Accept agency": "Aceptar la agencia",
        "Descline": "Desplificar",
        "wants to arrange you! To accept the proposal click on the button below": "¡Quiere organizar su carrera en Matcheam! Para aceptar la propuesta, haga clic en el botón de abajo.",
        "How does agency work on Matcheam?": "¿Cómo funciona la agencia en Matcheam?",
        "To be able to start the add your bank information you need to verify your email.": "Para poder iniciar la información de su banco, debe verificar su correo electrónico.",
        "Please select a country before adding yout bank information": "Seleccione un país antes de agregar la información bancaria de YoUT",
        "To be able to do this you need to verify your email.": "Para poder hacer esto, necesita verificar su correo electrónico.",
        "Request verification email": "Solicitar correo electrónico de verificación",
        "Verify your email": "verifica tu correo electrónico",
        "Add a new card": "Añadir una nueva tarjeta",
        "Payment methods": "Métodos de pago",
        "Proposal accepted successfully!": "¡La propuesta aceptada con éxito!",
        "Select a payment method": "Seleccione un método de pago",
        "To start receiving your payments, you must first include your bank account information.": "Para comenzar a recibir sus pagos, primero debe incluir la información de su cuenta bancaria.",
        "Germany": "Alemania",
        "Country": "País",
        "Choose a country to set up your payment informations": "Elija un país para configurar sus informaciones de pago.",
        "Drag and drop an file here or click": "Arrastre y suelte un archivo aquí o haga clic en",
        "- A link to your agent's profile will appear on your profile home page": "- Aparecerá un enlace al perfil de su agente en la página de inicio de su perfil",
        "Accept invite": "Aceptar invitación",
        "English": "Inglés",
        "Spanish": "Español",
        "Portuguese": "Portugués",
        "Deutschland": "Alemán",

        "Payment": "Pagamento",
        "Hide on search": "Ocultar en la búsqueda",
        "Cards": "Tarjetas",
        "Add or edit your cards to make payments": "Agregue o edite sus tarjetas para realizar pagos",
        "Add your bank information": "Agregue su información bancaria",
        "Paste your video link": "Pega el enlace de tu video (Ex: https://www.youtube.com/...)",
        "Talent": "Talento",
        "Agent": "Agente",
        "Employer": "Empleador",
        "Crew": "Equipo",

      }
    },
    de: {
      translations: {
        "Hey! Your new job is here!": "Hey! Ihre neue Aufgabe ist es hier!",
        "Who saw my profile": "Wer sah mein Profil",
        "See our Premium Features": "Sehen Sie unsere Premium-Features",
        "Chats": "Chats",
        "Jobs": "Arbeitsplätze",
        "Notifications": "Benachrichtigungen",
        "Profile": "Profil",
        "Register": "Registrieren",
        "Login": "Anmeldung",
        "Email Address": "E-Mail-Addresse",
        "Password": "Passwort",
        "Remember me": "Erinnere dich an mich",
        "Sign In": "Einloggen",
        "Don't have an account? Sign Up": "Sie haben noch kein Konto? Anmeldung",
        "Forgot password?": "Passwort vergessen?",
        "OR": "ODER",
        "Sign in": "Einloggen",
        "Continue with Facebook": "Weiter mit Facebook",
        "Continue with Google": "Fahren Sie mit Google",
        "Continue with Twitter": "Fahren Sie mit Twitter",
        "The password is invalid or the user does not have a password.": "Das Kennwort ist ungültig oder der Benutzer nicht über ein Passwort.",
        "Email already associated with another account.": "E-Mail bereits mit einem anderen Konto zugeordnet ist.",
        "The email address is badly formatted.": "Die E-Mail-Adresse ist falsch formatiert.",
        "Do you already have an account? Sign In": "Haben Sie bereits ein Konto? Einloggen",
        "Sign Up": "Anmeldung",
        "Full Name": "Vollständiger Name",
        "Welcome, looks like you're new here!": "Willkommen, sieht aus wie du bist neu hier!",
        "Don't worry, we are here help you to recover your password": "Mach dir keine Sorgen, wir sind hier helfen Sie Ihr Passwort wiederherstellen",
        "Create a page for a company or agency": "Erstellen Sie eine Seite für ein Unternehmen oder eine Agentur",
        "Welcome! We are glad to see you again.": "Willkommen! Wir freuen uns, Sie wieder zu sehen.",
        "Welcome! Looks like you're new here.": "Willkommen! Sieht so aus, als ob du neu hier bist.",
        "Recover password": "Passwort wiederherstellen",
        "Create a new account": "Ein neues Konto erstellen",
        "Create ": "Schaffen",
        "a page for a company or agency": "eine Seite für ein Unternehmen oder eine Agentur",
        "E-mail": "Email",
        "Log in": "Anmeldung",
        "Welcome! Looks like you are new here.": "Willkommen! Sieht so aus, als ob du neu hier bist.",
        "Create account": "Benutzerkonto erstellen",
        "error-user-null": "Fehler-user-null",
        "Edit photo cover": "Foto bearbeiten Abdeckung",
        "Social Media": "Sozialen Medien",
        "Bio": "Bio",
        "Experience": "Erfahrung",
        "Details": "Einzelheiten",
        "Employment Type": "Beschäftigungsverhältnis",
        "Add experience": "In Erfahrung",
        "Cancel": "Abbrechen",
        "Save": "Speichern",
        "Title": "Titel",
        "Company": "Gesellschaft",
        "Site": "Seite? ˅",
        "Location": "Standort",
        "Portfolio": "Mappe",
        "Categories": "Kategorien",
        "Hi! Include Jobs and let everyone know what you're capable of.": "Hi! Fügen Sie Jobs und jeder wissen lassen, was Sie fähig sind.",
        "Edit profile": "Profil bearbeiten",
        "Sign Out": "Austragen",
        "Follow": "Folgen",
        "Replace banner image": "Ersetzen Sie Banner-Bild",
        "Ideal dimensions 1280px x 320px": "Ideal Dimensionen 1280px x 320px",
        "Change": "Ändern",
        "Upload Cover": "Upload-Abdeckung",
        "Remove": "Entfernen",
        "Click to add a new category": "Klicken Sie auf eine neue Kategorie hinzufügen",
        "Click to edit this category": "Klicken Sie auf diese Kategorie bearbeiten",
        "Delete": "Löschen",
        "Drag'n'drop images, audios and videos or click to select files": "Drag'n'Drop Bilder, Audios und Videos oder klicken Sie auf Dateien auswählen",
        "Add a new Post": "Fügen Sie einen neuen Beitrag",
        "Write a caption...": "Schreibe eine Überschrift...",
        "New Post": "Neuer Beitrag",
        "Drag and drop an image here or click": "Drag-and-Drop ein Bild hier oder klicken",
        "Post": "Post",
        "Next": "Nächste",
        "Click here to add new medias": "Klicken Sie hier, um die neuen Medien hinzufügen",
        "Click here to delete this media": "Klicken Sie hier, um diese Medien zu löschen",
        "Click here to capture a thumbnail": "Klicken Sie hier, um eine Miniaturansicht zu erfassen",
        "Drag'n'drop a image or click to select a file": "Drag'n'Drop ein Bild oder klicken Sie auf eine Datei auszuwählen",
        "Log Out": "Ausloggen",
        "Edit your profile cover": "Bearbeiten Sie Ihr Profil Abdeckung",
        "Contact": "Kontakt",
        "Edit": "Bearbeiten",
        "Close": "Nah dran",
        "Delete post": "Beitrag löschen",
        "Do you really want to delete?": "Wollen Sie wirklich löschen?",
        "Start Date must be less than End Date": "Startdatum muss kleiner sein als Enddatum",
        "Show less": "Zeige weniger",
        "Show more": "Zeig mehr",
        "No results": "Keine Ergebnisse",
        "Submit your search query.": "Geben Sie Ihre Suchanfrage.",
        "Clear your search query.": "Löschen Sie Ihre Suchanfrage.",
        "Search here...": "Suche hier...",
        "Search locations here...": "Übersicht hier ...",
        "Find your talent": "Finden Sie Ihr Talent",
        "Our community": "Unsere Gemeinschaft",
        "Latest profiles": "Neuste Profile",
        "Beta - Welcome to the Matcheam family": "Beta - Willkommen in der Familie Matcheam",
        "The first complete search, scheduling, payment and career management platform for talent and contractors in the media and entertainment market.": "Die erste vollständige Suche, Planung, Bezahlung und Karrieremanagement-Plattform für Talente und Auftragnehmer in dem Medien- und Entertainment-Markt.",
        "Actors, models, musicians, athletes, photographers, extras, influencers, and many others. Enter and feel at ease in the biggest global showcase of talent and opportunities.": "Schauspieler, Models, Musiker, Sportler, Fotografen, Extras, Beeinflusser, und viele andere. Geben Sie und fühlen Sie sich wohl in dem größten globalen Schaufenster von Talent und Chancen.",
        "Hi! Include experiences and let everyone know what you're capable of.": "Hallo! Fügen Sie Erfahrungen ein und lassen Sie alle wissen, was Sie in der Lage sind.",
        "Delete Experience": "Erfahrung löschen",
        "Edit experience": "Erfahrung bearbeiten.",
        "Start Date": "Startdatum",
        "End Date": "Endtermin",
        "Latest highlights": "Neueste Highlights",
        "Testimony": "Zeugnis",
        "Logout": "Ausloggen",
        "Accepted Invitations": "Akzeptierte Einladungen",
        "General": "Allgemein",
        "Invites": "Lädt ein",
        "Actions": "Aktionen",
        "Code": "Code",
        "Created at": "Hergestellt in",
        "Expire": "Erlöschen",
        "Do you want to expire this invite?": "Möchten Sie diese Einladung ablaufen?",
        "Do you want to reactivate this invite?": "Möchten Sie diese Einladung wieder aktivieren?",
        "Reactivate": "Reaktivieren",
        "Expired": "Abgelaufen",
        "Accepted at": "Angenommen",
        "Invited": "Eingeladen",
        "Type": "Typ",
        "New invite": "Neue Einladung",
        "Link to invitation copied to clipboard": "Link zur Einladung, die in die Zwischenablage kopiert wurde",
        "By continuing, you agree to our ": "Indem Sie fortfahren, stimmen Sie unserem zu",
        "Terms": "Bedingungen",
        "Data Use Policy": "Datennutzungspolitik.",
        " and ": "und",
        "Cookie Use": "Keksnutzung.",
        "Select at least one media.": "Wählen Sie mindestens ein Medien aus.",
        "Filters": "Filter",
        "Locations": "Standorte",
        "Category": "Kategorie",
        "Privacy Policy": "Datenschutz-Bestimmungen",
        "Terms & Conditions": "Terms & amp; Bedingungen",
        "By continuing you accept our": "Wenn Sie fortfahren, akzeptieren Sie unsere",
        "and": "und",
        "Name": "Name",
        "Nickname": "Spitzname",
        "Headline": "Überschrift",
        "Settings": "Einstellungen",
        "WaitingForApproval": "Warten auf die Bestätigung",
        "WaitingToBeDone": "Wartebedone",
        "Accepted": "Akzeptiert",
        "Rejected": "Abgelehnt",
        "Canceled": "Abgesagt",
        "Accomplished": "Vollendet",
        "Completed": "Vollendet",
        "Waiting for approval": "warten auf die Bestätigung",
        "Waiting to be done": "Warten auf getan",
        "Copy": "Kopieren",
        "View accepted invitations": "Ansicht akzeptierte Einladungen",
        "Proposals": "Vorschläge",
        "invite": "einladen",
        "Go to onboarding": "Gehe zum Onboarding.",
        "In order to create deal proposals you need to go through our payments platform onboarding. Please register your bank data inorder to proceed.": "Um Deal-Vorschläge zu erstellen, müssen Sie unsere Zahlungsplattform an Bord fahren. Bitte registrieren Sie Ihre Bankdaten in ordnen.",
        "Validation error, try again": "Validierungsfehler, versuchen Sie es erneut",
        "Validating": "Validierung",
        "This content is not available.": "Dieser Inhalt ist nicht verfügbar.",
        "The content on this page is not available or the link you tried to access is not working.": "Der Inhalt auf dieser Seite ist nicht verfügbar, oder der Link, den Sie auf den Zugriff versucht haben, funktioniert nicht.",
        "Back to Matcheam": "Zurück nach Matcheam.",
        "Informations": "Information",
        "To start your onboarding verify your e-mail first": "Um das Onboarding zu starten, überprüfen Sie Ihre E-Mail zuerst",
        "followers": "Anhänger",
        "following": "folgen",
        "posts": "Beiträge",
        "See the portfolio of": "Sehen Sie das Portfolio von",
        "Invalid characters in nickname": "Ungültige Zeichen in Spitzname",
        "You can not use this nickname.": "Sie können diesen Spitznamen nicht verwenden.",
        "Do you need help?": "Brauchst du Hilfe?",
        "Contact us": "Kontaktiere uns",
        "Privacy": "Privatsphäre",
        "Type message here": "Geben Sie hier Nachricht ein",
        "Create Proposal": "Vorschlag erstellen",
        "View Details": "Details anzeigen",
        "Status": "Status",
        "Offer price": "Angebotspreis",
        "Impressum/Imprint": "Impressum / Impressum.",
        "Back to Top": "Zurück nach oben",
        "Upload file": "Datei hochladen",
        "Upload": "Hochladen",
        "Managed by": "Verwaltet von",
        "Congratulations, you have been invited to be managed!": "Herzlichen Glückwunsch, Sie wurden eingeladen, verwaltet zu werden!",
        "Accept agency": "Agentur akzeptieren.",
        "Descline": "Entziner",
        "wants to arrange you! To accept the proposal click on the button below": "will deine Karriere auf Matcheam arrangieren! Um den Vorschlag anzunehmen, klicken Sie auf die Schaltfläche unten",
        "How does agency work on Matcheam?": "Wie funktioniert die Agentur an Matcheam?",
        "To be able to start the add your bank information you need to verify your email.": "Um das Fügen Ihrer Bankinformationen zu starten, müssen Sie Ihre E-Mail bestätigen.",
        "Please select a country before adding yout bank information": "Bitte wählen Sie ein Land aus, bevor Sie Ihre Bankinformationen hinzufügen",
        "To be able to do this you need to verify your email.": "Um dies tun zu können, müssen Sie Ihre E-Mail bestätigen.",
        "Request verification email": "Bestätigungs-E-Mail anfordern",
        "Verify your email": "Bestätigen Sie Ihre E-Mail",
        "Add a new card": "Fügen Sie eine neue Karte hinzu",
        "Payment methods": "Zahlungsarten",
        "Proposal accepted successfully!": "Angebot erfolgreich angenommen!",
        "Select a payment method": "Wählen Sie eine Zahlungsmethode aus",
        "To start receiving your payments, you must first include your bank account information.": "Um Ihre Zahlungen zu erhalten, müssen Sie zuerst Ihre Bankkontoinformationen einschließen.",
        "Germany": "Deutschland",
        "Country": "Land",
        "Choose a country to set up your payment informations": "Wählen Sie ein Land aus, um Ihre Zahlungsinformationen einzurichten",
        "Drag and drop an file here or click": "Ziehen Sie eine Datei hier oder klicken Sie auf",
        "- A link to your agent's profile will appear on your profile home page": "- Ein Link zum Profil Ihres Agenten wird auf Ihrer Profil-Startseite angezeigt",
        "Accept invite": "Einladung annehmen",
        "English": "Englisch",
        "Spanish": "Spanisch",
        "Portuguese": "Portugiesisch",
        "Deutschland": "Deutschland.",

        "Payment": "Zahlung",
        "Hide on search": "Bei Suche ausblenden",
        "Cards": "Karten",
        "Add or edit your cards to make payments": "Karten hinzufügen oder bearbeiten, um Zahlungen zu tätigen",
        "Add your bank information": "Ihre Bankverbindung hinzufügen",
        "Paste your video link": "Fügen Sie Ihren Video-Link ein (Ex: https://www.youtube.com/...)",
        "Talent": "Talent",
        "Agent": "Agent",
        "Employer": "Arbeitgeber",
        "Crew": "Crew",
      }
    },
    fr: {
      translations: {
        "Hey! Your new job is here!": "Hey! Votre nouvel emploi est ici!",
        "Who saw my profile": "Qui a vu mon profil",
        "See our Premium Features": "Voir nos fonctionnalités Premium",
        "Chats": "chats",
        "Jobs": "Travaux",
        "Notifications": "notifications",
        "Profile": "Profil",
        "Register": "S'inscrire",
        "Login": "Connexion",
        "Email Address": "Adresse e-mail",
        "Password": "Mot de passe",
        "Remember me": "Souviens-toi de moi",
        "Sign In": "S'identifier",
        "Don't have an account? Sign Up": "Ne pas avoir un compte? S'inscrire",
        "Forgot password?": "Mot de passe oublié?",
        "OR": "OU",
        "Sign in": "S'identifier",
        "Continue with Facebook": "Continuer avec Facebook",
        "Continue with Google": "Continuer avec Google",
        "Continue with Twitter": "Continuer avec Twitter",
        "The password is invalid or the user does not have a password.": "Le mot de passe est invalide ou que l'utilisateur ne dispose pas d'un mot de passe.",
        "Email already associated with another account.": "E-mail déjà associé à un autre compte.",
        "The email address is badly formatted.": "L'adresse e-mail est mal formaté.",
        "Do you already have an account? Sign In": "Avez-vous déjà un compte? S'identifier",
        "Sign Up": "S'inscrire",
        "Full Name": "Nom et prénom",
        "Welcome, looks like you're new here!": "Bienvenue, Il semble que vous soyez nouveau!",
        "Don't worry, we are here help you to recover your password": "Ne vous inquiétez pas, nous sommes ici vous aider à récupérer votre mot de passe",
        "Create a page for a company or agency": "Créer une page pour une entreprise ou un organisme",
        "Welcome! We are glad to see you again.": "Bienvenue! Nous sommes heureux de vous revoir.",
        "Welcome! Looks like you're new here.": "Bienvenue! On dirait que vous êtes nouveau ici.",
        "Recover password": "Récupérer mot de passe",
        "Create a new account": "Créer un nouveau compte",
        "Create ": "Créer",
        "a page for a company or agency": "une page pour une entreprise ou un organisme",
        "E-mail": "E-mail",
        "Log in": "Connexion",
        "Welcome! Looks like you are new here.": "Bienvenue! On dirait que vous êtes nouveau ici.",
        "Create account": "Créer un compte",
        "error-user-null": "error-nulle de l'utilisateur",
        "Edit photo cover": "Modifier la couverture photo",
        "Social Media": "Des médias sociaux",
        "Bio": "Bio",
        "Experience": "Vivre",
        "Details": "Des détails",
        "Employment Type": "Type d'emploi",
        "Add experience": "Ajouter une expérience",
        "Cancel": "Annuler",
        "Save": "sauvegarder",
        "Title": "Titre",
        "Company": "Société",
        "Site": "Placer",
        "Location": "Emplacement",
        "Portfolio": "Portefeuille",
        "Categories": "Catégories",
        "Hi! Include Jobs and let everyone know what you're capable of.": "Salut! Inclure des emplois et que tout le monde savoir ce que vous êtes capable.",
        "Edit profile": "Editer le profil",
        "Sign Out": "Se déconnecter",
        "Follow": "Suivre",
        "Replace banner image": "Remplacer l'image bannière",
        "Ideal dimensions 1280px x 320px": "Dimensions idéales 1280px x 320px",
        "Change": "Changer",
        "Upload Cover": "Télécharger Cover",
        "Remove": "Supprimer",
        "Click to add a new category": "Cliquez pour ajouter une nouvelle catégorie",
        "Click to edit this category": "Cliquez pour modifier cette catégorie",
        "Delete": "Effacer",
        "Drag'n'drop images, audios and videos or click to select files": "Drag'n'Drop images, vidéos et audios ou cliquez pour sélectionner les fichiers",
        "Add a new Post": "Ajouter un nouveau message",
        "Write a caption...": "Écrivez une légende ...",
        "New Post": "Nouveau poste",
        "Drag and drop an image here or click": "Glissez-déposez une image ou cliquez ici",
        "Post": "Poster",
        "Next": "Prochain",
        "Click here to add new medias": "Cliquez ici pour ajouter de nouveaux médias",
        "Click here to delete this media": "Cliquez ici pour supprimer ce média",
        "Click here to capture a thumbnail": "Cliquez ici pour capturer une vignette",
        "Drag'n'drop a image or click to select a file": "Drag'n'Drop une image ou cliquez pour sélectionner un fichier",
        "Log Out": "Se déconnecter",
        "Edit your profile cover": "Modifier la couverture de votre profil",
        "Contact": "Contact",
        "Edit": "Éditer",
        "Close": "proche",
        "Delete post": "Supprimer le message",
        "Do you really want to delete?": "Voulez-vous vraiment supprimer?",
        "Start Date must be less than End Date": "Date de début doit être inférieur à Date de fin",
        "Show less": "Montrer moins",
        "Show more": "Montre plus",
        "No results": "Aucun résultat",
        "Submit your search query.": "Déposez votre demande de recherche.",
        "Clear your search query.": "Effacer votre requête de recherche.",
        "Search here...": "Cherche ici...",
        "Search locations here...": "Rechercher endroits ici ...",
        "Find your talent": "Trouvez votre talent",
        "Our community": "Notre communauté",
        "Latest profiles": "Les profils récents",
        "Beta - Welcome to the Matcheam family": "Beta - Bienvenue dans la famille Matcheam",
        "The first complete search, scheduling, payment and career management platform for talent and contractors in the media and entertainment market.": "La première recherche complète, la planification, le paiement et la plate-forme de gestion de carrière pour les talents et les entrepreneurs sur le marché des médias et du divertissement.",
        "Actors, models, musicians, athletes, photographers, extras, influencers, and many others. Enter and feel at ease in the biggest global showcase of talent and opportunities.": "Acteurs, mannequins, musiciens, athlètes, photographes, figurants, influenceurs, et bien d'autres. Entrez et se sentir à l'aise dans la plus grande vitrine mondiale des talents et des opportunités.",
      }
    }
  },
  fallbackLng: "en",
  debug: process.env.NODE_ENV === "development",

  whitelist: availableLanguages.map(a => { return a.value }),
  supportedLngs: availableLanguages.map(a => { return a.value }),

  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },
  detection: detectionOptions,

  react: {
    wait: true,
    useSuspense: true
  }
};


i18n.use(LanguageDetector).init(options);


export default i18n;
import { Button, Container, Grid, makeStyles, Paper } from "@material-ui/core";



import logoMatcheam from "../../images/marca/logo.png";

import LoginText from "./LoginText";
import { ILoginProp } from "./LoginProp";
import LoginForm from "./LoginForm/LoginForm";
import Register from "./Register/Register";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import bgLogin from "../../images/bg_home.jpg";
import ReadTermsAndPrivacyPolicy from "./ReadTermsAndPrivacyPolicy/ReadTermsAndPrivacyPolicy";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useProfileContext } from "../../ProfileContext";
import InviteController from "./InviteController";
import AnalyticsHelper from "../../infra/analytics/analyticsHelper";
import { useEffect, useState } from "react";
import Invite from "../../core/entities/Invite";
import Profile from "../../core/entities/Profile";
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  backButtons: {
    margin: '0 auto',
    width: '22%',
  },
  btnOne: {
    marginTop: '15%',
    width: '100%',
    backgroundColor: '#454545',
  },
  btnTwo: {
    width: '100%',
    marginTop: '2%',
    backgroundColor: 'grey'
  },
  boxProfilesLink: {
    width: '35%',
    paddingTop: '5%',
    margin: '0 auto',
  },
  photoProfileInviter: {
    display: 'inline-block',
    width: '60px',
    height: '60px',
    borderRadius: '100%',
    backgroundColor: '#141414 ',
    float: 'left',
    backgroundImage: 'url("https://cdn.discordapp.com/attachments/662383701434564659/918936453998850088/Design_sem_nome_1.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  photoProfileRecive: {
    display: 'inline-block',
    width: '60px',
    height: '60px',
    borderRadius: '100%',
    backgroundColor: '#141414 ',
    float: 'right',
    backgroundImage: 'url("https://cdn.discordapp.com/attachments/662383701434564659/918936453998850088/Design_sem_nome_1.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  dividerInviters: {
    marginLeft: '13px',
    marginRight: '13px',
    width: '30%',
    height: '30px',
    margin: '0 auto',
    display: 'inline-block',
    borderBottom: '3px dashed #141414',
  },
  photoHand: {
    marginLeft: '-13px',
    marginTop: '10px',
    position: 'absolute',
    width: '25px',
    height: '25px',
    display: 'inline-block',
    backgroundImage: 'url("https://cdn.discordapp.com/attachments/662383701434564659/919962604586274816/Design_sem_nome_2.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  boxMsgOne: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '6.999%',
  },
  msgOne: {
    fontSize: 30,
    color: 'black',
    width: '100%',
  },
  boxMsgTwo: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '6.999%',
  },
  msgTwo: {
    fontSize: 17,
    color: 'black',
    width: '100%',
  },
  nameInviter: {
    color: '#141414',
    fontSize: 17,
  },
  boxInfoAgency: {
    width: '100%',
    marginTop: '30px',
    fontSize: 17,
  },
  infoTitle: {
    color: 'black',
  },
  infoDescr: {
    color: 'black',
    width: '50%',
  },

}));
const inviteController = new InviteController();
const analyticsHelper = new AnalyticsHelper();





const AcceptInvite = () => {
  const classes = useStyles();
  const history = useHistory()
  const location = useLocation<{ token: string }>()
  const { profile } = useProfileContext()
  const [data, setData] = useState<{ profile: Profile, invite: Invite }>({} as { profile: Profile, invite: Invite })
  const [error, setError] = useState("");
  const [loadingInvite, setLoadingInvite] = useState(true);

  const [messages, setMessages] = useState<{
    principalText?: string,
    secondText?: string,
    howItWorksTitle?: string,
    howItWorksText?: string,
    acceptButton?: string
  }>({});

  async function handleAccept() {
    if (profile?.id) {
      await inviteController.accept(profile?.id, location.state.token);
      analyticsHelper.logEvent('token_accepted');
      history.push('/')
    }
  }

  function handleRefused() {
    history.push('/')
    // analyticsHelper.logEvent('token_accepted');
  }

  const getInfo = () => {
    setLoadingInvite(true);

    inviteController.getInfo(location.state.token)
      .then((data) => {
        setData(data)
        setMessages(getMessages(data.invite));
        setLoadingInvite(false);
      }).catch(error => {
        setError(error);
        setLoadingInvite(false);
      });
  }

  useEffect(() => {
    getInfo();
  }, [])

  const getMessages = (invite: Invite) => {

    let result = {
      principalText: "",
      secondText: "",
      howItWorksTitle: "",
      howItWorksText: "",
      acceptButton: ""
    };

    switch (invite.type) {
      case "common":
        result = {
          principalText: t("Congratulations, you have been invited to Matcheam!"),
          secondText: t("invited you! To accept click on the button below"),
          howItWorksTitle: "",
          howItWorksText: "",
          acceptButton: t("Accept invite")
        };
        break;
      case "agency":
        result = {
          principalText: t("Congratulations, you have been invited to be part of a team!"),
          secondText: t("wants to arrange you! To accept the proposal click on the button below"),
          howItWorksTitle: t("How does a team works on Matcheam?"),
          howItWorksText: t("- A link to your team's profile will appear on your profile home page"),
          acceptButton: t("Accept")
        };
        break;
      default:
        break;
    }


    return result;
  }



  const { t, i18n } = useTranslation();

  return (

    <Container>

      <div style={{ textAlign: "center", color: "red" }}>
        {error}
      </div>

      {(!loadingInvite && !error) &&
        <div className={classes.boxProfilesLink}>
          <div className={classes.photoProfileInviter}
            style={{
              backgroundImage: `url(${data?.profile?.profileImageUrl})`
            }}
          >

          </div>
          <div className={classes.photoProfileRecive}
            style={profile?.profileImageUrl ? {
              backgroundImage: `url(${profile?.profileImageUrl})`
            } : {}}
          >

          </div>
          <div className={classes.dividerInviters}></div>
          <div className={classes.photoHand}></div>
          <div className={classes.dividerInviters}></div>
        </div>
      }

      {(!loadingInvite && !error) &&
        <div>
          <div className={classes.boxMsgOne}>
            <span className={classes.msgOne}>
              {messages.principalText}
            </span>
          </div>

          <div className={classes.boxMsgTwo}>
            <span className={classes.nameInviter}>
              <Link to={`/${data?.profile?.nickname}`}>@{data?.profile?.nickname}</Link>
            </span>
            <span className={classes.msgTwo}>
              &nbsp;{messages.secondText}

            </span>
          </div>
        </div>
      }

      {(!loadingInvite && !error) &&
        <div className={classes.backButtons}>
          <Button
            className={classes.btnOne}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleAccept}>
            {messages.acceptButton}
          </Button>
          <br />
          <Button
            className={classes.btnTwo}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleRefused}>
            {t("Descline")}
          </Button>
        </div>
      }
      {(!loadingInvite && !error) &&
        <div className={classes.boxInfoAgency}>
          <span className={classes.infoTitle}>{messages.howItWorksTitle}</span>

          <div className={classes.infoDescr}>
            <br />
            <span>
              {messages.howItWorksText}

            </span>
          </div>
        </div>
      }
    </Container>

  );
};

export default AcceptInvite;

import { createElement, useEffect, useRef, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { connectAutoComplete } from 'react-instantsearch-dom';
import { autocomplete } from '@algolia/autocomplete-js';
import { render } from 'react-dom';

export const Autocomplete = (props) => {
    const containerRef = useRef(null);

    useEffect(() => {
        if (!containerRef.current) {
            return undefined;
        }
        const search = autocomplete({
            container: containerRef.current,
            renderer: { createElement, Fragment },
            render({ children }, root) {
                render(<div>{children}</div>, root);
            },
            ...props,
        });

        return () => {
            search.destroy();
        };
    }, [props]);

    return <div ref={containerRef} />;
}



const SearchAutoComplete = connectAutoComplete(Autocomplete);

export default SearchAutoComplete;